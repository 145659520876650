import LightBtn from 'components/global/Buttons/LightBtn'
import GlobalModal from 'components/global/GlobalModal'
import Page from 'components/global/Page'
import { MoveLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { useNavigate, useParams } from 'react-router-dom'
import AdminHeader from './AdminHeader'
import GlobalRadioButton from 'components/global/GlobalRadioButton'
import useStaff from '_hooks/useStaff'
import useAuth from '_hooks/useAuth'

const AdminStaffDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    staffs: { rows },
    position: { rows: postitonRows },
    grade: { rows: gradeRows },
    country: { rows: countryRows },
    team: { rows: teamRows },
    editMember,
  } = useStaff()
  const { user } = useAuth()

  const [deleteStaff, setDeleteStaff] = useState(false)
  const handleDeleteStaffModal = () => setDeleteStaff(!deleteStaff)
  const staff = rows?.find((row) => row.id === id)

  const [payload, setPayload] = useState(null)

  useEffect(() => {
    setPayload({
      memberId: staff?.id,
      gradeId: staff?.grade?.id,
      teamId: staff?.team?.id,
      positionId: staff?.position?.id,
      countryId: staff?.country?.id,
    })
  }, [staff])
  const [isValid, setIsvalid] = useState(staff?.deletedAt === null)
  const [password, setPassword] = useState('')

  useEffect(() => {
    setIsvalid(staff?.deletedAt === null)
  }, [staff])

  const handleUpdate = () => {
    editMember([payload], true)
  }

  const handleRadioChange = (bool) => {
    setIsvalid(bool)
  }

  return (
    <Page>
      {/* header */}
      <div id='header' className='w-full'>
        <AdminHeader />
      </div>
      {/* COntent */}
      <div className='flex flex-col gap-y-3'>
        {/* Header */}
        <div className='w-full flex gap-y-3 items-center justify-between'>
          <div className='flex gap-x-2 items-center'>
            <LightBtn onClick={() => navigate('/admin/staff-list')}>
              <MoveLeft size={16} />
            </LightBtn>
            <h1 className='text-base font-bold '>{staff?.fullName}</h1>
          </div>
          <div className='grid grid-cols-2 gap-x-2 py-4'>
            <button
              onClick={() => {
                navigate(`/admin/staff-list/${id}/action-history`)
              }}
              className='py-2 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold'
            >
              View Action History
            </button>
            <button
              onClick={handleDeleteStaffModal}
              className='py-2 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'
            >
              Delete Staff
            </button>
          </div>
        </div>
        {/* contents */}
        <div className='rounded-xl shadow-customBtnShadow p-6 flex flex-col gap-y-4 '>
          <div className='grid grid-cols-2 gap-6 auto-rows-fr'>
            <div className='flex flex-col gap-y-3'>
              <h5 className='text-base font-semibold'>Log In ID</h5>
              <input
                type='text'
                value={staff?.email}
                disabled
                className='bg-transparent py-1.5 px-3 rounded-xl border border-gray-300 text-sm font-medium outline-none'
              />
              <div className='flex gap-x-2'>
                <GlobalRadioButton
                  label='Valid'
                  name='shapeGroup'
                  value='square'
                  checked={isValid}
                  onChange={() => handleRadioChange(true)}
                  shape='md'
                />
                <GlobalRadioButton
                  label='Invalid'
                  name='shapeGroup'
                  value='square'
                  checked={!isValid}
                  onChange={() => handleRadioChange(false)}
                  shape='md'
                />
                {/* <span className='flex flex-nowrap gap-x-2 text-sm font-medium'>
                  <input type='radio' name='status' className='radio-btn' />{' '}
                  Valid
                </span>
                <span className='flex flex-nowrap gap-x-2 text-sm font-medium'>
                  <input type='radio' name='status' className='radio-btn' />
                  Invalid
                </span> */}
              </div>
            </div>
            <div className='flex flex-col gap-y-3'>
              <h5 className='text-base font-semibold'>Team</h5>
              <select
                className='bg-transparent py-1.5 px-3 rounded-xl border border-gray-300 text-sm font-medium outline-none'
                onChange={(e) =>
                  setPayload((prev) => ({ ...prev, teamId: e.target.value }))
                }
                value={payload?.teamId}
              >
                {teamRows?.map((grade) => {
                  return (
                    <option key={grade?.id} value={grade?.id}>
                      {grade?.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='flex flex-col gap-y-3'>
              <h5 className='text-base font-semibold'>Position</h5>
              <select
                className='bg-transparent py-1.5 px-3 rounded-xl border border-gray-300 text-sm font-medium outline-none'
                onChange={(e) =>
                  setPayload((prev) => ({
                    ...prev,
                    positionId: e.target.value,
                  }))
                }
                value={payload?.positionId}
              >
                {postitonRows?.map((grade) => {
                  return (
                    <option key={grade?.id} value={grade?.id}>
                      {grade?.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='flex flex-col gap-y-3'>
              <h5 className='text-base font-semibold'>Grade</h5>
              <select
                className='bg-transparent py-1.5 px-3 rounded-xl border border-gray-300 text-sm font-medium outline-none'
                onChange={(e) =>
                  setPayload((prev) => ({ ...prev, gradeId: e.target.value }))
                }
                value={payload?.gradeId}
              >
                {gradeRows?.map((grade) => {
                  return (
                    <option key={grade?.id} value={grade?.id}>
                      {grade?.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='flex flex-col gap-y-3'>
              <h5 className='text-base font-semibold'>Country</h5>
              <select
                className='bg-transparent py-1.5 px-3 rounded-xl border border-gray-300 text-sm font-medium outline-none'
                onChange={(e) =>
                  setPayload((prev) => ({ ...prev, countryId: e.target.value }))
                }
                value={payload?.countryId}
              >
                {countryRows?.map((grade) => {
                  return (
                    <option key={grade?.id} value={grade?.id}>
                      {grade?.name}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='flex justify-center items-center gap-x-4 py-4 col-span-2'>
              {user?.authorityName?.includes('staff_editing') && (
                <button
                  onClick={handleUpdate}
                  className='py-2 w-36 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold'
                >
                  Save
                </button>
              )}
              <button
                onClick={() => navigate('/admin/staff-list')}
                className='py-2 w-36 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* Staff Delete Modal  */}
        <GlobalModal
          isOpen={deleteStaff}
          handleClose={handleDeleteStaffModal}
          height={450}
        >
          <div className='flex flex-col items-center gap-y-10 h-full justify-center'>
            {/* Icon */}
            <div className='flex flex-col text-center gap-y-4'>
              <div className='flex justify-center items-center w-100'>
                <GoAlert className='bg-primary-200 p-3 text-teal-500 text-8xl' />
              </div>
              {/* text */}
              <div className='flex flex-col items-center gap-y-1'>
                <h5 className='font-semibold'>Final Confirmation</h5>
                <h6 className='font-medium text-md'>
                  Are you sure that you want to delete this staff?
                </h6>
                <small className='font-medium text-xs'>
                  *As soon as the staff is deleted, all data can not be
                  restored.
                </small>
              </div>
            </div>
            {/* input */}
            <div className='text-center w-full flex flex-col items-center'>
              <h6 className='text-sm mb-2'>Enter Passcode</h6>
              <input
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Enter Password'
                required
                className='mt-1 w-5/6 px-3 text-lg py-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
              />
            </div>
            <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
              <button className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'>
                Yes
              </button>
              <button
                className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
                onClick={handleDeleteStaffModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </GlobalModal>
      </div>
    </Page>
  )
}

export default AdminStaffDetails
