import React, { Fragment, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import GlobalAccordion from 'components/global/GlobalAccordion'

const HashtagFilter = ({ onClose, payload, setPayload }) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  useEffect(() => {
    setFromDate(payload?.from)
    setToDate(payload?.to)
  }, [payload])

  const handleApplyFilter = () => {
    let payload = {}

    setPayload({
      ...payload,
      from: fromDate,
      to: toDate,
    })
    onClose()
  }
  return (
    <Fragment>
      <div className='h-[226px] overflow-y-auto megaFilterScroll bg-universal '>
        {/* creation Date */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Creation Date</h6>}
          defaultExpanded={true}
          disableToggle={true}
          content={
            <div className='min-w-[16rem]'>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  From:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  To:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
            </div>
          }
        />
      </div>

      {/* apply and cancel button  */}
      <div className='flex gap-x-4 justify-center mt-3 mb-5'>
        <button
          onClick={handleApplyFilter}
          className='py-2 w-2/6  rounded-xl bg-text-dm-input shadow-buttonPrimary text-white'
        >
          Apply
        </button>
        <button
          onClick={onClose}
          className='border border-text-dm-input py-2 w-2/6 rounded-xl text-gray-400'
        >
          Cancel
        </button>
      </div>
    </Fragment>
  )
}

HashtagFilter.propTypes = {
  onClose: PropsTypes.func,
  payload: PropsTypes.object,
  setPayload: PropsTypes.func,
}

export default HashtagFilter
