export const userTableData = [
  {
    id: 1,
    username: 'user1',
    badge: 'online',
    displayname: 'John Doe',
    emailAddress: 'john.doe@example.com',
    birthday: '1990-01-15',
    age: 33,
    gender: 'Male',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'JavaScript',
    writing: 'English',
    creationDate: '2023-07-01',
    lastlogin: '2023-07-19',
    report: 2,
    reported: 1,
    banned: 0,
  },
  {
    id: 2,
    username: 'user2',
    badge: 'logout',
    displayname: 'Jane Smith',
    emailAddress: 'jane.smith@example.com',
    birthday: '1985-05-20',
    age: 38,
    gender: 'Female',
    country: 'Canada',
    appLanguage: 'French',
    devlanguage: 'Python',
    writing: 'French',
    creationDate: '2023-06-18',
    lastlogin: '2023-07-18',
    report: 0,
    reported: 0,
    banned: 1,
  },
  {
    id: 3,
    username: 'user3',
    badge: 'deactivate',
    displayname: 'Robert Johnson',
    emailAddress: 'robert.johnson@example.com',
    birthday: '1982-12-03',
    age: 40,
    gender: 'Male',
    country: 'UK',
    appLanguage: 'English',
    devlanguage: 'Java',
    writing: 'English',
    creationDate: '2023-07-05',
    lastlogin: '2023-07-20',
    report: 1,
    reported: 0,
    banned: 0,
  },
  {
    id: 4,
    username: 'user4',
    badge: 'deletedbyuser',
    displayname: 'Emily Brown',
    emailAddress: 'emily.brown@example.com',
    birthday: '1995-08-10',
    age: 28,
    gender: 'Female',
    country: 'Australia',
    appLanguage: 'English',
    devlanguage: 'C#',
    writing: 'Canada',
    creationDate: '2023-07-10',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 2,
    banned: 1,
  },
  {
    id: 5,
    username: 'user5',
    badge: 'suspension',
    displayname: 'Michael Lee',
    emailAddress: 'michael.lee@example.com',
    birthday: '1998-03-25',
    age: 25,
    gender: 'Male',
    country: 'Canada',
    appLanguage: 'English',
    devlanguage: 'C++',
    writing: 'English',
    creationDate: '2023-06-30',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 0,
    banned: 0,
  },
  {
    id: 6,
    username: 'user6',
    badge: 'deletion',
    displayname: 'Sophia Wilson',
    emailAddress: 'sophia.wilson@example.com',
    birthday: '1989-11-01',
    age: 33,
    gender: 'Female',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'Ruby',
    writing: 'canada',
    creationDate: '2023-07-02',
    lastlogin: '2023-07-19',
    report: 1,
    reported: 1,
    banned: 0,
  },
  {
    id: 7,
    username: 'user7',
    badge: 'online',
    displayname: 'William Jones',
    emailAddress: 'william.jones@example.com',
    birthday: '1992-09-12',
    age: 31,
    gender: 'Male',
    country: 'UK',
    appLanguage: 'English',
    devlanguage: 'PHP',
    writing: 'English',
    creationDate: '2023-07-11',
    lastlogin: '2023-07-20',
    report: 0,
    reported: 0,
    banned: 0,
  },
  {
    id: 8,
    username: 'user8',
    badge: 'logout',
    displayname: 'Olivia Martinez',
    emailAddress: 'olivia.martinez@example.com',
    birthday: '1997-04-18',
    age: 26,
    gender: 'Female',
    country: 'Spain',
    appLanguage: 'Spanish',
    devlanguage: 'TypeScript',
    writing: 'French',
    creationDate: '2023-07-03',
    lastlogin: '2023-07-18',
    report: 0,
    reported: 1,
    banned: 1,
  },
  {
    id: 9,
    username: 'user9',
    badge: 'deletion',
    displayname: 'Daniel Anderson',
    emailAddress: 'daniel.anderson@example.com',
    birthday: '1987-06-29',
    age: 36,
    gender: 'Male',
    country: 'Canada',
    appLanguage: 'English',
    devlanguage: 'Swift',
    writing: 'canada',
    creationDate: '2023-06-27',
    lastlogin: '2023-07-20',
    report: 3,
    reported: 0,
    banned: 0,
  },
  {
    id: 10,
    username: 'user10',
    badge: 'online',
    displayname: 'Ava Adams',
    emailAddress: 'ava.adams@example.com',
    birthday: '2000-02-05',
    age: 23,
    gender: 'Female',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'Kotlin',
    writing: 'English',
    creationDate: '2023-06-22',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 0,
    banned: 0,
  },
  {
    id: 11,
    username: 'user1',
    badge: 'online',
    displayname: 'John Doe',
    emailAddress: 'john.doe@example.com',
    birthday: '1990-01-15',
    age: 33,
    gender: 'Male',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'JavaScript',
    writing: 'French',
    creationDate: '2023-07-01',
    lastlogin: '2023-07-19',
    report: 2,
    reported: 1,
    banned: 0,
  },
  {
    id: 12,
    username: 'user2',
    badge: 'logout',
    displayname: 'Jane Smith',
    emailAddress: 'jane.smith@example.com',
    birthday: '1985-05-20',
    age: 38,
    gender: 'Female',
    country: 'Canada',
    appLanguage: 'French',
    devlanguage: 'Python',
    writing: 'South',
    creationDate: '2023-06-18',
    lastlogin: '2023-07-18',
    report: 0,
    reported: 0,
    banned: 1,
  },
  {
    id: 13,
    username: 'user3',
    badge: 'deactivate',
    displayname: 'Robert Johnson',
    emailAddress: 'robert.johnson@example.com',
    birthday: '1982-12-03',
    age: 40,
    gender: 'Male',
    country: 'UK',
    appLanguage: 'English',
    devlanguage: 'Java',
    writing: 'Jarvish',
    creationDate: '2023-07-05',
    lastlogin: '2023-07-20',
    report: 1,
    reported: 0,
    banned: 0,
  },
  {
    id: 14,
    username: 'user4',
    badge: 'deletedbyuser',
    displayname: 'Emily Brown',
    emailAddress: 'emily.brown@example.com',
    birthday: '1995-08-10',
    age: 28,
    gender: 'Female',
    country: 'Australia',
    appLanguage: 'English',
    devlanguage: 'C#',
    writing: 'Canada',
    creationDate: '2023-07-10',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 2,
    banned: 1,
  },
  {
    id: 15,
    username: 'user5',
    badge: 'suspension',
    displayname: 'Michael Lee',
    emailAddress: 'michael.lee@example.com',
    birthday: '1998-03-25',
    age: 25,
    gender: 'Male',
    country: 'Canada',
    appLanguage: 'English',
    devlanguage: 'C++',
    writing: 'English',
    creationDate: '2023-06-30',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 0,
    banned: 0,
  },
  {
    id: 16,
    username: 'user6',
    badge: 'deletion',
    displayname: 'Sophia Wilson',
    emailAddress: 'sophia.wilson@example.com',
    birthday: '1989-11-01',
    age: 33,
    gender: 'Female',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'Ruby',
    writing: 'Phillipins',
    creationDate: '2023-07-02',
    lastlogin: '2023-07-19',
    report: 1,
    reported: 1,
    banned: 0,
  },
  {
    id: 17,
    username: 'user7',
    badge: 'online',
    displayname: 'William Jones',
    emailAddress: 'william.jones@example.com',
    birthday: '1992-09-12',
    age: 31,
    gender: 'Male',
    country: 'UK',
    appLanguage: 'English',
    devlanguage: 'PHP',
    writing: 'English',
    creationDate: '2023-07-11',
    lastlogin: '2023-07-20',
    report: 0,
    reported: 0,
    banned: 0,
  },
  {
    id: 18,
    username: 'user8',
    badge: 'logout',
    displayname: 'Olivia Martinez',
    emailAddress: 'olivia.martinez@example.com',
    birthday: '1997-04-18',
    age: 26,
    gender: 'Female',
    country: 'Spain',
    appLanguage: 'Spanish',
    devlanguage: 'TypeScript',
    writing: 'Swedish',
    creationDate: '2023-07-03',
    lastlogin: '2023-07-18',
    report: 0,
    reported: 1,
    banned: 1,
  },
  {
    id: 19,
    username: 'user9',
    badge: 'deletion',
    displayname: 'Daniel Anderson',
    emailAddress: 'daniel.anderson@example.com',
    birthday: '1987-06-29',
    age: 36,
    gender: 'Male',
    country: 'Canada',
    appLanguage: 'English',
    devlanguage: 'Swift',
    writing: 'English',
    creationDate: '2023-06-27',
    lastlogin: '2023-07-20',
    report: 3,
    reported: 0,
    banned: 0,
  },
  {
    id: 20,
    username: 'user10',
    badge: 'online',
    displayname: 'Ava Adams',
    emailAddress: 'ava.adams@example.com',
    birthday: '2000-02-05',
    age: 23,
    gender: 'Female',
    country: 'USA',
    appLanguage: 'English',
    devlanguage: 'Kotlin',
    writing: 'Canada',
    creationDate: '2023-06-22',
    lastlogin: '2023-07-19',
    report: 0,
    reported: 0,
    banned: 0,
  },
]

export const MegaFilterAgeData = [
  // 'Teens',
  // '40s',
  // '20s',
  // '50s',
  // '30s',
  // 'Over 60s'
  {
    label: 'Teens',
    value: 'teen',
  },
  {
    label: '40s',
    value: 's40',
  },
  {
    label: '20s',
    value: 's20',
  },
  {
    label: '50s',
    value: 's50',
  },
  {
    label: '30s',
    value: 's30',
  },
  {
    label: 'Over 60s',
    value: 'over_60s',
  },
]

// ['Male', 'Non-binary', 'Female', 'No answer']
export const genderEnums = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Non-binary',
    value: 'non_binary',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'No answer',
    value: 'no_answer',
  },
]

export const userWithIcon = [
  {
    label: 'Online',
    field: 'online',
    value: 'online',
  },
  {
    label: 'Offline',
    field: 'logout',
    value: 'logout',
  },
  {
    label: 'Deactivate',
    field: 'deactivated',
    value: 'deactivated',
  },
  {
    label: 'Account deletion',
    field: 'deletion',
    value: 'deletion',
  },
  {
    label: 'Banned Account',
    field: 'account_deletion',
    value: 'Banned',
  },
  {
    label: 'Account suspension',
    field: 'account_suspension',
    value: 'account_suspension',
  },
]

export const hashtagtabledata = [
  {
    id: 1,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 2,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 3,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 4,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 5,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 6,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 7,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 8,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 9,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 10,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
  {
    id: 11,
    hashtagName: '#mathematiques',
    followersData: '50.6K',
    hashagposts: '1.5M',
    language: 'French',
    memo: '100Q (120A)',
    creationDate: '30/05/2023',
  },
]

export const cardsData = [
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
  {
    imgSrc:
      'https://assets.weforum.org/community/image/aeizQ7QVDy6LQDxpSflZ6IAkF18wHNx9Dt_UxYQ3ExM.jpg',
    name: 'Richardson.A',
    data: 'user02145879',
    userImg:
      'https://media.istockphoto.com/id/1418146721/photo/photo-of-charming-cool-young-man-wear-white-shirt-dark-eyewear-dancing-isolated-pink-color.webp?b=1&s=170667a&w=0&k=20&c=nLdq5v6vYPsozZQPt8gJF4M_zw3tm9HKoetwC07lcTg=',
  },
]

export const ReportData = [
  {
    id: 1,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'pending',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 4,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    TargetDataType: 'post',
  },
  {
    id: 2,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: '-',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 4,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    TargetDataType: 'user',
  },
]

// Commetns

export const Comments = [
  {
    username: 'Kate. W',
    comment: 'I love it!',
    date: '11/08/2023',
    subComment: [
      {
        username: 'Me',
        comment: 'Thanks!',
        date: '01/08/2023',
      },
    ],
  },
  {
    username: 'Ray. J',
    comment: 'Cool! Where did you learn this?',
    date: '11/08/2023',
    subComment: [
      {
        username: 'Me',
        comment: 'Well, I learned it in U.S.',
        date: '01/08/2023',
      },
      {
        username: 'Ray. J',
        comment: 'Nice !',
        date: '01/08/2023',
      },
    ],
  },
  {
    username: 'Jenny. T',
    comment: 'I learned so much.',
    date: '11/08/2023',
    subComment: [
      {
        username: 'Me',
        comment: 'My pleasure',
        date: '01/08/2023',
      },
      {
        username: 'Supertech',
        comment: 'Nice !',
        date: '01/08/2023',
      },
    ],
  },
  {
    username: 'io-data',
    comment: 'Hi, I have a question. Do you have a time for it?',
    date: '11/08/2023',
    subComment: [],
  },
]
// Home data
export const HomeData = [
  {
    id: 1,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'pending',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 5,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    language: 'English',
    memoType: 'post',
    lock: false,
  },
  {
    id: 2,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'approved',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 10,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    language: 'English',
    memoType: 'post',
    lock: true,
  },
  {
    id: 3,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'approved',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 3,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    language: 'English',
    memoType: 'user',
    lock: false,
  },
  {
    id: 4,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'underReview',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 5,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    language: 'English',
    memoType: 'user',
    lock: false,
  },
  {
    id: 5,
    dateAndTime: '25/08/2023 11:00',
    reason: 'Bullying or Harassement',
    status: 'pending',
    score: 3,
    result: 'No violation',
    pic: 'jennifer',
    name: 'Test',
    userName: 'test_123',
    point: 5,
    view: 0,
    like: 0,
    comment: 0,
    bookmark: 2,
    notIntrested: 5,
    postId: 123456125,
    language: 'English',
    memoType: 'user',
    lock: false,
  },
]

export const imageArray = [
  '/assets/Image/imageOne.jpeg',
  '/assets/Image/imageTwo.jpg',
]
