import axiosInstance from '_utils/axiosUtils'
import PropsTypes from 'prop-types'
import React, { createContext, useEffect, useState } from 'react'

const HomeContext = createContext({
  posts: {
    _count: 0,
    _posts: [],
    loading: false,
  },
  reports: {
    type: 'post',
    all: [],
    _count: 0,
    loading: false,
    _posts: [],
    _profile: [],
    _comments: [],
  },
  setReports: () => null,
  setPosts: () => null,
  search: '',
  setSearch: () => null,
  from: '',
  setFromDate: () => null,
  to: '',
  setToDate: () => null,
  u_points: [],
  setU_points: () => null,
  lang: [],
  setLang: () => null,
  status: [],
  setStatus: () => null,
  p_points: [],
  setP_points: () => null,
  pic: [],
  setPic: () => null,
})

export const HomeProvider = ({ children }) => {
  const [payload, setPayload] = useState(null)
  const [objectionPayload, setObjectionPayload] = useState(null)
  const [posts, setPosts] = useState({
    _count: 0,
    _posts: [],
    loading: false,
  })

  const [reports, setReports] = useState({
    type: '',
    _count: 0,
    all: [],
    loading: false,
    _posts: [],
    _profile: [],
    _comments: [],
  })
  const [search, setSearch] = useState('')

  const fetchReports = async () => {
    setReports((prev) => ({ ...prev, loading: true }))
    axiosInstance
      .get('/admin/report', {
        params: {
          type: reports.type || '',
          search: search,
          from: objectionPayload?.from ? objectionPayload?.from : null,
          to: objectionPayload?.to ? objectionPayload?.to : null,
          status:
            objectionPayload?.status?.length > 0
              ? JSON.stringify(
                  objectionPayload?.status?.map((x) => {
                    if (x === 'Approved') {
                      return 'objection_accepted'
                    }
                    if (x === 'Under Review') {
                      return 'under_review'
                    }
                    if (x === 'Disapproved') {
                      return 'objection_overruled'
                    }
                    if (x === 'Pending') {
                      return x.toLowerCase()
                    }
                  })
                )
              : undefined,
          reasons:
            objectionPayload?.reason?.length > 0
              ? JSON.stringify(objectionPayload?.reason)
              : undefined,
          results:
            objectionPayload?.result?.length > 0
              ? JSON.stringify(
                  objectionPayload?.result?.map((result) =>
                    result.toLowerCase()?.split(' ')?.join('_')
                  )
                )
              : undefined,
          picIds: objectionPayload?.pic
            ? JSON.stringify(objectionPayload?.pic)
            : undefined,
        },
      })
      .then((res) => {
        let key = 'all'
        if (reports.type === 'post') {
          key = '_posts'
        }
        if (reports.type === 'user') {
          key = '_profile'
        }
        if (reports.type === 'comment') {
          key = '_comments'
        }
        setReports((prev) => ({
          ...prev,
          [`${key}`]: res?.data?.data?.rows,
          _count: res?.data?.data?._count,
        }))
      })
      .catch((err) => console.log(err))
      .finally(() => setReports((prev) => ({ ...prev, loading: false })))
  }

  const updateStatusPost = async (id, status) => {
    let tempObj = { postId: id, status: status }
    try {
      const response = await axiosInstance.put(
        '/admin/post/status/update',
        tempObj
      )
      if (response.status === 200) {
        let postsArray = posts?._posts?.map((post) => {
          if (post?.id === id) {
            return { ...post, ...response?.data?.data }
          }
          return { ...post }
        })
        setPosts({ ...posts, _posts: postsArray })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateResultReport = async (id, status) => {
    let tempObj = { objectionId: id, results: status }
    try {
      const response = await axiosInstance.put(
        '/admin/report/result/update',
        tempObj
      )
      if (response.status === 200) {
        let key = 'all'
        if (reports.type === 'post') {
          key = '_posts'
        }
        if (reports.type === 'user') {
          key = '_profile'
        }
        if (reports.type === 'comment') {
          key = '_comments'
        }
        let postsArray = reports[`${key}`].map((post) => {
          if (post?.id === id) {
            return { ...post, ...response?.data?.data }
          }
          return { ...post }
        })
        setReports((prev) => ({
          ...prev,
          [`${key}`]: postsArray,
          _count: postsArray?.length,
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchPost = async () => {
    setPosts((prev) => ({
      ...prev,
      loading: true,
    }))
    axiosInstance
      .get('/admin/post', {
        params: {
          search: search ? search : null,
          from: payload?.from,
          to: payload?.to,
          u_points:
            payload?.U_points?.length > 0
              ? JSON.stringify(payload?.U_points)
              : undefined,
          lang: payload?.lang
            ? payload?.lang?.map((lang) => lang.toLowerCase())?.join(',')
            : undefined,
          status: payload?.status ? JSON.stringify(payload?.status) : undefined,
          p_points:
            payload?.p_points?.length > 0
              ? payload?.p_points?.join(',')
              : undefined,
          picId: payload?.pic ? JSON.stringify(payload?.pic) : undefined,
        },
      })
      .then((res) => {
        setPosts({
          ...res.data?.data,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  const handleSetPostFilter = (payload) => {
    setPayload((prev) => ({
      ...prev,
      ...payload,
    }))
  }

  useEffect(() => {
    fetchPost()
  }, [search, payload])

  useEffect(() => {
    fetchReports()
  }, [search, reports.type, objectionPayload])

  return (
    <HomeContext.Provider
      value={{
        posts,
        setPosts,
        search,
        setSearch,
        reports,
        setReports,
        payload,
        handleSetPostFilter,
        objectionPayload,
        setObjectionPayload,
        updateStatusPost,
        updateResultReport,
      }}
    >
      {children}
    </HomeContext.Provider>
  )
}

HomeProvider.propTypes = {
  children: PropsTypes.node,
}

export default HomeContext
