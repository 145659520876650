import useStaff from '_hooks/useStaff'
import { ChevronDown, ChevronUp, LucideCircle } from 'lucide-react'
import React, { useEffect, useState } from 'react'

const AuthorizationTeamWiseTable = () => {
  const permissionArray = [
    'change_status',
    'change_score',
    'change_point',
    'change_lock',
    'deletion_content',
    'deletion_memo',
    'deletion_hashtag',
    'deletion_account',
    'download_csv',
    'download_account',
    'staff_registration',
    'staff_editing',
    'staff_deletion',
  ]
  const {
    fetchTeams,
    team: { rows },
  } = useStaff()

  useEffect(() => {
    fetchTeams()
  }, [])
  const [openedTeam, setOpenedTeam] = useState('')

  const handleClick = (val) => {
    if (openedTeam === val) {
      setOpenedTeam('')
    } else {
      setOpenedTeam(val)
    }
  }
  return (
    <table className='static'>
      <thead>
        <tr>
          <th></th>
          <th
            colSpan='4'
            className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
          >
            Change
          </th>
          <th
            colSpan='4'
            className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
          >
            Deletion
          </th>
          <th
            colSpan='2'
            className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
          >
            Download
          </th>
          <th
            colSpan='3'
            className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
          >
            Staff
          </th>
        </tr>
        <tr>
          <th></th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Status
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Score
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Point
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Lock
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Content
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Memo
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Hashtag
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Account
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            CSV
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Account
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Registration
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Editing
          </th>
          <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
            Deletion
          </th>
        </tr>
      </thead>
      <tbody>
        {rows?.map((item) => {
          return (
            <>
              <tr key={item?.id} className='w-fit'>
                <td
                  className='flex items-center justify-between cursor-pointer'
                  onClick={() => {
                    if (item?.members?.length) {
                      handleClick(item?.id)
                    }
                  }}
                  disabled={item?.members?.length === 0}
                >
                  <div>{item?.name}</div>
                  {openedTeam === item?.id ? <ChevronUp /> : <ChevronDown />}
                </td>
                {permissionArray?.map((perm) => (
                  <td key={perm}></td>
                ))}
              </tr>
              {openedTeam === item?.id && (
                <>
                  {item?.members?.map((mem) => {
                    return (
                      <tr key={item?.id}>
                        <td
                          onClick={() => handleClick(item?.id)}
                          className='flex items-center justify-between w-full py-3'
                        >
                          <div
                            className='text-black text-[11px] font-poppins font-normal p-0 max-w-[100px]'
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {mem?.fullName}
                          </div>
                          <div className='text-black text-[11px] font-poppins font-normal shadow-customBtnShadow rounded-xl p-2'>
                            {mem?.grade?.name}
                          </div>
                        </td>
                        {permissionArray?.map((perm) => {
                          let typesArray = mem?.grade?.authority?.map(
                            (item) => item?.type
                          )
                          return (
                            <td key={perm}>
                              <div className='text-black text-[11px] font-poppins pt-2.5 pb-[13px] font-normal flex items-center justify-center'>
                                {typesArray?.includes(perm) && (
                                  <LucideCircle
                                    color='#00BDB2'
                                    strokeWidth={4}
                                    size={15}
                                  />
                                )}
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              )}
            </>
          )
        })}
      </tbody>
    </table>
  )
}

export default AuthorizationTeamWiseTable
