// import Page from 'components/global/Page'
// import React from 'react'
// import UserNameBadge from 'components/global/Badge/UserStatus'
// import { Link } from 'react-router-dom'
// import CustomTrash from 'components/global/icons/CustomTrash'
// import Header from 'components/settings/Header'

// const SettingsBookmarks = () => {
//   return (
//     <Page>
//       {/* header  */}
//       <div id='header' className='w-full'>
//         <Header />
//       </div>
//       {/* page content  */}
//       <div className='flex flex-col gap-y-4'>
//         <button className='p-3 rounded-xl bg-text-dm-input shadow-buttonPrimary self-end'>
//           <CustomTrash fillColor='#fff' />
//         </button>
//         <div className='w-full shadow-customBtnShadow p-5 rounded-xl '>
//           <div className='grid max-w-fit gap-x-7 gap-y-4'>

//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' /> 10/09/2023, 10:00
//               </span>
//               <h6 className='text-xs font-bold underline'>#mathématiques</h6>
//             </div>

//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' /> 10/09/2023, 17:00
//               </span>
//               <h6 className='text-xs font-bold underline'>#digitalmarketing</h6>
//             </div>

//             <div className='grid grid-cols-2 max-w-fit gap-x-2 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' /> 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('online', { size: 10 })} ABC1120
//                 </Link>
//                 Richardson
//               </h6>
//             </div>

//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' />
//                 09/09/2023, 10:12
//               </span>
//               <h6 className='text-xs font-bold underline'>#figma</h6>
//             </div>

//             <div className='grid grid-cols-2 max-w-fit gap-x-1 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' /> 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('logout', { size: 10 })} Ray6620
//                 </Link>
//                 Pocari0324
//               </h6>
//             </div>

//             <div className='grid grid-cols-2 max-w-fit gap-x-5 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' /> 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('account_deletion', { size: 10 })} Ray6620
//                 </Link>
//                 Rico-y
//               </h6>
//             </div>

//           </div>
//         </div>
//       </div>
//     </Page>
//   )
// }

// export default SettingsBookmarks















// import Page from 'components/global/Page'
// import React, { useState } from 'react'
// import UserNameBadge from 'components/global/Badge/UserStatus'
// import { Link } from 'react-router-dom'
// import CustomTrash from 'components/global/icons/CustomTrash'
// import Header from 'components/settings/Header'

// const SettingsBookmarks = () => {
//   // State variables
//   const [checkedItems, setCheckedItems] = useState(new Set());
//   const [itemsToDelete, setItemsToDelete] = useState([]);

//   console.log(itemsToDelete);

//   // Function to handle checkbox change
//   const handleCheckboxChange = (id) => {
//     const newCheckedItems = new Set(checkedItems);
//     if (checkedItems.has(id)) {
//       newCheckedItems.delete(id);
//     } else {
//       newCheckedItems.add(id);
//     }
//     setCheckedItems(newCheckedItems);
//   };

//   // Function to open confirmation popup
//   const handleOpenConfirmation = () => {
//     if (checkedItems.size > 0) {
//       if (window.confirm('Are you sure you want to delete the selected items?')) {
//         handleDeleteItems();
//       }
//     } else {
//       alert('Please select items to delete.');
//     }
//   };

//   // Function to handle deletion confirmation
//   const handleDeleteItems = () => {
//     const filteredItems = itemsToDelete.filter((item, index) => !checkedItems.has(index));
//     setItemsToDelete(filteredItems);
//     setCheckedItems(new Set()); // Clear checked items
//   };

//   return (
//     <Page>
//       {/* header  */}
//       <div id='header' className='w-full'>
//         <Header />
//       </div>
//       {/* page content  */}
//       <div className='flex flex-col gap-y-4'>
//         <button className='p-3 rounded-xl bg-text-dm-input shadow-buttonPrimary self-end' onClick={handleOpenConfirmation}>
//           <CustomTrash fillColor='#fff' />
//         </button>
//         <div className='w-full shadow-customBtnShadow p-5 rounded-xl '>
//           <div className='grid max-w-fit gap-x-7 gap-y-4'>

//             {/* First Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(0)} onChange={() => handleCheckboxChange(0)} />
//                 10/09/2023, 10:00
//               </span>
//               <h6 className='text-xs font-bold underline'>#mathématiques</h6>
//             </div>

//             {/* Second Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(1)} onChange={() => handleCheckboxChange(1)} />
//                 10/09/2023, 17:00
//               </span>
//               <h6 className='text-xs font-bold underline'>#digitalmarketing</h6>
//             </div>

//             {/* Third Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-2 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(2)} onChange={() => handleCheckboxChange(2)} />
//                 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('online', { size: 10 })} ABC1120
//                 </Link>
//                 Richardson
//               </h6>
//             </div>

//             {/* Fourth Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(3)} onChange={() => handleCheckboxChange(3)} />
//                 09/09/2023, 10:12
//               </span>
//               <h6 className='text-xs font-bold underline'>#figma</h6>
//             </div>

//             {/* Fifth Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-1 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(4)} onChange={() => handleCheckboxChange(4)} />
//                 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('logout', { size: 10 })} Ray6620
//                 </Link>
//                 Pocari0324
//               </h6>
//             </div>

//             {/* Sixth Item */}
//             <div className='grid grid-cols-2 max-w-fit gap-x-5 gap-y-4'>
//               <span className='text-xs font-normal flex items-center gap-x-2'>
//                 <input type='checkbox' checked={checkedItems.has(5)} onChange={() => handleCheckboxChange(5)} />
//                 09/09/2023, 13:46
//               </span>
//               <h6 className='text-xs font-normal flex items-center flex-nowrap gap-x-2'>
//                 <Link className='underline text-xs font-normal flex flex-nowrap items-center gap-x-2'>
//                   {UserNameBadge('account_deletion', { size: 10 })} Ray6620
//                 </Link>
//                 Rico-y
//               </h6>
//             </div>

//           </div>
//         </div>
//       </div>
//     </Page>
//   )
// }

// export default SettingsBookmarks;








import Page from 'components/global/Page'
import React, { useState } from 'react'
import UserNameBadge from 'components/global/Badge/UserStatus'
import { Link } from 'react-router-dom'
import CustomTrash from 'components/global/icons/CustomTrash'
import Header from 'components/settings/Header'

const SettingsBookmarks = () => {
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [items, setItems] = useState([
    { id: 0, date: '10/09/2023, 10:00', tag: '#mathématiques' },
    { id: 1, date: '10/09/2023, 17:00', tag: '#digitalmarketing' },
    { id: 2, date: '09/09/2023, 13:46', mark: <UserNameBadge status='online' size={10} />, tag: 'ABC1120', tag2: 'Richardson' },
    { id: 3, date: '09/09/2023, 13:46', tag: '#figma' },
    { id: 4, date: '09/09/2023, 13:46', mark: <UserNameBadge status='logout' size={10} />, tag: 'Ray6620', tag2: 'Pocari0324' },
    { id: 5, date: '09/09/2023, 13:46', mark: <UserNameBadge status='account_deletion' size={10} />, tag: 'Ray6620 ', tag2: 'Rico-y' },
  ]);

  const handleCheckboxChange = (id) => {
    const newCheckedItems = new Set(checkedItems);
    if (checkedItems.has(id)) {
      newCheckedItems.delete(id);
    } else {
      newCheckedItems.add(id);
    }
    setCheckedItems(newCheckedItems);
  };

  const handleOpenConfirmation = () => {
    if (checkedItems.size > 0) {
      if (window.confirm('Are you sure you want to delete the selected items?')) {
        handleDeleteItems();
      }
    }
  };

  const handleDeleteItems = () => {
    const filteredItems = items.filter((item) => !checkedItems.has(item.id));
    setItems(filteredItems);
    setCheckedItems(new Set());
  };

  return (
    <Page>
      <div id='header' className='w-full'>
        <Header />
      </div>
      <div className='flex flex-col gap-y-4'>
        <button className='p-3 rounded-xl bg-text-dm-input shadow-buttonPrimary self-end' onClick={handleOpenConfirmation}>
          <CustomTrash fillColor='#fff' />
        </button>
        <div className='w-full shadow-customBtnShadow p-5 rounded-xl '>
          <div className='grid max-w-fit gap-x-7 gap-y-4'>
            {items.map((item) => (
              <div key={item.id} className='grid grid-cols-2 max-w-fit gap-x-7 gap-y-4'>
                <span className='text-xs font-normal flex items-center gap-x-2'>
                  <input type='checkbox' checked={checkedItems.has(item.id)} onChange={() => handleCheckboxChange(item.id)} />
                  {item.date}
                </span>
                <h6 className='flex  text-xs '>
                  {item.mark && item.mark}
                  <Link className='underline flex flex-nowrap items-center gap-x-2 mr-1 font-bold '>
                    {item.tag}
                  </Link>
                  {item.tag2}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SettingsBookmarks;
