import React from 'react'
import PropTypes from 'prop-types'

const GlobalRadioButton = ({
  label,
  name,
  value,
  checked,
  onChange,
  disabled = false,
  shape,
}) => {
  const radioClasses = `outline outline-1 appearance-none h-3 w-3 rounded-${shape} checked:bg-bg-universal `

  return (
    <label className='flex items-center space-x-2 cursor-pointer p-1'>
      <input
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={radioClasses}
        style={{
          outlineColor: '#CDCDCD',
          outlineOffset: '3px',
          borderRadius: '2px',
        }}
      />
      <span className='font-poppins text-black font-medium text-xs'>
        {label}
      </span>
    </label>
  )
}

GlobalRadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  shape: PropTypes.string.isRequired,
}

export default GlobalRadioButton
