import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { ChevronDown, ChevronUp } from 'lucide-react'

const LightBtn = ({
  children,
  className,
  dropdown,
  // dropdownToggle,
  active,
  hover,
  showIndicator,
  isDropdownOpen,
  toggleDropdown,
  ...args
}) => {
  const applyHover = hover !== undefined ? hover : true
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  // const toggleDropdown = () => {
  //   if (dropdown) {
  //     setIsDropdownOpen((prevState) => !prevState)
  //   }
  // }

  // handle the onclick of the button
  const handleButtonClick = () => {
    if (active && dropdown) {
      toggleDropdown()
    } else if (args.onClick) {
      args.onClick()
    }
  }

  const header = React.Children.toArray(children).find(
    (child) => !child.props || child.props.id !== 'options'
  )
  const options = React.Children.toArray(children).filter(
    (child) => child.props && child.props.id === 'options'
  )

  // useEffect(() => {
  //   if (!active) {
  //     setIsDropdownOpen(false)
  //   }
  // }, [active])


  return (
    <div className={clsx({ 'relative inline-block': dropdown })}>
      <button
        {...args}
        className={clsx(
          'py-2 px-3 w-full border border-transparent shadow-customBtnShadow rounded-xl bg-universal',
          className,
          active && '!shadow-active-btn !border-teal-500',
          applyHover && 'hover:shadow-boxIn',
          dropdown && 'cursor-pointer',
          isDropdownOpen && '!border-0 border-transparent',
        )}
        onClick={handleButtonClick}
        style={{ position: 'relative' }}
      >
        {active && showIndicator && (
          isDropdownOpen ?
            <div />
            :
            <div
              style={{
                position: 'absolute',
                top: '-4px',
                right: '-3px',
                width: '14px',
                height: '14px',
                backgroundColor: '#00BDB2',
                borderRadius: '50%',
              }}
            />
        )}
        <span className='flex felx-nowrap gap-2 items-center justify-between'>
          {header}
          {dropdown && <ChevronDown size={16} />}
        </span>
      </button>
      {isDropdownOpen && dropdown && (
        <div className='absolute z-10 top-0 shadow-active-btn bg-universal rounded-2xl py-2 px-3 left-0 flex flex-col border border-teal-500 w-32'>
          <button
            className='flex felx-nowrap gap-2 items-center font-medium text-sm justify-between'
            onClick={toggleDropdown}
          >
            {header}
            {isDropdownOpen ? (
              <ChevronUp size={16} />
            ) : (
              <ChevronDown size={16} />
            )}
          </button>
          {options}
        </div>
      )}
    </div>
  )
}

LightBtn.propTypes = {
  children: PropTypes.node,
  dropdown: PropTypes.bool,
  active: PropTypes.bool,
  showIndicator: PropTypes.bool,
  // dropdownToggle:PropTypes.func,
  toggleDropdown: PropTypes.func,
  isDropdownOpen: PropTypes.func,
  hover: PropTypes.bool,
  className: PropTypes.string,
}

export default LightBtn