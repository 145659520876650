import React from 'react'
import PropsTypes from 'prop-types'
import { AuthProvider } from 'context/AuthContext'
import { UserProvider } from 'context/management/UserContext'
import { HashtagProvider } from 'context/management/HashtagContext'
import { ResumeProvider } from 'context/management/ResumeContext'
import { QnAProvider } from 'context/management/QnAContext'
import { HomeProvider } from 'context/home/HomeContext'
import { StaffProvider } from 'context/admin/StaffContext'
import { SettingsProvider } from 'context/settings/SettingsContext'

const Level_1_provider = ({ children }) => {
  return (
    <AuthProvider>
      <HomeProvider>
        <StaffProvider>
          <UserProvider>
            <HashtagProvider>
              <ResumeProvider>
                <QnAProvider>
                  <SettingsProvider>{children}</SettingsProvider>
                </QnAProvider>
              </ResumeProvider>
            </HashtagProvider>
          </UserProvider>
        </StaffProvider>
      </HomeProvider>
    </AuthProvider>
  )
}

Level_1_provider.propTypes = {
  children: PropsTypes.node,
}

export default Level_1_provider
