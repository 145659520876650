import React from 'react'
import PropTypes from 'prop-types'

const CustomSwitch = ({ isOn, handleToggle }) => {
  return (
    <div className='switch-container'>
      <input
        checked={isOn}
        onChange={handleToggle}
        className='switch-checkbox'
        id={'switch'}
        type='checkbox'
      />
      <label className='switch-label' htmlFor={'switch'}>
        <span className={'switch-button'} />
      </label>
    </div>
  )
}

CustomSwitch.propTypes = {
  isOn: PropTypes.bool,
  handleToggle: PropTypes.func,
}

export default CustomSwitch
