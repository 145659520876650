import axiosInstance from '_utils/axiosUtils'
import { defaultProfileImg } from '_utils/default'
import clsx from 'clsx'
import SlidingTabs from 'components/global/SlidingTabs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useHashtag from '_hooks/useHashtag'
import { a11y } from '_utils/a11y'

import { Link } from 'react-router-dom'

const FollowersFollowing = ({ user }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex)
  }

  return (
    <div className='flex flex-col w-full gap-y-5 max-h-[88vh]'>
      <div className='w-full bg-bg-tabs rounded-xl p-1 h-fit flex justify-around gap-x-2 border border-text-dm-input'>
        <SlidingTabs activeTab={activeTab}>
          <button
            className={clsx(
              'font-semibold z-10 py-2 w-full h-11 transition-colors text-sm',
              {
                'text-white': activeTab === 0,
              }
            )}
            onClick={() => {
              handleTabClick(0)
            }}
          >
            Followers ({user?._count?.followers})
          </button>
          <button
            className={clsx(
              'font-semibold z-10 py-2 w-full h-11 transition-colors text-sm',
              {
                'text-white': activeTab === 1,
              }
            )}
            onClick={() => {
              handleTabClick(1)
            }}
          >
            Following ({user?._count?.followings})
          </button>
          <button
            className={clsx(
              'font-semibold z-10 py-2 w-full h-11 transition-colors text-sm ',
              {
                'text-white': activeTab === 2,
              }
            )}
            onClick={() => {
              handleTabClick(2)
            }}
          >
            Hashtags ({user?._count?.followingHashtags})
          </button>
          <button
            className={clsx(
              'font-semibold z-10 py-2 w-full h-11 transition-colors text-sm ',
              {
                'text-white': activeTab === 3,
              }
            )}
            onClick={() => {
              handleTabClick(3)
            }}
          >
            Blocked by Me({user?._count?.blockedByUser})
          </button>
          <button
            className={clsx(
              'font-semibold z-10 py-2 w-full h-11 transition-colors text-sm ',
              {
                'text-white': activeTab === 4,
              }
            )}
            onClick={() => {
              handleTabClick(4)
            }}
          >
            Blocked Me ({user?._count?.blockedUser})
          </button>
        </SlidingTabs>
      </div>
      <div className='shadow-customBtnShadow w-full h-full rounded-xl grid grid-cols-card-md place-content-start justify-self-start p-4 overflow-y-auto max-h-full'>
        <Component active={activeTab} user={user} />
      </div>
    </div>
  )
}

FollowersFollowing.propTypes = {
  user: PropTypes.object,
}

const Component = ({ active, user }) => {
  switch (active) {
    case 0:
      // followers
      return (
        <>
          <FollowerMap user={user} />
        </>
      )
    case 1:
      return (
        // followings
        <FollowingMap user={user} />
      )
    case 2:
      return <HashtagMap user={user} />
    case 3:
      return <BlockByMeMap user={user} />
    case 4:
      return <BlockMeMap user={user} />
    default:
      return null
  }
}

Component.propTypes = {
  active: PropTypes.number,
  user: PropTypes.object,
}

const FollowerMap = ({ user }) => {
  const [followers, setFollowers] = useState([])
  const fetchFollowers = () => {
    axiosInstance
      .get(`/admin/user/follower/${user?.id}`, {
        params: {
          skip: 0,
          limit: 1000,
        },
      })
      .then((res) => {
        setFollowers(res.data.data)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (user?.id) {
      fetchFollowers()
    }
  }, [user])
  return (
    <>
      {followers?.length > 0 ? (
        followers?.map((item, index) => (
          // <Link key={index} to={`/user/${item?.follower?.id}`}>
          //   <div className='flex justify-start items-center gap-x-2 h-fit p-3 w-full '>
          //     <div className=''>
          //       <img
          //         src={defaultProfileImg(item?.follower?.profileImage, 'user')}
          //         alt='profile'
          //         className='!w-12 !h-12 !rounded-full'
          //       />
          //     </div>
          //     <div className='flex flex-col w-2/3'>
          //       <h6 className='font-semibold text-base'>
          //         {item?.follower?.fullName
          //           ? item?.follower?.fullName
          //           : item?.follower?.username}
          //       </h6>
          //       <h6 className='underline font-normal text-sm'>
          //         {item?.follower?.username}
          //       </h6>
          //     </div>
          //   </div>
          // </Link>

          <Link key={index} className='cursor-auto'>
            <div className='flex justify-start items-center gap-x-2 h-fit p-3 w-full '>
              <div className=''>
                <Link to={`/user/${item?.follower?.id}`} >
                  <img
                    src={defaultProfileImg(item?.follower?.profileImage, 'user')}
                    alt='profile'
                    className='!w-12 !h-12 !rounded-full'
                  />
                </Link>
              </div>
              <div className='flex flex-col'>
                <Link to={`/user/${item?.follower?.id}`}>
                  <h6 className='font-semibold text-base'>
                    {item?.follower?.fullName
                      ? item?.follower?.fullName
                      : item?.follower?.username}
                  </h6>
                </Link>
                <Link to={`/user/${item?.follower?.id}`}>
                  <h6 className='underline font-normal text-sm'>
                    {item?.follower?.username}
                  </h6>
                </Link>
              </div>
            </div>
          </Link>

        ))
      ) : (
        <h6 className='underline text-sm'>No Records</h6>
      )}
    </>
  )
}

FollowerMap.propTypes = {
  user: PropTypes.object,
}

const FollowingMap = ({ user }) => {
  const [following, setFollowing] = useState([])

  const fetchFollowing = () => {
    axiosInstance
      .get(`/admin/user/following/${user?.id}`, {
        params: {
          skip: 0,
          limit: 1000,
        },
      })
      .then((res) => setFollowing(res?.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (user) {
      fetchFollowing()
    }
  }, [user])
  return (
    <>
      {following?.length > 0 ? (
        following?.map((item, index) => (
          <Link key={index} className='cursor-auto'>
            <div className='flex justify-start items-center gap-x-2 h-fit p-3' >
              <Link to={`/user/${item?.user?.id}`}>
                <img
                  src={defaultProfileImg(item?.user?.profileImage, 'user')}
                  alt='profile'
                  className='w-12 h-12 rounded-full'
                />
              </Link>
              <div className='flex flex-col'>
                <Link to={`/user/${item?.user?.id}`}>
                  <h6 className='font-bold text-sm'>
                    {item?.user?.fullName
                      ? item?.user?.fullName
                      : item?.user?.username}
                  </h6>
                </Link>
                <Link to={`/user/${item?.user?.id}`}>
                  <h6 className='underline text-sm'>{item?.user?.username}</h6>
                </Link>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <h6 className='underline text-sm'>No Records</h6>
      )}
    </>
  )
}

FollowingMap.propTypes = {
  user: PropTypes.object,
}

const HashtagMap = ({ user }) => {
  const { handleOpen } = useHashtag()

  const [hashtags, setHashtags] = useState([])

  const fetchHashtags = () => {
    axiosInstance
      .get(`/admin/user/hashtag/${user?.id}`, {
        params: {
          skip: 0,
          limit: 1000,
        },
      })
      .then((res) => setHashtags(res?.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (user) {
      fetchHashtags()
    }
  }, [user])

  return (
    <>
      {hashtags?.length > 0 ? (
        hashtags?.map((item, index) => (
          <div
            key={index}
            className='flex flex-nowrap gap-x-2 p-3 items-center justify-start'
          >
            <img
              src={defaultProfileImg(item?.media[0]?.fileURL, 'hashtag')}
              {...a11y(() => handleOpen(item?.id, true, 'right'))}
              alt='hashtag'
              className='w-12 h-12 rounded-full'
            />

            <h6 className='text-sm font-bold'  {...a11y(() => handleOpen(item?.id, true, 'right'))} >#{item?.name}</h6>
          </div>
        ))
      ) : (
        <h6 className='underline text-sm'>No Records</h6>
      )}
    </>
  )
}

HashtagMap.propTypes = {
  user: PropTypes.object,
}

const BlockByMeMap = ({ user }) => {
  const [blockedUsers, setBlockedUsers] = useState([])

  const fetchBlockedUsers = () => {
    axiosInstance
      .get(`/admin/user/blocked-by-me/${user?.id}`, {
        params: {
          skip: 0,
          limit: 10000,
        },
      })
      .then((res) => setBlockedUsers(res?.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchBlockedUsers()
  }, [user])

  return (
    <>
      {blockedUsers?.length > 0 ? (
        blockedUsers?.map((item, index) => (
          <Link key={index} className='cursor-auto' >
            <div className='flex justify-start items-center gap-x-2 h-fit p-3'>
              <Link to={`/user/${item?.blockedUser?.id}`}>
                <img
                  src={defaultProfileImg(item?.blockedUser?.profileImage, 'user')}
                  alt='profile'
                  className='w-12 h-12 rounded-full'
                />
              </Link>
              <div className='flex flex-col'>
                <Link to={`/user/${item?.blockedUser?.id}`}>
                  <h6 className='font-bold text-sm'>
                    {item?.blockedUser?.fullName
                      ? item?.blockedUser?.fullName
                      : item?.blockedUser?.username}
                  </h6>
                </Link>
                <Link to={`/user/${item?.blockedUser?.id}`}>
                  <h6 className='underline text-sm'>
                    {item?.blockedUser?.username}
                  </h6>
                </Link>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <h6 className='underline text-sm'>No Records</h6>
      )}
    </>
  )
}

BlockByMeMap.propTypes = {
  user: PropTypes.object,
}

const BlockMeMap = ({ user }) => {
  const [users, setUsers] = useState([])
  const fetchUserBlock = () => {
    axiosInstance
      .get(`/admin/user/blocked-me/${user?.id}`)
      .then((res) => setUsers(res?.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (user?.id) {
      fetchUserBlock()
    }
  }, [user])

  return (
    <>
      {/* Blocked Me */}
      {users?.length > 0 ? (
        users?.map((item, index) => (
          <Link key={index} className='cursor-auto'>
            <div className='flex justify-start items-center gap-x-2 h-fit p-3'>
              <Link to={`/user/${item?.user?.id}`} >
                <img
                  src={defaultProfileImg(item?.user?.profileImage, 'user')}
                  alt='profile'
                  className='w-12 h-12  rounded-full'
                />
              </Link>
              <div className='flex flex-col'>
                <Link to={`/user/${item?.user?.id}`} >
                  <h6 className='font-bold text-sm'>
                    {item?.user?.fullName
                      ? item?.user?.fullName
                      : item?.user?.username}
                  </h6>
                </Link>
                <Link to={`/user/${item?.user?.id}`} >
                  <h6 className='underline text-sm'>{item?.user?.username}</h6>
                </Link>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <h6 className='underline text-sm'>No Records</h6>
      )}
    </>
  )
}

BlockMeMap.propTypes = {
  user: PropTypes.object,
}

export default FollowersFollowing
