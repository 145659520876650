import clsx from 'clsx'
import React, { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { getStatusClass } from '_utils/getStatusClass'
import PropsTypes from 'prop-types'
import useAuth from '_hooks/useAuth'
import useHome from '_hooks/useHome'

const Status = ({ contentStatus, id, pic, access }) => {
  const { user } = useAuth()
  const { updateStatusPost } = useHome()

  const [isOpenByStatus, setIsOpenByStatus] = useState(false)

  let statusArray = [
    { label: 'approved', value: 'approved' },
    { label: 'pending', value: 'pending' },
    { label: 'disapproved', value: 'disapproved' },
    { label: 'under_review', value: 'under_review' },
  ]

  const toggleDropdownByStatus = () => {
    setIsOpenByStatus((prevState) => !prevState)
  }

  return (
    <div>
      <button
        className={clsx('flex items-center', getStatusClass(contentStatus))}
        disabled={access === true ? pic && pic?.id !== user?.id : true}
        onClick={toggleDropdownByStatus}
      >
        {contentStatus}
        <FiChevronDown size={17} />
      </button>

      {isOpenByStatus && (
        <div className='absolute z-10 left-0 !border-text-dm-input !border-input-border !border-solid w-auto h-max rounded-xl flex items-center justify-center bg-white p-1 shrink-0'>
          <div className='flex flex-col text-center'>
            {statusArray?.map((status) => {
              return (
                <button
                  key={status.value}
                  className='text-sm font-medium font-poppins text-black p-1 cursor-pointer'
                  onClick={() => updateStatusPost(id, status?.value)}
                >
                  {status?.label}
                </button>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

Status.propTypes = {
  contentStatus: PropsTypes.object,
  id: PropsTypes.object,
  pic: PropsTypes.object,
  access: PropsTypes.bool,
}

export default Status
