import moment from 'moment'

export const startAndEnd = (startDate, endDate, isPresent) => {
  let _startDate = ''
  let _endDate = ''
  if(startDate) {
    _startDate = moment(startDate).format('MMMM YYYY')
  }

  if(endDate) {
    _endDate = moment(endDate).format('MMMM YYYY')
  }

  if(!_startDate && !_endDate) {
    return ''
  }
  if(_startDate && isPresent) {
    return `${_startDate} - Present` 
  }
  if(_startDate && !_endDate) {
    return `${_startDate} - Present`
  }
}