import Page from 'components/global/Page'
import UserDetailsSidebar from 'components/userDetails/UserDetailsSidebar'
import FollowersFollowing from 'components/userDetails/FollowersFollowing'
import React, { useEffect, useState } from 'react'
import LightBtn from 'components/global/Buttons/LightBtn'
import Loader from 'components/global/Loader'
import UserInformations from 'components/userDetails/UserInformations'
import clsx from 'clsx'
import UserDetailsHashtags from 'components/userDetails/UserDetailsHashtags'
import UserDetailsProfile from 'components/userDetails/UserDetailsProfile'
import { useParams, useSearchParams } from 'react-router-dom'
import axiosInstance from '_utils/axiosUtils'
import PostCard from 'components/userDetails/PostCard'
import PropsTypes from 'prop-types'
import ActionHistory from 'components/userDetails/ActionHistory'
import Resumes from 'components/userDetails/Resumes'
import Bio from 'components/userDetails/Bio'
import PostPreview from 'components/userDetails/PostPreview'
import ReportHistory from 'components/userDetails/ReportHistory'
import ReportedHistory from 'components/userDetails/ReportedHistory'
import BannedHistory from 'components/userDetails/BannedHistory'
import ObjectionHistory from 'components/userDetails/ObjectionHistory'
import Badges from 'components/userDetails/Badges'
import FamilyPairing from 'components/userDetails/FamilyPairing'
import ProfileInformation from 'components/userDetails/ProfileInformation'
import History from 'components/userDetails/History'

const UserDetails = () => {
  const { id } = useParams()
  const [_sParams] = useSearchParams()
  const [user, setUser] = useState({})
  const [badges, setBadges] = useState({})
  const [objections, setObjections] = useState({})
  const [correspondence, setCorrespondence] = useState([])
  const [reportedArray, setReportedArray] = useState([])
  const [reportingArray, setReportingArray] = useState([])
  const [changeLog, setChangeLog] = useState([])

  const [loading, setLoading] = useState(false)
  const [component, setComponent] = useState(0)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  // postpreview qr code state

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }

  const [qrCode, setQrCode] = useState(false)

  const GetSingleUser = async () => {
    try {
      setLoading(true)
      setComponent(0)
      const response = await axiosInstance.get(`/admin/user/${id}`)
      if (response.status === 200) {
        setUser(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    let tempReportedArray = []
    let tempReportingArray = []
    user?.objections?.forEach((item) => {
      if (item?.memoReport) {
        if (item?.memoReport?.reportedBy?.id === id) {
          tempReportedArray.push(item)
        } else {
          tempReportingArray.push(item)
        }
      }
      if (item?.commentReport) {
        if (item?.commentReport?.reportedBy?.id === id) {
          tempReportedArray.push(item)
        } else {
          tempReportingArray.push(item)
        }
      }
      if (item?.postReport) {
        if (item?.commentReport?.reportedBy?.id === id) {
          tempReportedArray.push(item)
        } else {
          tempReportingArray.push(item)
        }
      }
      if (item?.userReport) {
        if (item?.userReport?.reportedBy === id) {
          tempReportedArray.push(item)
        } else {
          tempReportingArray.push(item)
        }
      }
    })
    setReportedArray(tempReportedArray)
    setReportingArray(tempReportingArray)
  }, [user])

  const GetBadgesOfUser = async () => {
    try {
      setLoading(true)
      setComponent(0)
      const response = await axiosInstance.get(`/admin/user/${id}/badges`)
      if (response.status === 200) {
        setBadges(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const GetEmailsOfUser = async () => {
    try {
      setLoading(true)
      setComponent(0)
      const response = await axiosInstance.get(`/admin/user/${id}/emails`)
      if (response.status === 200) {
        setCorrespondence(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const GetProfileHistoryOfUser = async () => {
    try {
      setLoading(true)
      setComponent(0)
      const response = await axiosInstance.get(`/admin/user/${id}/changeLog`)
      if (response.status === 200) {
        setChangeLog(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const GetObjectionsOfUser = async () => {
    try {
      setComponent(0)
      const response = await axiosInstance.get(`/admin/user/${id}/objection`)
      if (response.status === 200) {
        setObjections(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ComponentDropDownMap = {
    2: 'Hashtags',
    3: 'Profile',
    4: 'History',
  }

  useEffect(() => {
    GetSingleUser()
    GetBadgesOfUser()
    GetObjectionsOfUser()
    GetProfileHistoryOfUser()
    GetEmailsOfUser()
  }, [id])

  useEffect(() => {
    if (_sParams.get('p_id')) {
      setComponent(14)
    }
  }, [_sParams])

  useEffect(() => {
    if (component) {
      setIsDropdownOpen(false)
    }
  }, [component])

  return (
    <Page>
      <div
        className='w-full  flex justify-start item-center gap-x-3'
        id='header'
      >
        <LightBtn
          className='font-medium text-sm'
          active={component === 0}
          onClick={() => setComponent(0)}
        >
          <h6>{user?._count?.posts} Posts</h6>
        </LightBtn>
        <LightBtn
          className='font-medium text-sm'
          active={component === 1}
          onClick={() => setComponent(1)}
        >
          <h6>
            {' '}
            {user?._count?.followers} Followers / {user?._count?.followings}{' '}
            Following{' '}
          </h6>
        </LightBtn>
        <LightBtn
          dropdown
          className='font-medium text-sm'
          active={[2, 3, 4].includes(component)}
          onClick={() => setComponent(2)}
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
        >
          <span>
            {ComponentDropDownMap[component] || ComponentDropDownMap[2]}
          </span>
          <div id='options'>
            {/* Dropdown options */}
            <button
              className={clsx('', component === 2 && '')}
              onClick={() => setComponent(2)}
            >
              Hashtags
            </button>
            <button
              className={clsx('', component === 3 && '')}
              onClick={() => setComponent(3)}
            >
              Profile
            </button>
            <button
              className={clsx('', component === 4 && '')}
              onClick={() => setComponent(4)}
            >
              History
            </button>
          </div>
        </LightBtn>
        <LightBtn
          className='font-medium text-sm'
          active={component === 5}
          onClick={() => setComponent(5)}
        >
          Medal
        </LightBtn>
        <LightBtn
          className='font-medium text-sm'
          active={component === 6}
          onClick={() => setComponent(6)}
        >
          Resume
        </LightBtn>
        <LightBtn
          className='font-medium text-sm'
          active={component === 7}
          onClick={() => setComponent(7)}
        >
          Bio
        </LightBtn>
      </div>
      <Loader loading={loading} />
      {/* Main Content */}
      <div className='flex max-sm:flex-col md:flex-row flex-auto gap-x-5 overflow-hidden xl:h-[88vh] md:h-[85vh]'>
        <UserDetailsSidebar
          setComponent={setComponent}
          component={component}
          qrCode={qrCode}
          setQrCode={setQrCode}
          user={user}
          objectionCount={
            user?.objections?.length ? user?.objections?.length : 0
          }
          reportedCount={reportedArray?.length ? reportedArray?.length : 0}
          reportingCount={reportingArray?.length ? reportingArray?.length : 0}
        />
        {/* cards  */}
        <Component
          objections={objections}
          correspondence={correspondence}
          changeLog={changeLog}
          component={component}
          user={user}
          qrCode={qrCode}
          setQrCode={setQrCode}
          setComponent={setComponent}
          badges={badges}
          reportedArray={reportedArray}
          reportingArray={reportingArray}
          id={id}
        />
      </div>
    </Page>
  )
}

const Component = ({
  component,
  user,
  setComponent,
  correspondence,
  changeLog,
  qrCode,
  setQrCode,
  badges,
  objections,
  reportedArray,
  reportingArray,
  id,
}) => {
  const [_searchParams] = useSearchParams()
  switch (component) {
    case 0:
      return <PostRender user={user} setComponent={setComponent} />
    case 1:
      return <FollowersFollowing user={user} />
    case 2:
      return <UserDetailsHashtags user={user} />
    case 3:
      return <UserDetailsProfile user={user} />
    case 4:
      return <History />
    case 5:
      return <Badges badges={badges} />
    case 6:
      return <Resumes user={user} />
    case 7:
      return <Bio user={user} />
    case 8:
      return (
        <UserInformations
          userSingleData={user}
          setComponent={setComponent}
          badges={badges}
          objections={objections}
          correspondence={correspondence}
          changeLog={changeLog}
        />
      )
    case 9:
      return (
        <ActionHistory
          recentSearch={user?.recentSearch}
          savedSearch={user?.savedSearch}
          id={id}
        />
      )
    case 10:
      return <BannedHistory user={user} />
    case 11:
      return (
        <ReportHistory reportingArray={reportingArray ? reportingArray : []} />
      )
    case 12:
      return (
        <ReportedHistory reportedArray={reportedArray ? reportedArray : []} />
      )
    case 13:
      return <ObjectionHistory objections={user?.objections} />
    case 14:
      return (
        <PostPreview
          setComponent={setComponent}
          qrCode={qrCode}
          setQrCode={setQrCode}
          postId={_searchParams.get('p_id')}
        />
      )
    case 15:
      return <FamilyPairing />
    case 16:
    case 17:
    case 18:
    case 19:
      return <ProfileInformation component={component} />
    default:
      return <div className='w-full'>{component}</div>
  }
}

Component.propTypes = {
  component: PropsTypes.number,
  user: PropsTypes.object,
  setComponent: PropsTypes.func,
  qrCode: PropsTypes.bool,
  setQrCode: PropsTypes.func,
  badges: PropsTypes.object,
  objections: PropsTypes.object,
  correspondence: PropsTypes.array,
  changeLog: PropsTypes.array,
  reportedArray: PropsTypes.array,
  reportingArray: PropsTypes.array,
  id: PropsTypes.string,
}

const PostRender = ({ user, setComponent }) => {
  const [posts, setPosts] = useState([])

  const fetchPosts = () => {
    axiosInstance
      .get(`/admin/user/post/${user?.id}`, {
        params: {
          skip: 0,
          limit: 10000,
          type: 'post',
        },
      })
      .then((res) => {
        setPosts(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (user?.id) {
      fetchPosts()
    }
  }, [user])

  return (
    <div className='flex flex-wrap w-full gap-3 h-fit xl:max-h-[88vh] md:max-h-[85vh] overflow-y-auto scroll-smooth'>
      {posts?.map((_post, index) => (
        <PostCard key={index} post={_post} setComponent={setComponent} />
      ))}
    </div>
  )
}

PostRender.propTypes = {
  user: PropsTypes.object,
  setComponent: PropsTypes.func,
}

export default UserDetails
