import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import LoaderIcon from '../LoaderIcon'

const UserSidebarButton = ({
  children,
  active,
  activeClassName,
  className,
  loading = false,
  disabled = false,
  ...args
}) => {
  return (
    <button
      {...args}
      className={clsx(
        'py-2 flex-nowrap cursor-pointer rounded-xl px-5 flex gap-2 border items-center justify-start',
        className,
        active && activeClassName
      )}
      disabled={disabled}
    >
      {loading ? (
        <div className='w-full flex justify-center items-center'>
          <LoaderIcon className='!w-5 !h-5' />
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  )
}

UserSidebarButton.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  loading: Boolean,
}

export default UserSidebarButton
