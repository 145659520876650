// socket.js
import io from 'socket.io-client'

let socket

export const connectSocket = (
  postId,
  onMessageReceived,
  onOldMessagesReceived
) => {
  socket = io('https://dev-api.hashmemo.com') // Replace with your backend URL

  socket.on('connect', () => {
    socket.emit('joinChatRoom', { contentId: postId })
  })

  socket.on('previousMessages', (oldMessages) => {
    onOldMessagesReceived(oldMessages)
  })

  socket.on('receiveMessage', (message) => {
    onMessageReceived(message)
  })

  socket.on('connect_error', (error) => {
    console.error('Connection Error:', error)
    alert('Could not connect to the chat server. Please try again later.')
  })
}

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect()
  }
}

export const sendMessage = (messageData) => {
  if (socket) {
    socket.emit('sendMessage', messageData)
  }
}
