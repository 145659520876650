export const getStatusClass = (status) => {
  switch (status) {
    case 'pending':
      return 'text-statusPending'
    case 'approved':
      return 'text-statusApproved'
    case 'under_review':
      return 'text-statusUnderReview'
    case 'disapproved':
      return 'text-statusDisapproved'
    default:
      return ''
  }
}