import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { PiWarningCircle } from 'react-icons/pi'

export default function OtpVerification() {
  const navigate = useNavigate()
  const [code, setcode] = useState('')
  const [error, setError] = useState('')
  const [ResendMsg, setResendMsg] = useState('')

  const searchParams = new URLSearchParams(window.location.search)
  const email = searchParams.get('email')

  const maskEmail = (email) => {
    const [username, domain] = email.split('@')
    const maskedUsername =
      username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(1)
    const maskedDomain =
      domain.charAt(0) + '*'.repeat(domain.length - 5) + domain.slice(-4)
    return `${maskedUsername}@${maskedDomain}`
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await fetch(
        'http://localhost:8080/api/v1/admin/auth/verifyCode',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code, email }),
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        setError(errorData.message)
        return
      }
      navigate('/NewPassword')
    } catch (error) {
      console.error('Error occurred:', error)
      setError('An error occurred. Please try again later.')
    }
  }

  const ResendOpt = async () => {
    try {
      const response = await fetch(
        'http://localhost:8080/api/v1/admin/auth/verifyEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        setResendMsg(errorData.message)
        return
      } else {
        const responseData = await response.json()
        setResendMsg(responseData.message)
        setTimeout(() => {
          setResendMsg('')
        }, 3000)
      }
    } catch (error) {
      console.error('Error occurred:', error)
      setResendMsg('An error occurred. Please try again later.')
    }
  }

  return (
    <div className='min-h-screen flex'>
      <div className='hidden xl:flex justify-center items-center xl:w-5/12 bg-cover bg-center bg-gradient-to-t from-bg-memo-start to-bg-memo-stop'>
        <img src='/assets/hashmemo.svg' alt='Hashmemo Logo' />
      </div>
      <form onSubmit={handleSubmit} className='xl:w-7/12 grid p-20'>
        <div className='w-full shadow-boxInMain rounded-2xl p-14 grid'>
          <div className='flex flex-col items-start row-start-1'>
            <h1 className='text-2xl font-semibold mt-4'>Enter 6-digit code</h1>
            <div className='text-sm text-slate-500'>
              Please enter 6-digit code sent to <span>{maskEmail(email)}</span>{' '}
              to reset password.
            </div>
          </div>
          <div className='row-start-2'>
            <OtpInput
              value={code}
              onChange={setcode}
              numInputs={6}
              renderSeparator={<span style={{ width: '25px' }}> </span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                border: '1px solid #CDCDCD',
                borderRadius: '12px',
                height: '70px',
                width: '60px',
              }}
            />
            {error && (
              <div className='flex pt-5 text-sm' style={{ color: '#FF1B1B' }}>
                <PiWarningCircle size={20} className='mr-2' />
                {error}
              </div>
            )}
            <div className='pt-2'>{ResendMsg}</div>
          </div>
          <div className='flex justify-center item-center h-12 row-start-7 mt-40 gap-5'>
            <button
              className='bg-white-500 border border-teal-400 text-gray-400 text-base px-3 rounded-xl font-bold w-60'
              type='button'
              onClick={ResendOpt}
            >
              Resend
            </button>
            <button
              className='text-white py-2 px-20 shadow-buttonPrimary bg-text-dm-input rounded-xl backdrop-blur-sm w-60'
              type='submit'
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
