import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'
import { defaultProfileImg } from '_utils/default'
import useQnA from '_hooks/useQnA'

const UserDetailsHashtags = ({ user }) => {
  const [hashCount, setHashCount] = useState(undefined)
  const [tags, setTags] = useState([])
  const [limit] = useState(2000)
  const [page, setPage] = useState(0)
  const [activeTag, setactiveTag] = useState(null)


  const { handleOpen } = useQnA()

  const fetchTags = async () => {
    const skip = page * limit
    axiosInstance.get(`/admin/user/hashtag/comment/${user.id}`,
      {
        params: {
          skip,
          limit
        }
      }
    )
      .then(res => res.data)
      .then(data => {
        setHashCount(data?.data?._count)
        setTags(data?.data?._tags)
        setPage(prev => prev + 1)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (user) {
      fetchTags()
    }
  }, [user])


  return (
    <div className='w-full h-[88vh] flex flex-col gap-2'>
      <div className='flex justify-start items-center'><h3 className='text-lg font-bold mr-2'>Hashtags</h3> {typeof hashCount !== 'undefined' && <p className='text-lg font-bold'>{hashCount}</p>}</div>
      <div className=' shadow-customBtnShadow w-full h-full rounded-xl grid grid-cols-card-md place-content-start justify-self-start p-4 overflow-y-auto max-h-full'>
        <>
          {
            tags.map((item, index) => (
              <button
                key={index}
                className={`flex flex-nowrap gap-x-2 p-3 items-center justify-start ${activeTag === item?.hashtag?.id ? 'text-text-dm-input' : ''
                  }`}
                onClick={() => {
                  handleOpen(item?.hashtag?.id)
                  setactiveTag(item?.hashtag?.id)
                }}
              >
                <img
                  src={defaultProfileImg(item?.hashtag?.media?.fileURL, 'hashtag')}
                  alt='hashtag'
                  className='w-12 h-12 rounded-full'
                />
                <h6 className='text-sm font-bold underline'>#{item?.hashtag?.name}</h6>
              </button>
            ))}
        </>
      </div>
    </div>
  )
}

UserDetailsHashtags.propTypes = {
  user: PropTypes.object,
}

export default UserDetailsHashtags
