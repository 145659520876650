import LightBtn from 'components/global/Buttons/LightBtn'
import { MoveLeft, PlusCircle } from 'lucide-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import PropsTypes from 'prop-types'
import moment from 'moment'
import config from 'config/config'
import User from 'components/global/User'
import Post from 'components/global/Post'
import { connectSocket, disconnectSocket, sendMessage } from '../../socket' // Import socket functions
import useAuth from '_hooks/useAuth'
import { MdMessage } from 'react-icons/md'
import RightCanvas from 'components/global/RightCanvas'
import Loader from 'components/global/Loader'
import Input from 'components/global/Input'

const ReportHistory = ({ reportingArray }) => {
  const { user } = useAuth()

  const [openChatHistory, setOpenChatHistory] = useState(false)
  const [chatLoader, setChatLoader] = useState(false)
  const [messages, setMessages] = useState([])
  const currentPostId = useRef(null)
  const messageEndRef = useRef(null)
  const inputRef = useRef()

  // Function to scroll to the bottom of chat messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleOpenChatHistory = (postId) => {
    currentPostId.current = postId
    setOpenChatHistory(true)
    setChatLoader(true)
    connectSocket(
      postId,
      (message) => {
        setMessages((prevMessages) => [...prevMessages, message])
        scrollToBottom()
      },
      (oldMessages) => {
        setChatLoader(false)
        setMessages(oldMessages)
        scrollToBottom()
      }
    )
  }

  const chatClose = () => {
    setOpenChatHistory(false)
    disconnectSocket() // Disconnect socket when chat is closed
  }

  const handleSendMessage = () => {
    if (inputRef.current.value.trim()) {
      const messageData = {
        contentId: currentPostId.current,
        adminId: user?.id,
        adminFullName: user?.fullName || 'Admin',
        message: inputRef.current.value,
        createdAt: moment().format(),
      }

      sendMessage(messageData) // Use sendMessage from the socket.js
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    if (openChatHistory) {
      scrollToBottom()
    }
  }, [messages, openChatHistory])

  // Custom cell styles for DataTable
  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
  }

  const columns = [
    {
      // name: 'Received Date',
      name: (
        <div className='flex w-full'>
          Received Date
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) =>
        moment(row?.createdAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      sortable: true,
      minWidth: '100px',
    },
    {
      // name: 'Reason',
      name: (
        <div className='flex w-full'>
          Reason
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <div className='flex-wrap'>
          {row?.commentReport?.reason?.reason ||
            row?.userReport?.reason?.reason ||
            row?.postReport?.reason?.reason ||
            row?.memoReport?.reason?.reason}
        </div>
      ),
    },
    {
      // name: 'User Info',
      name: (
        <div className='flex w-full'>
          User Info
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '180px',
      cell: (row) => (
        <User
          user={
            row?.commentReport?.comment?.user ||
            row?.userReport?.reportedUser ||
            row?.postReport?.post?.users ||
            row?.memoReport?.memo?.user
          }
        />
      ),
    },
    {
      name: 'Target Data',
      sortable: true,
      minWidth: '160px',
      cell: (row) => (
        <div>
          {row?.type === 'user' ? (
            <p>For account</p>
          ) : (
            <>
              <Post
                post={
                  row?.commentReport?.comment?.post || row?.postReport?.post
                }
                showId={true}
                rowId={row?.id}
              />
            </>
          )}
        </div>
      ),
    },
    {
      // name: 'Objection ID',
      name: (
        <div className='flex w-full'>
          Objection ID
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '20px',
      cell: (row) => <p className='truncate'>{row?.uid}</p>,
    },
    {
      // name: 'Status',
      name: (
        <div className='flex w-full'>
          Status
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => <p>{row?.status.replace(/_/g, ' ')}</p>,
    },
    {
      // name: 'Result',
      name: (
        <div className='flex w-full'>
          Result
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <>
          <select className='text-text-dm-input items-center outline-none bg-universal'>
            <option value='master'>{row?.result.replace(/_/g, ' ')}</option>
            <option value='none' className='text-text-dm-input font-medium'>
              Pending
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Overruled
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Accepted
            </option>
          </select>
        </>
      ),
    },
    {
      // name: 'PIC',
      name: (
        <div className='flex w-full'>
          PIC
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <p
          className='truncate'
          style={{ color: row?.pic?.fullName ? '#000000' : '#585858' }}
        >
          {row?.pic?.fullName || 'None'}
        </p>
      ),
    },
    {
      name: <div className='flex w-full'>Chat</div>,
      sortable: false,
      minWidth: '10px',
      cell: (row) => (
        <div className='flex'>
          <MdMessage
            size={25}
            className='!text-text-dm-input cursor-pointer'
            onClick={() => handleOpenChatHistory(row.id)}
          />
        </div>
      ),
    },
  ]

  const goBack = () => {
    window.history.back()
  }

  return (
    <>
      <div className='md:!w-[956px] xl:!w-[1055px] 2xl:!w-[99%] DataTableMain'>
        <div className='w-full flex gap-x-2 items-center'>
          <LightBtn onClick={goBack}>
            <MoveLeft size={16} />
          </LightBtn>
          <h1 className='text-base font-bold '> Report History </h1>
        </div>
        <div className='rounded-xl w-full max-w-full h-full'>
          <DataTable
            columns={columns}
            data={reportingArray}
            highlightOnHover
            selectableRows
            customStyles={customCellStyles}
            className='md:!w-[700px] xl:!w-[780px] 2xl:!w-[100%] DataTable'
            // onSelectedRowsChange={(row) => _setSelectedRows(row?.selectedRows)}
          />
        </div>
      </div>
      <RightCanvas isOpen={openChatHistory} onClose={chatClose}>
        <div className='h-screen flex flex-col overflow-hidden'>
          {' '}
          {/* Prevent outer scrolling */}
          {/* Sticky Header */}
          <div className='relative p-5 border-b sticky top-0 bg-white z-10'>
            {' '}
            {/* Sticky header */}
            <PlusCircle
              className='rotate-45 absolute left-4 top-5 cursor-pointer'
              onClick={chatClose}
            />
            <h1 className='text-center text-base font-bold'>Chat</h1>
          </div>
          {/* Chat messages */}
          <div className='flex flex-col justify-between h-full overflow-hidden'>
            <div className='p-5 overflow-y-auto flex-grow'>
              {' '}
              {/* Only this section scrolls */}
              {chatLoader ? (
                <Loader loading={chatLoader} />
              ) : (
                <Fragment>
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`p-4 shadow-customBtnShadow max-w-xs rounded-xl mt-3 clear-both max-w-[250px] ${
                        msg.adminId === user?.id ? 'float-right' : 'float-left'
                      }`}
                    >
                      <span className='w-full flex justify-between items-center gap-3'>
                        <h4 className='text-sm font-medium'>
                          {msg.adminFullName || 'Unknown'}
                        </h4>
                        <h5 className='text-xs font-light '>
                          {moment(msg.createdAt).format('HH:mm')}
                        </h5>
                      </span>
                      <div className='text-sm font-light max-w-[218px]'>
                        <p className='text-wrap break-all'>{msg.message}</p>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <div ref={messageEndRef} /> {/* Scroll to this div */}
            </div>

            {/* Sticky Input message */}
            <div className='p-5 sticky bottom-0 bg-white z-10'>
              {' '}
              {/* Sticky input */}
              <Input
                ref={inputRef}
                placeHolder='Start typing message...'
                inputClassName='bg-white'
                className='!p-1 !border-gray-400 !pl-5 !text-sm'
                rightIcon={
                  <button
                    onClick={handleSendMessage}
                    className='bg-text-dm-input shadow-buttonPrimary p-2 flex justify-center items-center rounded-xl'
                  >
                    <img src='/assets/svg/send-2.svg' alt='SendBtnIcon' />
                  </button>
                }
              />
            </div>
          </div>
        </div>
      </RightCanvas>
    </>
  )
}

ReportHistory.propTypes = {
  reportingArray: PropsTypes.array,
}

export default ReportHistory
