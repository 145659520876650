import React, { useEffect } from 'react'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent'
import { GoLocation } from 'react-icons/go'
import { CiSearch } from 'react-icons/ci'
import { Grid } from '@mui/material'
import GlobalAccordion from 'components/global/GlobalAccordion'
import PropTypes from 'prop-types'
import LightBtn from 'components/global/Buttons/LightBtn'
import { MoveLeft } from 'lucide-react'
import useSettings from '_hooks/useSettings'

const ActionHistory = ({ savedSearch, recentSearch, id }) => {
  const { fetchActionHistory, actionHistory } = useSettings()

  const goBack = () => {
    window.history.back()
  }

  useEffect(() => {
    if (id) {
      fetchActionHistory(id)
    }
  }, [id])

  console.log(actionHistory, 'actionHistory')

  return (
    <div className='md:w-[980px] xl:w-[1080px] 2xl:w-[100%]'>
      {/* <div className='w-full max-h-[88vh] flex flex-col gap-4 relative border border-black'> */}
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center justify-between'>
        <div className='flex gap-x-2 items-center'>
          <LightBtn onClick={goBack}>
            <MoveLeft size={16} />
          </LightBtn>
          <h1 className='text-base font-bold '> Action History</h1>
        </div>
        <div className='flex gap-x-2 items-center'>
          <h1 className='text-base font-bold '>Select a period:</h1>
          <LightBtn dropdown active={true} className='font-medium text-base'>
            <span className='text-sm'>Last Week</span>
            <div id='options'>
              {/* Dropdown options */}
              <button className='hover:text-text-dm-input ease-in-out text-base'>
                Today
              </button>
              <button className='hover:text-text-dm-input ease-in-out text-base'>
                Yesterday
              </button>
              <button className='hover:text-text-dm-input ease-in-out text-base'>
                Last Week
              </button>
              <button className='hover:text-text-dm-input ease-in-out text-base'>
                Last Month
              </button>
              <button className='hover:text-text-dm-input ease-in-out text-base'>
                Custom
              </button>
            </div>
          </LightBtn>
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <GlobalAccordion
            header={'30/09/2023'}
            content={
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineOppositeContent
                    className='font-poppins'
                    color='text.secondary'
                  >
                    13:00
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='font-poppins'>
                    Logout
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    12:45
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='flex !font-poppins items-start'>
                    Replied to memo
                    <div className='flex items-center ml-4'>
                      <img
                        src='https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/06/06/15/Chris-Pratt.jpg'
                        alt='iko'
                        className='w-8 h-8 rounded-full'
                      />
                      <div className='ml-2'>
                        <h3 className='text-sm not-italic font-semibold'>
                          io-data
                        </h3>
                        <p className='text-sm font-normal underline underline-offset-2'>
                          Robotty555
                        </p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    12:30
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='flex !font-poppins items-start'>
                    Liked
                    <div className='flex items-center ml-24'>
                      <img
                        src='https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/06/06/15/Chris-Pratt.jpg'
                        alt='iko'
                        className='w-7 h-10'
                      />
                      <img
                        src='https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/06/06/15/Chris-Pratt.jpg'
                        alt='iko'
                        className='w-8 h-8 ml-2 rounded-full'
                      />
                      <div className='ml-2'>
                        <h3 className='text-sm not-italic font-semibold'>
                          io-data
                        </h3>
                        <p className='text-sm font-normal underline underline-offset-2'>
                          Robotty555
                        </p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    11:50
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='flex !font-poppins items-start'>
                    Posted
                    <div className='flex items-center ml-20'>
                      <img
                        src='https://static.independent.co.uk/s3fs-public/thumbnails/image/2015/06/06/15/Chris-Pratt.jpg'
                        alt='iko'
                        className='w-7 h-10'
                      />
                    </div>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    11:00
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='!font-poppins'>
                    Timeline
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    10:05
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className='!font-poppins'>
                    Searched
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    className='!font-poppins'
                    color='text.secondary'
                  >
                    10:00
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className='black' />
                    {/* <TimelineConnector /> */}
                  </TimelineSeparator>
                  <TimelineContent className='flex !font-poppins items-start'>
                    Login
                    <div className='flex items-start ml-3'>
                      <GoLocation strokeWidth={1} size={20} />
                      <h3 className='text-sm not-italic text-black leading-normal !font-poppins font-normal pl-1'>
                        Android, Sony Xperia 10 III / Near
                      </h3>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <GlobalAccordion
            header={'Search History'}
            content={
              <div className='p-1 overflow-y-scroll max-h-[15vh]'>
                <ul>
                  {recentSearch?.map((history) => {
                    return (
                      <li className='p-1 flex items-center' key={history?.id}>
                        <CiSearch />{' '}
                        <h3 className='pl-1 font-poppins !text-dark text-xs font-medium leading-normal'>
                          {history?.term}
                        </h3>
                      </li>
                    )
                  })}
                </ul>
              </div>
            }
          />
          <GlobalAccordion
            header={'Saved'}
            content={
              <div className='p-1 min-h-[47vh]'>
                <ul>
                  {savedSearch?.map((history) => {
                    return (
                      <li className='p-1 flex items-center' key={history?.id}>
                        <CiSearch />{' '}
                        <h3 className='pl-1 font-poppins !text-dark text-xs font-medium leading-normal'>
                          {history?.term}
                        </h3>
                      </li>
                    )
                  })}
                </ul>
              </div>
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

ActionHistory.propTypes = {
  savedSearch: PropTypes.array,
  recentSearch: PropTypes.array,
  id: PropTypes.string,
}

export default ActionHistory
