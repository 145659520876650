import React, { createContext, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'

const CcpaContext = createContext({
  fetchUsers: async () => null,
  data: Array(),
  deleteUsers: async () => null,
  isError: false,
  errorMessage: null,
  search: '',
  handleSearchChange: () => null,
  age: {},
  handleAgeChange: () => null,
  gender: {},
  handleGenderChange: () => null,
  status: {},
  handleStatusChange: () => null,
  selectedRows: Array(),
  handleSelectedRowsChange: () => null
})

export const UserProvider = ({ children }) => {

  const [search, setSearch] = useState()
  const [data, setData] = useState(Array())
  const [age, setAge] = useState({})
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedRows, setSelectedRows] = useState(Array())

  const [gender, setGender] = useState({})
  const [status, setStatus] = useState({})

  const fetchUsers  = async () => {
    try {
      // const body = {
      //   search,
      //   age: Object.keys(age).filter(item => age[item]),
      //   status: Object.keys(status).filter(_status => status[_status]),
      //   gender: Object.keys(gender).filter(_gen => gender[_gen]),
      // }

      // const data = await axiosInstance.get('/admin/ccpa', body)
      console.log(data);
      setData(data?.data?.data)
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }

  const deleteUsers = async () => {
    try {
      const ids = selectedRows.map(sel => sel.id)
      await axiosInstance.delete('/admin/user', {
        data: { ids }
      })
      setIsError(false)
    } catch(err) {
      setIsError(true)

    }
    
  }

  const handleSearchChange = (value) => {
    setSearch(value)
  }
  const handleAgeChange = (value) => {
    setAge(value)
  }

  const handleStatusChange = (value) => {
    setStatus(value)
  }

  const handleGenderChange = (value) =>{
    setGender(value)
  }

  const handleSelectedRowsChange = (value) => {
    setSelectedRows(value)
  }

  useEffect(() => {

    async function callAPI() {
      await fetchUsers()
    }

    callAPI()
  }, [search])

  return (
    <CcpaContext.Provider
      value={{
        search,
        data,
        age,
        isError,
        errorMessage,
        gender,
        status,
        selectedRows,
        fetchUsers,
        deleteUsers,
        handleSearchChange,
        handleAgeChange,
        handleStatusChange,
        handleGenderChange,
        handleSelectedRowsChange
      }}
    >
      { children }
    </CcpaContext.Provider>
  )
}
UserProvider.propTypes = {
  children: PropsTypes.node
}


export default CcpaContext