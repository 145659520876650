import { PlusCircle } from 'lucide-react'
import React from 'react'
import PropTypes from 'prop-types'
import { startAndEnd } from '_utils/date'

const ResumeDrawer = ({ type, data, onClose }) => {
  return (
    <div className='px-5'>
      {/* drawer header */}
      <div className=' flex items-center gap-4 border-b p-4'>
        <PlusCircle
          onClick={onClose}
          className='rotate-45 font-bold cursor-pointer'
          size={22}
        />
        <div className='flex items-center gap-3'>
          <img
            src='/assets/Image/resumeicon.jpeg'
            className='rounded-full w-10 h-10'
            alt='resumeIcon'
          />
          <div>
            <h3 className='font-bold text-sm'>
              {data?.name || data?.school || data?.skill}
            </h3>
            <h3 className='text-sm font-semibold'>
              {data?.title || data?.degree}
            </h3>
          </div>
        </div>
      </div>
      <h6 className='flex justify-end py-2 text-xs font-light'>
        {type !== 'certificate'
          ? startAndEnd(data?.startDate, data?.endDate, data?.isPresent)
          : startAndEnd(data?.issueDate, data?.expireDate, data?.isPresent)}
      </h6>
      {/* Drawer Content */}
      <div className='py-3'>
        <p className='text-sm font-normal'>{data?.description}</p>
      </div>
    </div>
  )
}

ResumeDrawer.propTypes = {
  type: PropTypes.oneOf([
    'experience',
    'education',
    'skill',
    'certificate',
    '',
  ]),
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default ResumeDrawer
