import LightBtn from 'components/global/Buttons/LightBtn'
import UserSidebarButton from 'components/global/Buttons/UserSidebarButton'
import ImageSlider from 'components/global/ImageSlider'
import Popper from 'components/global/Popper'
import PropsTypes from 'prop-types'
import CustomTrash from 'components/global/icons/CustomTrash'
import Message from 'components/global/icons/Message'
import { ChevronDown, Info, PlusCircle, XCircle } from 'lucide-react'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { FiHeart } from 'react-icons/fi'
import ToggleButton from 'components/global/ToggelButton'
import clsx from 'clsx'
import LocationPin from 'components/global/icons/LocationsPin'
import RightCanvas from 'components/global/RightCanvas'
import Input from 'components/global/Input'
import { GoAlert } from 'react-icons/go'
import GlobalModal from 'components/global/GlobalModal'
import axiosInstance from '_utils/axiosUtils'
import Loader from 'components/global/Loader'
import moment from 'moment'
import config from 'config/config'
import { connectSocket, disconnectSocket, sendMessage } from '../../socket' // Import socket functions
// import { FaRegFlag } from 'react-icons/fa'
import LoaderIcon from 'components/global/LoaderIcon'
import { defaultProfileImg } from '_utils/default'
import { TextField } from '@mui/material'
import { RiLockPasswordLine } from 'react-icons/ri'
import useAuth from '_hooks/useAuth'

const PostPreview = ({ setQrCode, qrCode, postId, setComponent }) => {
  // for popper
  const locPopperRef = useRef()
  const labelsPopperRef = useRef()
  const [openLocPopper, setOpenLocPopper] = useState(false)
  const [openLabelsPopper, setOpenLabelsPopper] = useState(false)
  // Delete Post Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  // location slider
  const { user } = useAuth()

  const [openChatHistory, setOpenChatHistory] = useState(false)
  const [chatLoader, setChatLoader] = useState(false)
  const [messages, setMessages] = useState([])
  const currentPostId = useRef(null)
  const messageEndRef = useRef(null)
  const inputRef = useRef()

  // Function to scroll to the bottom of chat messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleOpenChatHistory = (postId) => {
    currentPostId.current = postId
    setOpenChatHistory(true)
    setChatLoader(true)
    connectSocket(
      postId,
      (message) => {
        setMessages((prevMessages) => [...prevMessages, message])
        scrollToBottom()
      },
      (oldMessages) => {
        setChatLoader(false)
        setMessages(oldMessages)
        scrollToBottom()
      }
    )
  }

  const chatClose = () => {
    setOpenChatHistory(false)
    disconnectSocket() // Disconnect socket when chat is closed
  }

  const handleSendMessage = () => {
    if (inputRef.current.value.trim()) {
      const messageData = {
        contentId: currentPostId.current,
        adminId: user?.id,
        adminFullName: user?.fullName || 'Admin',
        message: inputRef.current.value,
        createdAt: moment().format(),
      }

      sendMessage(messageData) // Use sendMessage from the socket.js
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    if (openChatHistory) {
      scrollToBottom()
    }
  }, [messages, openChatHistory])

  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(null)
  const [delLoading, setDelLoading] = useState(false)
  const [VideoPhoto, setVideoPhoto] = useState('')

  const [isOpenPointScale, setIsPointScale] = useState(false)

  const [lock, setLock] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openVerifyModal, setOpenVerifyModal] = useState(false)
  const [hasOTP, setHasOTP] = useState(false)
  const [pass, setPass] = useState('')
  const [locNumber, setLocNumber] = useState(0)
  const [tempNum, setTempNum] = useState(0)

  console.log(user, 'user')

  useEffect(() => {
    setHasOTP(!post?.score ? false : post?.score?.isLocked ? true : false)
    setLock(!post?.score ? false : post?.score?.isLocked ? true : false)
    setLocNumber(post?.score?.score)
    setTempNum(post?.score?.score)
  }, [post])

  const fetchPost = async (_id) => {
    setLoading(true)
    await axiosInstance
      .get(`/admin/post/${_id}`)
      .then((res) => res.data)
      .then((res) => {
        setPost(res?.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const handleConfirmDelete = async () => {
    setDelLoading(true)
    axiosInstance
      .delete(`/admin/post/${post?.id}`)
      .then(() => {
        setOpenDeleteModal(false)
        setComponent(0)
      })
      .catch((err) => console.log(err))
      .finally(() => setDelLoading(false))
  }

  const locModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }
  const LabelsPopperModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [30, -140],
        },
      },
    ],
  }

  // onclick id status toggel
  const [sideComponent, setSideComponent] = useState('memo')
  const handleStatusChange = (value) => setSideComponent(value)

  const imageURI = useMemo(() => {
    // return post?.postMedia?.map(m => m.fileURL) || []
    if (post && post.postMedia[0].fileType === 'video/mp4') {
      const videos = post.postMedia.map((m) => m.fileURL)
      setVideoPhoto('video')
      return [...videos]
    } else {
      setVideoPhoto('Photo')
      return post?.postMedia?.map((m) => m.fileURL) || []
    }
  }, [post, post?.postMedia])

  useEffect(() => {
    if (postId) {
      fetchPost(postId)
    }
  }, [postId])

  const toggleDropedownByPointScale = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
  }

  const PointScaleCancel = () => {
    setTempNum(locNumber)
    setIsPointScale(false)
  }

  const savePointScale = () => {
    setLocNumber(tempNum)
    setIsPointScale(false)
  }

  const getColor = (index) => {
    if (index + 1 === tempNum) {
      return 'white' // Selected number color
    } else if (index + 1 <= 3) {
      return '#FF0000' // 1, 2, 3 color
    } else if (index + 1 <= 6) {
      return '#FF7F00' // 4, 5, 6 color
    } else {
      return '#29BC52' // 7, 8, 9, 10 color
    }
  }

  const updateScorePost = async (id, postId, score, isLocked = false, pass) => {
    let tempObj = { postId: postId, score: score, isLocked }
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    if (pass && isLocked) {
      tempObj = { ...tempObj, pin: pass }
    }
    try {
      const response = await axiosInstance.put(
        '/admin/post/score/update',
        tempObj
      )
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const verifyOTP = async (id, pass) => {
    let tempObj = {}
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    tempObj = { ...tempObj, pin: pass }
    try {
      const response = await axiosInstance.post(
        '/admin/post/score/verifyPin',
        tempObj
      )
      if (response.status === 200) {
        setHasOTP(false)
        setLock(false)
        setOpenVerifyModal(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const inputRefs = useRef([])

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '')

    e.target.value = value

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      //focus on the next one when a digit is entered
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      // focus on the previous one when a digit is removed
      inputRefs.current[index - 1].focus()
    }
    setPass('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const pass = inputRefs.current.map((input) => input.value).join('')
    if (pass) {
      updateScorePost(post?.score?.id, post?.id, tempNum, lock, pass)
    }
  }

  const handleVerifySubmit = (e) => {
    e.preventDefault()
    const pass = inputRefs.current.map((input) => input.value).join('')
    verifyOTP(post?.score?.id, pass)
  }

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div className='w-full flex gap-x-4'>
      <div className='p-4 shadow-customBtnShadow w-3/5 rounded-xl'>
        {post?.postMedia?.length && imageURI && (
          <ImageSlider
            imageUrls={imageURI}
            className='w-full h-2/3 aspect-square rounded-xl overflow-hidden'
            VideoPhoto={VideoPhoto}
          />
        )}
        {/* Post Details  */}
        <div className='space-y-1'>
          <p className=' text-xs font-medium leading-5 py-1'>
            {post?.description}
          </p>
          {/* Hashtags and counts  */}
          <div className='flex'>
            {/* hashtags  */}
            <div className='flex-1 flex flex-wrap gap-x-2'>
              {post?.postTags?.map((t, index) => (
                <a
                  href={'/hashtag'}
                  key={index}
                  className='text-xs font-bold underline'
                >
                  #{t?.hashtag?.name}
                </a>
              ))}
            </div>
            {/* Post Details */}
            <div className='flex-1 flex gap-x-2 items-start justify-evenly'>
              <span className='flex flex-nowrap items-center'>
                <img src='/assets/Icons/eye.svg' alt='eye' className='w-4' />{' '}
                &nbsp; <u className='text-xs'>0</u>
              </span>
              <span className='flex flex-nowrap justify-center items-center'>
                <FiHeart size={16} fill='#f00' color='#f00' /> &nbsp;
                <u className='text-xs'>{post?._count?.likes}</u>
              </span>
              <span className='flex flex-nowrap items-center'>
                <img
                  src='/assets/svg/bookmark.svg'
                  alt='BookmarkIcon'
                  width={14}
                  height={14}
                />
                {/* <Bookmark size={17} /> */}
                &nbsp; <u className='text-xs'>{post?._count?.saved}</u>
              </span>
              <span className='flex flex-nowrap items-center'>
                <img
                  src='/assets/Icons/message.svg'
                  alt='eye'
                  className='w-4'
                />{' '}
                &nbsp; <u className='text-xs'>{post?._count?.comments}</u>
              </span>
              <span className='flex flex-nowrap items-center'>
                <img src='/assets/Icons/shared.svg' alt='eye' className='w-4' />{' '}
                &nbsp; <u className='text-xs'>{post?._count?.share}</u>
              </span>
            </div>
          </div>
          {/* Date and Language */}
          <h6 className='text-xs font-normal py-2'>
            {moment(post?.createdAt).format(config.DATE_FORMAT)}
            <span style={{ paddingLeft: '20px' }}>English</span>
          </h6>
          {/* post info buttons */}
          <div className='flex items-center justify-between gap-x-3'>
            <button
              onClick={() => handleStatusChange('status')}
              className='flex-1 underline bg-universal py-2.5 px-3 shadow-customBtnShadow rounded-xl flex flex-nowrap items-center text-xs text-ellipsis truncate font-medium justify-center'
            >
              {post?.id.slice(0, 9) + '...'}
            </button>
            <button
              onClick={() => setQrCode(true)}
              className={clsx(
                'flex-1 bg-universal py-2.5 px-3 shadow-customBtnShadow rounded-xl flex flex-nowrap border border-transparent items-center text-xs font-medium justify-center',
                qrCode && '!shadow-active-btn  !border-text-dm-input'
              )}
            >
              QR Code
            </button>
            {post?.isReported && (
              <button className='flex-1 px-3 py-2.5 shadow-customBtnShadow bg-universal cursor-pointer rounded-xl text-xs font-semibold flex items-center  gap-x-2 justify-center text-pending'>
                Pending <ChevronDown size={12} />
              </button>
            )}
            {post?.isReported && (
              <button className='flex-1 bg-universal py-2.5 px-3 shadow-customBtnShadow rounded-xl flex flex-nowrap items-center text-xs font-medium justify-center'>
                Jennifer
              </button>
            )}
            <button
              ref={locPopperRef}
              onClick={toggleDropedownByPointScale}
              disabled={!user?.authorityName?.includes('change_point')}
              className=' bg-universal py-2.5 px-3 shadow-customBtnShadow rounded-xl flex flex-nowrap items-center text-xs font-medium justify-center'
            >
              {locNumber}
              <ChevronDown size={14} />
            </button>
            <Popper
              open={openLocPopper}
              popperOptions={locModifier}
              className='!mt-3'
              onClose={() => setOpenLocPopper(false)}
              refElement={locPopperRef.current}
            >
              {isOpenPointScale && (
                <div
                  className='absolute z-10 -left-12 !border-text-dm-input !border-input-border !border-solid w-48 h-36 rounded-xl flex items-center justify-center'
                  style={{
                    backgroundColor: '#F9FAFF',
                    marginTop: '-20px',
                    marginLeft: '-50px',
                  }}
                >
                  <div className='w-full px-3 py-0'>
                    <div className='w-full flex flex-col pb-2'>
                      <div className='w-full flex justify-between gap-x-1'>
                        {[...Array(10)].map((_, index) => (
                          <button
                            key={index}
                            onClick={() => !lock && setTempNum(index + 1)}
                            value={tempNum}
                            style={{
                              color: getColor(index),
                            }}
                          >
                            {tempNum === index + 1 ? (
                              <LocationPin number={index + 1} color='black' />
                            ) : (
                              index + 1
                            )}
                          </button>
                        ))}
                      </div>
                      <progress
                        className='h-1 w-full'
                        value={tempNum}
                        max='10'
                        color={getColor(locNumber)}
                        content={getColor(locNumber)}
                        style={{
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: getColor(locNumber),
                        }}
                      ></progress>
                    </div>

                    {/* unlock or lock */}
                    <div className='flex flex-nowrap gap-x-2 items-center'>
                      <img src='/assets/svg/lock.svg' alt='' />{' '}
                      <p
                        className={clsx(
                          'text-xs font-medium',
                          !lock && '!font-bold'
                        )}
                      >
                        Unlock
                      </p>
                      <ToggleButton
                        toggle={lock}
                        setToggle={() => {
                          if (lock) {
                            if (hasOTP) {
                              setOpenVerifyModal(true)
                            } else {
                              setLock(!lock)
                            }
                          } else {
                            setLock(!lock)
                          }
                          setIsChange(true)
                        }}
                      />
                      <p
                        className={clsx(
                          'text-xs font-medium',
                          lock && '!font-bold'
                        )}
                      >
                        Lock
                      </p>
                    </div>
                    <div className='flex py-2 gap-x-2 w-full'>
                      <button
                        className='px-3 py-1 w-1/2 bg-text-dm-input shadow-buttonPrimary h-9 rounded-xl text-sm text-white font-semibold'
                        onClick={() => {
                          if (lock === false) {
                            savePointScale()
                            updateScorePost(
                              post?.score?.id,
                              post?.id,
                              tempNum,
                              lock,
                              pass
                            )
                          } else {
                            if (isChange) {
                              setOpenModal(true)
                            }
                          }
                        }}
                      >
                        Save
                      </button>
                      <button
                        onClick={PointScaleCancel}
                        className='px-3 py-1 w-1/2 bg-universal h-9 border border-teal-400 text-gray-400 rounded-xl text-sm font-medium'
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Popper>

            <GlobalModal
              isOpen={openModal}
              handleClose={() => setOpenModal(false)}
              height={432}
            >
              <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
                {/* Icon */}
                <div className='flex justify-center items-center w-100'>
                  <RiLockPasswordLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
                </div>
                <div className='flex flex-col text-center gap-y-4'>
                  {/* text */}
                  <div className='flex flex-col items-center gap-y-1'>
                    <h6 className='font-medium text-md'>
                      {`Do you want to ${lock ? 'lock' : 'unlock'} the score?`}
                    </h6>
                    {lock ? (
                      <p>
                        If you lock the score, it will not fluctuate
                        automatically
                      </p>
                    ) : (
                      <p>
                        If you unlock the score, it will fluctuate automatically
                      </p>
                    )}
                  </div>

                  <p>Enter Passcode</p>
                  <div className='flex gap-3 px-4 otp-text'>
                    {[...Array(4)].map((_, index) => (
                      <TextField
                        key={index}
                        type='password'
                        fullWidth
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        placeholder='0'
                        variant='outlined'
                        error={false}
                        name={`otp-${index}`}
                        inputProps={{
                          maxLength: 1,
                          type: 'password',
                          pattern: '[0-9]*',
                        }}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        sx={{ textAlign: 'center !important' }}
                      />
                    ))}
                  </div>
                </div>
                <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
                  <button
                    onClick={handleSubmit}
                    disabled={
                      inputRefs?.current?.filter((input) => input?.value)
                        ?.length === 4
                    }
                    className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
                  >
                    Save
                  </button>
                  <button
                    className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </GlobalModal>

            <GlobalModal
              isOpen={openVerifyModal}
              handleClose={() => setOpenVerifyModal(false)}
              height={432}
            >
              <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
                {/* Icon */}
                <div className='flex justify-center items-center w-100'>
                  <RiLockPasswordLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
                </div>
                <div className='flex flex-col text-center gap-y-4'>
                  {/* text */}
                  <div className='flex flex-col items-center gap-y-1'>
                    <h6 className='font-medium text-md'>
                      Do you want to unlock the score?
                    </h6>
                    <p>
                      If you unlock the score, it will fluctuate automatically
                    </p>
                  </div>

                  <p>Enter Passcode</p>
                  <div className='flex gap-3 px-4 otp-text'>
                    {[...Array(4)].map((_, index) => (
                      <TextField
                        key={index}
                        type='password'
                        fullWidth
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        placeholder='0'
                        variant='outlined'
                        error={false}
                        name={`otp-${index}`}
                        inputProps={{
                          maxLength: 1,
                          type: 'password',
                          pattern: '[0-9]*',
                        }}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        sx={{ textAlign: 'center !important' }}
                      />
                    ))}
                  </div>
                </div>
                <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
                  <button
                    onClick={handleVerifySubmit}
                    className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
                  >
                    Save
                  </button>
                  <button
                    className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
                    onClick={() => setOpenVerifyModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </GlobalModal>

            <Message
              className='cursor-pointer'
              onClick={() => handleOpenChatHistory(post?.id)}
            />
            {post?.isReported ? (
              <img src='/assets/svg/bookmarkFlag.svg' alt='Bookmark' />
            ) : (
              <img src='/assets/svg/bookmarkFlag.svg' alt='Bookmark' />
              // <FaRegFlag className='text-black' />
            )}
          </div>
          {/* Detected lables and delete button */}
          <div className='flex flex-col '>
            {/* detected Labels */}
            <h6 className='text-xxs font-medium py-2'>Detected Labels:</h6>
            <div className='flex items-start'>
              <div className='flex-1 flex flex-nowrap gap-x-2'>
                {post?.isReported ? (
                  <>
                    <button className='text-xs !px-3 py-2.5 font-medium  border rounded-xl'>
                      Rude Gestures
                    </button>
                    <button className='text-xs !px-3 py-2.5 font-medium  border rounded-xl'>
                      Tobacco
                    </button>
                    <button className='text-xs !px-3 py-2.5 font-medium  border rounded-xl'>
                      Tobacco
                    </button>
                    <button
                      ref={labelsPopperRef}
                      onClick={() => setOpenLabelsPopper(true)}
                      className='text-xs !px-3 py-2 !text-text-dm-input font-medium underline border rounded-xl'
                    >
                      +3
                    </button>

                    {/* Detected lables popper  */}
                    <Popper
                      open={openLabelsPopper}
                      popperOptions={LabelsPopperModifier}
                      onClose={() => setOpenLabelsPopper(false)}
                      refElement={labelsPopperRef.current}
                    >
                      <div className='border rounded-xl relative flex flex-col bg-universal border-text-dm-input'>
                        <XCircle
                          size={14}
                          onClick={() => setOpenLabelsPopper(false)}
                          className='cursor-pointer absolute right-1 top-1'
                        />
                        <span className='px-3 py-2 text-xs font-normal'>
                          Tobacco
                        </span>
                        <span className='px-3 py-2 text-xs font-normal'>
                          Rude Manners
                        </span>
                        <span className='px-3 py-2 text-xs font-normal'>
                          Rude Gestures
                        </span>
                      </div>
                    </Popper>
                  </>
                ) : (
                  <h6 className='underline text-sm'>No labels</h6>
                )}
              </div>
              {/* Delete Button */}
              <UserSidebarButton
                className='text-badge-danger flex-1 !py-2.5 shadow-customBtnShadow border-transparent text-xs font-medium max-h-fit max-w-fit'
                onClick={() => setOpenDeleteModal(true)}
                disabled={!user?.authorityName?.includes('deletion_content')}
              >
                <CustomTrash fillColor='#f00' />
                Delete this Post
              </UserSidebarButton>
            </div>
          </div>
        </div>
      </div>
      <div className='shadow-customBtnShadow w-2/5 rounded-xl  '>
        <RenderSidePannelComponent
          component={sideComponent}
          handleStatusChange={handleStatusChange}
          post={post}
          deleteAccess={user?.authorityName?.includes('deletion_memo')}
        />
        {/* chat section  */}
        <RightCanvas isOpen={openChatHistory} onClose={chatClose}>
          <div className='h-screen flex flex-col overflow-hidden'>
            {' '}
            {/* Prevent outer scrolling */}
            {/* Sticky Header */}
            <div className='relative p-5 border-b sticky top-0 bg-white z-10'>
              {' '}
              {/* Sticky header */}
              <PlusCircle
                className='rotate-45 absolute left-4 top-5 cursor-pointer'
                onClick={chatClose}
              />
              <h1 className='text-center text-base font-bold'>Chat</h1>
            </div>
            {/* Chat messages */}
            <div className='flex flex-col justify-between h-full overflow-hidden'>
              <div className='p-5 overflow-y-auto flex-grow'>
                {' '}
                {/* Only this section scrolls */}
                {chatLoader ? (
                  <Loader loading={chatLoader} />
                ) : (
                  <Fragment>
                    {messages.map((msg, index) => (
                      <div
                        key={index}
                        className={`p-4 shadow-customBtnShadow max-w-xs rounded-xl mt-3 clear-both max-w-[250px] ${
                          msg.adminId === user?.id
                            ? 'float-right'
                            : 'float-left'
                        }`}
                      >
                        <span className='w-full flex justify-between items-center gap-3'>
                          <h4 className='text-sm font-medium'>
                            {msg.adminFullName || 'Unknown'}
                          </h4>
                          <h5 className='text-xs font-light '>
                            {moment(msg.createdAt).format('HH:mm')}
                          </h5>
                        </span>
                        <div className='text-sm font-light max-w-[218px]'>
                          <p className='text-wrap break-all'>{msg.message}</p>
                        </div>
                      </div>
                    ))}
                  </Fragment>
                )}
                <div ref={messageEndRef} /> {/* Scroll to this div */}
              </div>

              {/* Sticky Input message */}
              <div className='p-5 sticky bottom-0 bg-white z-10'>
                {' '}
                {/* Sticky input */}
                <Input
                  ref={inputRef}
                  placeHolder='Start typing message...'
                  inputClassName='bg-white'
                  className='!p-1 !border-gray-400 !pl-5 !text-sm'
                  rightIcon={
                    <button
                      onClick={handleSendMessage}
                      className='bg-text-dm-input shadow-buttonPrimary p-2 flex justify-center items-center rounded-xl'
                    >
                      <img src='/assets/svg/send-2.svg' alt='SendBtnIcon' />
                    </button>
                  }
                />
              </div>
            </div>
          </div>
        </RightCanvas>

        {/* Delete the post modal  */}
        <DeletePostModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          handleConfirm={handleConfirmDelete}
          loading={delLoading}
        />
      </div>
    </div>
  )
}

const RenderSidePannelComponent = ({
  component,
  handleStatusChange,
  post,
  deleteAccess,
}) => {
  switch (component) {
    case 'memo':
      return <SideMemo post={post} deleteAccess={deleteAccess} />
    case 'status':
      return <StatusPannel handleStatusChange={handleStatusChange} />
    default:
      return null
  }
}

RenderSidePannelComponent.propTypes = {
  component: PropsTypes.string,
  handleStatusChange: PropsTypes.func,
  post: PropsTypes.object,
  deleteAccess: PropsTypes.bool,
}

const DeletePostModal = ({ open, onClose, handleConfirm, loading }) => {
  const [password, setPassword] = useState('')
  const _handleConfirm = async () => {
    try {
      const data = await axiosInstance.post('/admin/auth/validate/password', {
        password,
      })
      if (!data.data?.data?.validation?.password) return
    } catch (error) {
      return
    }

    await handleConfirm?.()
  }
  return (
    <GlobalModal isOpen={open} handleClose={onClose} height={450}>
      <div className='flex flex-col items-center gap-y-10 h-full justify-center'>
        {/* Icon */}
        <div className='flex flex-col text-center gap-y-4'>
          <div className='flex justify-center items-center w-100'>
            <GoAlert className='bg-primary-200 p-3 text-teal-500 text-8xl' />
          </div>
          {/* text */}
          <div className='flex flex-col items-center gap-y-1'>
            <h5 className='font-semibold'>Final Confirmation</h5>
            <h6 className='font-medium text-md'>
              Are you sure that you want to delete this post?
            </h6>
            <small className='font-medium text-xs'>
              *As soon as the comment is deleted, it also will be removed from
              the apps.
            </small>
          </div>
        </div>
        {/* input */}
        <div className='text-center w-full flex flex-col items-center'>
          <h6 className='text-sm mb-2'>Enter Password</h6>
          <input
            type='password'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='mt-1 w-5/6 px-3 text-lg py-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
          />
        </div>
        <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
          <button
            className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            onClick={_handleConfirm}
          >
            {loading ? <LoaderIcon className='!w-5 !h-5' /> : 'Yes'}
          </button>
          <button
            className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
            onClick={onClose}
          >
            {loading ? <LoaderIcon className='!w-5 !h-5' /> : 'Cancel'}
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

DeletePostModal.propTypes = {
  open: PropsTypes.bool,
  loading: PropsTypes.bool,
  onClose: PropsTypes.func,
  handleConfirm: PropsTypes.func,
}

PostPreview.propTypes = {
  qrCode: PropsTypes.bool,
  setQrCode: PropsTypes.func,
  postId: PropsTypes.string,
  setComponent: PropsTypes.func,
}

// side memo
const SideMemo = ({ post, deleteAccess }) => {
  // for delete modal
  const [open, setOpen] = useState(false)
  const [comments, setComments] = useState([])
  const [count, setCount] = useState(null)
  const [delInfo, setDelInfo] = useState({})

  const fetchComments = async (_id) => {
    await axiosInstance
      .get(`/admin/post/comments/${_id}`)
      .then((res) => res.data)
      .then((data) => {
        setComments(data?.data?.comments)
        setCount(data?.data?._count?.comments)
      })
      .catch((err) => console.log(err))
  }

  const openModal = (id, pId) => {
    setDelInfo({
      _id: id,
      isSubComment: Boolean(pId),
      _pId: pId,
    })
    setOpen(true)
  }

  const handleDelete = async () => {
    const { _id, isSubComment, _pId } = delInfo

    await axiosInstance
      .delete(`/admin/post/comments/${_id}`)
      .then((res) => res.data?.data)
      .then((data) => {
        if (!isSubComment) {
          setComments((prev) => prev.filter((com) => com?.id !== _id))
        } else {
          setComments((prev) => {
            return prev.map((com) => {
              if (com?.id !== _pId) {
                return {
                  ...com,
                }
              } else {
                const subs = com?.subComments?.filter((s) => s?.id !== _id)
                return {
                  ...com,
                  subComments: subs,
                }
              }
            })
          })
        }
        setCount(data?._count?.comments)
      })
      .catch((err) => console.log(err))
      .finally(() => setOpen(false))
  }

  useEffect(() => {
    if (post && post?.id) {
      fetchComments(post?.id)
    }
  }, [post])

  return (
    <>
      <h1 className='text-lg font-semibold p-4 border-b'>Memo ({count})</h1>
      <div className='flex flex-col max-h-[81vh] overflow-y-auto p-4'>
        {/* memo one with sub comments */}
        <Comments
          comments={comments}
          handleDelete={openModal}
          deleteAccess={deleteAccess}
        />
        {/* Delete Modal  */}
        <DeleteCommentModal
          open={open}
          onClose={() => setOpen(false)}
          handleConfirm={handleDelete}
        />
      </div>
    </>
  )
}

const DeleteCommentModal = ({ open, loading, onClose, handleConfirm }) => {
  const [password, setPassword] = useState('')

  const _handleConfirm = async () => {
    try {
      const data = await axiosInstance.post('/admin/auth/validate/password', {
        password,
      })
      if (!data.data?.data?.validation?.password) return
    } catch (error) {
      return
    }

    await handleConfirm?.()
    onClose()
  }
  return (
    <GlobalModal isOpen={open} handleClose={onClose} height={450}>
      <div className='flex flex-col items-center gap-y-10 h-full justify-center'>
        {/* Icon */}
        <div className='flex flex-col text-center gap-y-4'>
          <div className='flex justify-center items-center w-100'>
            <GoAlert className='bg-primary-200 p-3 text-teal-500 text-8xl' />
          </div>
          {/* text */}
          <div className='flex flex-col items-center gap-y-1'>
            <h5 className='font-semibold'>Final Confirmation</h5>
            <h6 className='font-medium text-md'>
              Are you sure that you want to delete this comment?
            </h6>
            <small className='font-medium text-xs'>
              *As soon as the comment is deleted, it also will be removed from
              the apps.
            </small>
          </div>
        </div>
        {/* input */}
        <div className='text-center w-full flex flex-col items-center'>
          <h6 className='text-sm mb-2'>Enter Password</h6>
          <input
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className='mt-1 w-5/6 px-3 text-lg py-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
          />
        </div>
        <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
          <button
            className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            onClick={_handleConfirm}
          >
            {loading ? <LoaderIcon className='!w-5 !h-5' /> : 'Yes'}
          </button>
          <button
            className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
            onClick={onClose}
          >
            {loading ? <LoaderIcon className='!w-5 !h-5' /> : 'Cancel'}
          </button>
        </div>
      </div>
    </GlobalModal>
  )
}

DeleteCommentModal.propTypes = {
  open: PropsTypes.bool,
  loading: PropsTypes.bool,
  onClose: PropsTypes.func,
  handleConfirm: PropsTypes.func,
}

const Comments = ({ comments, handleDelete, deleteAccess }) => {
  return comments?.map((comment, index) => (
    <div key={index} className='w-full flex gap-x-2 border-b py-3'>
      <img
        src={defaultProfileImg(comment?.user?.profileImage, 'user')}
        alt='commentsProfile'
        className='w-7 h-7 rounded-full'
      />
      <div className='flex flex-col w-full'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-col'>
            <h5 className='font-semibold text-xs'>
              {comment?.isOwn
                ? 'Me'
                : comment?.user?.fullName || comment?.user?.username}
            </h5>
            <h5 className='text-xs font-normal'>{comment?.comment}</h5>
            <h5 className='text-xxs font-light'>
              {moment(comment?.createdAt).format(config.DATE_FORMAT)}
            </h5>
          </div>
          {deleteAccess && (
            <LightBtn
              className='!py-2'
              onClick={() => handleDelete(comment?.id)}
            >
              <CustomTrash fillColor='#ff0000' />
            </LightBtn>
          )}
        </div>
        {/* sub comments 1 */}
        {comment?.subComments?.map((sub, index) => (
          <div key={index} className='w-full flex pt-2 gap-x-2'>
            <img
              src={defaultProfileImg(sub?.user?.profileImage, 'user')}
              alt='commentsProfile'
              className='w-7 h-7 rounded-full'
            />
            <div className='flex flex-col w-full'>
              <div className='flex justify-between w-full'>
                <div className='flex flex-col'>
                  <h6 className='font-semibold text-xs'>
                    {comment?.isOwn
                      ? 'Me'
                      : comment?.user?.fullName || comment?.user?.username}
                  </h6>
                  <h6 className='text-xs font-normal'>{sub?.comment}</h6>
                  <h6 className='text-xxs font-light'>
                    {moment(sub?.createdAt).format(config.DATE_FORMAT)}
                  </h6>
                </div>
                <LightBtn
                  className='!py-2'
                  onClick={() => handleDelete(sub?.id, comment?.id)}
                >
                  <CustomTrash fillColor='#ff0000' />
                </LightBtn>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ))
}

Comments.propTypes = {
  comments: PropsTypes.array,
  handleDelete: PropsTypes.func,
  deleteAccess: PropsTypes.bool,
}

SideMemo.propTypes = {
  post: PropsTypes.object,
  deleteAccess: PropsTypes.bool,
}

// status pannel on click of id
const StatusPannel = ({ handleStatusChange }) => {
  return (
    <>
      <h1 className='text-lg font-semibold p-4 border-b'>249518278</h1>
      <div className='flex flex-col justify-between h-full max-h-[81vh] overflow-y-auto p-4'>
        <div className='grid grid-cols-5 gap-y-3'>
          {/* Headings */}
          <span className='col-span-2 text-xs font-medium'>Details</span>
          <span className='text-xs font-medium'>Status</span>
          <span className='text-xs font-medium flex flex-nowrap items-center'>
            Score &nbsp;
            <Info
              size={12}
              color='gray'
              className='cursor-pointer hover:stroke-text-dm-input'
            />
          </span>
          <span className='text-xs font-medium'>Person</span>

          {/* extra gap  */}
          <div className='col-span-5 py-2'></div>

          {/* Pending data */}
          <div className='col-span-2'>
            <h4 className='text-xs font-medium'>27/07/2023, 17:32</h4>
            <h4 className='text-xs font-medium'>Rechecking</h4>
          </div>
          <span className='text-pending text-xs font-medium'>Pending</span>
          <span className='text-xs font-medium'>3</span>
          <span className='text-xs font-medium'>Jennifer</span>

          {/* Approved data */}
          <div className='col-span-2'>
            <h4 className='text-xs font-medium'>27/07/2023, 17:32</h4>
            <h4 className='text-xs font-medium'>Rechecking</h4>
          </div>
          <span className='text-approved text-xs font-medium'>Approved</span>
          <span className='text-xs font-medium'>3</span>
          <span className='text-xs font-medium underline break-words'>
            user9802145
          </span>
        </div>
        <div className='flex justify-center'>
          <button
            className='py-2 px-8 text-white text-sm font-semibold rounded-xl bg-text-dm-input shadow-buttonPrimary'
            onClick={() => handleStatusChange('memo')}
          >
            Back
          </button>
        </div>
      </div>
    </>
  )
}
StatusPannel.propTypes = {
  handleStatusChange: PropsTypes.func,
}

// side chatpannel
export default PostPreview
