import React, { useEffect, useRef, useState } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import PropTypes from 'prop-types'
import { FiCheckCircle, FiInfo, FiSlash } from 'react-icons/fi'
import { HiOutlineLink } from 'react-icons/hi'
import UserSidebarButton from 'components/global/Buttons/UserSidebarButton'
import clsx from 'clsx'
import { defaultProfileImg } from '_utils/default'
import axiosInstance from '_utils/axiosUtils'
import { ChevronDown, PlusCircle } from 'lucide-react'
import UserNameBadge from 'components/global/Badge/UserStatus'
import LocationPin from 'components/global/icons/LocationsPin'
import ToggleButton from 'components/global/ToggelButton'
import Popper from 'components/global/Popper'

const UserDetailsSidebar = ({
  component,
  setComponent,
  user,
  qrCode,
  setQrCode,
  objectionCount,
  reportingCount,
  reportedCount,
}) => {
  const [isban, setIsBan] = useState(null)
  const [loading, setLoading] = useState(false)
  const locPopperRef = useRef()
  const statusFromLocalStorage = localStorage.getItem('status')
  const [status] = useState(statusFromLocalStorage)

  const [isOpenPointScale, setIsPointScale] = useState(false)
  const [openLocPopper, setOpenLocPopper] = useState(false)
  const [lock, setLock] = useState(user.score != null && user.score.isLocked)
  const [tempNum, setTempNum] = useState(
    user?.score != null && user?.score?.score
  )
  const [locNumber, setLocNumber] = useState(
    user?.score != null && user?.score?.score
  )

  useEffect(() => {
    setLocNumber(user?.score != null && user?.score?.score)
  }, [user])

  const updateScoreUser = async (id, userId, score) => {
    let tempObj = { userId: userId, score: score }
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    try {
      const response = await axiosInstance.put(
        '/admin/user/score/update',
        tempObj
      )
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleDropedownByPointScale = () => {
    setTempNum(locNumber)
    setIsPointScale(true)
  }

  const PointScaleCancel = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
  }

  const savePointScale = () => {
    setLocNumber(tempNum)
    setIsPointScale(false)
  }

  const getColor = (index) => {
    if (index + 1 === tempNum) {
      return 'white' // Selected number color
    } else if (index + 1 <= 3) {
      return '#FF0000' // 1, 2, 3 color
    } else if (index + 1 <= 6) {
      return '#FF7F00' // 4, 5, 6 color
    } else {
      return '#29BC52' // 7, 8, 9, 10 color
    }
  }

  const locModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  }

  useEffect(() => {
    if (user) {
      setIsBan(user?.isBanned)
    }
  }, [user])

  const handleActivateOrBan = () => {
    if (isban) {
      activateUser()
    } else {
      banUser()
    }
  }

  const banUser = () => {
    setLoading(true)
    axiosInstance
      .post(`/admin/user/ban/${user.id}`)
      .then((res) => {
        if (res.data.data.isBanned) {
          setIsBan(res.data.data.isBanned)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  const activateUser = () => {
    setLoading(true)
    axiosInstance
      .delete(`/admin/user/ban/${user.id}`)
      .then((res) => {
        if (res.data.data.isBanned === false) {
          setIsBan(false)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }

  return (
    <div
      className='card rounded-2xl px-6 py-4 min-h-full flex flex-col items-center shadow-customBtnShadow gap-y-2 !w-[300px] widthfixx'
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <div
        className={clsx(
          'userInfo w-12 h-12 rounded-full relative',
          user?.badge === 'online' && 'online'
        )}
      >
        {/* {UserNameBadge(user.badge, { size: 10 })} */}
        <img
          className='w-full h-full rounded-full'
          src={defaultProfileImg(user?.profileImage, 'user')}
          alt='userImg'
        />
        <span className='absolute bottom-0 right-0'>
          {UserNameBadge(status, { size: 13 })}
        </span>
      </div>
      <h6 className='text-sm font-medium'>
        {user?.fullName ? user?.fullName : user?.username}
      </h6>
      <h5 className='text-base font-normal'>@{user?.username}</h5>
      {/* Points dropdown */}
      <button
        ref={locPopperRef}
        onClick={toggleDropedownByPointScale}
        className=' bg-universal py-2.5 px-3 shadow-customBtnShadow rounded-xl flex flex-nowrap items-center text-xs font-medium justify-center'
      >
        {locNumber ? locNumber : 0} Points
        <ChevronDown size={14} />
      </button>
      <Popper
        open={openLocPopper}
        popperOptions={locModifier}
        className='!mt-0'
        onClose={() => setOpenLocPopper(true)}
        refElement={locPopperRef.current}
      >
        {isOpenPointScale && (
          <div
            className='absolute z-10 -left-24 !border-text-dm-input !border-input-border !border-solid w-48 h-36 rounded-xl flex items-center justify-center'
            style={{ backgroundColor: '#F9FAFF' }}
          >
            <div className='w-full px-3 py-0'>
              <div className='w-full flex flex-col pb-2'>
                <div className='w-full flex justify-between gap-x-1'>
                  {[...Array(10)].map((_, index) => (
                    <button
                      key={index}
                      onClick={() => !lock && setTempNum(index + 1)}
                      value={tempNum}
                      style={{
                        color: getColor(index),
                      }}
                    >
                      {tempNum === index + 1 ? (
                        <LocationPin
                          number={index + 1}
                          color={getColor(index + 1)}
                        />
                      ) : (
                        index + 1
                      )}
                    </button>
                  ))}
                </div>
                <progress
                  className='h-1 w-full'
                  value={tempNum}
                  max='10'
                  color={getColor(locNumber)}
                  content={getColor(locNumber)}
                  style={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: getColor(tempNum),
                  }}
                ></progress>
              </div>

              <div className='flex flex-nowrap gap-x-2 items-center'>
                <img src='/assets/svg/lock.svg' alt='' />{' '}
                <p
                  className={clsx('text-xs font-medium', !lock && '!font-bold')}
                >
                  Unlock
                </p>
                <ToggleButton toggle={lock} setToggle={setLock} />
                <p
                  className={clsx('text-xs font-medium', lock && '!font-bold')}
                >
                  Lock
                </p>
              </div>
              <div className='flex py-2 gap-x-2 w-full'>
                <button
                  className={`px-3 py-1 w-1/2 bg-text-dm-input shadow-buttonPrimary h-9 rounded-xl text-sm text-white ${
                    lock ? 'pointer-events-none opacity-50' : ''
                  }`}
                  onClick={() => {
                    savePointScale()
                    updateScoreUser(user?.score?.id, user?.id, tempNum)
                  }}
                >
                  Save
                </button>
                <button
                  onClick={PointScaleCancel}
                  className='px-3 py-1 w-1/2 bg-universal h-9 border border-teal-400 text-gray-400 rounded-xl text-sm'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </Popper>

      <div className='flex flex-nowrap text-xs '>
        <u>{user?.email} </u> &nbsp;&nbsp;{' '}
        {user?.children && user?.children?.length ? (
          <>
            <img
              src='/assets/Icons/homePeople.svg'
              alt='home'
              className='w-5 h-5'
            />
            &nbsp;+1
          </>
        ) : null}
      </div>
      <div className='flex flex-col gap-y-3 !w-[190px]'>
        <UserSidebarButton
          active={component === 8}
          onClick={() => setComponent(8)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out'
        >
          <FiInfo />
          User Information
        </UserSidebarButton>
        <UserSidebarButton
          active={component === 9}
          onClick={() => setComponent(9)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out '
        >
          <BiTimeFive />
          Action History
        </UserSidebarButton>
        <UserSidebarButton
          active={component === 10}
          onClick={() => setComponent(10)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out '
        >
          Banned History : 0
        </UserSidebarButton>
        <UserSidebarButton
          active={component === 11}
          onClick={() => setComponent(11)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out '
        >
          Report History : {reportingCount ? reportingCount : 0}
        </UserSidebarButton>
        <UserSidebarButton
          active={component === 12}
          onClick={() => setComponent(12)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out '
        >
          Reported History : {reportedCount ? reportedCount : 0}
        </UserSidebarButton>
        <UserSidebarButton
          active={component === 13}
          onClick={() => setComponent(13)}
          activeClassName='!border-text-dm-input'
          className='border-gray-300 text-sm hover:border-text-dm-input ease-in hover:ease-out'
        >
          Objection History : {objectionCount ? objectionCount : 0}
        </UserSidebarButton>
        <UserSidebarButton
          loading={loading}
          onClick={handleActivateOrBan}
          className={clsx(
            'shadow-customBtnShadow',
            'text-sm',
            'hover:shadow-boxIn',
            isban ? 'text-text-dm-input whitespace-nowrap' : 'text-badge-danger'
          )}
        >
          <div className='!w-3 !h-3 flex justify-center items-center'>
            {isban ? (
              <FiCheckCircle
                style={{ marginRight: '-8px', marginLeft: '-10px' }}
              />
            ) : (
              <FiSlash />
            )}
          </div>
          {isban ? 'Activate this account' : 'BAN this account'}
        </UserSidebarButton>

        {(component === 5 ||
          component === 6 ||
          component === 7 ||
          component === 14) && (
          <UserSidebarButton className='text-text-dm-input whitespace-nowrap shadow-customBtnShadow text-sm'>
            <HiOutlineLink size={16} />
            Copy Link
          </UserSidebarButton>
        )}
      </div>

      {/* Qr code  */}
      <div
        className={clsx(
          'shadow-customBtnShadow p-4 rounded-xl relative flex flex-col items-center ml-auto my-auto',
          !qrCode && '!hidden'
        )}
      >
        <PlusCircle
          size={14}
          className='rotate-45 absolute right-2 top-2 cursor-pointer'
          onClick={() => setQrCode(false)}
        />
        <img src='/assets/hashmemo.svg' alt='logo' className='w-12 h-4' />
        <img src='/assets/Image/qrcode.png' alt='qrCode' />
        <h3 className='text-xs font-semibold'>Richardson</h3>
      </div>
    </div>
  )
}

UserDetailsSidebar.propTypes = {
  component: PropTypes.bool.isRequired,
  setComponent: PropTypes.func.isRequired,
  user: PropTypes.object,
  qrCode: PropTypes.bool,
  setQrCode: PropTypes.func,
  objectionCount: PropTypes.number,
  reportingCount: PropTypes.number,
  reportedCount: PropTypes.number,
}

export default UserDetailsSidebar
