import React from 'react'
import './App.css'
import Login from 'pages/Login'
import { Route, Routes } from 'react-router-dom'
import UserDetails from 'pages/UserDetails'
import Usemgmt from 'pages/Usemgmt'
import Hashtag from 'pages/Hashtag'
import Page404 from 'components/global/404'
import Home from 'pages/Home'
import SettingsInformation from 'pages/SettingsInformation'
import SettingsBookmarks from 'pages/SettingsBookmarks'
import SettingsReports from 'pages/SettingsReports'
import SettingsActionHistory from 'pages/SettingsActionHistory'
import Objection from 'pages/Objection'
// import StaffReport from 'components/Admin/StaffReport'
// import GeneralSettings from 'components/Admin/GeneralSettings'
// import Authorization from 'components/Admin/Authorization'
// import AdminStaffList from 'pages/AdminStaffList'
// import AdminStaffDetails from 'components/Admin/AdminStaffDetails'
// import AdminStaffActionHistory from 'components/Admin/AdminStaffActionHistory'
// import AdminTeamList from 'pages/AdminTeamList'
import StaffReport from 'components/admin/StaffReport'
import GeneralSettings from 'components/admin/GeneralSettings'
import Authorization from 'components/admin/Authorization'
import AdminStaffList from 'pages/AdminStaffList'
import AdminStaffDetails from 'components/admin/AdminStaffDetails'
import AdminStaffActionHistory from 'components/admin/AdminStaffActionHistory'
import AdminTeamList from 'pages/AdminTeamList'
import ForgotPassword from 'pages/ForgotPassword'
import OtpVerification from 'pages/OtpVerification'
import NewPassword from 'pages/NewPassword'
import CcpaForm from 'pages/Ccpa'
import Notification from 'pages/Notification'
import Template from 'pages/Template'
import StaffTimeSheet from 'pages/StaffTimeSheet'

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Login />} />
      <Route exact path='/ForgotPassword' element={<ForgotPassword />} />
      <Route exact path='/OtpVerification' element={<OtpVerification />} />
      <Route exact path='/NewPassword' element={<NewPassword />} />
      <Route exact path='/home' element={<Home />} />
      <Route exact path='/ccpa-form' element={<CcpaForm />} />
      <Route exact path='/notification' element={<Notification />} />
      <Route exact path='/template' element={<Template />} />
      <Route exact path='/user' element={<Usemgmt />} />
      <Route exact path='/user/:id' element={<UserDetails />} />
      <Route exact path='/hashtag' element={<Hashtag />} />
      <Route
        exact
        path='/settings/information'
        element={<SettingsInformation />}
      />
      <Route exact path='/settings/bookmark' element={<SettingsBookmarks />} />
      <Route exact path='/settings/my-report' element={<SettingsReports />} />
      <Route
        exact
        path='/settings/action-history'
        element={<SettingsActionHistory />}
      />
      <Route exact path='/objection' element={<Objection />} />
      <Route exact path='/admin/staff-report' element={<StaffReport />} />
      <Route
        exact
        path='/admin/staff-report/:id'
        element={<StaffTimeSheet />}
      />
      <Route
        exact
        path='/admin/general-Settings'
        element={<GeneralSettings />}
      />
      <Route exact path='/admin/authorization' element={<Authorization />} />
      <Route exact path='/admin/staff-list' element={<AdminStaffList />} />
      <Route
        exact
        path='/admin/staff-list/:id'
        element={<AdminStaffDetails />}
      />
      <Route
        exact
        path='/admin/staff-list/:id/action-history'
        element={<AdminStaffActionHistory />}
      />
      <Route exact path='/admin/team-list' element={<AdminTeamList />} />
      <Route exact path='*' element={<Page404 />} />
    </Routes>
  )
}

export default App
