import React, { createContext, useEffect, useMemo, useState } from 'react'
import PropsTypes from 'prop-types'
import RightCanvas from 'components/global/RightCanvas'
import axiosInstance from '_utils/axiosUtils'
import HashtagDrawer from 'components/global/hashtag/HashtagDrawer'
import moment from 'moment'

const _defaultValues = {
  _open: false,
  _hashtag: '',
  type: 'post',
  posts: [],
  question: [],
  hashtag: {},
}

const HashtagContext = createContext({
  handleOpen: () => null,
  handleClose: () => null,
  hashtag: '',
  fetchPosts: async () => null,
})

export const HashtagProvider = ({ children }) => {
  const [_open, _setOpen] = useState(false)
  const [_hashtagId, _setHashtagId] = useState('')
  const [type, setType] = useState('post')
  const [posts, setPosts] = useState([])
  const [question, setQuestion] = useState([])
  const [hashtag, setHashtag] = useState({})

  const handleOpen = (hashtag, bool, anchor) => {
    _setHashtagId(hashtag)
    toggleDrawer(anchor, bool)
  }

  const toggleDrawer = (anchor, bool) => {
    _setOpen(bool)
  }

  const handleClose = () => {
    _setHashtagId(_defaultValues._hashtag)
    setType(_defaultValues.type)
    setPosts(_defaultValues.posts)
    setQuestion(_defaultValues.question)
    toggleDrawer('right', _defaultValues._open)
  }

  const handleChangeType = (type) => {
    setType(type)
  }

  const fetchPosts = () => {
    if (_hashtagId) {
      axiosInstance
        .get(`/admin/hashtag/post/${_hashtagId}`, {
          params: {
            skip: 0,
            limit: 1000,
            type,
          },
        })
        .then((res) => {
          if (type === 'post') {
            setPosts(res?.data?.data?.rows)
          }
          if (type === 'question') {
            setQuestion(res?.data?.data?.rows)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const downloadCSV = () => {
    axiosInstance
      .get('/admin/user/downloadCsv/hashtag')
      .then(() => {})
      .catch((err) => console.log(err))
  }

  const fetchHashtag = () => {
    axiosInstance
      .get(`/admin/hashtag/${_hashtagId}`)
      .then((res) => {
        setHashtag(res?.data?.data)
      })
      .catch((err) => console.log(err))
  }

  const _hashtag = useMemo(() => {
    let response = {
      id: hashtag?.id,
    }

    const createdAt = moment(hashtag?.createdAt).format('DD/MM/YYYY') || ''
    response = {
      ...response,
      name: hashtag?.name,
      createdAt,
      followers: hashtag?._count?.followers,
      posts: hashtag?._count?.postTags,
      memo: `${hashtag?._count?.memo}Q(${hashtag?._count?.answer})A`,
      language: 'English',
      _memo: hashtag?._count?.memo || 0,
      _answer: hashtag?._count?.answer || 0,
    }
    return response
  }, [hashtag])

  useEffect(() => {
    if (_hashtagId) {
      fetchHashtag()
      fetchPosts()
    }
  }, [_hashtagId, type])

  return (
    <HashtagContext.Provider
      value={{
        handleOpen,
        hashtag: _hashtag,
        fetchPosts,
        downloadCSV,
      }}
    >
      {children}
      <RightCanvas isOpen={_open} onClose={handleClose}>
        <HashtagDrawer
          name={_hashtag?.name}
          postCount={_hashtag?.posts}
          followersCount={_hashtag?.followers}
          memoCount={_hashtag?._memo}
          answerCount={_hashtag?._answer}
          onClose={handleClose}
          postType={type}
          posts={posts}
          question={question}
          changeType={handleChangeType}
        />
      </RightCanvas>
    </HashtagContext.Provider>
  )
}

HashtagProvider.propTypes = {
  children: PropsTypes.node,
}

export default HashtagContext
