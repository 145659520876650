const formatDate = (dateStr) => {
  const date = new Date(dateStr)

  // Format the date as MM/DD
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' })

  return `${month}/${day} (${dayOfWeek})`
}

export default formatDate
