import React from 'react'
import PropsTypes from 'prop-types'
import clsx from 'clsx'

const TextArea = ({
  className = '',
  children,
  leftIcon = null,
  rightIcon = null,
  placeHolder = '',
  onChange,
  value,
  disabled,
}) => {
  return (
    <div
      className={clsx(
        'flex justify-between h-full min-h-[30px] items-center space-x-2 rounded-lg border-input-border !border-input-border !border-solid !border-light-border-search',
        className
      )}
    >
      {leftIcon && (
        <div className='flex justify-center items-center'>{leftIcon}</div>
      )}
      <textarea
        className={clsx(
          'focus:outline-none flex-grow py-2.5 px-3 bg-universal h-full min-h-[100px] rounded-lg'
        )}
        placeholder={placeHolder}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {children}
      </textarea>
      {rightIcon && (
        <div className='flex justify-center items-center'>{rightIcon}</div>
      )}
    </div>
  )
}

TextArea.propTypes = {
  children: PropsTypes.oneOfType([
    PropsTypes.func,
    PropsTypes.node,
    PropsTypes.string,
  ]),
  type: PropsTypes.string,
  placeHolder: PropsTypes.string,
  leftIcon: PropsTypes.node,
  rightIcon: PropsTypes.node,
  className: PropsTypes.string,
  onChange: PropsTypes.func,
  value: PropsTypes.any,
  disabled: PropsTypes.bool,
}

export default TextArea
