import * as React from 'react'
const Message = (props) => (
  <svg
    width={19}
    height={17}
    viewBox='0 0 19 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id='Vector'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2.68421C0 1.97231 0.300267 1.28957 0.834746 0.786187C1.36922 0.2828 2.09413 0 2.85 0H16.15C16.9059 0 17.6308 0.2828 18.1653 0.786187C18.6997 1.28957 19 1.97231 19 2.68421V11.6316C19 12.3435 18.6997 13.0262 18.1653 13.5296C17.6308 14.033 16.9059 14.3158 16.15 14.3158H5.38365C5.1781 14.3158 4.97809 14.3786 4.81365 14.4947L1.52 16.8211C1.37886 16.9207 1.21103 16.9815 1.03532 16.9964C0.859602 17.0113 0.682948 16.9798 0.525147 16.9055C0.367347 16.8312 0.234635 16.717 0.141882 16.5757C0.0491289 16.4343 0 16.2714 0 16.1053V2.68421ZM4.75 2.68421C4.49804 2.68421 4.25641 2.77848 4.07825 2.94627C3.90009 3.11407 3.8 3.34165 3.8 3.57895C3.8 3.81625 3.90009 4.04383 4.07825 4.21162C4.25641 4.37942 4.49804 4.47368 4.75 4.47368H14.25C14.502 4.47368 14.7436 4.37942 14.9218 4.21162C15.0999 4.04383 15.2 3.81625 15.2 3.57895C15.2 3.34165 15.0999 3.11407 14.9218 2.94627C14.7436 2.77848 14.502 2.68421 14.25 2.68421H4.75ZM4.75 6.26316C4.49804 6.26316 4.25641 6.35742 4.07825 6.52522C3.90009 6.69302 3.8 6.9206 3.8 7.15789C3.8 7.39519 3.90009 7.62277 4.07825 7.79057C4.25641 7.95836 4.49804 8.05263 4.75 8.05263H14.25C14.502 8.05263 14.7436 7.95836 14.9218 7.79057C15.0999 7.62277 15.2 7.39519 15.2 7.15789C15.2 6.9206 15.0999 6.69302 14.9218 6.52522C14.7436 6.35742 14.502 6.26316 14.25 6.26316H4.75ZM4.75 9.8421C4.49804 9.8421 4.25641 9.93637 4.07825 10.1042C3.90009 10.272 3.8 10.4995 3.8 10.7368C3.8 10.9741 3.90009 11.2017 4.07825 11.3695C4.25641 11.5373 4.49804 11.6316 4.75 11.6316H8.55C8.80196 11.6316 9.04359 11.5373 9.22175 11.3695C9.39991 11.2017 9.5 10.9741 9.5 10.7368C9.5 10.4995 9.39991 10.272 9.22175 10.1042C9.04359 9.93637 8.80196 9.8421 8.55 9.8421H4.75Z'
      fill='#00BDB2'
    />
  </svg>
)
export default Message
