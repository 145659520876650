import useStaff from '_hooks/useStaff'
import GlobalModal from 'components/global/GlobalModal'
import React, { useEffect, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import PropsTypes from 'prop-types'
import GradeItem from './GradeItem'

const AuthorizationGradeWiseTable = ({
  setOpenModal,
  setEditGradeAuthority,
}) => {
  const [EditGradeAuthorityName, setEditGradeAuthorityName] = useState(null)
  const [inputvalue, setInputValue] = useState('')
  const [payload, setPayload] = useState([])

  const {
    fetchGrade,
    grade: { rows },
    editAuthority,
    editGradeName,
  } = useStaff()

  useEffect(() => {
    fetchGrade()
  }, [])

  useState(() => {
    setPayload(
      rows?.map((row) => {
        return {
          gradeId: row?.id,
          types: row?.authority ? row?.authority?.map((perm) => perm.type) : [],
        }
      })
    )
  }, [rows])

  const handelEditAuthorityGradeName = (val) => {
    setEditGradeAuthorityName(val)
    setInputValue(val?.name)
    setOpenModal(true)
  }

  const handelEditName = () => {
    editGradeName({ gradeId: EditGradeAuthorityName?.id, name: inputvalue })
    setInputValue('')
    setEditGradeAuthorityName(false)
    setOpenModal(false)
  }

  return (
    <>
      <table className='editable'>
        <thead>
          <tr>
            <th></th>
            <th
              colSpan='4'
              className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
            >
              Change
            </th>
            <th
              colSpan='4'
              className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
            >
              Deletion
            </th>
            <th
              colSpan='2'
              className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
            >
              Download
            </th>
            <th
              colSpan='3'
              className='font-semibold text-black text-xs p-3 pb-4 font-poppins'
            >
              Staff
            </th>
          </tr>
          <tr>
            <th></th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Status
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Score
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Point
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Lock
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Content
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Memo
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Hashtag
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Account
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              CSV
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Account
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Registration
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Editing
            </th>
            <th className='col-span-1 text-[11px] font-poppins text-black border pt-2 pb-[7px] font-normal'>
              Deletion
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((item) => {
            return (
              <GradeItem
                item={item}
                key={item?.id}
                handelEditAuthorityGradeName={handelEditAuthorityGradeName}
                payload={payload}
                setPayload={setPayload}
              />
            )
          })}
        </tbody>
      </table>
      <div className='flex justify-end gap-2 p-5 mt-auto'>
        <button
          onClick={() => {
            editAuthority(payload)
          }}
          className='w-28 h-11 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
        >
          Save
        </button>

        <button
          onClick={() => setEditGradeAuthority(false)}
          className='bg-white-500 w-28 h-11 border border-teal-400 text-gray-400 text-base px-3 rounded-xl'
        >
          Cancel
        </button>
      </div>
      <GlobalModal
        isOpen={EditGradeAuthorityName}
        handleClose={handelEditAuthorityGradeName}
        height={400}
      >
        <div className='flex flex-col items-center gap-y-6 h-auto justify-center pt-2'>
          {/* Icon */}
          <div className='flex flex-col text-center pt-0'>
            <div className='flex justify-center items-center w-100'>
              <GoAlert className='bg-primary-200 p-3 text-teal-500 text-8xl' />
            </div>
            {/* text */}
            <div className='flex flex-col items-center gap-y-1 pt-5'>
              <h6 className='font-medium text-md'>
                Are you sure that you want to edit the grade name?
              </h6>
            </div>
          </div>
          {/* input */}
          <div className='text-center w-full flex flex-col items-center'>
            <input
              type='text'
              value={inputvalue}
              placeholder='Enter grade name'
              onChange={(e) => setInputValue(e.target.value)}
              required
              className='mt-1 w-5/6 px-4 text-lg pt-4 pb-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
            />
          </div>
          <div className='w-full pt-3 text-center flex flex-nowrap justify-center gap-5'>
            <button
              onClick={handelEditName}
              className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            >
              Yes
            </button>
            <button
              className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
              onClick={() => {
                setOpenModal(false)
                setEditGradeAuthorityName(false)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </GlobalModal>
    </>
  )
}

AuthorizationGradeWiseTable.propTypes = {
  setOpenModal: PropsTypes.func,
  setEditGradeAuthority: PropsTypes.func,
}

export default AuthorizationGradeWiseTable
