import React from 'react'
import PropsTypes from 'prop-types'
import Header from './Header'
import SideNavBar from './SideNav'

const Page = ({ children }) => {
  // get the header
  const headerChildren = React.Children.toArray(children).filter((child) => {
    return child.props?.id === 'header'
  })

  // remove the header from main
  const mainChildren = React.Children.toArray(children).filter((child) => {
    return child.props?.id !== 'header'
  })

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate('/')
  //   }
  // }, [])
  return (
    <div className='flex justify-center items-center'>
      <Header>{headerChildren}</Header>
      <SideNavBar />
      <div className='w-fit-screen pt-24 pl-44 h-100 '>{mainChildren}</div>
    </div>
  )
}

Page.propTypes = {
  children: PropsTypes.node,
  header: PropsTypes.element,
}

export default Page
