import clsx from 'clsx'
import React from 'react'
import PropsTypes from 'prop-types'

function ToggleButton({ toggle, setToggle }) {
  return (
    <button
      className={clsx(
        'w-8 h-4 flex items-center transition-colors bg-universal border rounded-full p-1 cursor-pointer',
        toggle && 'border-text-dm-input'
      )}
      onClick={() => {
        setToggle(!toggle)
      }}
    >
      {/* Switch */}
      <div
        className={clsx(
          'bg-gray-500 h-3 w-3 rounded-full shadow-md  transform duration-300 ease-in-out',
          toggle && 'transform translate-x-3 !bg-text-dm-input'
        )}
      ></div>
    </button>
  )
}

ToggleButton.propTypes = {
  toggle: PropsTypes.bool,
  setToggle: PropsTypes.func,
}

export default ToggleButton
