import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
// import { BsFiletypeCsv } from 'react-icons/bs'
import { GoAlert, GoTrash } from 'react-icons/go'
import { HiOutlineStar } from 'react-icons/hi'
// import GlobalPopper from 'components/global/GlobalPopper'
// import UserNameBadge from 'components/global/Badge/UserStatus'
import FilterBox from 'components/global/DataTableTools.js/FilterBox'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import GlobalModal from 'components/global/GlobalModal'
import DataTable from 'react-data-table-component'
// import MegaFilter from '../components/usemgmt/MegaFilter'
import { NavLink } from 'react-router-dom'
import Popper from 'components/global/Popper'
import { a11y } from '_utils/a11y'
import Page from 'components/global/Page'
import moment from 'moment'
import MegaFilter from 'components/usemgmt/MegaFilter'
import Input from 'components/global/Input'
import { RiSearchLine } from 'react-icons/ri'
import { SlidersHorizontal } from 'lucide-react'
import useCcpa from '_hooks/useCcpa'
import axiosInstance from '_utils/axiosUtils'
import useAuth from '_hooks/useAuth'

const CcpaForm = () => {
  // popper handler
  const anchorRef = useRef(null)
  const [popperOpen, setPopperOpen] = useState(false)
  // const [megaFilter, setMegaFilter] = useState(false)
  const { handleSelectedRowsChange, deleteUsers } = useCcpa()

  const { user } = useAuth()

  const [data, setData] = useState([])

  const [password, setPassword] = useState('')

  const [_selectedRows, _setSelectedRows] = useState([])

  const fetchUsers = async () => {
    try {
      const body = {
        search,
        // age: Object.keys(age).filter(item => age[item]),
        // status: Object.keys(status).filter(_status => status[_status]),
        // gender: Object.keys(gender).filter(_gen => gender[_gen]),
      }

      const data = await axiosInstance.get('/admin/ccpa', body)
      if (data?.data?.data) {
        setData(data?.data?.data)
      } else {
        setData([])
      }
      // setIsError(false)
      // setErrorMessage(null)
    } catch (error) {
      // setIsError(true)
      // setErrorMessage(error)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleExtension = async (id) => {
    await axiosInstance
      .put(`admin/ccpa/extention/${id}`)
      .then((res) => {
        let newArray = data?.map((form) => {
          if (form?.id === id) {
            return { ...form, ...res.data.data }
          }
          return { ...form }
        })
        setData(newArray)
      })
      .catch((err) => console.log(err))
  }

  // const navigate = useNavigate()
  // const buttonRef = useRef(null)
  const [filteredColumn, setFilteredColumn] = useState([])
  const DataShowHideManage = (columns) => {
    if (filteredColumn.length === 0) {
      return columns
    }
    const filtered = columns.filter((x) => filteredColumn.includes(x.selector))
    return filtered
  }

  // search component
  const popperRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)
  const { search, handleSearchChange } = useCcpa()

  const customizedUsername = (row) => {
    return (
      <div
        className='flex items-center justify-start font-bold gap-2 whitespace-nowrap'
        style={{ minHeight: '8vh' }}
      >
        {/* <HiOutlineStar
          className={`${row.username === 'user1'
            ? 'fill-teal-400 text-teal-400'
            : 'text-gray-500'
            } translate-x-[-10px]`}
          size={20}
        /> */}
        {/* {UserNameBadge(row.badge, { size: 10 })}{' '} */}
        <NavLink
          to={`/user/${row?.userId}`}
          onClick={() => {
            localStorage.setItem('status', row.badge)
            localStorage.setItem('ccpa', row?.userId)
          }}
          className='underline w-44'
        >
          {row.fullName}
        </NavLink>
      </div>
    )
  }

  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '600',
        fontSize: '1rem',
        lineHeight: 'normal',
        color: '#000',
        background: 'var(--bg-color)',
      },
    },
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '40px',
      },
    },
  }

  const [selectAll, setSelectAll] = useState(false)
  const [individualCheckboxes, setIndividualCheckboxes] = useState({})

  useEffect(() => {
    const initialCheckboxes = {}
    data.forEach((row) => {
      initialCheckboxes[row.id] = false
    })
    setIndividualCheckboxes(initialCheckboxes)
  }, [data])

  const handleGetAllData = () => {
    const newSelectAll = !selectAll
    const newIndividualCheckboxes = { ...individualCheckboxes }

    Object.keys(newIndividualCheckboxes).forEach((key) => {
      newIndividualCheckboxes[key] = newSelectAll
    })

    setSelectAll(newSelectAll)
    setIndividualCheckboxes(newIndividualCheckboxes)
  }

  // const handleCheckboxChange = (row) => {
  //   const newIndividualCheckboxes = { ...individualCheckboxes };
  //   newIndividualCheckboxes[row.id] = !newIndividualCheckboxes[row.id];

  //   const areAllChecked = Object.values(newIndividualCheckboxes).every(value => value);
  //   setSelectAll(areAllChecked);
  //   setIndividualCheckboxes(newIndividualCheckboxes);
  // };

  const handleCheckboxChange = (row) => {
    const updatedSelectedRows = { ...individualCheckboxes }
    updatedSelectedRows[row.id] = !updatedSelectedRows[row.id]
    setIndividualCheckboxes(updatedSelectedRows)
    _setSelectedRows(
      Object.keys(updatedSelectedRows).filter((id) => updatedSelectedRows[id])
    )
  }

  const _data = useMemo(() => {
    return data?.map((value) => {
      const today = moment()
      const daysSinceRequest = value.dateOfRequest
        ? today.diff(moment(value.dateOfRequest), 'days')
        : null
      const daysLeft =
        daysSinceRequest !== null
          ? Math.max(
              (value?.totalDays ? Number(value?.totalDays) : 45) -
                daysSinceRequest,
              0
            )
          : null
      let extensionStatus = ''
      if (daysLeft !== null) {
        if (daysLeft > 1) {
          extensionStatus = 'Extension'
        } else if (daysLeft === 1) {
          extensionStatus = 'Extendable until tomorrow'
        } else {
          extensionStatus = 'Extension request no longer available'
        }
      }
      const remainingDaysText =
        daysLeft !== null
          ? `${daysLeft} day${daysLeft !== 1 ? 's' : ''} left`
          : null
      value['dateOfRequest'] = value.dateOfRequest
        ? moment(value.dateOfRequest).format('DD/MMM/YYYY')
        : null
      value['createdAt'] = moment(value.createdAt).format('DD/MMM/YYYY')
      return {
        ...value,
        deadLineDays: remainingDaysText,
        extensionStatus: extensionStatus,
      }
    })
  }, [data])

  const columns = [
    {
      name: (
        <div className='flex mr-11'>
          <input
            type='checkbox'
            style={{
              height: '23px',
              width: '23px',
              fontWeight: 'bold',
              fontSize: '23px',
              marginLeft: '6px',
            }}
            onChange={() => handleGetAllData()}
            checked={selectAll}
          />
        </div>
      ),
      selector: true,
      cell: (row) => {
        const [showStar, setShowStar] = useState(true)

        const handleToggle = () => {
          setShowStar(!showStar)
        }

        return (
          <div className='flex items-center justify-center'>
            <input
              type='checkbox'
              style={{
                height: '23px',
                width: '23px',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
              className='mr-5'
              onChange={() => handleCheckboxChange(row)}
              checked={individualCheckboxes[row.id] || false}
            />
            {showStar ? (
              <HiOutlineStar
                className='-translate-x-2.5 cursor-pointer'
                size={20}
                color='#292D32'
                onClick={handleToggle}
              />
            ) : (
              <input
                type='image'
                src='/assets/svg/star.svg'
                className='-translate-x-2.5 cursor-pointer'
                alt='star'
                onClick={handleToggle}
              />
            )}
          </div>
        )
      },
      allowOverflow: true,
      button: true,
    },
    {
      name: (
        <div className='flex w-auto'>
          Username
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'fullName',
      sortable: true,
      cell: (row) => customizedUsername(row),
      minWidth: '250px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Email Address
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'email',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Physical Address
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: 'physicalAddress',
      minWidth: '150px',
    },
    {
      name: (
        <div className='flex w-full'>
          Agent Name
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'agentFullName',
      sortable: true,
      minWidth: '180px',
      sortFunction: (a, b) => {
        // Custom sort function to handle null values
        if (a.agentFullName === null && b.agentFullName === null) return 0
        if (a.agentFullName === null) return 1
        if (b.agentFullName === null) return -1
        return a.agentFullName.localeCompare(b.agentFullName)
      },
    },
    {
      name: (
        <div className='flex w-full'>
          Agent Email Address
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'agentEmail',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Agent Address
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'agentPhysicalAddress',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Request To Know
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'isReqKnow',
      sortable: true,
      cell: (row) => (row?.isReqKnow ? 'Yes' : 'No'),
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Request To Correct
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'isReqCorrect',
      sortable: true,
      cell: (row) => (row?.isReqCorrect ? 'Yes' : 'No'),
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Request To Delete
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'isReqDelete',
      sortable: true,
      cell: (row) => (row?.isReqDelete ? 'Yes' : 'No'),
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Descrption
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'description',
      sortable: true,
      minWidth: '150px',
    },
    {
      name: (
        <div className='flex w-full'>
          Date of Request
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'dateOfRequest',
      sortable: true,
      minWidth: '150px',
    },
    {
      name: (
        <div className='flex w-full'>
          Who is Requesting?
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'whoIsRequesting',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Created At
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'createdAt',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Date By Dead Line
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'deadLineDays',
      sortable: true,
      minWidth: '180px', // Set the width here
    },
    {
      name: (
        <div className='flex w-full'>
          Extension
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'extensionStatus',
      sortable: true,
      minWidth: '180px', // Set the width here
      cell: (row) => {
        return (
          <>
            {!row?.isExtended ? (
              <button
                onClick={() => handleExtension(row.id)}
                className='px-4 py-2 text-white hover:underline rounded-md hover:bg-blue-600'
                style={{
                  borderRadius: '12px',
                  background: '#00BDB2',
                  boxShadow:
                    '-4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
                  backdropFilter: 'blur(5px)',
                }}
              >
                Extend
              </button>
            ) : (
              row['extensionStatus']
            )}
          </>
        )
      },
    },
    {
      name: (
        <div className='flex w-full'>
          Attachement Links
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: 'ccpaMedia',
      sortable: false,
      cell: (row) => {
        return (
          <div className='flex flex-col'>
            {row?.ccpaMedia?.map((media, index) => {
              return (
                <a
                  key={media?.fileURL}
                  href={`${process.env.REACT_APP_FTP_URL}${media?.fileURL}`}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {`Link #${index + 1}`}
                </a>
              )
            })}
          </div>
        )
      },
      minWidth: '180px', // Set the width here
    },
  ]

  const TableFilterModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-75, 10],
        },
      },
    ],
  }

  const [open, setOpen] = useState(false)
  // const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleOpen = () => {
    if (_selectedRows.length > 0) {
      setOpen(true)
    } else {
      alert('Select Any Username')
    }
  }

  const handleDeleteUsers = async () => {
    await deleteUsers()
    handleClose()
    handleSelectedRowsChange([])
  }

  const downloadcsv = () => {
    if (_data.length > 0) {
      const csvData = prepareCSVData(_data)
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'ccpaform.csv'
      link.click()
    }
  }

  const prepareCSVData = (data) => {
    let csv = ''
    const headers = Object.keys(data[0])
    csv += headers.join(',') + '\n'
    data.forEach((row) => {
      const values = headers?.map((header) => {
        let value = row[header]
        if (value === null || value === undefined) {
          value = ''
        } else if (typeof value === 'string' && value.includes(',')) {
          value = `"${value}"`
        }
        if (header === 'ccpaMedia') {
          value = value
            ?.map((val) => `${process.env.REACT_APP_FTP_URL}${val?.fileURL}`)
            .join(',')
        }
        return value
      })
      csv += values.join(',') + '\n'
    })
    return csv
  }

  return (
    <Fragment>
      <Page>
        {/* search pannel  */}
        <div className='w-full' id='header'>
          <Input
            placeHolder='Search...'
            value={search}
            onChange={(e) => handleSearchChange(e.target.value)}
            leftIcon={<RiSearchLine className='w-6 h-6' />}
            rightIcon={
              <SlidersHorizontal
                ref={popperRef}
                onClick={() => setFilterOpen(true)}
                className='w-6 h-6 cursor-pointer'
                style={{ color: filterOpen ? '#00BDB2' : '' }}
              />
            }
            className={`flex-grow !border-light-border-search !rounded-xl ${
              open ? 'pointer-events-none' : ''
            }`}
            disabled={open}
          />
          <Popper
            refElement={popperRef.current}
            open={filterOpen}
            onClose={() => setFilterOpen(false)}
            popperOptions={{
              strategy: 'fixed',
              placement: 'bottom-end',
            }}
          >
            <div
              className='bg-universal p-3 rounded-2xl border border-teal-500'
              style={{
                height: '500px',
                width: 'auto',
                overflowY: 'scroll',
                overflowX: 'hidden',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                WebkitOverflowScrolling: 'touch',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <MegaFilter onClose={() => setFilterOpen(false)} />
            </div>
          </Popper>
        </div>

        {/* user management table  */}
        <div>
          <div className='w-full flex justify-between px-5'>
            <h1 className='text-2xl'>
              <span className='font-semibold'>Total </span>
              {_data?.length}
            </h1>
            {/* <Popper
              refElement={buttonRef.current}
              open={megaFilter}
              onClose={() => setMegaFilter(false)}
              popperOptions={megaFilterModifier}
            >
              <div className='bg-universal p-3 rounded-2xl  border border-teal-500'>
                <MegaFilter />
              </div>
            </Popper> */}

            <div className='flex gap-2 mb-5'>
              {/* Table filter */}

              <button
                ref={anchorRef}
                {...a11y(() => setPopperOpen(true))}
                className='border border-teal-400 py-3 px-4 rounded-xl flex flex-evenly text-sm font-medium'
              >
                Display on chart &nbsp;&nbsp;
                {popperOpen ? (
                  <FiChevronUp className='text-md' size={23} />
                ) : (
                  <FiChevronDown className='text-md' size={23} />
                )}
              </button>
              <Popper
                open={popperOpen}
                popperOptions={TableFilterModifier}
                onClose={() => setPopperOpen(false)}
                refElement={anchorRef.current}
              >
                <div className=' p-3 rounded-2xl  border border-teal-500 bg-universal'>
                  <FilterBox
                    data={columns}
                    filteredColumn={filteredColumn}
                    setFilteredColumn={setFilteredColumn}
                  />
                </div>
              </Popper>

              {/* Delete button */}
              <button
                onClick={handleOpen}
                className='px-4 py-2 text-white hover:underline rounded-md hover:bg-blue-600'
                style={{
                  borderRadius: '12px',
                  background: '#00BDB2',
                  boxShadow:
                    '-4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
                  backdropFilter: 'blur(5px)',
                }}
              >
                <GoTrash className='bg-transparent text-white' />
              </button>

              {/* Delet Modal  */}
              <GlobalModal isOpen={open} handleClose={handleClose} height={450}>
                <div className='flex flex-col items-center gap-y-10 h-full justify-center'>
                  {/* Icon */}
                  <div className='flex flex-col text-center gap-y-4'>
                    <div className='flex justify-center items-center w-100'>
                      <GoAlert className='bg-[#00bdb211] rounded-xl p-3 text-teal-500 text-8xl' />
                    </div>
                    {/* text */}
                    <div className='flex flex-col items-center gap-y-1'>
                      <h5 className='font-semibold'>Final Confirmation</h5>
                      <h6 className='font-medium text-md'>
                        Are you sure that you want to delete the user?
                      </h6>
                      <small className='font-medium text-xs'>
                        *All data and information of this user will be deleted.
                      </small>
                    </div>
                  </div>
                  {/* input */}
                  <div className='text-center w-full flex flex-col items-center'>
                    <h6 className='text-sm mb-2'>Enter Password</h6>
                    <input
                      type='password'
                      required
                      autoComplete='new-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='mt-1 w-5/6 px-3 text-lg py-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
                    />
                  </div>
                  <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
                    <button
                      style={{
                        boxShadow:
                          ' -4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
                      }}
                      className='bg-teal-500 w-2/6 py-2 rounded-lg text-white'
                      onClick={handleDeleteUsers}
                    >
                      Yes
                    </button>
                    <button
                      className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </GlobalModal>

              {/* download csv button */}
              {user?.authorityName.includes('download_csv') && (
                <button
                  onClick={downloadcsv}
                  className='bg-white-500 border border-teal-400 text-teal-500 text-xl py-2 px-3 rounded-xl'
                >
                  {/* <BsFiletypeCsv /> */}
                  <img
                    src='/assets/svg/Csv.svg'
                    alt='Csv'
                    className='w-6 h-6'
                  />
                </button>
              )}
            </div>
          </div>
          <div style={{ maxHeight: 'calc(100vh - 200px)' }}>
            <DataTable
              columns={DataShowHideManage(columns)}
              data={_data}
              // selectableRows
              highlightOnHover
              // onSelectedRowsChange={({ selectedRows }) =>
              //   handleSelectedRowsChange(selectedRows)
              // }
              onSelectedRowsChange={(row) =>
                _setSelectedRows(row?.selectedRows)
              }
              customStyles={customCellStyles}
              className='h-[35rem]  2xl:h-[45rem] !overflow-y-scroll'
            />
          </div>
        </div>
      </Page>
    </Fragment>
  )
}

export default CcpaForm
