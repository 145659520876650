import React, { useEffect, useRef, useState } from 'react'
import Page from 'components/global/Page'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import RightCanvas from 'components/global/RightCanvas'
import { ChevronDown, PlusCircle, ChevronUp } from 'lucide-react'
import Popper from 'components/global/Popper'
import clsx from 'clsx'
import GlobalAccordion from 'components/global/GlobalAccordion'
import Header from 'components/settings/Header'
import useSettings from '_hooks/useSettings'
import useAuth from '_hooks/useAuth'
import moment from 'moment'
import actionHistoryText from '_utils/actionHistory'

const SettingsActionHistory = () => {
  const filterRef = useRef()
  const [openFilterPopper, setOpenFilterPopper] = useState(false)
  const [dateFilter, setDateFilter] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const { user } = useAuth()
  const { fetchActionHistory, actionHistory } = useSettings()
  const [selectedFilter, setSelectedFilter] = useState('lastweek')
  const [openChatHistory, setOpenChatHistory] = useState(false)
  const handleOpenChatHistory = () => setOpenChatHistory(!openChatHistory)
  const handleSelectFilter = (value) => {
    setSelectedFilter(value)
    if (value !== 'custom') {
      setOpenFilterPopper(false)
    }
  }

  useEffect(() => {
    if (user) {
      fetchActionHistory(
        user?.id,
        selectedFilter,
        dateFilter?.from,
        dateFilter?.to
      )
    }
  }, [user, selectedFilter, dateFilter])

  const handleFilter = () => {
    setDateFilter({ from: fromDate, to: toDate })
    setOpenFilterPopper(false)
  }

  const filterModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }

  const filterOptions = [
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
    },
    {
      label: 'Last Week',
      value: 'lastweek',
    },
    {
      label: 'Last Month',
      value: 'lastmonth',
    },
    {
      label: 'Custom',
      value: 'custom',
    },
  ]

  return (
    <Page>
      {/* header  */}
      <div id='header' className='w-full'>
        <Header />
      </div>
      {/* page content  */}
      <div className='w-full'>
        {/* filter */}
        <div className='w-full flex justify-end items-center gap-x-2'>
          <h6 className='text-sm font-medium' style={{ marginRight: '1em' }}>
            Select a period:
          </h6>
          <button
            className='bg-universal py-2 px-3 shadow-customBtnShadow rounded-xl text-sm font-medium flex justify-between items-center w-44'
            ref={filterRef}
            onClick={() => setOpenFilterPopper(true)}
          >
            {
              filterOptions?.find((item) => item?.value === selectedFilter)
                ?.label
            }
            {openFilterPopper ? (
              <ChevronUp size={14} />
            ) : (
              <ChevronDown size={14} />
            )}
          </button>
          <Popper
            open={openFilterPopper}
            popperOptions={filterModifier}
            onClose={() => setOpenFilterPopper(false)}
            refElement={filterRef.current}
          >
            <div className='border border-text-dm-input bg-universal p-1 rounded-xl w-44 flex flex-col items-start mt-[-25px]'>
              {filterOptions?.map((item) => {
                return (
                  <button
                    className={clsx(
                      'hover:bg-hovered-bg ease-in-out text-sm font-semibold p-2 w-full text-start  rounded-xl mb-1',
                      selectedFilter === item.value && 'bg-hovered-bg'
                    )}
                    onClick={() => handleSelectFilter(item?.value)}
                    key={item?.value}
                  >
                    {item?.label}
                  </button>
                )
              })}
              {selectedFilter === 'custom' && (
                <div className='w-full px-2'>
                  <label className='block'>
                    <span className='block text-sm font-semibold text-gray-800'>
                      From:
                    </span>
                    <input
                      className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-1 w-full rounded-xl'
                      placeholder='MM/DD/YYYY'
                      type='date'
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      onChange={(e) => {
                        setFromDate(e.target.value)
                      }}
                    />
                  </label>
                  <label className='block mt-3'>
                    <span className='block text-sm font-semibold text-gray-800'>
                      To:
                    </span>
                    <input
                      className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-1 w-full rounded-xl'
                      placeholder='MM/DD/YYYY'
                      type='date'
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      onChange={(e) => {
                        setToDate(e.target.value)
                      }}
                    />
                  </label>
                  <div className='flex flex-nowrap py-3 gap-x-2'>
                    <button
                      className='w-full px-2 py-1 rounded-xl text-white bg-text-dm-input shadow-buttonPrimary'
                      onClick={handleFilter}
                    >
                      Save
                    </button>
                    <button className='w-full px-2 py-1 rounded-xl text-gray-600 border border-text-dm-input '>
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Popper>
        </div>

        {/* main content */}
        <div className='w-full flex flex-col pt-3 gap-y-4'>
          {actionHistory?.map((tempObj) => {
            return (
              <GlobalAccordion
                header={
                  <h5 className='text-base font-semibold'>
                    {`${moment(tempObj?.date).format('DD/MM/YYYY')}`}
                  </h5>
                }
                content={
                  <div className='flex'>
                    {/* timeline */}
                    <div className='w-full flex justify-between'>
                      <div className='w-full flex justify-between'>
                        {/* ========= status time line ========= */}
                        <Timeline className='!self-start !w-5/6' align='left'>
                          {tempObj?.actionHistory
                            .reverse()
                            .map((item, index) => {
                              return (
                                <TimelineItem
                                  className='!min-h-timeline-Height w-max'
                                  key={item?.id}
                                >
                                  <TimelineOppositeContent
                                    className='!text-sm !font-normal !text-black !text-left !w-max'
                                    sx={{ flex: '0' }}
                                  >
                                    {moment(item?.createdAt).format('HH:mm')}
                                  </TimelineOppositeContent>
                                  <TimelineSeparator>
                                    <TimelineDot className='!border-none !bg-black' />
                                    {index !==
                                      tempObj?.actionHistory.length - 1 && (
                                      <TimelineConnector className='!bg-black border !border-dotted' />
                                    )}
                                  </TimelineSeparator>
                                  <TimelineContent className='!text-sm !font-normal !text-black'>
                                    {actionHistoryText(item)}
                                  </TimelineContent>
                                </TimelineItem>
                              )
                            })}

                          {/* </div> */}
                        </Timeline>
                        {/*====== result timeline ======= */}
                        {/* <Timeline className='border-l-2 !w-1/6 h-4/5'>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden '>
                            <TimelineContent className='!text-sm !font-normal !text-black'>
                              <h6 className='text-sm font-semibold'>#math</h6>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-xs !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-xs !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-xs !font-normal !text-black'>
                              <div className='flex justify-start items-center gap-x-2 h-fit'>
                                <img
                                  src='/assets/Image/imageTwo.jpg'
                                  alt='profile'
                                  className='w-9 h-9 rounded-full'
                                />
                                <div className='flex flex-col'>
                                  <h6 className='font-bold text-sm'>io-data</h6>
                                  <h6 className='underline font-normal text-sm'>
                                    Robotty555
                                  </h6>
                                </div>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                          <TimelineItem className='!min-h-timeline-Height before:hidden'>
                            <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                          </TimelineItem>
                        </Timeline> */}
                      </div>
                    </div>
                  </div>
                }
                key={tempObj?.date}
              />
            )
          })}
        </div>

        {/* Chat history */}
        <RightCanvas isOpen={openChatHistory} onClose={handleOpenChatHistory}>
          <div>
            <div className='h-screen flex flex-col'>
              {/* header */}
              <div className='relative p-5 border-b '>
                <PlusCircle
                  onClick={handleOpenChatHistory}
                  className='rotate-45 absolute left-4 top-5 cursor-pointer'
                />
                <h1 className='text-center  text-base font-bold'>
                  Chat History
                </h1>
              </div>

              {/*content   */}
              <div className='flex flex-col p-5 gap-y-4'>
                <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                  <div className='flex justify-between'>
                    <h5 className='text-base font-medium'>Jennifer</h5>
                    <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                  </div>
                  <div className='flex justify-between'>
                    <p className='text-sm font-light'>
                      @Manager : Thank you for confirmation. I changed the
                      status
                    </p>
                  </div>
                </div>
                <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                  <div className='flex justify-between'>
                    <h5 className='text-base font-medium'>Manager</h5>
                    <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                  </div>
                  <div className='flex justify-between'>
                    <p className='text-sm font-light'>
                      Go ahead to change to “Approved”.
                    </p>
                  </div>
                </div>
                <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                  <div className='flex justify-between'>
                    <h5 className='text-base font-medium'>Jennifer</h5>
                    <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                  </div>
                  <div className='flex justify-between'>
                    <p className='text-sm font-light'>
                      I&apos;m checking with the manager if it should be
                      &apos;Approved&apos;.
                    </p>
                    <img
                      src='/assets/Image/imageFour.jpg'
                      alt='actionImg'
                      className='w-9 h-14 rounded-sm'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RightCanvas>
      </div>
    </Page>
  )
}

export default SettingsActionHistory
