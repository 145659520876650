import React, { createContext, useState } from 'react'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'
import getYYYYMMDDformat from '_utils/getYYYYMMDDformat'

const SettingsContext = createContext({
  fetchMyTimeSheet: async () => null,
  fetchTimeSheetById: async () => null,
  fetchStaffReport: async () => null,
  timeSheet: Array(),
  staffReport: Array(),
  actionHistory: Array(),
})

export const SettingsProvider = ({ children }) => {
  const [timeSheet, setTimeSheet] = useState(Array())
  const [staffReport, setStaffReport] = useState(Array())
  const [actionHistory, setActionHistory] = useState(Array())

  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const fetchMyTimeSheet = async (id, payload) => {
    if (payload) {
      try {
        const res = await axiosInstance.get(
          `/admin/adminTimeSheet/timeSheet/${id}`,
          {
            params: {
              from: getYYYYMMDDformat(payload.from),
              to: getYYYYMMDDformat(payload.to),
            },
          }
        )
        setTimeSheet(res?.data?.data)
        setIsError(false)
        setErrorMessage(null)
      } catch (error) {
        setIsError(true)
        setErrorMessage(error)
      }
    } else {
      try {
        const res = await axiosInstance.get(
          `/admin/adminTimeSheet/timeSheet/${id}`
        )
        setTimeSheet(res?.data?.data)
        setIsError(false)
        setErrorMessage(null)
      } catch (error) {
        setIsError(true)
        setErrorMessage(error)
      }
    }
  }

  const editTimeSheet = async (payload) => {
    try {
      await axiosInstance.put('/admin/adminTimeSheet/timeSheet', {
        timeSheets: payload,
      })
      window.location.reload()
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }

  const fetchActionHistory = async (id, filterType = 'lastweek', from, to) => {
    let data = { adminId: id, filterType: filterType }
    if (filterType === 'custom') {
      data = { adminId: id, filterType: filterType, from, to }
    }
    try {
      const res = await axiosInstance.post(
        '/admin/adminActionHistory/getActionHistory',
        data
      )
      setActionHistory(res?.data?.data)
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }

  const fetchTimeSheetById = async (id) => {
    try {
      const res = await axiosInstance.get('/admin/staff/timeSheet', {
        params: {
          id,
        },
      })
      setTimeSheet(res?.data?.data?.timeSheet)
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }
  const updateStatus = async ({ id, status }) => {
    try {
      await axiosInstance.post('/admin/adminTimeSheet/status', {
        adminId: id,
        status,
      })
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }

  const fetchStaffReport = async () => {
    try {
      const res = await axiosInstance.get('/admin/adminTimeSheet/timeSheet')
      setStaffReport(res?.data?.data)
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setStaffReport([])
      setIsError(true)
      setErrorMessage(error)
    }
  }

  return (
    <SettingsContext.Provider
      value={{
        timeSheet,
        fetchMyTimeSheet,
        fetchTimeSheetById,
        fetchStaffReport,
        isError,
        errorMessage,
        actionHistory,
        fetchActionHistory,
        updateStatus,
        editTimeSheet,
        staffReport,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropsTypes.node,
}

export default SettingsContext
