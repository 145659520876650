import React, { createContext, useCallback, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'

const UserContext = createContext({
  fetchUsers: async () => null,
  data: Array(),
  deleteUsers: async () => null,
  isError: false,
  errorMessage: null,
  search: '',
  handleSearchChange: () => null,
  selectedRows: Array(),
  handleSelectedRowsChange: () => null,
})

export const UserProvider = ({ children }) => {
  const [search, setSearch] = useState()
  const [data, setData] = useState(Array())
  const [age, setAge] = useState({})
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedRows, setSelectedRows] = useState(Array())
  const [payload, setPayload] = useState({})

  const [gender, setGender] = useState({})
  const [status, setStatus] = useState({})

  const fetchUsers = async () => {
    let tempFilter = {
      age: payload.age,
      gender: payload.gender,
      status: payload.status,
      languages: payload.appLanguage,
    }
    let recieveReports = payload.reportedArray?.map((report) => {
      if (report === '0') {
        return 0
      }
      if (report === 'More than 1') {
        return 2
      }
    })
    let sendReports = payload.reportArray?.map((report) => {
      if (report === '0') {
        return 0
      }
      if (report === 'More than 1') {
        return 2
      }
    })
    let banned = payload.bannedArray?.map((report) => {
      if (report === '0') {
        return 0
      }
      if (report === 'More than 1') {
        return 2
      }
    })
    if (recieveReports?.length > 0) {
      tempFilter = { ...tempFilter, recieveReports }
    }
    if (sendReports?.length > 0) {
      tempFilter = { ...tempFilter, sendReports }
    }
    if (banned?.length > 0) {
      tempFilter = { ...tempFilter, banned }
    }
    if (payload.createFrom && payload.createTo) {
      tempFilter = {
        ...tempFilter,
        create: {
          from: payload?.createFrom,
          to: payload?.createTo,
        },
      }
    }
    if (payload.lastLoginFrom && payload.lastLoginTo) {
      tempFilter = {
        ...tempFilter,
        lastLogin: {
          from: payload?.lastLoginFrom,
          to: payload?.lastLoginTo,
        },
      }
    }
    try {
      const body = {
        search,
        ...tempFilter,
      }

      const data = await axiosInstance.post(
        '/admin/user?skip=0&limit=200',
        body
      )
      setData(data?.data?.data)
      setIsError(false)
      setErrorMessage(null)
    } catch (error) {
      setIsError(true)
      setErrorMessage(error)
    }
  }

  const deleteUsers = useCallback(async () => {
    if (selectedRows?.length > 0) {
      try {
        await axiosInstance.delete('/admin/user', {
          data: { ids: selectedRows },
        })
        let tempAarray = data?.map((item) => {
          if (selectedRows?.includes(item?.id)) {
            return { ...item, status: 'admin_blocked' }
          }
          return { ...item }
        })
        setData(tempAarray)
        setIsError(false)
      } catch (err) {
        setIsError(true)
      }
    }
  }, [selectedRows])

  const downloadCSV = () => {
    axiosInstance
      .get('/admin/user/downloadCsv/user')
      .then(() => {})
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    deleteUsers()
  }, [deleteUsers])

  const handleSearchChange = (value) => {
    setSearch(value)
  }
  const handleAgeChange = (value) => {
    setAge(value)
  }

  const handleStatusChange = (value) => {
    setStatus(value)
  }

  const handleGenderChange = (value) => {
    setGender(value)
  }

  const handleSelectedRowsChange = (value) => {
    setSelectedRows(value)
  }

  useEffect(() => {
    async function callAPI() {
      await fetchUsers()
    }

    callAPI()
  }, [search, payload])

  return (
    <UserContext.Provider
      value={{
        search,
        data,
        age,
        isError,
        errorMessage,
        gender,
        status,
        selectedRows,
        fetchUsers,
        deleteUsers,
        handleSearchChange,
        handleAgeChange,
        handleStatusChange,
        handleGenderChange,
        handleSelectedRowsChange,
        payload,
        setPayload,
        downloadCSV,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
UserProvider.propTypes = {
  children: PropsTypes.node,
}

export default UserContext
