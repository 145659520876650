import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import clsx from 'clsx'

import GlobalAccordion from 'components/global/GlobalAccordion'

import Page from 'components/global/Page'
import Popper from 'components/global/Popper'
import { ChevronDown } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { MoveLeft } from 'lucide-react'
import LightBtn from 'components/global/Buttons/LightBtn'

import AdminHeader from './AdminHeader'
import { useNavigate, useParams } from 'react-router-dom'

const AdminStaffActionHistory = () => {
  const filterRef = useRef()
  const [openFilterPopper, setOpenFilterPopper] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('Last week')
  const { id } = useParams()
  const navigate = useNavigate()

  const handleSelectFilter = (value) => {
    setSelectedFilter(value)
  }
  const filterModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }
  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader />
      </div>
      {/* page content  */}
      <div className='w-full'>
        {/* filter */}
        <div className='w-full flex justify-between items-center gap-x-2'>
          <div className='flex gap-x-2 items-center'>
            <LightBtn onClick={() => navigate(`/admin/staff-list/${id}`)}>
              <MoveLeft size={16} />
            </LightBtn>
            <h1 className='text-base font-bold '>Jennifer :</h1>{' '}
            <span>Action History</span>
          </div>
          <div className='flex justify-end items-center'>
            <h6 className='text-sm font-medium mr-4'>Select a period: </h6>
            <button
              className='bg-universal py-2 px-3 shadow-customBtnShadow rounded-xl text-sm font-medium flex justify-between items-center w-44'
              ref={filterRef}
              onClick={() => setOpenFilterPopper(true)}
            >
              {selectedFilter}
              <ChevronDown
                size={14}
                className={clsx(
                  'transition-transform',
                  openFilterPopper && 'rotate-180'
                )}
              />
            </button>
            <Popper
              open={openFilterPopper}
              popperOptions={filterModifier}
              onClose={() => setOpenFilterPopper(false)}
              refElement={filterRef.current}
            >
              <div className='border border-text-dm-input bg-universal p-1 rounded-xl w-44 flex flex-col items-start'>
                <button
                  className={clsx(
                    'hover:bg-hovered-bg ease-in-out text-sm font-semibold py-2 w-full text-start px-2 rounded-xl',
                    selectedFilter === 'Today' && 'bg-hovered-bg'
                  )}
                  onClick={() => handleSelectFilter('Today')}
                >
                  Today
                </button>
                <button
                  className={clsx(
                    'hover:bg-hovered-bg ease-in-out text-sm font-semibold py-2 w-full text-start px-2 rounded-xl',
                    selectedFilter === 'Yesterday' && 'bg-hovered-bg'
                  )}
                  onClick={() => handleSelectFilter('Yesterday')}
                >
                  Yesterday
                </button>
                <button
                  className={clsx(
                    'hover:bg-hovered-bg ease-in-out text-sm font-semibold py-2 w-full text-start px-2 rounded-xl',
                    selectedFilter === 'Last Week' && 'bg-hovered-bg'
                  )}
                  onClick={() => handleSelectFilter('Last Week')}
                >
                  Last Week
                </button>
                <button
                  className={clsx(
                    'hover:bg-hovered-bg ease-in-out text-sm font-semibold py-2 w-full text-start px-2 rounded-xl',
                    selectedFilter === 'Last Month' && 'bg-hovered-bg'
                  )}
                  onClick={() => setSelectedFilter('Last Month')}
                >
                  Last Month
                </button>
                <button
                  className={clsx(
                    'hover:bg-hovered-bg ease-in-out text-sm font-semibold py-2 w-full text-start px-2 rounded-xl',
                    selectedFilter === 'Custom' && 'bg-hovered-bg'
                  )}
                  onClick={() => handleSelectFilter('Custom')}
                >
                  Custom
                </button>
                {selectedFilter === 'Custom' && (
                  <div className='w-full px-2'>
                    <label className='block'>
                      <span className='block text-sm font-semibold text-gray-800'>
                        From:
                      </span>
                      <input
                        className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none px-2 py-1 w-full rounded-xl'
                        placeholder='MM/DD/YYYY'
                        type='date'
                      />
                    </label>
                    <label className='block mt-3'>
                      <span className='block text-sm font-semibold text-gray-800'>
                        To:
                      </span>
                      <input
                        className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none px-2 py-1 w-full rounded-xl mb-3'
                        placeholder='MM/DD/YYYY'
                        type='date'
                      />
                    </label>
                    {/* <div className='flex flex-nowrap py-3 gap-x-2'>
                    <button className='w-full px-2 py-1 rounded-xl text-white bg-text-dm-input shadow-buttonPrimary'>
                      Save
                    </button>
                    <button className='w-full px-2 py-1 rounded-xl text-gray-600 border border-text-dm-input '>
                      Cancel
                    </button>
                  </div> */}
                  </div>
                )}
              </div>
            </Popper>
          </div>
        </div>

        {/* main content */}
        <div className='w-full flex flex-col pt-3 gap-y-4'>
          <GlobalAccordion
            header={<h5 className='text-base font-semibold'>30/09/2023</h5>}
            content={
              <div className='flex'>
                {/* timeline */}
                <div className='w-full flex justify-between'>
                  <div className='w-full flex justify-between'>
                    {/* ========= status time line ========= */}
                    <Timeline className='w-2/3 -translate-x-1/3'>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Logout
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Bookmarked
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Changed status to
                          <span className='text-approved'>“Active”</span>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Changed status to
                          <span className='text-disapproved'>“Away”</span>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Checked user information
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Checked email
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Changed status to
                          <span className='text-approved'>“Approved”</span>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Left Comment
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height'>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                          <TimelineConnector className='!bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Changed status to
                          <span className='text-under-review'>
                            “Under Review”
                          </span>
                        </TimelineContent>
                      </TimelineItem>

                      <TimelineItem>
                        <TimelineOppositeContent className='!text-sm !font-normal !text-black'>
                          09:30
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot className='!border-none !bg-black' />
                        </TimelineSeparator>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          Login
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                    {/*====== result timeline ======= */}
                    <Timeline className='w-fit  border-l-2'>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden '>
                        <TimelineContent className='!text-sm !font-normal !text-black'>
                          <h6 className='text-sm font-semibold'>#math</h6>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-xs !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-xs !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-xs !font-normal !text-black'>
                          <div className='flex justify-start items-center gap-x-2 h-fit'>
                            <img
                              src='/assets/Image/imageTwo.jpg'
                              alt='profile'
                              className='w-9 h-9 rounded-full'
                            />
                            <div className='flex flex-col'>
                              <h6 className='font-bold text-sm'>io-data</h6>
                              <h6 className='underline font-normal text-sm'>
                                Robotty555
                              </h6>
                            </div>
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                      <TimelineItem className='!min-h-timeline-Height before:hidden'>
                        <TimelineContent className='!text-sm !font-normal !text-black'></TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </Page>
  )
}

export default AdminStaffActionHistory
