import React, { createContext, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import RightCanvas from 'components/global/RightCanvas'
import QnADrawer from 'components/global/QnA/QnADrawer'
import axiosInstance from '_utils/axiosUtils'

const QnAContext = createContext({
  hashId: null,
  handleOpen: () => null
})

export const QnAProvider = ({ children }) => {

  const [open, setOpen] = useState(false)
  const [hashId, setHashId] = useState(null)
  const [questions, setQuestions] = useState([])
  const [stats, setStats] = useState({})

  const handleOpen = (id) => {
    setHashId(id)
    setOpen(true)
  } 

  const handleClose = () => {
    setHashId(null)
    setOpen(false)
  }

  //get stats
  const getStats = async () => {
    await axiosInstance.get(`/admin/hashtag/${hashId}`)
    .then(res => res.data)
    .then(data => {
      setStats(data.data)
    })
    .catch(err => console.log(err))
  }

  //fetch related question
  const fetchQuestions = async () => {
    await axiosInstance.get(`/admin/question/hashtag/${hashId}`, {
      params: {
        skip: 0,
        limit: 20000
      }
    })
    .then(res => res.data)
    .then(data => setQuestions(data.data))
    .catch(err => console.log(err))
  }

  const deleteQuestion = async (ids) => {
    await axiosInstance.delete(`/admin/hashtag/${hashId}`, {
      data: {
        ids
      },
    })
      .then(res => res.data)
      .then( async data => {
        if(data?.success) {
          await getStats()
          setQuestions(prev => prev.filter(q => !ids?.includes(q?.id)))
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if(open) {
      getStats()
      fetchQuestions()
    }
  }, [open])

  return (
    <QnAContext.Provider
      value={{
        hashId,
        handleOpen
      }}
    >
      { children }
      <RightCanvas isOpen={open} onClose={handleClose} width={700}>
        <QnADrawer
          stats={stats}
          questions={questions}
          onClose={handleClose}
          deleteQuestion={deleteQuestion}
        />
      </RightCanvas>
    </QnAContext.Provider>
  )
}

QnAProvider.propTypes = {
  children: PropsTypes.node
}

export default QnAContext