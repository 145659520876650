import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const GlobalAccordion = ({
  header,
  content,
  defaultExpanded = false,
  disableToggle = false,
}) => {
  return (
    <Fragment>
      <div className='mb-2' style={{ backgroundColor: '#F9FAFF' }}>
        <Accordion
          className='font-poppins rounded-2xl !shadow-none'
          style={{
            backgroundColor: '#F9FAFF',
            borderBottom: '1px solid #CBCBCB',
          }}
          defaultExpanded={defaultExpanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#F9FAFF' }}
            disabled={disableToggle}
          >
            {header}
          </AccordionSummary>
          <AccordionDetails>{content}</AccordionDetails>
        </Accordion>
      </div>
    </Fragment>
  )
}

// PropTypes definition for the MegaFilter component
GlobalAccordion.propTypes = {
  header: PropTypes.node.isRequired, // Expecting a React node for the header
  content: PropTypes.node.isRequired, // Expecting a React node for the content
  defaultExpanded: PropTypes.bool, // default Expanded
  disableToggle: PropTypes.bool, // disable Toggle Button
}

export default GlobalAccordion
