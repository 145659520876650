import React, { useEffect, useMemo, useState } from 'react'
import Input from 'components/global/Input'
import GlobalRadioButton from 'components/global/GlobalRadioButton'
import CustomSelect from 'components/global/CustomSelect'
import Page from 'components/global/Page'
import AdminHeader from './AdminHeader'
import useStaff from '_hooks/useStaff'
import useAuth from '_hooks/useAuth'

const GeneralSettings = () => {
  const [isValid, setIsvalid] = useState(true)
  const { team, position, grade, country, addStaff, addKey } = useStaff()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const { user } = useAuth()

  const [newValue, setNewValue] = useState({
    team: '',
    position: '',
    grade: '',
    country: '',
  })

  const [selected, setSelected] = useState({
    team: '',
    position: '',
    grade: '',
    country: '',
  })

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = () => {
    // if (!email || !fullName) return
    if (!email || !fullName) {
      setError('Please Enter a valid Log In ID and name.')
      return
    }
    if (!isValidEmail(email)) {
      setError('Please Enter a valid Log In ID.')
      return
    }

    setError('')

    const obj = {
      teamId: selected.team,
      positionId: selected.position,
      gradeId: selected.grade,
      countryId: selected.country,
      isValid,
      email,
      fullName,
    }
    addStaff(obj)

    setFullName('')
    setEmail('')
  }

  const handleSubmitNewValue = async (type) => {
    if (!newValue[type]) return
    await addKey(type, newValue[type])
    setNewValue((prev) => ({ ...prev, [type]: '' }))
  }

  const handleRadioChange = (bool) => {
    setIsvalid(bool)
  }

  const data = useMemo(() => {
    return {
      team: team?.rows?.map((v) => ({ label: v.name, value: v.id })),
      position: position?.rows?.map((v) => ({ label: v.name, value: v.id })),
      grade: grade?.rows?.map((v) => ({ label: v.name, value: v.id })),
      country: country?.rows?.map((v) => ({ label: v.name, value: v.id })),
    }
  }, [team, position, grade, country])

  useEffect(() => {
    setSelected((p) => ({
      ...p,
      team: data?.team?.[0]?.value,
      position: data?.position?.[0]?.value,
      grade: data?.grade?.[0]?.value,
      country: data?.country?.[0]?.value,
    }))
  }, [data])

  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader noSearch={true} />
      </div>
      <div className='grid grid-cols-2 gap-4'>
        <div className='shadow-customBtnShadow rounded-2xl h-[888px]'>
          <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
            <p className='text-black font-semibold font-poppins text-base ml-5'>
              Add New Staff
            </p>
            {user?.authorityName?.includes('staff_registration') && (
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
          <div className='grid grid-cols-2 gap-4 p-2 px-6 pt-10'>
            <div>
              <p className='text-black font-poppins text-sm font-semibold'>
                Name
              </p>
              <Input
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search rounded-r-xl'
                type='text'
                placeHolder='Enter name'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div>
              <p className='text-black font-poppins text-sm font-semibold'>
                Log In ID
              </p>
              <Input
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search rounded-r-xl'
                type='email'
                placeHolder='Enter log in ID'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className='flex items-center gap-x-6 mt-2'>
                <GlobalRadioButton
                  label='Valid'
                  name='shapeGroup'
                  value='square'
                  checked={isValid}
                  onChange={() => handleRadioChange(true)}
                  shape='md'
                />
                <GlobalRadioButton
                  label='Invalid'
                  name='shapeGroup'
                  value='square'
                  checked={!isValid}
                  onChange={() => handleRadioChange(false)}
                  shape='md'
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-4 p-2 px-6 py-10'>
            <div>
              <p className='text-black font-poppins text-sm pb-2 font-semibold'>
                Team
              </p>
              <CustomSelect
                width='251px'
                options={data?.team}
                selectedValue={selected.team}
                onChange={(value) =>
                  setSelected((prev) => ({ ...prev, team: value }))
                }
                fullWidth={true}
              />
            </div>
            <div>
              <p className='text-black font-poppins text-sm pb-2 font-semibold'>
                Position
              </p>
              <CustomSelect
                width='251px'
                options={data?.position}
                fullWidth={true}
                selectedValue={selected.position}
                onChange={(value) =>
                  setSelected((prev) => ({ ...prev, position: value }))
                }
              />
            </div>
          </div>
          <div className='px-6 py-4'>
            <p className='text-black font-poppins text-sm pb-2 font-semibold'>
              Grade
            </p>
            <CustomSelect
              width='522px'
              options={data?.grade}
              selectedValue={selected.grade}
              fullWidth={true}
              onChange={(value) =>
                setSelected((prev) => ({ ...prev, grade: value }))
              }
            />
          </div>
          {error && <span className='text-sm text-red-500 pl-6'>{error}</span>}
          <div className='px-6 py-4'>
            <p className='text-black font-poppins text-sm pb-2 font-semibold'>
              Country
            </p>
            <CustomSelect
              width='522px'
              options={data?.country}
              selectedValue={selected.country}
              fullWidth={true}
              onChange={(value) =>
                setSelected((prev) => ({ ...prev, country: value }))
              }
            />
          </div>
          {error && <span className='text-sm text-red-500 pl-6'>{error}</span>}
        </div>
        <div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                Add New Team
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={() => handleSubmitNewValue('team')}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Name
              </p>
              <Input
                value={newValue.team}
                onChange={(e) =>
                  setNewValue((prev) => ({ ...prev, team: e.target.value }))
                }
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Enter New Team'
              />
            </div>
          </div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                Add New Position
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={() => handleSubmitNewValue('position')}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Name
              </p>
              <Input
                value={newValue.position}
                onChange={(e) =>
                  setNewValue((prev) => ({ ...prev, position: e.target.value }))
                }
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Enter New Position'
              />
            </div>
          </div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                Add New Grade
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={() => handleSubmitNewValue('grade')}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Name
              </p>
              <Input
                value={newValue.grade}
                onChange={(e) =>
                  setNewValue((prev) => ({ ...prev, grade: e.target.value }))
                }
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Enter New Grade'
              />
            </div>
          </div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                Add New Country
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={() => handleSubmitNewValue('country')}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Name
              </p>
              <Input
                value={newValue.country}
                onChange={(e) =>
                  setNewValue((prev) => ({ ...prev, country: e.target.value }))
                }
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Enter New Country'
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default GeneralSettings
