import React, { createContext, useEffect, useState } from 'react'
import config from 'config/config'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  logout: () => null,
  login: () => null,
  isError: false,
  errorMessage: null,
})

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const navigate = useNavigate()

  const login = async (email, password) => {
    if (!email) throw 'no email!'
    if (!password) throw 'no password!'
    axiosInstance
      .post('/admin/auth/login', {
        email,
        password,
      })
      .then((res) => {
        window.localStorage.setItem(config.TOKEN_KEY, res.data.data?.token)
        setUser(res.data.data?.admin)
        setIsAuthenticated(true)
        setIsError(false)
        setErrorMessage(null)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.response.data.message)
      })
  }

  const logout = async () => {
    axiosInstance
      .get('/admin/auth/logout')
      .then(() => {
        setUser(null)
        setIsAuthenticated(false)
        setIsError(false)
        setErrorMessage(undefined)

        window.localStorage.removeItem(
          process.env.REACT_APP_HASHMEMO_TOKEN || 'my-indent'
        )
        navigate('/')
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.response.data.message)
      })
  }

  useEffect(() => {
    const token = window.localStorage.getItem(config.TOKEN_KEY)
    if (!token) {
      setIsAuthenticated(false)
      return
    } else {
      axiosInstance
        .get('/admin/user/me')
        .then((res) => {
          let authorityName = res.data.data.grade.authority.map(
            (item) => item.type
          )
          setUser({ ...res.data.data, authorityName })
          setIsAuthenticated(true)
        })
        .catch((err) => {
          console.log(err)
          setIsAuthenticated(false)
        })
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        logout,
        login,
        isError,
        errorMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropsTypes.node,
}

export default AuthContext
