const actionHistoryText = (item) => {
  let textString = ''
  switch (item?.type) {
    case 'login':
      textString = 'Login'
      break
    case 'delete_post':
      textString = 'Deleted Post'
      break
    case 'logout':
      textString = 'Logout'
      break
    case 'delete_hashtag':
      textString = 'Deleted Hashtag'
      break
    case 'delete_comments':
      textString = 'Deleted Comments'
      break
    case 'delete_user':
      textString = 'Deleted User'
      break
    case 'unlocked_score':
      textString = 'Unlocked Score of Post'
      break
    case 'change_profile_score':
      textString = `Changed Score of User from ${item?.oldValue} to ${item?.newValue}`
      break
    case 'changed_post_status':
      textString = `Changed Status of Post from ${item?.oldValue} to ${item?.newValue}`
      break
    case 'changed_post_locked_score':
      textString = `Changed Score of Post from ${item?.oldValue} to ${item?.newValue}`
      break
    case 'changed_post_unlocked_score':
      textString = 'Unlocked Score of Post'
      break
    case 'changed_report_result':
      textString = `Changed Result of Report from ${item?.oldValue
        ?.split('_')
        .join(' ')} to ${item?.newValue?.split('_').join(' ')}`
      break
    case 'changed_staff_details':
      textString = 'Changed Details of Staff'
      break
    case 'changed_authorities':
      textString = 'Changed Authority of Grade'
      break
    case 'changed_grade_name':
      textString = `Changed Grade Name from ${item?.oldValue} to ${item?.newValue}`
      break
    case 'download_csv_user':
      textString = 'Downloaded CSV File of Users'
      break
    case 'download_csv_hashtag':
      textString = 'Downloaded CSV File of Hashtag'
      break
    case 'delete_user_memo':
      textString = 'Deleted Memo'
      break
    case 'delete_question_answer':
      textString = 'Deleted Answers of Question'
      break
    case 'add_team':
      textString = 'Added A Team'
      break
    case 'add_grade':
      textString = 'Added A Grade'
      break
    case 'add_position':
      textString = 'Added A Position'
      break
    case 'add_country':
      textString = 'Added A Country'
      break
    default:
      textString = ''
      break
  }
  return textString
}

export default actionHistoryText
