import Page from 'components/global/Page'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { HiOutlineStar } from 'react-icons/hi'
import { GoAlert, GoTrash } from 'react-icons/go'
import GlobalModal from 'components/global/GlobalModal'
import { BsFiletypeCsv } from 'react-icons/bs'
import axiosInstance from '_utils/axiosUtils'
import moment from 'moment'
import Loader from 'components/global/Loader'
import Input from 'components/global/Input'
import { RiSearchLine } from 'react-icons/ri'
import { SlidersHorizontal } from 'lucide-react'
import useHashtag from '_hooks/useHashtag'
import { a11y } from '_utils/a11y'
import Popper from 'components/global/Popper'
import HashtagFilter from 'components/hashtag/HashtagFilter'
import useAuth from '_hooks/useAuth'

const Hashtag = () => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [payload, setPayload] = useState(null)
  const handleClose = () => setOpen(false)
  const [hashtags, setHashtags] = useState([])
  const [skip] = useState(0)
  const [limit] = useState(1000)
  const [total, setTotal] = useState(0)
  const [_selectedRows, _setSelectedRows] = useState([])
  const [password, setPassword] = useState('')
  const [errorPass, setErrorPass] = useState('')

  // search component
  const popperRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)
  const [search, setSearch] = useState('')

  const handleSearchChange = (val) => {
    setSearch(val)
  }

  const _paginationRef = useRef(null)

  const { handleOpen, downloadCSV } = useHashtag()
  const { user } = useAuth()

  const handleOpenconform = () => {
    if (_selectedRows.length > 0) {
      setOpen(true)
    } else {
      alert('Select Any Hashtag')
    }
  }

  const fetchHashtags = (search) => {
    setLoading(true)
    axiosInstance
      .get('/admin/hashtag', {
        params: {
          search: search,
          skip: skip,
          limit: limit,
          from: payload?.from ? payload?.from : null,
          to: payload?.to ? payload?.to : null,
        },
      })
      .then((res) => {
        setHashtags(res.data.data.rows)
        setTotal(res?.data?.data?._count)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  // Call fetchHashtags with 'search' as argument
  useEffect(() => {
    fetchHashtags(search)
  }, [limit, skip, search, payload])

  const validatePass = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('/admin/auth/validate/password', { password })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const deleteHashtag = (_id) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/admin/hashtag/${_id}`)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => reject(err))
    })
  }

  // const _deleteOnSubmit = () => {
  //   if (!password) {
  //     return
  //   }

  //   validatePass()
  //     .then(async () => {
  //       if (_selectedRows.length) {
  //         const _id = _selectedRows.map((value) => value.id)
  //         console.log(_id);
  //         await Promise.all(_id.map(value => deleteHashtag(value)))
  //           .then(() => {
  //             setPassword('')
  //             _selectedRows([])
  //             handleClose()
  //           })
  //           .catch(err => console.log(err))
  //       }
  //     })
  //     .catch(err => console.log(err))
  // }

  const _deleteOnSubmit = () => {
    if (password.length === 0) {
      setErrorPass('Enter Your Password')
    }
    if (!password) {
      return
    }

    validatePass()
      .then(async () => {
        if (_selectedRows.length) {
          await Promise.all(_selectedRows.map((value) => deleteHashtag(value)))
            .then(() => {
              setPassword('')
              _setSelectedRows([])
              handleClose()
              window.location.reload()
            })
            .catch((err) => console.log(err))
        }
      })
      .catch((err) => setErrorPass(err.response.data.message.en))
  }

  const customCellStyles = {
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '30px',
        paddingLeft: '50px',
      },
    },
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
        paddingLeft: '50px',
      },
    },
  }

  const [selectAll, setSelectAll] = useState(false)
  const [individualCheckboxes, setIndividualCheckboxes] = useState({})

  useEffect(() => {
    const initialCheckboxes = {}
    hashtags.forEach((row) => {
      initialCheckboxes[row.id] = false
    })
    setIndividualCheckboxes(initialCheckboxes)
  }, [hashtags])

  const handleGetAllData = () => {
    const newSelectAll = !selectAll
    const newIndividualCheckboxes = { ...individualCheckboxes }

    Object.keys(newIndividualCheckboxes).forEach((key) => {
      newIndividualCheckboxes[key] = newSelectAll
    })

    setSelectAll(newSelectAll)
    setIndividualCheckboxes(newIndividualCheckboxes)
  }

  const handleCheckboxChange = (row) => {
    const updatedSelectedRows = { ...individualCheckboxes }
    updatedSelectedRows[row.id] = !updatedSelectedRows[row.id]
    setIndividualCheckboxes(updatedSelectedRows)
    _setSelectedRows(
      Object.keys(updatedSelectedRows).filter((id) => updatedSelectedRows[id])
    )
  }

  const customizedHashtagName = (row) => {
    return (
      <div className='flex items-center justify-start font-bold gap-2 whitespace-nowrap min-h-[8vh]'>
        <u {...a11y(() => handleOpen(row?.id, true, 'right'))}>{row['name']}</u>
      </div>
    )
  }

  const columns = [
    {
      name: (
        <div className='flex mr-11'>
          <input
            type='checkbox'
            style={{
              height: '23px',
              width: '23px',
              fontWeight: 'bold',
              fontSize: '23px',
              marginLeft: '6px',
            }}
            onChange={() => handleGetAllData()}
            checked={selectAll}
          />
        </div>
      ),
      selector: true,
      cell: (row) => {
        const [showStar, setShowStar] = useState(true)

        const handleToggle = () => {
          setShowStar(!showStar)
        }

        return (
          <div className='flex items-center justify-center'>
            <input
              type='checkbox'
              style={{
                height: '23px',
                width: '23px',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
              className='mr-5'
              onChange={() => handleCheckboxChange(row)}
              checked={individualCheckboxes[row.id] || false}
            />
            {showStar ? (
              <HiOutlineStar
                className='-translate-x-2.5 cursor-pointer'
                size={20}
                color='#292D32'
                onClick={handleToggle}
              />
            ) : (
              <input
                type='image'
                src='/assets/svg/star.svg'
                className='-translate-x-2.5 cursor-pointer'
                alt='star'
                onClick={handleToggle}
              />
            )}
          </div>
        )
      },
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Hashtags',
      selector: (row) => row['name'],
      sortable: true,
      cell: (row) => customizedHashtagName(row),
    },
    {
      name: 'Followers',
      selector: (row) => row['followers'],
      sortable: true,
    },
    {
      name: 'Posts',
      selector: (row) => row['posts'],
      sortable: true,
    },
    {
      name: 'Language',
      selector: (row) => row['language'],
      sortable: true,
    },
    {
      name: 'Memo',
      selector: (row) => row['memo'],
      sortable: true,
    },
    {
      name: 'Creation Date',
      selector: (row) => row['createdAt'],
      sortable: true,
    },
  ]

  const _hashtags = useMemo(() => {
    return (
      hashtags.map((_hash) => {
        let response = {
          id: _hash?.id,
        }

        const createdAt = moment(_hash?.createdAt).format('DD/MM/YYYY') || ''
        response = {
          ...response,
          name: _hash?.name,
          createdAt,
          followers: _hash?._count?.followers,
          posts: _hash?._count?.postTags,
          language: _hash?._count?.language || 'English',
          memo: `${_hash?._count?.memo}Q(${_hash?._count?.answer}A)`,
          _memo: _hash?._count?.memo || 0,
          _answer: _hash?._count?.answer || 0,
        }
        return response
      }) || []
    )
  }, [hashtags])

  const downloadcsv = () => {
    if (_hashtags.length > 0) {
      downloadCSV()
      const csvData = prepareCSVData(_hashtags)
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'hashtags.csv'
      link.click()
    } else {
      console.log('No data available to download.')
    }
  }

  const prepareCSVData = (data) => {
    let csv = 'Hashtags,Followers,Posts,Language,Memo,Creation Date\n'
    data.forEach((row) => {
      csv += `${row.name},${row.followers},${row.posts},${row.language},${row.memo},${row.createdAt}\n`
    })
    return csv
  }

  return (
    <Page>
      <div className='w-full pl-12' id='header'>
        <Input
          placeHolder='Search...'
          value={search}
          onChange={(e) => handleSearchChange(e.target.value)}
          leftIcon={<RiSearchLine className='w-6 h-6' />}
          rightIcon={
            <SlidersHorizontal
              ref={popperRef}
              onClick={() => setFilterOpen(true)}
              className='w-6 h-6 cursor-pointer'
              style={{ color: filterOpen ? '#00BDB2' : '' }}
            />
          }
          className='flex-grow !border-light-border-search !rounded-xl'
        />
        <Popper
          refElement={popperRef.current}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          popperOptions={{
            strategy: 'fixed',
            placement: 'bottom-end',
          }}
        >
          <div
            className='bg-universal p-3 rounded-2xl border border-teal-500 '
            style={{
              height: '314px',
              width: 'auto',
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              WebkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <HashtagFilter
              payload={payload}
              setPayload={setPayload}
              onClose={() => setFilterOpen(false)}
            />
          </div>
        </Popper>
      </div>
      <div className='w-full flex justify-between px-5'>
        <h1 className='text-2xl'>
          <span className='font-semibold'>Total </span>
          {total}
        </h1>

        <div className='flex gap-2 mb-5'>
          {user?.authorityName.includes('deletion_hashtag') && (
            <button
              onClick={handleOpenconform}
              className='px-4 py-2 border text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
            >
              <GoTrash className='bg-transparent text-white' />
            </button>
          )}

          <GlobalModal isOpen={open} handleClose={handleClose} height={450}>
            <div className='flex flex-col items-center gap-y-10 h-full justify-center'>
              <div className='flex flex-col text-center gap-y-4'>
                <div className='flex justify-center items-center w-100'>
                  <GoAlert className='bg-bg-alert p-3 text-teal-500 text-8xl' />
                </div>
                <div className='flex flex-col items-center gap-y-1'>
                  <h5 className='font-semibold'>Final Confirmation</h5>
                  <h6 className='font-medium text-md'>
                    Are you sure that you want to delete this hashtag?
                  </h6>
                  <small className='font-medium text-xs'>
                    *All data and information of this user will be deleted.
                  </small>
                </div>
              </div>
              <div className='text-center w-full flex flex-col items-center'>
                <h6 className='text-sm mb-2'>Enter Password</h6>
                <input
                  type='password'
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='mt-1 w-5/6 px-3 text-lg py-4 bg-transparent border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-teal-400 focus:ring-teal-400 block rounded-md sm:text-sm focus:ring-1'
                />
                {errorPass && (
                  <span className='text-red-500 text-xs self-start pl-9'>
                    {errorPass}
                  </span>
                )}
              </div>
              <div className='w-full text-center flex flex-nowrap justify-center gap-2 pb-2'>
                <button
                  className='bg-teal-500 w-1/3 py-2 rounded-lg text-white shadow-buttonPrimary'
                  onClick={_deleteOnSubmit}
                >
                  Yes
                </button>
                <button
                  className='border-teal-500 w-1/3  rounded-lg border text-gray-400 bg-transparent py-2'
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </GlobalModal>

          {user?.authorityName.includes('download_csv') && (
            <button
              onClick={downloadcsv}
              className='bg-white-500 border border-teal-400 text-teal-500 text-xl py-2 px-3 rounded-xl'
            >
              <BsFiletypeCsv />
            </button>
          )}
        </div>
      </div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <DataTable
          columns={columns}
          data={_hashtags}
          highlightOnHover
          // selectableRows
          customStyles={customCellStyles}
          className='custom-data-table'
          onSelectedRowsChange={(row) => _setSelectedRows(row?.selectedRows)}
        />
      )}
      <div className='h-5 mb-5 w-full' ref={_paginationRef} />
    </Page>
  )
}

export default Hashtag
