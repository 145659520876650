import React, { createContext, useState } from 'react'
import PropsTypes from 'prop-types'
import RightCanvas from 'components/global/RightCanvas'
import ResumeDrawer from 'components/global/Resume/ResumeDrawer'

const ResumeContext = createContext({
  handleOpen: () => null,
  handleClose: () => null,
  data: null
})

export const ResumeProvider = ({ children }) => {
  const [_open, _setOpen] = useState(false)
  const [type, setType] = useState('')
  const [data, setData] = useState(null)

  const handleOpen = (_type, _data) => {
    setType(_type)
    setData(_data)
    _setOpen(true)
  }

  const handleClose = () => {
    setType('')
    setData(null)
    _setOpen(false)
  }
  return (
    <ResumeContext.Provider
      value={{
        handleOpen,
        handleClose,
        data
      }}
    >
      {children}
      <RightCanvas isOpen={_open} onClose={handleClose}>
        <ResumeDrawer
          type={type}
          data={data}
          onClose={handleClose}
        />
      </RightCanvas>
    </ResumeContext.Provider>
  )
}

ResumeProvider.propTypes = {
  children: PropsTypes.node
}

export default ResumeContext