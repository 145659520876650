import React, { useEffect, useState } from 'react'
import Page from 'components/global/Page'
import Input from 'components/global/Input'
import { Eye, Info } from 'lucide-react'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import Header from 'components/settings/Header'
import useAuth from '_hooks/useAuth'
import axiosInstance from '_utils/axiosUtils'
import { defaultProfileImg } from '_utils/default'
import moment from 'moment'
import config from 'config/config'
import useSettings from '_hooks/useSettings'
import CustomSwitch from 'components/global/CustomSwitch'

const SettingsInformation = () => {
  const [showCurrentPasword, setShowCurrentPasword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [settings, setSettings] = useState(null)
  const [data, setData] = useState()

  const { user } = useAuth()
  const { updateStatus } = useSettings()

  const fetchUser = async () => {
    axiosInstance
      .get(`/admin/staff/${user?.id}`)
      .then((res) => {
        setData(res.data?.data)
      })
      .catch((err) => console.log(err))
  }

  const fetchSettings = async () => {
    axiosInstance
      .get('admin/adminSetting')
      .then((res) => {
        setSettings(res.data?.data)
      })
      .catch((err) => console.log(err))
  }

  const updateSettings = async (payload) => {
    delete payload['maintenanceStartDate']
    delete payload['maintenanceEndDate']
    delete payload['id']
    axiosInstance
      .put('admin/adminSetting', payload)
      .then((res) => {
        setSettings(res.data?.data)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchUser()
  }, [user])

  useEffect(() => {
    fetchSettings()
  }, [])

  //====================== ForImage
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      setSelectedImage(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <Page>
      {/* header  */}
      <div id='header' className='w-full'>
        <Header />
      </div>
      {/* page content  */}
      <div className='w-full shadow-customBtnShadow rounded-xl h-max flex flex-col p-6'>
        {/* first row  */}
        <div className='w-full grid grid-cols-4 gap-x-5 border-b py-6'>
          <div className=' flex flex-col w-full'>
            <h6 className='text-base font-semibold px-1 pb-2'>Staff Name</h6>
            <Input
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.fullName}
              disabled
            />
          </div>

          <div className=' flex flex-col w-full'>
            <h6 className='text-base font-semibold px-1 pb-2'>Team</h6>
            <Input
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.team?.name}
              disabled
            />
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-base font-semibold px-1 pb-2 flex w-full justify-between items-center'>
              Account Type <Info size={13} className='cursor-pointer' />
            </h6>
            <Input
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.grade?.name}
              disabled
            />
          </div>
          <div className='flex flex-col w-full'>
            <h6 className='text-base font-semibold px-1 pb-2'>Position</h6>
            <Input
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.position?.name}
              disabled
            />
          </div>
        </div>
        {/* second Row */}
        <div className='w-full grid grid-cols-4 gap-x-5 border-b py-6'>
          <div className=' flex flex-col w-full'>
            <h6 className='text-base font-semibold px-1 pb-2'>Log In ID</h6>
            <Input
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.email}
              disabled
            />
          </div>
        </div>
        {/* Third Row */}
        <div className='w-full grid grid-cols-4 gap-x-5 border-b  py-6 gap-y-3'>
          <div className='col-span-4 flex justify-between'>
            <h6 className='text-base font-semibold select-none'>Password</h6>
            <h6 className='text-base font-semibold'>
              Last Updated:
              <span className='text-base !font-normal'>
                {' '}
                {moment(data?.updatedAt).format(`${config.DATE_FORMAT} HH:mm`)}
              </span>
            </h6>
          </div>
          <div className=' flex flex-col w-full'>
            <label
              className='text-sm font-medium pb-2 select-none'
              htmlFor='input'
            >
              Current Password
            </label>
            <Input
              type={!showCurrentPasword && 'password'}
              rightIcon={
                showCurrentPasword ? (
                  <Eye
                    size={16}
                    onClick={() => setShowCurrentPasword(!showCurrentPasword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={16}
                    onClick={() => setShowCurrentPasword(!showCurrentPasword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                )
              }
              placeHolder='Enter password'
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium w-full'
            />
          </div>
          <div className=' flex flex-col w-full'>
            <label
              className='text-sm font-medium pb-2 select-none'
              htmlFor='input'
            >
              New Password
            </label>
            <Input
              type={!showNewPassword && 'password'}
              rightIcon={
                showNewPassword ? (
                  <Eye
                    size={16}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={16}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                )
              }
              placeHolder='Enter password'
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
            />
          </div>
          <div className=' flex flex-col w-full'>
            <label
              className='text-sm font-medium pb-2 select-none'
              htmlFor='input'
            >
              Confirm Password
            </label>
            <Input
              type={!showConfirmPassword && 'password'}
              rightIcon={
                showConfirmPassword ? (
                  <Eye
                    size={16}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={16}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className='text-text-dm-input cursor-pointer'
                  />
                )
              }
              placeHolder='Enter password'
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
            />
          </div>
          <div className='flex flex-nowrap gap-x-4 w-full h-full items-end'>
            <button className='shadow-buttonPrimary w-full bg-text-dm-input text-white text-base font-semibold py-2 px-4 rounded-xl'>
              Save
            </button>
            <button className=' w-full border border-text-dm-input text-gray-400 text-base font-semibold py-2 px-4 rounded-xl'>
              Cancel
            </button>
          </div>
        </div>
        {/* Fourth Row  */}
        <div className='w-full grid grid-cols-4 gap-x-5 border-b  py-6 gap-y-3'>
          <div className='flex flex-col gap-y-4'>
            <h6 className='text-base font-semibold'>Profile Image</h6>
            <div className='flex flex-nowrap items-center gap-x-3'>
              <img
                src={
                  selectedImage || defaultProfileImg(data?.profileImage, 'user')
                }
                alt='uploadedImg'
                className='w-14 h-14 rounded-full object-cover'
              />
              <input
                type='file'
                name='uploadImg'
                id='uploadImage'
                className='hidden'
                onChange={handleImageChange}
              />
              <label
                htmlFor='uploadImage'
                className='underline text-text-dm-input text-sm font-medium cursor-pointer'
              >
                Select image
              </label>
            </div>
          </div>
          <div className='flex flex-col gap-y-5'>
            <h6 className='text-base font-semibold'>Status</h6>
            <select
              name='status'
              id='status'
              className='border-gray-300 border rounded-xl px-3 w-full py-2 bg-universal text-sm font-medium'
              onChange={(e) =>
                updateStatus({ id: user?.id, status: e.target.value })
              }
            >
              <option value='active'>Active</option>
              <option value='away'>Away</option>
              <option value='meeting'>Meeting</option>
              <option value='logout'>Logout</option>
            </select>
          </div>
          <div className=' flex flex-col gap-y-4'>
            <h6 className='text-sm font-medium pb-2'>Country</h6>
            <Input
              placeHolder='Enter country name'
              // disabled
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium'
              value={data?.country?.name}
            />
          </div>
        </div>
        {/* Fifth Row */}
        <div className='w-full grid grid-cols-4 gap-x-5 border-b  py-6 gap-y-3'>
          <div className='col-span-4 flex justify-between'>
            <h6 className='text-base font-semibold'>External URL</h6>
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-sm font-medium pb-2'>Webflow</h6>
            <Input
              placeHolder='Enter password'
              disabled
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium underline'
              value='https://xxxx.xxxx.com'
            />
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-sm font-medium pb-2'>Analytics</h6>
            <Input
              placeHolder='Enter password'
              disabled
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium underline'
              value='https://xxxxx.xxxxx.com'
            />
          </div>
        </div>
        <div className='w-full grid grid-cols-4 gap-x-5   py-6 gap-y-3'>
          <div className='col-span-4 flex justify-between'>
            <h6 className='text-base font-semibold'>General Settings</h6>
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-sm font-medium pb-2'>App Version (Android) </h6>
            <Input
              placeHolder='Enter password'
              disabled
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium underline'
              value={settings?.androidAppVersion}
            />
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-sm font-medium pb-2'>App Version (Ios) </h6>
            <Input
              placeHolder='Enter password'
              disabled
              className='!border-gray-300 !border-2 rounded-xl !px-3 !py-2 bg-universal text-sm font-medium underline'
              value={settings?.iosAppVersion}
            />
          </div>
          <div className=' flex flex-col w-full'>
            <h6 className='text-sm font-medium pb-2'>Is Maintenance On?</h6>
            <CustomSwitch
              isOn={settings?.isMaintenanceOn}
              handleToggle={() =>
                setSettings({
                  ...settings,
                  isMaintenanceOn: !settings.isMaintenanceOn,
                })
              }
            />
          </div>
          <div className='flex flex-nowrap gap-x-4 w-full h-full items-end'>
            <button
              onClick={() => updateSettings(settings)}
              className='shadow-buttonPrimary w-full bg-text-dm-input text-white text-base font-semibold py-2 px-4 rounded-xl'
            >
              Save
            </button>
            <button className='w-full border border-text-dm-input text-gray-400 text-base font-semibold py-2 px-4 rounded-xl'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SettingsInformation
