import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Input = forwardRef(
  (
    {
      className = '',
      leftIcon = null,
      rightIcon = null,
      type = 'text',
      placeHolder = '',
      onChange,
      value,
      disabled,
      inputClassName,
    },
    ref
  ) => {
    return (
      <div
        className={clsx(
          'flex justify-between py-2.5 px-3 items-center space-x-2 !border-input-border !border-solid border-text-dm-input rounded-lg',
          className
        )}
      >
        {leftIcon && (
          <div className='flex justify-start items-center basis-3/12'>
            {leftIcon}
          </div>
        )}
        <input
          type={type}
          className={clsx(
            'focus:outline-none shrink md:max-w-[140px] lg:w-full bg-universal',
            inputClassName
          )}
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
          ref={ref}
          disabled={disabled}
        />
        {rightIcon && (
          <div className='flex justify-end basis-3/12 items-center'>
            {rightIcon}
          </div>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

Input.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}

export default Input
