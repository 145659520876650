import clsx from 'clsx'
import RightCanvas from 'components/global/RightCanvas'
import PropsTypes from 'prop-types'
import { ChevronRight, PlusCircle } from 'lucide-react'
import React, { useState } from 'react'

const History = () => {
  const [activeHistory, setActiveHistory] = useState('profile')
  const [ForProfile] = useState(1)
  const [ForPosts] = useState(1)
  const handleChangeHistory = (value) => {
    setActiveHistory(value)
  }

  const RenderComponent = ({ activeHistory }) => {
    switch (activeHistory) {
      case 'profile':
        return <ProfileCards />
      case 'posts':
        return <PostsCards />
      default:
        return null
    }
  }

  RenderComponent.propTypes = {
    activeHistory: PropsTypes.string,
  }

  return (
    <div className='w-full flex flex-col h-[88vh]'>
      <h1 className='font-bold text-lg py-3'>
        {activeHistory === 'profile' ? 'History 1' : 'Memo History 1'}
      </h1>
      <div className='w-full flex bg-bg-tabs rounded-xl p-1 h-fit justify-around gap-x-2 border border-solid border-text-dm-input'>
        <button
          className={clsx(
            'font-semibold py-2 w-full h-full transition-all text-sm',
            activeHistory !== 'profile' && 'text-text-lm-primary',
            activeHistory === 'profile' &&
              'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={() => {
            handleChangeHistory('profile')
          }}
        >
          For Profile {`(${ForProfile})`}
        </button>
        <button
          className={clsx(
            'font-semibold z-10 py-2 w-full h-full transition-all text-sm',
            activeHistory !== 'posts' && 'text-text-lm-primary',
            activeHistory === 'posts' &&
              'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={() => {
            handleChangeHistory('posts')
          }}
        >
          For Posts {`(${ForPosts})`}
        </button>
      </div>

      {/* content */}
      <RenderComponent activeHistory={activeHistory} />
    </div>
  )
}

// profile card
const ProfileCards = () => {
  const [isMemoOpen, setIsMemoOpen] = useState(false)
  const HandleMemoDrawer = () => setIsMemoOpen(!isMemoOpen)
  return (
    // Profile Cards Main Container
    <div className='w-full py-3 grid grid-cols-card-md place-items-start gap-y-3 p-4 '>
      {/* Profile Cards */}
      <button
        onClick={HandleMemoDrawer}
        className='flex items-center max-w-max gap-x-2 hover:text-text-dm-input transition cursor-pointer p-3'
      >
        <img
          src='/assets/Image/Ellipse 7495.png'
          alt='user'
          className='rounded-full w-10 h-10'
        />
        <span className='flex flex-col items-start justify-between'>
          <h2 className='font-semibold text-sm'>Joker King00</h2>
          <h3 className='underline text-xs'>joker_0000</h3>
        </span>
        <ChevronRight size={18} />
      </button>

      {/* Profile Memo Drawer */}
      <RightCanvas isOpen={isMemoOpen} onClose={HandleMemoDrawer}>
        <div className='w-full px-3'>
          {/* header */}
          <div className='flex items-center gap-x-3 p-4'>
            <PlusCircle
              className='rotate-45 cursor-pointer'
              onClick={HandleMemoDrawer}
            />
            {/* profile */}
            <div className='flex items-center gap-x-2'>
              <img
                src='/assets/Image/Ellipse 7495.png'
                alt='user'
                className='rounded-full w-14 h-14'
              />
              <span className='flex flex-col items-start justify-between'>
                <h2 className='font-bold text-lg'>Joker King00</h2>
                <h3 className='underline font-semibold text-sm'>joker_0000</h3>
              </span>
            </div>
          </div>

          {/* Comments  */}
          <div className='px-4 flex flex-col'>
            {/* Comment */}
            <div className='flex items-start gap-x-2 border-t py-3'>
              <img
                src='/assets/Image/Ellipse 7495.png'
                alt='user'
                className='rounded-full w-10 h-10'
              />
              <span className='flex flex-col items-start '>
                <h4 className='text-xs font-bold'>Me</h4>
                <h5 className='text-xs font-normal'>
                  Hi, I have a question about your school you went.
                </h5>
                <h5 className='text-xs font-light'>30/07/2023</h5>
                {/* Sub Comments  */}
                <div className='flex items-start gap-x-2 pt-3'>
                  <img
                    src='/assets/Image/Ellipse 7495.png'
                    alt='user'
                    className='rounded-full w-7 h-7'
                  />
                  <span className='flex flex-col items-start '>
                    <h4 className='text-xs font-bold'>Joker King00</h4>
                    <h5 className='text-xs font-normal'>Yes, what is it?</h5>
                    <h5 className='text-xs font-light'>30/07/2023</h5>
                  </span>
                </div>
                {/* Sub Comment 2  */}
                <div className='flex items-start gap-x-2 pt-3'>
                  <img
                    src='/assets/Image/Ellipse 7495.png'
                    alt='user'
                    className='rounded-full w-7 h-7'
                  />
                  <span className='flex flex-col items-start '>
                    <h4 className='text-xs font-bold'>Me</h4>
                    <h5 className='text-xs font-normal'>
                      XX school you went to, what classes did you take? I
                      actually graduated from the same school.
                    </h5>
                    <h5 className='text-xs font-light'>30/07/2023</h5>
                  </span>
                </div>
                {/* sub comment 3 */}
                <div className='flex items-start gap-x-2 pt-3'>
                  <img
                    src='/assets/Image/Ellipse 7495.png'
                    alt='user'
                    className='rounded-full w-7 h-7'
                  />
                  <span className='flex flex-col items-start '>
                    <h4 className='text-xs font-bold'>Joker King00</h4>
                    <h5 className='text-xs font-normal'>
                      Really? What a coincidence!
                    </h5>
                    <h5 className='text-xs font-light'>30/07/2023</h5>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </RightCanvas>
    </div>
  )
}

const PostsCards = () => {
  return (
    // Post Cards Main Container
    <div className='w-full py-3 grid grid-cols-2 gap-4 max-h-max overflow-y-auto p-2'>
      {/* PostCard */}
      <div className='shadow-customBtnShadow rounded-xl flex justify-between items-center p-3 cursor-pointer'>
        <div className='flex items-center gap-3'>
          <img
            src='/assets/Image/english 9.png'
            alt='userImg'
            className='w-14 h-14 rounded-full'
          />
          <div className='flex flex-col gap-1'>
            <h5 className='text-base font-bold'>Me</h5>
            <h5 className='text-sm font-medium'>I have a question about AI</h5>
            <h5 className='text-xs font-medium'>01/15/23 3:03 PM</h5>
          </div>
        </div>
        <img
          src='/assets/Image/english 9.png'
          alt='userpost'
          className='w-14 h-20 object-cover rounded-lg'
        />
      </div>
    </div>
  )
}

export default History
