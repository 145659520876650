import React from 'react'

import PropTypes from 'prop-types'
import { Box, Modal } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  backgroundColor: '#F9FAFF', // Corrected property name
  border: '1px solid #00BDB2',
  borderRadius: '12px',
  boxShadow: ' 0px 4px 40px 0px rgba(0, 0, 0, 0.12);', // Valid CSS value for boxShadow
  p: 4,
}

const GlobalModal = ({ isOpen, handleClose, children, width, height }) => {
  // set the hight and width dynamically

  const dynamicStyle = {
    ...style,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={dynamicStyle}>{children}</Box>
    </Modal>
  )
}

// Define PropTypes for the component
GlobalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Allow width to be a number or string
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Allow height to be a number or string
}

export default GlobalModal
