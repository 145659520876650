import GlobalAccordion from 'components/global/GlobalAccordion'
import React, { Fragment, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import useHome from '_hooks/useHome'

const ObjectionFilter = ({ onClose }) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const {
    reports: { all },
    objectionPayload,
    setObjectionPayload,
  } = useHome()

  useEffect(() => {
    if (objectionPayload?.status?.length) {
      objectionPayload?.status?.forEach((name) => {
        setStatus((prevState) => ({
          ...prevState,
          [name]: true,
        }))
      })
    }
    if (objectionPayload?.reason?.length) {
      objectionPayload?.reason?.forEach((name) => {
        setReasonOptions((prevState) => ({
          ...prevState,
          [name]: true,
        }))
      })
    }
    if (objectionPayload?.result?.length) {
      objectionPayload?.result?.forEach((name) => {
        setResultOptions((prevState) => ({
          ...prevState,
          [name]: true,
        }))
      })
    }
    if (objectionPayload?.pic?.length) {
      setSelectedPicOptions(objectionPayload?.pic)
    }
    setFromDate(objectionPayload?.from)
    setToDate(objectionPayload?.to)
  }, [objectionPayload])

  const [reasonOptions, setReasonOptions] = useState({
    'Minor safety': false,
    'Self-injury (suicide, eating disorders)': false,
    'Violent, extreme, or dangerous acts': false,
    'Nudity or sexual activities': false,
    'Bullying or harassment, Hateful behavior': false,
    'Hate acts (discriminatory, prejudicial)': false,
    Spam: false,
    'Misinformation, scam (spoofing), fraud': false,
    'Illegal activities, regulated goods (drugs, weapons)': false,
    'Infringement of intellectual property rights, copyrights and trademark': false,
    'Pretending to be someone else': false,
    'May be under 13': false,
    Other: false,
  })

  const [status, setStatus] = useState({
    Approved: false,
    'Under review': false,
    Pending: false,
    Disapproved: false,
  })

  const [resultOptions, setResultOptions] = useState({
    'No violation': false,
    'Sent warning to poster': false,
    'Sent warning to reporter': false,
    'Require to delete': false,
    'Account suspension': false,
    'Account deletion': false,
  })

  const [picOptions, setPicOptions] = useState([])
  const [selectedPicOption, setSelectedPicOptions] = useState([])

  useEffect(() => {
    let tempArray = []
    all?.forEach((post) => {
      let idArray = tempArray?.map((item) => item?.id)
      let idIncludes = idArray?.includes(post?.pic?.id)
      if (!idIncludes && post?.pic?.id) {
        tempArray?.push(post?.pic)
      }
    })
    setPicOptions(
      tempArray?.map((item) => {
        return { value: item?.id, label: item?.fullName }
      })
    )
  }, [all])

  const handleApplyFilter = () => {
    let payloadObj = {}
    const checkedReasonOptions = []
    for (const [key, value] of Object.entries(reasonOptions)) {
      if (value) {
        checkedReasonOptions.push(key)
      }
    }
    payloadObj = { ...payloadObj, reason: checkedReasonOptions }

    const checkedStatus = []
    for (const [key, value] of Object.entries(status)) {
      if (value) {
        checkedStatus.push(key)
      }
    }
    payloadObj = { ...payloadObj, status: checkedStatus }

    const checkedResultOptions = []
    for (const [key, value] of Object.entries(resultOptions)) {
      if (value) {
        checkedResultOptions.push(key)
      }
    }
    payloadObj = { ...payloadObj, result: checkedResultOptions }

    // payloadObj = { ...payloadObj, pic: checkedPICOptions }
    payloadObj = {
      ...payloadObj,
      from: fromDate,
      to: toDate,
      pic: selectedPicOption?.length > 0 ? selectedPicOption : null,
    }
    setObjectionPayload({ ...objectionPayload, ...payloadObj })
    onClose()
  }

  const handleStatusChange = (event) => {
    const { name, checked } = event.target
    setStatus((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handlePICOptionChange = (event) => {
    const { value } = event.target
    if (selectedPicOption?.includes(value)) {
      setSelectedPicOptions((prev) => prev.filter((option) => option !== value))
    } else {
      setSelectedPicOptions((prev) => [...prev, value])
    }
  }

  const handleReasonOptionChange = (event) => {
    const { name, checked } = event.target
    setReasonOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleResultOptionChange = (event) => {
    const { name, checked } = event.target
    setResultOptions((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  return (
    <Fragment>
      <div className='overscroll-contain'>
        <div className='max-h-[70vh] overflow-y-auto megaFilterScroll bg-universal '>
          {/* creation Date */}
          <div className='min-w-[16rem] pb-3'>
            <label className='block relative'>
              <span className='block text-sm font-semibold text-gray-800'>
                From:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                placeholder='MM/DD/YYYY'
                type='date'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                style={{ height: '20px', width: '20px' }}
              />
            </label>

            <label className='block relative'>
              <span className='block text-sm font-semibold text-gray-800'>
                To:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 cursor-pointer outline-none py-2 px-4 w-full rounded-2xl no-calendar '
                placeholder='MM/DD/YYYY'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                type='date'
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2'
                style={{ height: '20px', width: '20px' }}
              />
            </label>
          </div>
          {/* Reason */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Reason</h6>}
            content={
              <div className='min-w-[16rem] flex flex-col gap-y-4'>
                {Object.entries(reasonOptions).map(([key, value]) => (
                  <div className='flex flex-nowrap items-center' key={key}>
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleReasonOptionChange}
                    />{' '}
                    &nbsp;&nbsp;{' '}
                    <h6 className='font-medium text-sm w-56'>{key}</h6>
                  </div>
                ))}
              </div>
            }
          />

          {/* Status  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Status</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
                {Object.entries(status).map(([key, value]) => (
                  <div className='flex flex-nowrap items-center' key={key}>
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleStatusChange}
                    />{' '}
                    &nbsp;&nbsp; <h6 className='font-medium text-sm'>{key}</h6>
                  </div>
                ))}
              </div>
            }
          />

          {/* Result */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Result</h6>}
            content={
              <div className='min-w-[16rem] flex flex-col gap-y-4'>
                {Object.entries(resultOptions).map(([key, value]) => (
                  <div className='flex flex-nowrap items-center' key={key}>
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleResultOptionChange}
                    />{' '}
                    &nbsp;&nbsp; <h6 className='font-medium text-sm'>{key}</h6>
                  </div>
                ))}
              </div>
            }
          />

          {/* PIC  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>PIC</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-1 gap-y-2'>
                {picOptions?.map((item) => (
                  <div
                    className='flex flex-nowrap items-center'
                    key={item?.value}
                  >
                    <input
                      type='checkbox'
                      name={item?.label}
                      checked={selectedPicOption?.includes(item?.value)}
                      value={item?.value}
                      onChange={handlePICOptionChange}
                    />{' '}
                    &nbsp;&nbsp;{' '}
                    <h6 className='font-medium text-sm'>{item?.label}</h6>
                  </div>
                ))}
              </div>
            }
          />
        </div>
        {/* apply and cancel button  */}
        <div className='flex gap-x-4 justify-center mt-3 mb-5'>
          <button
            onClick={handleApplyFilter}
            className='py-2 w-2/6 h-11 rounded-lg bg-text-dm-input shadow-buttonPrimary text-white'
          >
            Apply
          </button>
          <button
            onClick={onClose}
            className='border h-11 border-text-dm-input py-2 w-2/6 rounded-lg text-gray-400'
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  )
}

ObjectionFilter.propTypes = {
  onClose: PropsTypes.func,
}

export default ObjectionFilter
