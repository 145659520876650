import UserNameBadge from 'components/global/Badge/UserStatus'
import GlobalAccordion from 'components/global/GlobalAccordion'
import {
  MegaFilterAgeData,
  genderEnums,
  userWithIcon,
} from 'DummyData/DummyData'
import React, { Fragment, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import useUser from '_hooks/useUser'

const MegaFilter = ({ onClose }) => {
  const { payload, setPayload } = useUser()
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [fromDate2, setFromDate2] = useState('')
  const [toDate2, setToDate2] = useState('')
  const [checkedStatus, setCheckedStatus] = useState({})
  const [checkedCountries, setCheckedCountries] = useState([])
  const [checkedAppLanguage, setCheckedAppLanguage] = useState([])
  const [checkedDevLanguage, setCheckedDevLanguage] = useState([])
  const [checkedWriting, setCheckedWriting] = useState([])
  const [checkedReport, setCheckedReport] = useState([])
  const [checkedReported, setCheckedReported] = useState([])
  const [checkedBanned, setCheckedBanned] = useState([])
  const [age, setAge] = useState([])
  const [gender, setGender] = useState({})

  useEffect(() => {
    if (payload?.status?.length > 0) {
      let tempObj = {}
      payload?.status?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setCheckedStatus((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.age?.length > 0) {
      let tempObj = {}
      payload?.age?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setAge((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.gender?.length > 0) {
      let tempObj = {}
      payload?.gender?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setGender((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.countries?.length > 0) {
      let tempObj = {}
      payload?.countries?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setCheckedCountries((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.appLanguage?.length > 0) {
      let tempObj = {}
      payload?.appLanguage?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setCheckedAppLanguage((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.devLangauge?.length > 0) {
      let tempObj = {}
      payload?.devLangauge?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setCheckedDevLanguage((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.writing?.length > 0) {
      let tempObj = {}
      payload?.writing?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setCheckedWriting((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.reportArray?.length > 0) {
      payload?.reportArray?.forEach((item) => {
        setCheckedReport((prev) => [...prev, item])
      })
    }
    if (payload?.reportedArray?.length > 0) {
      payload?.reportedArray?.forEach((item) => {
        setCheckedReported((prev) => [...prev, item])
      })
    }
    if (payload?.bannedArray?.length > 0) {
      payload?.bannedArray?.forEach((item) => {
        setCheckedBanned((prev) => [...prev, item])
      })
    }
    if (payload?.age?.length > 0) {
      let tempObj = {}
      payload?.age?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setAge((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.genderArray?.length > 0) {
      let tempObj = {}
      payload?.genderArray?.forEach((item) => {
        tempObj = { ...tempObj, [item]: true }
      })
      setGender((prev) => ({ ...prev, ...tempObj }))
    }
  }, [payload])

  const handleStatusChange = (e) => {
    const { name, checked } = e.target
    setCheckedStatus((prevStatus) => ({
      ...prevStatus,
      [name]: checked,
    }))
  }

  const onChangeAge = (e) => {
    const isChecked = e.target.checked
    const value = e.target.value

    setAge((prevAge) => ({
      ...prevAge,
      [value]: isChecked,
    }))
  }

  const onGenderChange = (e) => {
    const isChecked = e.target.checked
    const value = e.target.value

    setGender((prevGender) => ({
      ...prevGender,
      [value]: isChecked,
    }))
  }

  const handleCountryChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedCountries((prevCheckedCountries) => [
        ...prevCheckedCountries,
        value,
      ])
    } else {
      setCheckedCountries((prevCheckedCountries) =>
        prevCheckedCountries.filter((country) => country !== value)
      )
    }
  }

  const handleAppLanguageChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedAppLanguage((prevCheckedLanguages) => [
        ...prevCheckedLanguages,
        value,
      ])
    } else {
      setCheckedAppLanguage((prevCheckedLanguages) =>
        prevCheckedLanguages.filter((language) => language !== value)
      )
    }
  }

  const handleDevLanguageChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedDevLanguage((prevCheckedLanguages) => [
        ...prevCheckedLanguages,
        value,
      ])
    } else {
      setCheckedDevLanguage((prevCheckedLanguages) =>
        prevCheckedLanguages.filter((language) => language !== value)
      )
    }
  }

  const handleWritingChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedWriting((prevCheckedLanguages) => [
        ...prevCheckedLanguages,
        value,
      ])
    } else {
      setCheckedWriting((prevCheckedLanguages) =>
        prevCheckedLanguages.filter((language) => language !== value)
      )
    }
  }

  const handleReportChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedReport((prevChecked) => [...prevChecked, value])
    } else {
      setCheckedReport((prevChecked) =>
        prevChecked.filter((item) => item !== value)
      )
    }
  }

  const handleReportedChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedReported((prevChecked) => [...prevChecked, value])
    } else {
      setCheckedReported((prevChecked) =>
        prevChecked.filter((item) => item !== value)
      )
    }
  }

  const handleBannedChange = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedBanned((prevChecked) => [...prevChecked, value])
    } else {
      setCheckedBanned((prevChecked) =>
        prevChecked.filter((item) => item !== value)
      )
    }
  }

  const handleApplyFilter = () => {
    const status = []
    let payload = {}

    for (const [key, value] of Object.entries(checkedStatus)) {
      if (value) {
        status.push(key)
      }
    }
    payload.status = status

    const countries = []
    for (const [key, value] of Object.entries(checkedCountries)) {
      if (value) {
        countries.push(key)
      }
    }
    payload.countries = countries
    const appLanguage = []
    for (const [key, value] of Object.entries(checkedAppLanguage)) {
      if (value) {
        appLanguage.push(key)
      }
    }
    payload.appLanguage = appLanguage

    const devLangauge = []
    for (const [key, value] of Object.entries(checkedDevLanguage)) {
      if (value) {
        devLangauge.push(key)
      }
    }
    payload.devLangauge = devLangauge

    const writing = []
    for (const [key, value] of Object.entries(checkedWriting)) {
      if (value) {
        writing.push(key)
      }
    }
    payload.writing = writing

    payload.reportArray = checkedReport
    payload.reportedArray = checkedReported
    payload.bannedArray = checkedBanned

    const ageArray = []
    for (const [key, value] of Object.entries(age)) {
      if (value) {
        ageArray.push(key)
      }
    }
    payload.age = ageArray

    const genderArray = []
    for (const [key, value] of Object.entries(gender)) {
      if (value) {
        genderArray.push(key)
      }
    }
    payload.gender = genderArray

    setPayload({
      ...payload,
      createFrom: fromDate,
      createTo: toDate,
      lastLoginFrom: fromDate2,
      lastLoginTo: toDate2,
    })
    // handleSetPostFilter({ ...payload, to: payloadTo, from: payloadFrom })
    onClose()
  }
  return (
    <Fragment>
      <div className='h-[420px] overflow-y-auto megaFilterScroll bg-universal '>
        {/* creation Date */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Creation Date</h6>}
          content={
            <div className='min-w-[16rem]'>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  From:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  To:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
            </div>
          }
        />
        {/* Last Login */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Last Login</h6>}
          content={
            <div className='min-w-[16rem]'>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  From:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={fromDate2}
                  onChange={(e) => setFromDate2(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
              <label className='block relative'>
                <span className='block text-sm font-semibold text-gray-800'>
                  To:
                </span>
                <input
                  className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                  placeholder='MM/DD/YYYY'
                  type='date'
                  value={toDate2}
                  onChange={(e) => setToDate2(e.target.value)}
                />
                <img
                  src='/assets/Icons/calendar-2.svg'
                  alt='calendar'
                  className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                  style={{ height: '20px', width: '20px' }}
                />
              </label>
            </div>
          }
        />
        {/* Age  */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Age</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {MegaFilterAgeData.map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item.value}
                    value={item.value}
                    onChange={onChangeAge}
                    // checked={age[item.value]}
                    checked={age[item.value] || false}
                  />{' '}
                  &nbsp;&nbsp;{' '}
                  <h6 className='font-medium text-sm'>{item.label}</h6>
                </div>
              ))}
            </div>
          }
        />
        {/* Gender  */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Gender</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {genderEnums.map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item.value}
                    value={item.value}
                    onChange={onGenderChange}
                    checked={gender[item.value] || false}
                  />{' '}
                  &nbsp;&nbsp;{' '}
                  <h6 className='font-medium text-sm'>{item.label}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* Country */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Country</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['Japan', 'U.S.A'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleCountryChange}
                    checked={checkedCountries.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* App Language */}
        <GlobalAccordion
          header={<h6 className='font-medium'>App Language</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['Japanese', 'English'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleAppLanguageChange}
                    checked={checkedAppLanguage?.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* dEV lANGUAGE */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Dev Language</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['Japanese', 'English'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleDevLanguageChange}
                    checked={checkedDevLanguage.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* Writing */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Writing</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['Japanese', 'English'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleWritingChange}
                    checked={checkedWriting.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* User Status */}
        <GlobalAccordion
          header={<h6 className='font-medium'>User Status</h6>}
          content={
            <div className='min-w-[16rem] flex flex-col gap-y-2'>
              {userWithIcon.map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item.value}
                    checked={checkedStatus[item.value] || false}
                    onChange={handleStatusChange}
                  />
                  &nbsp;&nbsp; {UserNameBadge(item.field, { size: 12 })} &nbsp;{' '}
                  <h6 className='font-medium text-sm'>&nbsp;{item.label}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* Rport */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Report</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['0', 'More than 1'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleReportChange}
                    checked={checkedReport.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* Reported */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Reported</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['0', 'More than 1'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleReportedChange}
                    checked={checkedReported.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />

        {/* Reported */}
        <GlobalAccordion
          header={<h6 className='font-medium'>Banned</h6>}
          content={
            <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
              {['0', 'More than 1'].map((item, index) => (
                <div key={index} className='flex flex-nowrap items-center'>
                  <input
                    type='checkbox'
                    name={item}
                    value={item}
                    onChange={handleBannedChange}
                    checked={checkedBanned?.includes(item)}
                  />{' '}
                  &nbsp;&nbsp; <h6 className='font-medium text-sm'>{item}</h6>
                </div>
              ))}
            </div>
          }
        />
      </div>

      {/* apply and cancel button  */}
      <div className='flex gap-x-4 justify-center mt-3 mb-5'>
        <button
          onClick={handleApplyFilter}
          className='py-2 w-2/6  rounded-xl bg-text-dm-input shadow-buttonPrimary text-white'
        >
          Apply
        </button>
        <button
          onClick={onClose}
          className='border border-text-dm-input py-2 w-2/6 rounded-xl text-gray-400'
        >
          Cancel
        </button>
      </div>
    </Fragment>
  )
}

MegaFilter.propTypes = {
  onClose: PropsTypes.func,
}

export default MegaFilter
