import axiosInstance from '_utils/axiosUtils'
import { defaultProfileImg } from '_utils/default'
import React, { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import LocationPin from './icons/LocationsPin'
import ToggleButton from './ToggelButton'
import clsx from 'clsx'
import PropsTypes from 'prop-types'

const User = ({ user, access }) => {
  const [isOpenPointScale, setIsPointScale] = useState(false)
  const [lock, setLock] = useState(user.score != null && user.score.isLocked)
  const [tempNum, setTempNum] = useState(user.score != null && user.score.score)
  const [locNumber, setLocNumber] = useState(
    user.score != null && user.score.score
  )

  const updateScoreUser = async (id, userId, score) => {
    let tempObj = { userId: userId, score: score }
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    try {
      const response = await axiosInstance.put(
        '/admin/user/score/update',
        tempObj
      )
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleDropedownByPointScale = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
  }

  const PointScaleCancel = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
  }

  const savePointScale = () => {
    setLocNumber(tempNum)
    setIsPointScale(false)
  }

  const getColor = (index) => {
    if (index + 1 === tempNum) {
      return 'white' // Selected number color
    } else if (index + 1 <= 3) {
      return '#FF0000' // 1, 2, 3 color
    } else if (index + 1 <= 6) {
      return '#FF7F00' // 4, 5, 6 color
    } else {
      return '#29BC52' // 7, 8, 9, 10 color
    }
  }

  return (
    <div className='flex '>
      <img
        className='w-9 h-9 rounded-full'
        src={defaultProfileImg(user?.profileImage, 'user')}
        alt='userimage'
      />
      <div className='ml-1'>
        <h4 className='font-semibold text-xs text-black'>
          {user?.fullName || user?.username}
        </h4>
        <NavLink
          to={`/user/${user.id}`}
          className='font-normal underline underline-offset-2'
        >
          {user.username}
        </NavLink>
        {/* <p className='font-normal underline underline-offset-2' >
          {user?.username}
        </p> */}
        {access && (
          <button
            onClick={toggleDropedownByPointScale}
            className='px-2.5 py-1 my-2 rounded-lg text-black shadow-customBtnShadow font-medium text-sm flex justify-center items-center'
            style={{ backgroundColor: getColor(locNumber) }}
          >
            <p className='font-medium text-sm'>{locNumber} Points</p> &nbsp;{' '}
            <FiChevronDown />
          </button>
        )}
      </div>
      {isOpenPointScale && (
        <div
          className='absolute z-10 top-28 right-0 !border-text-dm-input !border-input-border !border-solid w-48 h-36 rounded-xl flex items-center justify-center'
          style={{ backgroundColor: '#F9FAFF' }}
        >
          <div className='w-full px-3 py-0'>
            <div className='w-full flex flex-col pb-2'>
              <div className='w-full flex justify-between gap-x-1'>
                {[...Array(10)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => !lock && setTempNum(index + 1)}
                    value={tempNum}
                    style={{
                      color: getColor(index),
                    }}
                  >
                    {tempNum === index + 1 ? (
                      <LocationPin
                        number={index + 1}
                        color={getColor(index + 1)}
                      />
                    ) : (
                      index + 1
                    )}
                  </button>
                ))}
              </div>
              <progress
                className='h-1 w-full'
                value={tempNum}
                max='10'
                color={getColor(locNumber)}
                content={getColor(locNumber)}
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: getColor(tempNum),
                }}
              ></progress>
            </div>

            <div className='flex flex-nowrap gap-x-2 items-center'>
              <img src='/assets/svg/lock.svg' alt='' />{' '}
              <p className={clsx('text-xs font-medium', !lock && '!font-bold')}>
                Unlock
              </p>
              <ToggleButton toggle={lock} setToggle={setLock} />
              <p className={clsx('text-xs font-medium', lock && '!font-bold')}>
                Lock
              </p>
            </div>
            <div className='flex py-2 gap-x-2 w-full'>
              <button
                className={`px-3 py-1 w-1/2 bg-text-dm-input shadow-buttonPrimary h-9 rounded-xl text-sm text-white ${
                  lock ? 'pointer-events-none opacity-50' : ''
                }`}
                onClick={() => {
                  savePointScale()
                  updateScoreUser(user?.score?.id, user?.id, tempNum)
                }}
              >
                Save
              </button>
              <button
                onClick={PointScaleCancel}
                className='px-3 py-1 w-1/2 bg-universal h-9 border border-teal-400 text-gray-400 rounded-xl text-sm'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

User.propTypes = {
  user: PropsTypes.object,
  access: PropsTypes.bool,
}

export default User
