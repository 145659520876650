import clsx from 'clsx'
import React, { useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { getStatusClass } from '_utils/getStatusClass'
import PropsTypes from 'prop-types'
import useAuth from '_hooks/useAuth'
import useHome from '_hooks/useHome'

const Result = ({ result, id, pic }) => {
  const { user } = useAuth()
  const { updateResultReport } = useHome()

  const [isOpenByStatus, setIsOpenByStatus] = useState(false)

  let statusArray = [
    { label: 'No Violation', value: 'no_violation' },
    { label: 'Sent Warning To Poster', value: 'sent_warning_to_poster' },
    { label: 'Sent Warning To Reporter', value: 'sent_warning_to_reporter' },
    { label: 'Require To Delete', value: 'require_to_delete' },
    { label: 'Account Suspension', value: 'account_suspension' },
    { label: 'Account Deletion', value: 'account_deletion' },
  ]

  const toggleDropdownByStatus = () => {
    setIsOpenByStatus((prevState) => !prevState)
  }

  return (
    <div>
      <button
        className={clsx('flex items-center', getStatusClass(result))}
        disabled={pic && pic?.id !== user?.id}
        onClick={toggleDropdownByStatus}
      >
        {result}
        <FiChevronDown size={17} />
      </button>

      {isOpenByStatus && (
        <div className='absolute z-10 left-0 !border-text-dm-input !border-input-border !border-solid w-auto h-max rounded-xl flex items-center justify-center bg-white p-1 shrink-0'>
          <div className='flex flex-col text-center'>
            {statusArray?.map((status) => {
              return (
                <button
                  key={status.value}
                  className='text-sm font-medium font-poppins text-black p-1 cursor-pointer'
                  onClick={() => updateResultReport(id, status?.value)}
                >
                  {status?.label}
                </button>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

Result.propTypes = {
  result: PropsTypes.object,
  id: PropsTypes.object,
  pic: PropsTypes.object,
}

export default Result
