// Loader.js
import React from 'react'
import PropTypes from 'prop-types'
import LoaderIcon from './LoaderIcon'

const Loader = ({ loading }) => {
  if (!loading) {
    return null 
  }

  return (
    <div
      role='status'
      className='absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 w-screen h-screen flex justify-center items-center bg-loader-bg z-overlay-content '
    >
      <LoaderIcon />
      <span className='sr-only'>Loading...</span>
    </div>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool,
}

export default Loader
