import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const Badges = ({ badges }) => {
  // const [openMemo, setOpenMemo] = useState(false)
  // const handleMemoDrawer = () => setOpenMemo(!openMemo)

  return (
    <div className='w-full flex flex-col gap-y-4'>
      {/* Golden Badge */}
      <div className='shadow-customBtnShadow p-6 w-full rounded-xl grid grid-rows-2 grid-cols-4 gap-x-5 gap-y-5 items-start'>
        {/* badge */}
        <div className='flex gap-x-3 row-span-2 items-center'>
          <img
            src='/assets/svg/gold-badge.svg'
            alt='goldBadge'
            className='w-9 h-9'
          />
          <h6 className='text-sm font-light flex gap-x-2 items-center '>
            <span className='text-base font-medium'>
              {badges['gold']?.length}
            </span>
            gold medals
          </h6>
        </div>
        {/* hashtags */}
        {badges['gold']?.map((badge) => {
          return (
            <div className='flex justify-between row-span-1' key={badge.name}>
              <h6 className='text-sm font-bold'>#{badge?.name}</h6>
              <h6 className='text-xs font-medium'>
                {dayjs(badge?.createdAt)?.format('DD/MM/YYYY')}
              </h6>
            </div>
          )
        })}
      </div>
      {/* Silver Badge */}
      <div className='shadow-customBtnShadow p-6 w-full rounded-xl grid grid-rows-2 grid-cols-4 grid-flow-col gap-x-5 gap-y-5 items-start'>
        {/* badge */}
        <div className='flex gap-x-3 row-span-2 items-center'>
          <img
            src='/assets/svg/silver-badge.svg'
            alt='goldBadge'
            className='w-9 h-9'
          />
          <h6 className='text-sm font-light flex gap-x-2 items-center '>
            <span className='text-base font-medium'>
              {badges['silver']?.length}
            </span>
            silver medals
          </h6>
        </div>
        {/* hashtags */}
        {badges['silver']?.map((badge) => {
          return (
            <div className='flex justify-between row-span-1' key={badge.name}>
              <h6 className='text-sm font-bold'>#{badge?.name}</h6>
              <h6 className='text-xs font-medium'>
                {dayjs(badge?.createdAt)?.format('DD/MM/YYYY')}
              </h6>
            </div>
          )
        })}
      </div>
      {/* Bronze Badge */}
      <div className='shadow-customBtnShadow p-6 w-full rounded-xl grid grid-rows-2 grid-cols-4 grid-flow-col gap-x-5 gap-y-5 items-start'>
        {/* badge */}
        <div className='flex gap-x-3 row-span-2 items-center'>
          <img
            src='/assets/svg/bronze-badge.svg'
            alt='goldBadge'
            className='w-9 h-9'
          />
          <h6 className='text-sm font-light flex gap-x-2 items-center'>
            <span className='text-base font-medium'>
              {badges['bronze']?.length}
            </span>
            bronze medals
          </h6>
        </div>
        {/* hashtags */}
        {badges['bronze']?.map((badge) => {
          return (
            <div className='flex justify-between row-span-1' key={badge.name}>
              <h6 className='text-sm font-bold'>#{badge?.name}</h6>
              <h6 className='text-xs font-medium'>
                {dayjs(badge?.createdAt)?.format('DD/MM/YYYY')}
              </h6>
            </div>
          )
        })}
      </div>
      {/* Memo  */}
    </div>
  )
}

Badges.propTypes = {
  badges: PropTypes.object,
}

export default Badges
