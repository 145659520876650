const getOnlyTime = (dateStr) => {
  const date = new Date(dateStr)

  // Get hours and minutes
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  // Format as hh:MM
  const formattedTime = `${hours}:${minutes}`

  return formattedTime
}

export default getOnlyTime
