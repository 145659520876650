import React, { useEffect, useState } from 'react'
import Input from 'components/global/Input'
import CustomSelect from 'components/global/CustomSelect'
import Page from 'components/global/Page'
import 'react-datepicker/dist/react-datepicker.css'
import { GoPencil, GoTrash } from 'react-icons/go'
import axiosInstance from '_utils/axiosUtils'
import DataTable from 'react-data-table-component'
import actionTypeObj from '_utils/actionTypeObj'
import TextArea from 'components/global/TextArea'

export default function Template() {
  const [notificationType, setNotificationType] = useState('server_maintenance')
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [data, setData] = useState([])

  let initalVal = {
    id: '',
    actionType: 'server_maintenance',
    jaTitle: '',
    jaText: '',
    jaBtnText: '',
    enTitle: '',
    enText: '',
    enBtnText: '',
  }

  const [payload, setPayload] = useState(initalVal)

  const notificationTypeArray = [
    {
      label: 'Server Maintanence Notification',
      value: 'server_maintenance',
    },
    { label: 'App Version Updates', value: 'app_version_update' },
    { label: 'Other', value: 'admin_announcement' },
  ]

  const fetchTemplate = async () => {
    await axiosInstance
      .get('admin/notificationTemplate')
      .then((res) => {
        setData(res?.data.data)
      })
      .catch((err) => console.log(err))
      .finally(() => {})
  }

  const deleteTemplate = async (id) => {
    await axiosInstance
      .delete(`admin/notificationTemplate/${id}`)
      .then(() => {
        setData(data?.filter((item) => item.id !== id))
      })
      .catch((err) => console.log(err))
      .finally(() => {})
  }

  const editTemplate = async (payload) => {
    let { id, ...rest } = payload
    await axiosInstance
      .put(`admin/notificationTemplate/${id}`, rest)
      .then((res) => {
        let tempArray = data?.map((item) => {
          if (item?.id === id) {
            return { ...item, ...res.data.data }
          }
          return { ...item }
        })
        setData(tempArray)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setPayload(initalVal)
        window.location.reload()
      })
  }

  const addTemplate = async (payload) => {
    await axiosInstance
      .post('admin/notificationTemplate', payload)
      .then((res) => {
        setData((prev) => [...prev, res?.data?.data])
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setPayload(initalVal)
        // window.location.reload()
      })
  }

  useEffect(() => {
    fetchTemplate()
  }, [])

  useEffect(() => {
    let disabled = true
    let tempArray = ['id', 'createdAt', 'updatedAt', 'deletedAt']
    if (notificationType !== 'app_version_update') {
      tempArray = [
        'id',
        'createdAt',
        'jaBtnText',
        'enBtnText',
        'updatedAt',
        'deletedAt',
        'jaBtnText',
        'enBtnText',
      ]
    }
    disabled = Object.keys(payload)
      ?.filter((key) => !tempArray?.includes(key))
      ?.every((key) => payload[key])
    setBtnDisabled(!disabled)
  }, [notificationType, payload])

  const handleSubmit = async () => {
    let payloadObj = {
      ...payload,
      actionType: notificationType,
    }
    if (notificationType !== 'app_version_update') {
      delete payloadObj['jaBtnText']
      delete payloadObj['enBtnText']
    }
    if (payload.id) {
      delete payloadObj['deletedAt']
      delete payloadObj['createdAt']
      delete payloadObj['updatedAt']
      editTemplate(payloadObj)
    } else {
      delete payloadObj['id']

      addTemplate(payloadObj)
    }
  }

  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: 'normal',
        color: '#000',
        background: 'var(--bg-color)',
      },
    },
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '35px',
      },
    },
  }

  const columns = [
    {
      name: (
        <div className='flex w-full'>
          Title
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) => row.enTitle,
      sortable: true,
      minWidth: '100px',
    },
    {
      name: (
        <div className='flex w-full'>
          Type
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      selector: (row) => row?.type,
      minWidth: '50px',
      cell: (row) => (
        <div className='capitalize'>{actionTypeObj[row?.type]}</div>
      ),
    },
    {
      name: <div className='flex w-full'>Action</div>,
      sortable: false,
      minWidth: '50px',
      cell: (row) => (
        <div className='flex gap-2'>
          <button
            onClick={() => deleteTemplate(row.id)}
            className='px-4 py-2 text-white hover:underline rounded-md hover:bg-blue-600'
            style={{
              borderRadius: '12px',
              background: '#00BDB2',
              boxShadow:
                '-4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
              backdropFilter: 'blur(5px)',
            }}
          >
            <GoTrash className='bg-transparent text-white' />
          </button>
          <button
            onClick={() => {
              let tempObj = { ...row, actionType: row?.type }
              setNotificationType(tempObj?.type)
              delete tempObj['type']
              setPayload(tempObj)
            }}
            className='px-4 py-2 text-white hover:underline rounded-md hover:bg-blue-600'
            style={{
              borderRadius: '12px',
              background: '#00BDB2',
              boxShadow:
                '-4px -4px 15px 0px #051569 inset, 3px 3px 12px 0px #7BD9A6 inset',
              backdropFilter: 'blur(5px)',
            }}
          >
            <GoPencil className='bg-transparent text-white' />
          </button>
        </div>
      ),
    },
  ]

  return (
    <Page>
      <div className='grid grid-cols-2 gap-4'>
        <div className='shadow-customBtnShadow rounded-2xl'>
          <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
            <p className='text-black font-semibold font-poppins text-base ml-5'>
              Templates
            </p>
          </div>
          <div className='p-4 '>
            <DataTable
              columns={columns}
              data={data}
              highlightOnHover
              selectableRows
              customStyles={customCellStyles}
              className='custom-data-table'
            />
          </div>
        </div>
        <div>
          <div className='shadow-customBtnShadow rounded-2xl mb-7 pb-7'>
            <div className='flex items-center justify-between rounded-2xl bg-bg-universal bg-opacity-10 p-2'>
              <p className='text-black font-semibold font-poppins text-base ml-5'>
                {!payload?.id ? 'Add Template' : 'Edit Template'}
              </p>
              <button
                className='w-20 h-10 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                onClick={handleSubmit}
                disabled={btnDisabled}
              >
                Save
              </button>
            </div>
            <div className='px-6 py-6 w-full'>
              <p className='text-black font-poppins text-sm font-semibold mb-2 '>
                Notification Type
              </p>
              <CustomSelect
                width='100%'
                options={notificationTypeArray}
                selectedValue={notificationType}
                onChange={(value) => {
                  setNotificationType(value)
                  setPayload((prev) => ({
                    ...prev,
                    actionType: value,
                    jaBtnText:
                      value !== 'app_version_update' ? '' : payload?.jaBtnText,
                    enBtnText:
                      value !== 'app_version_update' ? '' : payload?.enBtnText,
                  }))
                }}
                fullWidth={true}
              />
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Notification Title (In English)
              </p>
              <Input
                value={payload.enTitle}
                onChange={(e) => {
                  setPayload({ ...payload, enTitle: e.target.value })
                }}
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Notification Title (In English)'
              />
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Notification Title (In Japanese)
              </p>
              <Input
                value={payload.jaTitle}
                onChange={(e) => {
                  setPayload({ ...payload, jaTitle: e.target.value })
                }}
                className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                type='text'
                placeHolder='Notification Title (In Japanese)'
              />
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Notification Text (In English)
              </p>
              <TextArea
                value={payload.enText}
                onChange={(e) => {
                  setPayload({ ...payload, enText: e.target.value })
                }}
              />
            </div>
            <div className='px-6 py-6'>
              <p className='text-black font-poppins text-sm font-semibold'>
                Notification Text (In Japanese)
              </p>
              <TextArea
                value={payload.jaText}
                onChange={(e) => {
                  setPayload({ ...payload, jaText: e.target.value })
                }}
              />
            </div>
            {notificationType === 'app_version_update' ? (
              <>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Button Text (In English)
                  </p>
                  <Input
                    value={payload.enBtnText}
                    onChange={(e) => {
                      setPayload({ ...payload, enBtnText: e.target.value })
                    }}
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Button Text (In English)'
                  />
                </div>
                <div className='px-6 py-6'>
                  <p className='text-black font-poppins text-sm font-semibold'>
                    Button Text (In Japanese)
                  </p>
                  <Input
                    value={payload.jaBtnText}
                    onChange={(e) => {
                      setPayload({ ...payload, jaBtnText: e.target.value })
                    }}
                    className='mt-2 h-10 !border-input-border !border-solid !border-light-border-search !rounded-r-xl'
                    type='text'
                    placeHolder='Button Text (In Japanese)'
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}
