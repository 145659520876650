import React from 'react'
import PropTypes from 'prop-types'
import { FiCircle, FiSlash } from 'react-icons/fi'

const UserStatus = (type, { ...args }) => {
  switch (type) {
    case 'online':
      return <FiCircle {...args} fill='#29BC52' color='#29BC52' />
    case 'logout':
      return <FiCircle {...args} fill='#A3A3A3' color='#A3A3A3' />
    case 'deactivated':
      return <FiCircle {...args} fill='#FF7F00' color='#FF7F00' />
    case 'account_deletion':
      return <FiCircle {...args} fill='#E90909' color='#E90909' />
    case 'admin_blocked':
      return <FiCircle {...args} fill='#E90909' color='#E90909' />
    case 'account_suspension':
      return <FiCircle {...args} fill='#FFDE00' color='#FFDE00' />
    case 'deletion':
      return <FiSlash color='#F00' {...args} />

    default:
      return null // Return null if the type doesn't match any case
  }
}

UserStatus.propTypes = {
  type: PropTypes.string.isRequired,
}

export default UserStatus
