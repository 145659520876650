import React from 'react'
import PropTypes from 'prop-types'

const Bio = ({ user }) => {
  return (
    <div className='w-full flex flex-col gap-5'>
      <h1 className='text-lg  font-bold'>Bio</h1>

      <div className=' shadow-customBtnShadow p-8 rounded-xl h-full w-full max-h-full'>
        <p className='text-sm font-normal text-ellipsis'>{user?.bio}</p>
      </div>
    </div>
  )
}

Bio.propTypes = {
  user: PropTypes.object,
}

export default Bio
