import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import PropsTypes from 'prop-types'
import { LucideCircle } from 'lucide-react'

const GradeItem = ({
  item,
  handelEditAuthorityGradeName,
  payload,
  setPayload,
}) => {
  const originalArray = item?.authority
    ? item?.authority?.map((perm) => perm.type)
    : []

  const [newArray, setNewArray] = useState(
    item?.authority ? item?.authority?.map((perm) => perm.type) : []
  )

  const permissionArray = [
    'change_status',
    'change_score',
    'change_point',
    'change_lock',
    'deletion_content',
    'deletion_memo',
    'deletion_hashtag',
    'deletion_account',
    'download_csv',
    'download_account',
    'staff_registration',
    'staff_editing',
    'staff_deletion',
  ]

  const handleChange = (val) => {
    let tempArray = []
    if (newArray?.includes(val)) {
      tempArray = newArray?.filter((item) => item !== val)
    } else {
      tempArray = [...newArray, val]
    }
    setNewArray(tempArray)
    handlePayload(tempArray)
  }

  const handlePayload = (tempArray) => {
    setPayload(
      payload.map((payloadItem) => {
        if (payloadItem?.gradeId === item?.id) {
          return {
            ...payloadItem,
            types: tempArray,
          }
        }
        return payloadItem
      })
    )
  }

  return (
    <tr key={item?.id} className='w-fit'>
      <td className='flex items-center justify-between cursor-pointer'>
        <div className='flex items-center justify-evenly w-full'>
          <div className='flex items-center justify-between w-[119px] p-1 shadow-customBtnShadow rounded-full'>
            <div className='text-black text-[11px] font-poppins font-normal pl-2'>
              {item?.name}
            </div>
            <button
              onClick={() => handelEditAuthorityGradeName(item)}
              className='text-black cursor-pointer text-[11px] font-poppins font-normal rounded-xl bg-bg-universal p-2'
            >
              <BiEditAlt className='cursor-pointer' color='#fff' size={11} />
            </button>
          </div>
        </div>
      </td>
      {permissionArray?.map((perm) => {
        const isOld = originalArray?.includes(perm)
        const [isNew, setIsNew] = useState(newArray?.includes(perm))

        useEffect(() => {
          setIsNew(newArray?.includes(perm))
        }, [newArray])

        return (
          <td
            key={perm}
            onClick={() => handleChange(perm)}
            className={isOld && !isNew && 'empty'}
          >
            {isOld && !isNew && <></>}
            {isOld && isNew && (
              <div className='text-black text-[11px] font-poppins pt-2.5 pb-[13px] font-normal flex items-center justify-center'>
                <LucideCircle color={'#00BDB2'} strokeWidth={4} size={15} />
              </div>
            )}
            {isNew && !isOld && (
              <div className='text-black text-[11px] font-poppins pt-2.5 pb-[13px] font-normal flex items-center justify-center'>
                <LucideCircle color={'red'} strokeWidth={4} size={15} />
              </div>
            )}
          </td>
        )
      })}
    </tr>
  )
}

GradeItem.propTypes = {
  item: PropsTypes.object,
  handelEditAuthorityGradeName: PropsTypes.func,
  payload: PropsTypes.array,
  setPayload: PropsTypes.func,
}

export default GradeItem
