import Input from 'components/global/Input'
import Page from 'components/global/Page'
import Popper from 'components/global/Popper'
import { RiFolderTransferLine, RiSearchLine } from 'react-icons/ri'
import { SlidersHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import LightBtn from 'components/global/Buttons/LightBtn'
import clsx from 'clsx'
import HomeFilter from 'components/home/HomeFilter'
import PostTable from 'components/home/PostTable'
import AllTable from 'components/home/AllTable'
import Detection from 'components/home/Detection'
import Rechecking from 'components/home/Rechecking'
import ProfileTable from 'components/home/ProfileTable'
import CommentTable from 'components/home/CommentTable'
import ReportFilter from 'components/home/ReportFilter'
import PropsTypes from 'prop-types'
import useHome from '_hooks/useHome'
import { ChevronDown, ChevronUp } from 'lucide-react'

export default function Home() {
  const popperRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)
  const { search, setSearch, setReports } = useHome()
  const [component, setComponent] = useState('allTable')
  const [isOpenMoveTo, setIsOpenMoveTo] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const filterRef = useRef()
  const [openFilterPopper, setOpenFilterPopper] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('Rechecking')
  const handleSelectFilter = (value) => {
    setSelectedFilter(value)
    setOpenFilterPopper(false)
  }
  const filterModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  }

  const toggleDropdownForMoveTo = () => {
    setIsOpenMoveTo(!isOpenMoveTo)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }

  useEffect(() => {
    if (component) {
      setIsDropdownOpen(false)
    }
  }, [component])

  const Component = ({ component }) => {
    switch (component) {
      case 'allTable':
        return <AllTable />
      case 'detection':
        return <Detection />
      case 'recheking':
        return <Rechecking />
      case 'postTable':
        return <PostTable />
      case 'profileTable':
        return <ProfileTable />
      case 'commentTable':
        return <CommentTable />
      default:
        return <AllTable />
    }
  }

  Component.propTypes = {
    component: PropsTypes.string,
  }

  return (
    <Page>
      {/* search panel */}
      <div className='w-full flex items-center justify-between' id='header'>
        <div className='w-2/5 flex gap-2 items-center'>
          <LightBtn
            className='font-medium text-sm px-8 '
            active={component === 'allTable'}
            showIndicator={component === 'allTable'}
            onClick={() => setComponent('allTable')}
          >
            <h6>All</h6>
          </LightBtn>
          <LightBtn
            className='font-medium text-sm px-6'
            active={component === 'detection'}
            onClick={() => setComponent('detection')}
            showIndicator={component === 'detection'}
          >
            <h6>Detection</h6>
          </LightBtn>
          <LightBtn
            className='font-medium text-sm px-6'
            active={component === 'recheking'}
            onClick={() => setComponent('recheking')}
            showIndicator={component === 'recheking'}
          >
            <h6>Rechecking</h6>
          </LightBtn>
          <LightBtn
            dropdown={true}
            // className='font-medium text-sm px-4 !w-full'
            className={`font-medium text-sm px-4 ${
              component === 'postTable' ? '!w-28 !pl-5' : '!w-24'
            } ${component === 'profileTable' ? '!w-28' : '!w-24'} ${
              component === 'commentTable' ? '!w-32' : '!w-24'
            } ${component === 'reportTable' ? '!w-28' : '!w-24'} 
             ${component === 'Report' ? '!w-28' : '!w-28'}`}
            active={[
              'reportTable',
              'postTable',
              'profileTable',
              'commentTable',
            ].includes(component)}
            onClick={() => setComponent('reportTable')}
            showIndicator={[
              'reportTable',
              'postTable',
              'profileTable',
              'commentTable',
            ].includes(component)}
            isDropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
          >
            <span>
              {component === 'postTable' && 'Post'}
              {component === 'profileTable' && 'Profile'}
              {component === 'commentTable' && 'Comment'}
              {(component === 'reportTable' ||
                !['postTable', 'profileTable', 'commentTable'].includes(
                  component
                )) &&
                'Report'}
            </span>
            <div id='options' className='flex flex-col justify-start'>
              {/* Dropdown options */}
              <button
                className={clsx(
                  'hover:text-text-dm-input ease-in-out text-start',
                  'text-text-dm-input' && component === 'postTable'
                )}
                onClick={() => {
                  setReports((prev) => ({ ...prev, type: 'post' }))
                  setComponent('postTable')
                }}
              >
                Post
              </button>
              <button
                className={clsx(
                  'hover:text-text-dm-input ease-in-out text-start',
                  'text-text-dm-input' && component === 'profileTable'
                )}
                onClick={() => {
                  setReports((prev) => ({ ...prev, type: 'user' }))
                  setComponent('profileTable')
                }}
              >
                Profile
              </button>
              <button
                className={clsx(
                  'hover:text-text-dm-input ease-in-out text-start',
                  'text-text-dm-input' && component === 'commentTable'
                )}
                onClick={() => {
                  setReports((prev) => ({ ...prev, type: 'comment' }))
                  setComponent('commentTable')
                }}
              >
                Comment
              </button>
            </div>
          </LightBtn>
        </div>

        <div className='flex justify-self-end w-2/5 2xl:w-3/5'>
          <Input
            placeHolder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            leftIcon={<RiSearchLine className='w-6 h-6' />}
            rightIcon={
              <SlidersHorizontal
                ref={popperRef}
                onClick={() => setFilterOpen(true)}
                className='w-6 h-6 cursor-pointer'
                style={{ color: filterOpen ? '#00BDB2' : '' }}
              />
            }
            className='flex-grow !border-light-border-search !rounded-xl'
          />
          <Popper
            refElement={popperRef.current}
            open={filterOpen}
            onClose={() => setFilterOpen(false)}
            popperOptions={{
              strategy: 'fixed',
              placement: 'bottom-end',
            }}
          >
            <div
              className='p-3 rounded-2xl border border-teal-500'
              style={{ backgroundColor: '#F9FAFF' }}
            >
              {component === 'postTable' ||
              component === 'profileTable' ||
              component === 'commentTable' ? (
                <ReportFilter onClose={() => setFilterOpen(false)} />
              ) : (
                <HomeFilter onClose={() => setFilterOpen(false)} />
              )}
            </div>
          </Popper>
        </div>
      </div>
      <div className='w-full flex justify-between pr-5 pl-2 mb-5'>
        <div className='w-full relative flex gap-x-2 items-center'>
          <h1 className='text-base font-bold'> Total </h1>
          {component === 'allTable' && (
            <LightBtn onClick={toggleDropdownForMoveTo}>
              <RiFolderTransferLine size={22} />
            </LightBtn>
          )}
          {component === 'allTable' && isOpenMoveTo && (
            <div
              className={` absolute z-10 top-11 left-0 w-36 ${
                openFilterPopper ? 'h-40' : 'h-auto pb-3'
              }  rounded-xl flex items-center justify-center bg-white border border-text-dm-input `}
            >
              <div className='self-start pt-2'>
                <p>Move to :</p>

                <button
                  className={`bg-universal py-2 px-3 shadow-customBtnShadow text-xs font-medium flex justify-between items-center w-28 ${
                    openFilterPopper ? 'rounded-t-lg' : 'rounded-xl'
                  } `}
                  ref={filterRef}
                  onClick={() => setOpenFilterPopper((prev) => !prev)}
                >
                  {selectedFilter}
                  {openFilterPopper ? (
                    <ChevronUp size={14} />
                  ) : (
                    <ChevronDown size={14} />
                  )}
                </button>
                <Popper
                  open={openFilterPopper}
                  popperOptions={filterModifier}
                  onClose={() => setOpenFilterPopper(false)}
                  refElement={filterRef.current}
                >
                  <div
                    className={`border border-text-dm-input bg-white ${
                      openFilterPopper ? '' : 'shadow-customBtnShadow'
                    }  rounded-xl w-28 flex flex-col items-start`}
                    style={{ marginTop: '-62px' }}
                  >
                    {['Rechecking', 'Rechecking1', 'Rechecking2'].map(
                      (filter, index) => (
                        <button
                          key={index}
                          className={
                            'hover:text-[#00BDB2] ease-in-out text-sm font-semibold p-2 w-full text-start rounded-xl mb-1 '
                          }
                          onClick={() => handleSelectFilter(filter)}
                        >
                          {filter}
                        </button>
                      )
                    )}
                  </div>

                  {/* <div className={`border border-text-dm-input bg-universal ${openFilterPopper ? '' : 'shadow-customBtnShadow'}  rounded-xl w-28 flex flex-col items-start`} style={{ marginTop: '-62px' }}>
                    <button
                      className={clsx(
                        'hover:bg-hovered-bg ease-in-out text-sm font-semibold p-2 w-full text-start  rounded-xl mb-1',
                        selectedFilter === 'Today' && 'bg-hovered-bg'
                      )}
                      onClick={() => handleSelectFilter('Rechecking') & setOpenFilterPopper(false)}
                    >
                      Rechecking
                    </button>
                    <button
                      className={clsx(
                        'hover:bg-hovered-bg ease-in-out text-sm font-semibold p-2 w-full text-start  rounded-xl mb-1',
                        selectedFilter === 'Today' && 'bg-hovered-bg'
                      )}
                      onClick={() => handleSelectFilter('Rechecking1') & setOpenFilterPopper(false)}
                    >
                      Rechecking1
                    </button>
                    <button
                      className={clsx(
                        'hover:bg-hovered-bg ease-in-out text-sm font-semibold p-2 w-full text-start  rounded-xl mb-1',
                        selectedFilter === 'Today' && 'bg-hovered-bg'
                      )}
                      onClick={() => handleSelectFilter('Rechecking2') & setOpenFilterPopper(false)}
                    >
                      Rechecking2
                    </button>
                  </div> */}
                </Popper>
              </div>
            </div>
          )}
        </div>

        {/* <div className='flex gap-2 mb-5'>
          <button  className='w-20 h-11 text-white hover:underline rounded-xl !text-base bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'>
            Save
          </button>

          <button className='bg-white-500 border border-teal-400 text-gray-400 text-base px-3 rounded-xl'>
            Cancel
          </button>
        </div> */}
      </div>
      <Component component={component} />
    </Page>
  )
}
