import React from 'react'
import PropTypes from 'prop-types'

const Language = ({ color, size, innerStrokeWidth, strokeWidth }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill={color}
      strokeWidth={strokeWidth}
      viewBox='0 0 25 25'
    >
      {/* First path */}
      <path
        fill={color}
        strokeWidth={innerStrokeWidth}
        d='M9.556 13.089c-.04-.2-1.42-7.106-1.465-7.324a.732.732 0 00-.718-.59H5.908c-.349 0-.65.247-.718.59l-1.465 7.324a.732.732 0 101.437.287l.468-2.34h2.022l.468 2.34a.732.732 0 101.436-.287zM5.923 9.57l.586-2.93h.264l.586 2.93H5.923z'
      />

      {/* Second path */}
      <path
        fill={color}
        strokeWidth={innerStrokeWidth}
        d='M21.29 11.035h-2.198v-.732a.732.732 0 00-1.465 0v.732H15.43a.732.732 0 100 1.465h.177a9.164 9.164 0 001.737 3.212c-.563.515-1.132.937-1.64 1.343a.732.732 0 10.916 1.144c.51-.408 1.121-.862 1.74-1.43.618.569 1.23 1.023 1.74 1.43a.732.732 0 10.914-1.144c-.506-.405-1.076-.827-1.64-1.343a9.163 9.163 0 001.738-3.212h.177a.732.732 0 100-1.465zm-2.93 3.601a7.714 7.714 0 01-1.206-2.14h2.41a7.717 7.717 0 01-1.205 2.14z'
      />

      {/* Third path */}
      <path
        fill={color}
        strokeWidth={innerStrokeWidth}
        d='M22.803 4.443H12.236l-.314-2.518A2.2 2.2 0 009.742 0H2.197A2.2 2.2 0 000 2.197V18.36a2.2 2.2 0 002.197 2.198h6.176l.31 2.518A2.202 2.202 0 0010.863 25h11.94A2.2 2.2 0 0025 22.803V6.64a2.2 2.2 0 00-2.197-2.198zM2.197 19.092a.733.733 0 01-.732-.733V2.197c0-.404.328-.732.732-.732h7.545c.369 0 .681.276.727.641l2.117 16.986H2.197zm7.897 3.454l-.245-1.99h1.965l-1.72 1.99zm13.441.257a.733.733 0 01-.732.732H11.175l2.789-3.225a.731.731 0 00.177-.587L12.419 5.908h10.384c.404 0 .732.329.732.733v16.162z'
      />
    </svg>
  )
}

Language.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  innerStrokeWidth: PropTypes.number,
  strokeWidth: PropTypes.number,
}

Language.defaultProps = {
  color: '#000',
  size: 25,
  innerStrokeWidth: 1.5,
  strokeWidth: 1.5,
}

export default Language
