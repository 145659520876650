import React, { useEffect, useState } from 'react'
import LightBtn from 'components/global/Buttons/LightBtn'
import Language from 'components/global/icons/Language'
import PropTypes from 'prop-types'
import { Instagram, MoveLeft, Settings } from 'lucide-react'
import { FiChevronRight } from 'react-icons/fi'
import {
  BiLogoLinkedin,
  BiLogoTiktok,
  BiUser,
  BiLogoYoutube,
} from 'react-icons/bi'
import { GrFacebookOption } from 'react-icons/gr'
import moment from 'moment'
import config from 'config/config'
import ExcelJS from 'exceljs'
import useAuth from '_hooks/useAuth'
const UserInformations = ({
  userSingleData,
  setComponent,
  badges,
  objections,
  correspondence,
  changeLog,
}) => {
  console.log(userSingleData, 'userSingleData')
  const { user } = useAuth()

  // ======================== Birthday ======================= //
  const [formattedBirthdate, setFormattedBirthdate] = useState('')
  const [age, setAge] = useState('')
  const id = localStorage.getItem('ccpa')

  useEffect(() => {
    const calculateAge = () => {
      const today = new Date()
      const dob = new Date(userSingleData.dob)
      let age = today.getFullYear() - dob.getFullYear()
      const monthDiff = today.getMonth() - dob.getMonth()
      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dob.getDate())
      ) {
        age--
      }
      setAge(age)
    }

    const formatBirthdate = () => {
      const dob = new Date(userSingleData.dob)
      const formatted = `${dob.getDate()}/${
        dob.getMonth() + 1
      }/${dob.getFullYear()}`
      setFormattedBirthdate(formatted)
    }
    calculateAge()
    formatBirthdate()
  }, [userSingleData.dob])

  ////////////////////////////////// CopyforCCPA ///////////////////////////////////
  const copyForCCPA = () => {
    const {
      fullName,
      username,
      dob,
      email,
      bio,
      createdAt,
      gender,
      lastLoginDate,
      experience,
      education,
      links,
      likes,
      posts,
      skills,
      certificates,
      userSessions,
      _count,
      followers,
      followings,
      followingHashtags,
      settings,
      share,
      comments,
      notInterested,
      commentReport,
      postReports,
      recieveReports,
      sendReports,
      favourite,
      recentSearch,
      savedSearch,
      blockedUser,
      memo,
    } = userSingleData

    const workbook = new ExcelJS.Workbook()
    // Add Sheet 1 (Basic)
    const sheet1 = workbook.addWorksheet('Basic')
    sheet1.addRow(['Basic Info'])
    sheet1.addRow(['Item', 'Data'])
    sheet1.addRow(['Email Address', email || ''])
    sheet1.addRow(['Google/ Apple account (if any)', ''])
    sheet1.addRow(['Username', username || ''])
    sheet1.addRow(['Display name', fullName || ''])
    sheet1.addRow(['Birthdate (Age)', dob || ''])
    sheet1.addRow(['Gender', gender || ''])
    sheet1.addRow([
      'Account Creation Date',
      createdAt ? createdAt.slice(0, 10) : '',
    ])
    sheet1.addRow(['Last Login Date&Time', lastLoginDate || ''])
    sheet1.addRow(['Bio', bio || ''])
    sheet1.addRow([
      'Social account(s)',
      links?.facebook,
      links?.instagram,
      links?.youtube,
      links?.twitter,
      links?.tiktok,
    ])

    // Add Sheet 2 (Resume)
    const sheet2 = workbook.addWorksheet('Resume (Experience)')
    sheet2.addRow(['Resume (Experience)'])
    sheet2.addRow([
      'Company name',
      'Title',
      'Start Date',
      'End Date',
      'Description',
      'Image (yes/no)',
      'Posting Date&Time',
    ])
    experience?.forEach((item) => {
      sheet2.addRow([
        item?.name || '',
        item?.title || '',
        item?.startDate || '',
        item?.endDate || '',
        item?.description || '',
        item?.logo ? 'yes' : 'no',
        item?.startDate || '',
      ])
    })

    // Add Sheet 3 (Education)
    const sheet3 = workbook.addWorksheet('Resume (Education)')
    sheet3.addRow(['Resume (Education)'])
    sheet3.addRow([
      'School',
      'Degree',
      'Field of study',
      'Start Date',
      'End Date',
      'Description',
      'Image (yes/no)',
      'Posting Date&Time',
    ])
    education?.forEach((item) => {
      sheet3.addRow([
        item?.school || '',
        item?.degree || '',
        item?.field || '',
        item?.startDate || '',
        item?.endDate || '',
        item?.description || '',
        item?.logo ? 'yes' : 'no',
        item?.startDate || '',
      ])
    })

    // Add Sheet 4 (Skills)
    const sheet4 = workbook.addWorksheet('Resume (Skills)')
    sheet4.addRow(['Resume (Skills)'])
    sheet4.addRow([
      'Skill',
      'Start Date',
      'End Date',
      'Description',
      'Image (yes/no)',
      'Posting Date&Time',
    ])
    skills?.forEach((item) => {
      sheet4.addRow([
        item?.skill || '',
        item?.startDate || '',
        item?.endDate || '',
        item?.description || '',
        item?.logo ? 'yes' : 'no',
        item?.startDate || '',
      ])
    })

    // Add Sheet 5 Resume (Certificates)
    const sheet5 = workbook.addWorksheet('Resume (Certificates)')
    sheet5.addRow(['Resume (Certificates)'])
    sheet5.addRow([
      'Name',
      'Issuing',
      'organization',
      'Issue Date',
      'Expiration Date',
      'Posting Date&Time',
    ])
    certificates?.forEach((item) => {
      sheet5.addRow([
        item?.name || '',
        item?.issueDate || '',
        item?.organisation || '',
        item?.issueDate || '',
        item?.expireDate || '',
        item?.issueDate || '',
      ])
    })

    // Add Sheet 6 (Device)
    const sheet6 = workbook.addWorksheet('Device')
    sheet6.addRow(['Device info'])
    sheet6.addRow(['Item', 'Data'])
    sheet6.addRow(['Device identifier(s)', userSessions[0]?.deviceInfo || ''])
    sheet6.addRow(['Device OS', userSessions[0]?.deviceInfo || ''])
    sheet6.addRow([
      'Device Model',
      userSessions[0]?.deviceInfo.toLowerCase().startsWith('ip')
        ? 'ios'
        : 'android' || '',
    ])
    sheet6.addRow(['Permission (Camera/Photo Library Access)', 'Yes'])
    sheet6.addRow(['Data acquisition date', ''])

    // Add Sheet 7 (Localization)
    const sheet7 = workbook.addWorksheet('Localization')
    sheet7.addRow(['Localization info'])
    let headerArray = []
    let ipAddressArray = []
    let timeZoneArray = []
    let usageLang = []
    let dateArray = []
    userSessions?.forEach((session) => {
      headerArray.push('Data')
      ipAddressArray.push(session?.ipAddress || '')
      timeZoneArray.push(session?.timeZone || '')
      usageLang.push(session?.lang || '')
      dateArray.push(
        moment(session?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || ''
      )
    })
    sheet7.addRow(['Item', ...headerArray])
    sheet7.addRow(['IP Address', ...ipAddressArray])
    sheet7.addRow(['Device Time Zone', ...timeZoneArray])
    sheet7.addRow(['Device Setting Language', ...usageLang])
    sheet7.addRow(['App Setting Language', ...usageLang])
    sheet7.addRow(['In-App Usage Language(s)', ...usageLang])
    sheet7.addRow(['Data acquisition date', ...dateArray])

    // Add Sheet 8 (Reporting and banning (Basic))
    const sheet8 = workbook.addWorksheet('Reporting and banning (Basic)')
    sheet8.addRow(['Reporting and banning (Basic)'])
    sheet8.addRow(['Item', 'Data'])
    sheet8.addRow([
      'Reported count (Total)',
      sendReports?.length + postReports?.length + commentReport?.length,
    ])
    sheet8.addRow(['Reported count (for Profile)', sendReports?.length])
    sheet8.addRow(['Reported count (for Posts)', postReports?.length])
    sheet8.addRow(['Reported count (for Comments)', commentReport?.length])
    sheet8.addRow(['Reports Made (Total)', ''])
    sheet8.addRow(['Reports Made (for Profiles)', recieveReports?.length])
    sheet8.addRow(['Reports Made (for Posts)', _count.postReports || 0])
    sheet8.addRow(['Reports Made (for Comments & Q&As)', ''])
    sheet8.addRow(['Banned count', ''])
    sheet8.addRow(['Blocked count', _count.blockedUser || 0])

    // Add Sheet 9 (Reported count per post)
    const sheet9 = workbook.addWorksheet('Reported count per post')
    sheet9.addRow(['Reported count per post'])
    sheet9.addRow(['Item', 'Date &Time of Posting', 'Number of Reports'])
    posts?.forEach((item) => {
      sheet9.addRow([
        item.id || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        item._count.reports,
      ])
    })

    // Add Sheet 10 (Reported count per comment)
    const sheet10 = workbook.addWorksheet('Reported count per comment')
    sheet10.addRow(['Reported count per comment'])
    sheet10.addRow(['Item', 'Date&Time of Posting', 'Number of Reports'])
    comments?.forEach((item) => {
      sheet10.addRow([
        item.id || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        item?._count.commentReport,
      ])
    })

    // Add Sheet 11 Reporting details (for profiles)
    const sheet11 = workbook.addWorksheet('Reporting details (for profiles)')
    sheet11.addRow(['Reporting details (for profiles)'])
    sheet11.addRow(['Target', 'Date&Time of Posting', 'Reason'])
    recieveReports?.forEach((item) => {
      sheet11.addRow([
        item.reportedByUser?.fullName || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || '',
        item?.reason,
      ])
    })

    // Add Sheet 12 (Reporting details for posts)
    const sheet12 = workbook.addWorksheet('Reporting details (for posts)')
    sheet12.addRow(['Reporting details (for posts)'])
    sheet12.addRow(['Target', 'Content Owner', 'Reporting Date&Time', 'Reason'])
    postReports
      ?.filter((report) => report?.post?.postType.toLowerCase() === 'post')
      ?.forEach((item) => {
        sheet12.addRow([
          item?.post?.id || '',
          item.post?.user?.fullName || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || '',
          item.reason || '',
        ])
      })

    // Add Sheet 13 (Reporting details for comments )
    const sheet13 = workbook.addWorksheet('Reporting details (for comments)')
    sheet13.addRow(['Reporting details (for comments + Q&As)'])
    sheet13.addRow(['Target', 'Content Owner', 'Reporting Date&Time', 'Reason'])
    commentReport?.forEach((item) => {
      sheet13.addRow([
        item.comment?.comment || '',
        item.user?.name || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || '',
        item.reason || '',
      ])
    })
    postReports
      ?.filter((report) => report?.post?.postType.toLowerCase() === 'question')
      ?.forEach((item) => {
        sheet12.addRow([
          item?.post?.id || '',
          item.post?.user?.fullName || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || '',
          item.reason || '',
        ])
      })

    // Add Sheet 14 (Banned History)
    const sheet14 = workbook.addWorksheet('Banned History')
    sheet14.addRow(['Banned History'])
    sheet14.addRow(['Target', 'Date&Time of Posting', 'Date&Time of Ban'])
    experience?.forEach((item) => {
      sheet14.addRow([
        item.PostURL || item.ActualComment || item.Username || '',
        item.DateAndTimeOfPosting || '',
        item.DateAndTimeOfBan || '',
      ])
    })

    // Add Sheet 15 (Objection History)
    const sheet15 = workbook.addWorksheet('Objection History')
    sheet15.addRow(['Objection History'])
    sheet15.addRow([
      'Objection ID',
      'Target',
      'Type',
      'Date&Time of Posting',
      'Date&Time of Objection',
    ])
    objections?.rows?.forEach((item) => {
      sheet15.addRow([
        item?.id || '',
        item?.postReport?.post?.id ||
          item?.commentReport?.comment?.id ||
          item?.userReport?.user?.id,
        item?.type,
        moment(
          item?.postReport?.post?.createdAt ||
            item?.commentReport?.comment?.createdAt ||
            item?.userReport?.user?.createdAt
        ).format(`${config.DATE_FORMAT}, HH:mm`) || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`) || '',
      ])
    })

    // Add Sheet 16 (Interactions - Basic)
    const sheet16 = workbook.addWorksheet('Interactions (Basic)')
    sheet16.addRow(['Interactions (Basic)'])
    sheet16.addRow(['Item', 'Data'])
    sheet16.addRow(['Number of followers', _count?.followers || ''])
    sheet16.addRow(['Number of followings', _count?.followings || ''])
    sheet16.addRow([
      'Number of following hashtags',
      _count?.followingHashtags || '',
    ])
    sheet16.addRow(['Number of blocked accounts', _count?.blockedUser || ''])
    sheet16.addRow(['Number of Likes', _count?.likes || ''])
    sheet16.addRow(['Number of Bookmarks', _count?.favorite || 0])
    sheet16.addRow(['Number of Shares', share?.length])
    sheet16.addRow(['Number of Not interested', notInterested?.length || 0])

    // Add Sheet 17 (Followers)
    const sheet17 = workbook.addWorksheet('Followers')
    sheet17.addRow(['Followers'])
    sheet17.addRow(['User', 'Date &Time of Follow'])
    followers?.forEach((item) => {
      sheet17.addRow([item?.follower?.username || '', item?.createdAt || ''])
    })

    // Add Sheet 18 (Following)
    const sheet18 = workbook.addWorksheet('Following')
    sheet18.addRow(['Following'])
    sheet18.addRow(['User', 'Date &Time of Follow'])
    followings
      ?.filter((fol) => fol.isActive)
      ?.forEach((item) => {
        sheet18.addRow([item?.user?.username || '', item?.createdAt || ''])
      })

    // Add Sheet 19 (Following hashtags)
    const sheet19 = workbook.addWorksheet('Following hashtags')
    sheet19.addRow(['Following hashtags'])
    sheet19.addRow(['Hashtag', 'Date &Time of Follow'])
    followingHashtags
      ?.filter((fol) => fol.isActive)
      ?.forEach((item) => {
        sheet19.addRow([item.hashTagId || '', item?.createdAt || ''])
      })

    // Add Sheet 20 (Unfollowed accounts)
    const sheet20 = workbook.addWorksheet('Unfollowed accounts')
    sheet20.addRow(['Unfollowed accounts'])
    sheet20.addRow(['User', 'Date &Time of Unfollow'])
    followings
      ?.filter((fol) => !fol.isActive)
      ?.forEach((item) => {
        sheet20.addRow([item?.user?.username || '', item?.createdAt || ''])
      })
    // Add Sheet 21 (Unfollowed hashtags)
    const sheet21 = workbook.addWorksheet('Unfollowed hashtags')
    sheet21.addRow(['Unfollowed hashtags'])
    sheet21.addRow(['Hashtag', 'Date &Time of Unfollow'])
    followingHashtags
      ?.filter((fol) => !fol.isActive)
      ?.forEach((item) => {
        sheet21.addRow([item.hashtag || '', ''])
      })

    // Add Sheet 22 (Blocked accounts)
    const sheet22 = workbook.addWorksheet('Blocked account(s)')
    sheet22.addRow(['Blocked accounts'])
    sheet22.addRow(['User', 'Date &Time of Block'])
    blockedUser?.forEach((item) => {
      sheet22.addRow([
        item.blockedUser?.name || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
      ])
    })

    // Add Sheet 23 (Medals and its hashtags)
    const sheet23 = workbook.addWorksheet('Medals and its hashtags')
    sheet23.addRow(['Medals and its hashtags'])
    sheet23.addRow(['Type of Medal', 'Hashtag', 'Acquisition Date'])
    let goldArray = badges['gold']
    let silverArray = badges['silver']
    let bronzeArray = badges['bronze']
    goldArray?.forEach((item) => {
      sheet23.addRow(['Gold', item?.name, item?.createdAt || ''])
    })
    silverArray?.forEach((item) => {
      sheet23.addRow(['Silver', item?.name, item?.createdAt || ''])
    })
    bronzeArray?.forEach((item) => {
      sheet23.addRow(['Bronze', item?.name, item?.createdAt || ''])
    })

    // Add Sheet 24 (Liked content - Posts)
    const sheet24 = workbook.addWorksheet('Liked content (Posts)')
    sheet24.addRow(['Liked content (Posts)'])
    sheet24.addRow(['Post URL', 'Content Owner', 'Liked Date&Time'])
    likes
      ?.filter((like) => like.isPost)
      ?.forEach((item) => {
        sheet24.addRow([
          item.postId || '',
          item?.user?.fullName,
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 25 (Liked content - Comments)
    const sheet25 = workbook.addWorksheet('Liked content (Comments)')
    sheet25.addRow(['Liked content (Comments)'])
    sheet25.addRow(['Comment', 'Content Owner', 'Liked Date&Time'])
    likes
      ?.filter((like) => like.isComment)
      ?.forEach((item) => {
        sheet25.addRow([
          item.comment || '',
          '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 26 (Liked content - Q&As)
    const sheet26 = workbook.addWorksheet('Liked content (Q&As)')
    sheet26.addRow(['Liked content (Q&As)'])
    sheet26.addRow([
      'Q&A',
      'Question or Answer',
      'Content Owner',
      'Liked Date&Time',
    ])
    experience?.forEach((item) => {
      sheet26.addRow([item.qa || '', '', '', ''])
    })

    // Add Sheet 27 (Bookmarked Posts)
    const sheet27 = workbook.addWorksheet('Bookmarked Posts')
    sheet27.addRow(['Bookmarked Posts'])
    sheet27.addRow(['Post URL', 'Content Owner', 'Date&Time'])
    favourite?.forEach((item) => {
      sheet27.addRow([
        item.postURL || '',
        item?.user?.fullName,
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
      ])
    })

    // Add Sheet 28 (Shared Posts)
    const sheet28 = workbook.addWorksheet('Shared Posts')
    sheet28.addRow(['Shared Posts'])
    sheet28.addRow(['Post URL', 'Content Owner', 'Shared Date&Time'])
    share?.forEach((item) => {
      sheet28.addRow([item.postURL || '', '', ''])
    })

    // Add Sheet 29 (Not interested Posts)
    const sheet29 = workbook.addWorksheet('Not interested Posts')
    sheet29.addRow(['Not interested Posts'])
    sheet29.addRow(['Post URL', 'Content Owner', 'Not interested Date&Time'])
    notInterested?.forEach((item) => {
      sheet29.addRow([item.postURL || '', '', ''])
    })

    // Add Sheet 31 (Search History)
    const sheet30 = workbook.addWorksheet('Search History')
    sheet30.addRow(['Search History'])
    sheet30.addRow(['Term', 'Date & Time', 'Saved (T/F)'])
    savedSearch?.forEach((item) => {
      sheet30.addRow([
        item.term || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        'T',
      ])
    })
    recentSearch?.forEach((item) => {
      sheet30.addRow([
        item.term || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        'F',
      ])
    })

    // Add Sheet 31 (Posts)
    const sheet31 = workbook.addWorksheet('Posts')
    sheet31.addRow(['Posts'])
    sheet31.addRow([
      'Post URL',
      'Hashtags',
      'Description',
      'Date&Time of Posting',
      'Turn Off Commenting (T/F)',
    ])
    posts?.forEach((item) => {
      sheet31.addRow([
        item?.postId || '',
        item?.postTags?.map((tag) => tag?.hashtag?.name)?.join(', '), //improvement
        item?.description,
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        item?.isComment,
      ])
    })

    // Add Sheet 32 (Posted Comments for Posts)
    const sheet32 = workbook.addWorksheet('Posted Comments (for Posts)')
    sheet32.addRow(['Posted Comments (for Posts)'])
    sheet32.addRow([
      'Comment',
      'Post URL',
      'Content owner',
      'Date&Time of Commenting',
    ])
    comments
      ?.filter((comment) => comment?.isPost)
      ?.forEach((item) => {
        sheet32.addRow([
          item.comment || '',
          item.id || '',
          item.user?.name || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 33 (Posted Comments for Profiles)
    const sheet33 = workbook.addWorksheet('Posted Comments (for Profiles)')
    sheet33.addRow(['Posted   (for Profiles)'])
    sheet33.addRow(['Comment', 'Profile owner', 'Date&Time of Commenting'])
    memo?.forEach((item) => {
      sheet33.addRow([
        item.memo || '',
        item.profile.fullName || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
      ])
    })

    // Add Sheet 34 (Posted Questions)
    const sheet34 = workbook.addWorksheet('Posted Questions')
    sheet34.addRow(['Posted Questions'])
    sheet34.addRow([
      'Hashtags',
      'Title',
      'Question',
      'Image (T/F)',
      'Date&Time of Posting',
    ])
    posts
      ?.filter((post) => post?.postType.toLowerCase() === 'question')
      ?.forEach((item) => {
        sheet34.addRow([
          item?.postTags?.map((tag) => tag?.hashtag?.name)?.join(', '),
          item?.title,
          item?.title,
          '',
          Boolean(
            item?.postMedia?.filter(
              (fileType) => fileType?.toLowerCase() === 'image'
            ).length
          ),
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 35 (Posted Answers)
    const sheet35 = workbook.addWorksheet('Posted Answers')
    sheet35.addRow(['Posted Answers'])
    sheet35.addRow(['Answer', 'Date&Time of Posting'])
    comments
      ?.filter((comment) => comment.userId === userSingleData?.id)
      ?.filter(
        (comment) => comment?.post?.postType.toLowerCase() === 'question'
      )
      .forEach((item) => {
        sheet35.addRow([
          item.comment || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 36 (Received comments)
    const sheet36 = workbook.addWorksheet('Received Comments')
    sheet36.addRow(['Received Comments'])
    sheet36.addRow([
      'Comment',
      'Post URL/Profile URL',
      'Content owner',
      'Date&Time of Commenting',
    ])
    posts?.forEach((post) => {
      let commentsArray = post?.comments
      commentsArray.forEach((item) => {
        sheet36.addRow([
          item.comment || '',
          post.id,
          item.user?.fullName || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })
    })

    // Add Sheet 37 (Correspondence via email)
    const sheet37 = workbook.addWorksheet('Correspondence via Email')
    sheet37.addRow(['Correspondence via Email'])
    sheet37.addRow(['Subject', 'Message', 'From', 'Date&Time of Email'])
    correspondence?.forEach((item) => {
      sheet37.addRow([
        item.subject || '',
        item.message || '',
        item.from || '',
        moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
      ])
    })

    // Add Sheet 38 (Archived Posts)
    const sheet38 = workbook.addWorksheet('Archived Posts')
    sheet38.addRow(['Archived Posts'])
    sheet38.addRow(['Post ID', 'Hashtags', 'Description', 'Deleted Date&Time'])
    posts
      ?.filter((post) => !post.isActive)
      ?.forEach((item) => {
        sheet38.addRow([
          item?.postId || '',
          item?.postTags?.map((tag) => tag?.hashtag?.name)?.join(', '), //improvement
          item?.description,
          moment(item?.deletedAt).format(`${config.DATE_FORMAT}, HH:mm`),
          item?.isComment,
        ])
      })

    // Add Sheet 39 (Profile Change History)
    const sheet39 = workbook.addWorksheet('Profile Change History')
    sheet39.addRow(['Profile Change History'])
    sheet39.addRow([
      'Changed item',
      'Previous Value',
      'New Value',
      'Changed Date&Time',
    ])
    changeLog
      ?.filter((log) => log.type.toLowerCase() === 'profile')
      ?.forEach((item) => {
        sheet39.addRow([
          item.subType || '',
          item.oldValue || '',
          item.newValue || '',
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 40 (Password Change History)
    const sheet40 = workbook.addWorksheet('Password Change History')
    sheet40.addRow(['Password Change History'])
    sheet40.addRow(['Changed Date&Time'])
    changeLog
      ?.filter((log) => log.type.toLowerCase() === 'password')
      ?.forEach((item) => {
        sheet40.addRow([
          moment(item?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`),
        ])
      })

    // Add Sheet 41 (Notification Settings)
    const sheet41 = workbook.addWorksheet('Notification Settings')
    sheet41.addRow(['Notification Settings'])
    sheet41.addRow([
      'All (T/F)',
      'Likes (T/F)',
      'Bookmarks (T/F)',
      'New followers (T/F)',
      'New posts of your following (T/F)',
      'New posts with hashtags you follow (T/F)',
      'Comments on your posts (T/F)',
      'Comments on your profile (T/F)',
      'Comments on following hashtags (T/F)',
      'From hashmemo (T/F)',
    ])
    sheet41.addRow([
      settings?.all || '',
      settings?.likes || '',
      settings?.bookmarks || '',
      settings?.newFollowers || '',
      settings?.newPostFollowing || '',
      settings?.newHashtagFollowing || '',
      settings?.comment || '',
      settings?.commentOnProfile || '',
      settings?.commentOnFollowingHashtag || '',
      settings?.fromHashmemo || '',
    ])

    // Apply background color to Header Row
    const applyFormatting = (sheet) => {
      const headerRow = sheet.getRow(1)
      headerRow.eachCell((cell) => {
        cell.font = { bold: true }
      })
      const secondRow = sheet.getRow(2)
      secondRow.eachCell((cell) => {
        cell.font = { bold: true }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ADD8E6' },
        }
      })
    }

    // for Bold Header Rows
    workbook.eachSheet((sheet) => {
      applyFormatting(sheet)
    })

    // Define sheet name for tab color
    const sheetGroups = [
      {
        sheetNames: [
          'Basic',
          'Resume (Experience)',
          'Resume (Education)',
          'Resume (Skills)',
          'Resume (Certificates)',
        ],
        tabColor: 'fffcbdbd',
      },
      { sheetNames: ['Device', 'Localization'], tabColor: 'fffce7bd' },
      {
        sheetNames: [
          'Reporting and banning (Basic)',
          'Reported count per post',
          'Reported count per comment',
          'Reporting details (for profiles',
          'Reporting details (for posts)',
          'Reporting details (for comments',
          'Banned History',
          'Objection History',
        ],
        tabColor: 'ff84b091',
      },
      {
        sheetNames: [
          'Interactions (Basic)',
          'Followers',
          'Following',
          'Following hashtags',
          'Unfollowed accounts',
          'Unfollowed hashtags',
          'Blocked account(s)',
          'Medals and its hashtags',
          'Liked content (Posts)',
          'Liked content (Comments)',
          'Liked content (Q&As)',
          'Bookmarked Posts',
          'Shared Posts',
          'Not interested Posts',
          'Search History',
        ],
        tabColor: 'ffd2bdfc',
      },
      {
        sheetNames: [
          'Posts',
          'Posted Comments (for Posts)',
          'Posted Comments (for Profiles)',
          'Posted Questions',
          'Posted Answers',
          'Received Comments',
          'Correspondence via Email',
          'Archived Posts',
        ],
        tabColor: 'fffcbdf4',
      },
      {
        sheetNames: [
          // 'Link History',
          'Profile Change History',
          'Password Change History',
          'Notification Settings',
        ],
        tabColor: 'ffc0fcf6',
      },
    ]

    // Apply tabColor to specified sheets
    sheetGroups?.forEach((group) => {
      applyTabColorToSheets(workbook, group.sheetNames, group.tabColor)
    })

    // Generate CSV file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${username}_data_with_sheets.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  const applyTabColorToSheets = (workbook, sheetNames, tabColor) => {
    sheetNames?.forEach((sheetName) => {
      const sheet = workbook.getWorksheet(sheetName)
      if (sheet) {
        sheet.properties.tabColor = { argb: tabColor }
      }
    })
  }

  const goBack = () => {
    window.history.back()
  }

  return (
    <div className='md:w-[980px] xl:w-[1080px] 2xl:w-[100%]'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn className='' onClick={goBack}>
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>User Informations</h1>
      </div>

      <div className='shadow-customBtnShadow rounded-xl w-full h-full p-6'>
        {/* account information */}
        <div className='grid grid-cols-5 place-items-start my-2'>
          <span className='flex items-center gap-x-3 w-full'>
            <Settings strokeWidth={1} />
            <h2 className='text-base font-medium'>Account Information</h2>
          </span>
          <span className='col-span-2 flex flex-col w-full gap-y-2'>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Birthday
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {/* 30/11/1989 (33)  */}
                {formattedBirthdate} {`(${age})`}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button
                className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'
                onClick={() => setComponent(15)}
              >
                Family Pairing
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                + {userSingleData.children?.length} <FiChevronRight />
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Gender
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {userSingleData?.gender}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Country
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                U.S.A.
              </h4>
            </span>
          </span>
          <span className=' col-span-2 flex flex-col w-full gap-y-2 '>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Account Creation Date
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {/* 30/05/2023, 12:00 */}
                {moment(userSingleData.createdAt).format(
                  `${config.DATE_FORMAT}, HH:mm`
                )}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Last Login Date
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {/* 30/07/2023, 12:00 */}
                {moment(userSingleData.userSessions[0]?.lastActivity).format(
                  `${config.DATE_FORMAT}, HH:mm`
                )}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                OS
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                Android
                {/* {userSingleData?.userSessions[0]?.deviceInfo} */}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Model
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                Sony Xperia 10 III
              </h4>
            </span>
          </span>
        </div>

        <hr className='my-6' />

        {/* Language */}
        <div className='grid grid-cols-5 place-items-start my-2'>
          <span className='flex items-center gap-x-3 w-full'>
            <Language size={22} />{' '}
            <h2 className='text-base font-medium'>Language</h2>
          </span>
          <span className='col-span-2 flex flex-col w-full gap-y-2'>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                App Language
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold capitalize'>
                {userSingleData?.language?.name}
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Dev Language
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                English
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Writing
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                English
              </h4>
            </span>
          </span>
        </div>

        <hr className='my-6' />

        {/* Pofile Information */}
        <div className='grid grid-cols-5 place-items-start my-2'>
          <span className='flex items-center gap-x-3 w-full'>
            <BiUser size={22} />{' '}
            <h2 className='text-base font-medium'>Profile Information</h2>
          </span>
          <span className='col-span-2 flex flex-col w-full gap-y-2'>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button
                className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'
                onClick={() => setComponent(16)}
              >
                Like
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {userSingleData?.likes?.length} <FiChevronRight />
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button
                className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'
                onClick={() => setComponent(17)}
              >
                Bookmark
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                32 <FiChevronRight />
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button
                className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'
                onClick={() => setComponent(18)}
              >
                Share
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {userSingleData?.share?.length}
                <FiChevronRight />
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button
                className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'
                onClick={() => setComponent(19)}
              >
                Not interested
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                {userSingleData?.notInterested?.length}
                <FiChevronRight />
              </h4>
            </span>
          </span>
          <span className='col-span-2 flex flex-col w-full gap-y-2 '>
            {userSingleData?.links?.instagram && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3  rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <Instagram size={20} />
                </button>
                <h4 className='max-w-fit md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData.links.instagram}
                  <FiChevronRight />
                </h4>
              </span>
            )}

            {userSingleData?.links?.tiktok && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3  rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <BiLogoTiktok size={20} />
                </button>
                <h4 className='max-w-fit  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData?.links?.tiktok}
                  <FiChevronRight />
                </h4>
              </span>
            )}

            {userSingleData?.links?.linkedin && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <BiLogoLinkedin size={20} />
                </button>
                <h4 className='max-w-fit  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData?.links?.linkedin}
                  <FiChevronRight />
                </h4>
              </span>
            )}

            {userSingleData?.links?.facebook && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3  rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <GrFacebookOption size={20} />
                </button>
                <h4 className='max-w-fit  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData?.links?.facebook}
                  <FiChevronRight />
                </h4>
              </span>
            )}

            {userSingleData?.links?.youtube && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3  rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <BiLogoYoutube size={20} />
                </button>
                <h4 className='max-w-fit  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData?.links?.youtube}
                  <FiChevronRight />
                </h4>
              </span>
            )}

            {userSingleData?.links?.twitter && (
              <span className='flex flex-nowrap items-center justify-start gap-x-2'>
                <button className='w-fit py-1.5 px-3  rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                  <img
                    src='https://res.cloudinary.com/dhpvia1ae/image/upload/v1709189036/Twitter_pvz6lp.jpg'
                    alt='twitter'
                    width={20}
                    height={20}
                  />
                </button>
                <h4 className='max-w-fit  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                  {userSingleData?.links?.twitter}
                  <FiChevronRight />
                </h4>
              </span>
            )}
          </span>
        </div>

        {/* Copy for CCPA*/}
        {userSingleData?.id === id &&
          user?.authorityName?.includes('download_account') && (
            <button
              onClick={copyForCCPA}
              className='absolute bottom-8 right-8 !py-1.5 font-medium !px-5 text-sm shadow-customBtnShadow rounded-xl'
            >
              Copy for CCPA
            </button>
          )}
      </div>
    </div>
  )
}

UserInformations.propTypes = {
  userSingleData: PropTypes.object,
  setComponent: PropTypes.func,
  badges: PropTypes.object,
  objections: PropTypes.object,
  correspondence: PropTypes.array,
  changeLog: PropTypes.array,
}

export default UserInformations
