import { defaultProfileImg } from '_utils/default'
import config from 'config/config'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'
import PropsTypes from 'prop-types'

const TargetData = ({ data }) => {
  return (
    <div className='flex'>
      <img
        className='w-9 h-9 rounded-full'
        src={defaultProfileImg(data?.profileImage, 'user')}
        alt='userimage'
      />
      <div className='ml-1'>
        <h4 className='font-semibold text-xs text-black'>{data?.fullName}</h4>
        <NavLink
          to={`/user/${data?.id}`}
          className='font-normal underline underline-offset-2 pb-2'
        >
          {data?.username}
        </NavLink>
        <p className='font-normal'>
          {moment(data?.createdAt).format(config.DATE_FORMAT)}
        </p>
      </div>
    </div>
  )
}

TargetData.propTypes = {
  data: PropsTypes.object,
}

export default TargetData
