import React, { useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import PropsTypes from 'prop-types'
import ClickAwayListener from 'react-click-away-listener'

const Popper = ({
  children,
  refElement,
  open,
  onClose,
  className,
  closeOnClickAway = true,
  popperOptions = {
    strategy: 'fixed',
    placement: 'bottom',
  },
}) => {
  const [_open, _setOpen] = useState(false)
  useEffect(() => _setOpen(open), [open])

  const [popperElement, setPopperElement] = useState(null)

  const { styles, attributes } = usePopper(
    refElement,
    popperElement,
    popperOptions
  )

  const handleRefElementClick = () => {
    if (_open === null) _setOpen(true)
    else _setOpen(!_open)
  }

  useEffect(() => {
    if (refElement) {
      refElement?.addEventListener('click', handleRefElementClick, true)

      return refElement?.removeEventListener('click', handleRefElementClick)
    }
  }, [refElement])

  const handleClickAway = () => {
    if (closeOnClickAway) {
      _setOpen(false)
      onClose?.()
    }
  }

  const RenderChildren = useMemo(() => {
    return typeof children === 'function' ? children() : children
  }, [children])

  if (!_open) return null

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          zIndex: 9999999999,
        }}
        className={`!shadow-popper rounded-xl mt-5 ${className}`}
        {...attributes.popper}
      >
        {RenderChildren}
      </div>
    </ClickAwayListener>
  )
}

Popper.propTypes = {
  children: PropsTypes.oneOfType([PropsTypes.func, PropsTypes.node]).isRequired,
  refElement: PropsTypes.instanceOf(HTMLElement),
  open: PropsTypes.bool,
  onClose: PropsTypes.func,
  closeOnClickAway: PropsTypes.bool,
  popperOptions: PropsTypes.object,
  className: PropsTypes.string,
}

export default Popper
