import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  RiAccountBoxLine,
  RiHashtag,
  RiHome5Line,
  RiLogoutCircleRLine,
  RiSettings4Line,
  RiUserSearchLine,
} from 'react-icons/ri'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'
import { FaFileWaveform } from 'react-icons/fa6'
import useAuth from '_hooks/useAuth'
import GlobalModal from './GlobalModal'
import useUser from '_hooks/useUser'
import useStaff from '_hooks/useStaff'
import useSettings from '_hooks/useSettings'
// import useCcpa from '_hooks/useCcpa'

const ActiveNavMap = {
  home: false,
  user: false,
  hashtag: false,
  objection: false,
  settings: false,
  admin: false,
}

const SideNavBar = () => {
  const [activeNav, SetActiveNav] = useState(ActiveNavMap)
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const { updateStatus } = useSettings()

  const [isLogOut, setIsLogOut] = useState(false)
  const HandleLogoutModal = () => setIsLogOut(!isLogOut)
  const { data } = useUser()
  // const { data } = useCcpa()

  const location = useLocation()
  const pathSegments = location.pathname
    .split('/')
    .filter((segment) => segment !== '')
  const [userid, SetUserid] = useState('')
  const {
    staffs: { rows },
  } = useStaff()
  const [staffId, SetStaffId] = useState('')

  useEffect(() => {
    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (pathSegments[1] === item.id) {
          SetUserid(pathSegments[1])
        }
      })
    }
  }, [data, pathSegments, location.pathname])

  useEffect(() => {
    if (Array.isArray(rows)) {
      rows.forEach((item) => {
        if (pathSegments[2] === item.id) {
          SetStaffId(pathSegments[2])
        }
      })
    }
  }, [rows, pathSegments, location.pathname])

  const checkActiveNav = () => {
    const check = navItems.findIndex((nv) =>
      window.location.pathname.includes(nv.path)
    )
    if (check >= 0) {
      SetActiveNav((prev) => {
        let _active = {}
        Object.entries(prev).forEach(([key, value]) => {
          if (value) {
            _active[key] = !value
          } else {
            _active[key] = value
          }
        })
        const key = navItems[check].label.toLowerCase()

        return {
          ..._active,
          [`${key}`]: true,
        }
      })
    }
  }

  const handleLogout = () => {
    updateStatus({ id: user?.id, status: 'logout' })
      .then(() => logout())
      .then(() => navigate('/'))
  }

  const navItems = [
    {
      icon: <RiHome5Line className='w-8 h-8' size={24} />,
      label: 'Home',
      path: '/home',
    },
    {
      icon: <RiUserSearchLine size={24} />,
      label: 'User',
      path: '/user',
      subpaths: [`/user/${userid}`],
    },
    {
      icon: <RiHashtag size={24} />,
      label: 'Hashtag',
      path: '/hashtag',
    },
    {
      icon: <HiOutlineArchiveBoxXMark size={24} />,
      label: 'Objection',
      path: '/objection',
    },
    {
      icon: <FaFileWaveform size={24} />,
      label: 'CCPA',
      path: '/ccpa-form',
    },
    {
      icon: <FaFileWaveform size={24} />,
      label: 'Notification',
      path: '/notification',
    },
    {
      icon: <FaFileWaveform size={24} />,
      label: 'Template',
      path: '/template',
    },
    {
      icon: <RiSettings4Line size={24} />,
      label: 'Settings',
      path: '/settings/information',
      subpaths: [
        '/settings/information',
        '/settings/action-history',
        '/settings/bookmark',
        '/settings/my-report',
      ],
    },
    {
      icon: <RiAccountBoxLine size={24} />,
      label: 'Admin',
      path: '/admin/staff-list',
      subpaths: [
        '/admin/staff-list',
        `/admin/staff-list/${staffId}`,
        '/admin/team-list',
        '/admin/staff-report',
        '/admin/general-Settings',
        '/admin/authorization',
      ],
    },
  ]

  useEffect(() => {
    checkActiveNav()
  }, [])

  useEffect(() => {
    const currentPath = location.pathname
    navItems.forEach((item) => {
      const isActive =
        item.path === currentPath ||
        (item.subpaths && item.subpaths.includes(currentPath))

      SetActiveNav((prev) => ({
        ...prev,
        [item.label.toLowerCase()]: isActive,
      }))
    })
  }, [location.pathname, userid, staffId])

  return (
    <div className='fixed top-24 left-7 flex flex-col max-h-screen max-w-[163px] py-2.5 justify-between rounded-2xl backdrop-blur-sm drop-shadow-lg shadow-boxIn bg-universal z-nav-menu'>
      <ul className='flex flex-col justify-evenly h-full'>
        {navItems.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className={clsx(
              'px-9 pb-4 2xl:pb-6 flex flex-col justify-center items-center',
              activeNav[item.label.toLowerCase()] &&
                '!text-text-dm-input !font-bold '
            )}
          >
            {item.icon}
            <p>{item.label}</p>
            <div
              className={clsx(
                'h-1 w-6 rounded-full bg-inherit',
                activeNav[item.label.toLowerCase()] && 'bg-text-dm-input'
              )}
            />
          </NavLink>
        ))}
      </ul>
      <button
        className='mt-4 2xl:mt-16 mb-7 cursor-pointer'
        onClick={HandleLogoutModal}
      >
        <div className='grid place-items-center'>
          <RiLogoutCircleRLine size={24} />
          <p>Log Out</p>
        </div>
      </button>

      <GlobalModal
        isOpen={isLogOut}
        handleClose={HandleLogoutModal}
        height={432}
      >
        <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
          {/* Icon */}
          <div className='flex flex-col text-center gap-y-4'>
            <div className='flex justify-center items-center w-100'>
              <RiLogoutCircleRLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
            </div>
            {/* text */}
            <div className='flex flex-col items-center gap-y-1'>
              <h6 className='font-medium text-md'>
                Are you sure you want to log out?
              </h6>
            </div>
          </div>
          <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
            <button
              onClick={handleLogout}
              className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            >
              Yes
            </button>
            <button
              className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
              onClick={HandleLogoutModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </GlobalModal>
    </div>
  )
}

export default SideNavBar
