import React from 'react'
import PropTypes from 'prop-types'

const CustomTrash = ({ size, fillColor, ...args }) => (
  <svg
    {...args}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 18 18'
    fill={fillColor}
  >
    <path d='M15.75 5.04687C15.735 5.04687 15.7125 5.04687 15.69 5.04687C11.7225 4.64937 7.7625 4.49937 3.84 4.89687L2.31 5.04687C1.995 5.07687 1.7175 4.85187 1.6875 4.53687C1.6575 4.22187 1.8825 3.95187 2.19 3.92187L3.72 3.77187C7.71 3.36687 11.7525 3.52437 15.8025 3.92187C16.11 3.95187 16.335 4.22937 16.305 4.53687C16.2825 4.82937 16.035 5.04687 15.75 5.04687Z' />
    <path d='M6.37507 4.29C6.34507 4.29 6.31507 4.29 6.27757 4.2825C5.97757 4.23 5.76757 3.9375 5.82007 3.6375L5.98507 2.655C6.10507 1.935 6.27007 0.9375 8.01757 0.9375H9.98257C11.7376 0.9375 11.9026 1.9725 12.0151 2.6625L12.1801 3.6375C12.2326 3.945 12.0226 4.2375 11.7226 4.2825C11.4151 4.335 11.1226 4.125 11.0776 3.825L10.9126 2.85C10.8076 2.1975 10.7851 2.07 9.99007 2.07H8.02507C7.23007 2.07 7.21507 2.175 7.10257 2.8425L6.93007 3.8175C6.88507 4.095 6.64507 4.29 6.37507 4.29Z' />
    <path d='M11.4075 17.0617H6.59255C3.97505 17.0617 3.87005 15.6142 3.78755 14.4442L3.30005 6.8917C3.27755 6.5842 3.51755 6.3142 3.82505 6.2917C4.14005 6.2767 4.40255 6.5092 4.42505 6.8167L4.91255 14.3692C4.99505 15.5092 5.02505 15.9367 6.59255 15.9367H11.4075C12.9825 15.9367 13.0125 15.5092 13.0875 14.3692L13.575 6.8167C13.5975 6.5092 13.8675 6.2767 14.175 6.2917C14.4825 6.3142 14.7225 6.5767 14.7 6.8917L14.2125 14.4442C14.13 15.6142 14.025 17.0617 11.4075 17.0617Z' />
    <path d='M10.2451 12.9375H7.74756C7.44006 12.9375 7.18506 12.6825 7.18506 12.375C7.18506 12.0675 7.44006 11.8125 7.74756 11.8125H10.2451C10.5526 11.8125 10.8076 12.0675 10.8076 12.375C10.8076 12.6825 10.5526 12.9375 10.2451 12.9375Z' />
    <path d='M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z' />
  </svg>
)

CustomTrash.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
}

CustomTrash.defaultProps = {
  size: 18,
  fillColor: '#000000',
}

export default CustomTrash
