import LightBtn from 'components/global/Buttons/LightBtn'
import { MoveLeft, PlusCircle } from 'lucide-react'
import React, { useState } from 'react'
import DataTable from 'react-data-table-component'
import RightCanvas from 'components/global/RightCanvas'
import PropsTypes from 'prop-types'
import config from 'config/config'
import moment from 'moment'
import User from 'components/global/User'
import Post from 'components/global/Post'

const ObjectionHistory = ({ objections }) => {
  const [openChatHistory, setOpenChatHistory] = useState(false)
  const handleOpenChatHistory = () => setOpenChatHistory(!openChatHistory)
  const chatclose = () => setOpenChatHistory(false)

  // Custom cell styles for DataTable
  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
  }

  const columns = [
    {
      // name: 'Received Date',
      name: (
        <div className='flex w-full'>
          Received Date
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) =>
        moment(row?.createdAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      sortable: true,
      minWidth: '100px',
    },
    {
      // name: 'Reason',
      name: (
        <div className='flex w-full'>
          Reason
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <div className='flex-wrap'>
          {row?.commentReport?.reason?.reason ||
            row?.userReport?.reason?.reason ||
            row?.postReport?.reason?.reason ||
            row?.memoReport?.reason?.reason}
        </div>
      ),
    },
    {
      // name: 'User Info',
      name: (
        <div className='flex w-full'>
          User Info
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '180px',
      cell: (row) => (
        <User
          user={
            row?.commentReport?.comment?.user ||
            row?.userReport?.reportedUser ||
            row?.postReport?.post?.users ||
            row?.memoReport?.memo?.user
          }
        />
      ),
    },
    {
      name: 'Target Data',
      sortable: true,
      minWidth: '160px',
      cell: (row) => (
        <div>
          {row?.type === 'user' ? (
            <p>For account</p>
          ) : (
            <>
              <Post
                post={
                  row?.commentReport?.comment?.post || row?.postReport?.post
                }
                showId={true}
                rowId={row?.id}
              />
            </>
          )}
        </div>
      ),
    },
    {
      // name: 'Objection ID',
      name: (
        <div className='flex w-full'>
          Objection ID
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '20px',
      cell: (row) => <p className='truncate'>{row?.uid}</p>,
    },
    {
      // name: 'Status',
      name: (
        <div className='flex w-full'>
          Status
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => <p>{row?.status.replace(/_/g, ' ')}</p>,
    },
    {
      // name: 'Result',
      name: (
        <div className='flex w-full'>
          Result
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <>
          <select className='text-text-dm-input items-center outline-none bg-universal'>
            <option value='master'>{row?.result.replace(/_/g, ' ')}</option>
            <option value='none' className='text-text-dm-input font-medium'>
              Pending
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Overruled
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Accepted
            </option>
          </select>
        </>
      ),
    },
    {
      // name: 'PIC',
      name: (
        <div className='flex w-full'>
          PIC
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <p
          className='truncate'
          style={{ color: row?.pic?.fullName ? '#000000' : '#585858' }}
        >
          {row?.pic?.fullName || 'None'}
        </p>
      ),
    },
    {
      name: 'Form',
      sortable: true,
      minWidth: '10px',
      cell: () => (
        <div className='flex'>
          <img
            src='/assets/Icons/OutlookIcon.svg'
            alt='eye'
            className='h-5 w-6'
          />
        </div>
      ),
    },
  ]

  const goBack = () => {
    window.history.back()
  }

  return (
    <>
      <div className='md:!w-[956px] xl:!w-[1055px] 2xl:!w-[99%] DataTableMain'>
        <div className='w-full flex gap-x-2 items-center'>
          <LightBtn onClick={goBack}>
            <MoveLeft size={16} />
          </LightBtn>
          <h1 className='text-base font-bold '> Objection History </h1>
        </div>
        <div className='rounded-xl w-full max-w-full h-full'>
          <DataTable
            columns={columns}
            data={objections}
            highlightOnHover
            selectableRows
            customStyles={customCellStyles}
            className='md:!w-[700px] xl:!w-[780px] 2xl:!w-[100%] DataTable'
            // onSelectedRowsChange={(row) => _setSelectedRows(row?.selectedRows)}
          />
        </div>
      </div>

      {/* Chat history */}
      <RightCanvas isOpen={openChatHistory} onClose={handleOpenChatHistory}>
        <div>
          <div className='h-screen flex flex-col'>
            {/* header */}
            <div className='relative p-5 border-b '>
              <PlusCircle
                className='rotate-45 absolute left-4 top-5 cursor-pointer'
                onClick={chatclose}
              />
              <h1 className='text-center  text-base font-bold'>Chat History</h1>
            </div>

            {/*content   */}
            <div className='flex flex-col p-5 gap-y-4'>
              <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                <div className='flex justify-between'>
                  <h5 className='text-base font-medium'>Jennifer</h5>
                  <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                </div>
                <div className='flex justify-between'>
                  <p className='text-sm font-light'>
                    @Manager : Thank you for confirmation. I changed the status
                  </p>
                </div>
              </div>
              <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                <div className='flex justify-between'>
                  <h5 className='text-base font-medium'>Manager</h5>
                  <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                </div>
                <div className='flex justify-between'>
                  <p className='text-sm font-light'>
                    Go ahead to change to “Approved”.
                  </p>
                </div>
              </div>
              <div className='w-full rounded-xl shadow-customBtnShadow p-3 flex flex-col gap-y-2'>
                <div className='flex justify-between'>
                  <h5 className='text-base font-medium'>Jennifer</h5>
                  <h6 className='text-xs font-light'>16/08/2023, 9:00</h6>
                </div>
                <div className='flex justify-between'>
                  <p className='text-sm font-light'>
                    I&apos;m checking with the manager if it should be
                    &apos;Approved&apos;.
                  </p>
                  <img
                    src='/assets/Image/imageFour.jpg'
                    alt='actionImg'
                    className='w-9 h-14 rounded-sm'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </RightCanvas>
    </>
  )
}

ObjectionHistory.propTypes = {
  objections: PropsTypes.object,
}

export default ObjectionHistory
