import clsx from 'clsx'
import LightBtn from 'components/global/Buttons/LightBtn'
import PropsTypes from 'prop-types'
import { Hash, MoveLeft } from 'lucide-react'
import React, { useState } from 'react'
import { FiHeart } from 'react-icons/fi'

const ProfileInformation = ({ component }) => {
  const RenderComponent = ({ component }) => {
    switch (component) {
      case 16:
        return <ProfileInfoLikes />
      case 17:
        return <ProfileInfoBookmarks />
      case 18:
        return <ProfileInfoShares />
      case 19:
        return <ProfileInfoNotInterested />
      default:
        null
    }
  }

  return <RenderComponent component={component} />
}
ProfileInformation.propTypes = {
  component: PropsTypes.number,
}

// Prifle Likes
const ProfileInfoLikes = () => {
  const [activeTab, setActiveTab] = useState('post')
  const handleChangeContent = (value) => {
    setActiveTab(value)
  }
  const RenderContent = ({ activeTab }) => {
    switch (activeTab) {
      case 'post':
        return <Posts />
      case 'comment':
        return <Comments />
      default:
        break
    }
  }

  RenderContent.propTypes = {
    activeTab: PropsTypes.string,
  }

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='w-full  max-h-[88vh] flex flex-col gap-4 relative'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn onClick={goBack} >
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>
          Profile Information - Likes: 113
        </h1>
      </div>
      {/* Tabs */}
      <div className='w-full flex bg-bg-tabs rounded-xl p-1 h-fit justify-around gap-x-2 border border-solid border-text-dm-input'>
        <button
          className={clsx(
            'font-semibold py-2 w-full h-full transition-all text-sm',
            activeTab !== 'post' && 'text-text-lm-primary',
            activeTab === 'post' &&
            'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={(e) => {
            e.stopPropagation()
            handleChangeContent('post')
          }}
        >
          Post
        </button>
        <button
          className={clsx(
            'font-semibold z-10 py-2 w-full h-full transition-all text-sm',
            activeTab !== 'comment' && 'text-text-lm-primary',
            activeTab === 'comment' &&
            'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={(e) => {
            e.stopPropagation()
            handleChangeContent('comment')
          }}
        >
          Comment
        </button>
      </div>

      {/* content as per tabs */}
      <RenderContent activeTab={activeTab} />
    </div>
  )
}

// Profile Bookmarks
const ProfileInfoBookmarks = () => {

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='w-full  max-h-[88vh] flex flex-col gap-4 relative'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn onClick={goBack}>
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>
          Profile Information - Bookmark: 32
        </h1>
      </div>
      <Posts />
    </div>
  )
}

// shares Posts
const ProfileInfoShares = () => {

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='w-full  max-h-[88vh] flex flex-col gap-4 relative'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn onClick={goBack}>
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>
          Profile Information - Shares: 16
        </h1>
      </div>
      <Posts />
    </div>
  )
}

// profile Not Interested
const ProfileInfoNotInterested = () => {

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='w-full  max-h-[88vh] flex flex-col gap-4 relative'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn onClick={goBack}>
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>
          Profile Information - Not Interested: 5
        </h1>
      </div>
      <Posts />
    </div>
  )
}

const Posts = () => {
  return (
    <div className='flex flex-wrap gap-2 w-full h-full max-h-max overflow-y-auto place-content-start'>
      {/* Post cards */}
      <div className='w-44 h-72 shadow-customBtnShadow rounded-xl p-3 flex flex-col'>
        <div className='grid grid-cols-4 h-3/5 gap-x-2'>
          <img
            src='/assets/Image/imageOne.jpeg'
            className='col-span-3 h-full rounded-xl'
            alt='cardImg'
          />
          <div className='flex-1 flex-col flex gap-x-2 items-start justify-start'>
            <span className='flex flex-nowrap items-center'>
              <img src='/assets/Icons/eye.svg' alt='eye' className='w-3' />{' '}
              &nbsp; <u className='text-xxs'>974</u>
            </span>
            <span className='flex flex-nowrap justify-center items-center'>
              <FiHeart size={12} fill='#f00' color='#f00' /> &nbsp;
              <u className='text-xxs'>100</u>
            </span>
            <span className='flex flex-nowrap items-center'>
              <img
                src='/assets/svg/bookmark.svg'
                alt='BookmarkIcon'
                width={12}
                height={12}
              />
              {/* <Bookmark size={17} /> */}
              &nbsp; <u className='text-xxs'>1</u>
            </span>
            <span className='flex flex-nowrap items-center'>
              <img src='/assets/Icons/message.svg' alt='eye' className='w-3' />{' '}
              &nbsp; <u className='text-xxs'>50</u>
            </span>
            <span className='flex flex-nowrap items-center'>
              <img src='/assets/Icons/shared.svg' alt='eye' className='w-3' />{' '}
              &nbsp; <u className='text-xxs'>3</u>
            </span>
          </div>
        </div>
        <div className='flex justify-start items-center gap-x-2 h-fit py-2'>
          <img
            src='/assets/Image/imageTwo.jpg'
            alt='profile'
            className='w-7 h-7 rounded-full'
          />
          <div className='flex flex-col'>
            <h6 className='font-bold text-sm leading-3'>Kate.W</h6>
            <h6 className='underline text-xs font-normal'>Cream0780</h6>
          </div>
        </div>
        <div className='flex justify-start items-center flex-wrap gap-1'>
          <span className='flex justify-start items-center flex-nowrap text-xs font-medium text-text-dm-card w-fit'>
            <Hash size={13} />
            digital marketing
          </span>
          <span className='flex justify-start items-center flex-nowrap text-xs font-medium text-text-dm-card w-fit'>
            <Hash size={13} />
            Spicy
          </span>
          <span className='flex justify-start items-center flex-nowrap text-xs font-medium text-text-dm-card w-fit'>
            <Hash size={13} />
            Spicy
          </span>
          <span className='flex justify-start items-center flex-nowrap text-xs font-medium text-text-dm-card w-fit'>
            <Hash size={13} />
            Spicy
          </span>
        </div>
        <div className='flex items-center flex-nowrap mt-auto mr-auto text-sm text-text-dm-card justify-between w-full'>
          <span className='flex flex-nowrap gap-x-1 text-sm font-semibold underline items-center'>
            <img src='/assets/Icons/id.svg' alt='postId' className='w-3 h-3' />
            <button className='text-ellipsis max-w-[106px] truncate text-xxs font-medium text-black'>
              2234435
            </button>
          </span>
          <h6 className='text-xxs text-gray-500'>11/08/2023</h6>
        </div>
      </div>
    </div>
  )
}
const Comments = () => {
  return (
    <div className='w-full h-full shadow-customBtnShadow rounded-xl p-5 max-h-max overflow-y-auto'>
      {/* comments  */}
      <div className='flex items-start p-5 gap-x-2 border-b'>
        <img
          src='/assets/Image/imageThree.jpg'
          alt='ProfileImage'
          className='w-10 h-10 rounded-full'
        />
        <div className='flex flex-col gap-y-1'>
          <h1 className='text-base font-bold'>Kate. W</h1>
          <h5 className='text-xs font-normal underline'>5689712</h5>
          <h5 className='text-sm font-normal'>Loream ipsum dummy text</h5>
          <h5 className='text-xs font-normal'>01/15/23 3:03 PM</h5>
        </div>
      </div>
    </div>
  )
}

export default ProfileInformation
