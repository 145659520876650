import React from 'react'
import PropTypes from 'prop-types'

const SlidingTabs = ({ activeTab, children }) => {
  const numTabs = React.Children.count(children)
  const calculateIndicatorPosition = () => {
    const tabWidth = 100 / numTabs
    return `calc(${tabWidth * activeTab}%)`
  }

  return (
    <div className='flex flex-nowrap justify-around w-full relative rounded-xl'>
      {children}

      <div
        className='absolute transition-all top-0 h-full bg-text-dm-input shadow-buttonPrimary rounded-xl '
        style={{
          left: calculateIndicatorPosition(),
          width: `calc(100% / ${numTabs})`,
        }}
      ></div>
    </div>
  )
}

SlidingTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default SlidingTabs
