import Page from 'components/global/Page'
import UserStatus from 'components/global/Badge/UserStatus'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import AdminHeader from 'components/admin/AdminHeader'
import useStaff from '_hooks/useStaff'
import moment from 'moment'
import config from 'config/config'
import useAuth from '_hooks/useAuth'

const AdminStaffList = () => {
  const {
    staffs: { rows, _count },
    position: { rows: postitonRows },
    grade: { rows: gradeRows },
    country: { rows: countryRows },
    team: { rows: teamRows },
    fetchStaffs,
    editMember,
  } = useStaff()

  const { user } = useAuth()

  const [payload, setPayload] = useState([])
  const [_selectedRows, _setSelectedRows] = useState([])

  useEffect(() => {
    fetchStaffs()
  }, [])

  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        padding: '1.6rem .5rem',
        color: '#000',
      },
    },
  }

  useEffect(() => {
    setPayload(
      rows?.map((item) => {
        return {
          memberId: item?.id,
          gradeId: item?.grade?.id,
          teamId: item?.team?.id,
          positionId: item?.position?.id,
          countryId: item?.country?.id,
        }
      })
    )
  }, [rows])

  const handleClick = () => {
    editMember(
      payload?.filter((item) => _selectedRows?.includes(item?.memberId)),
      true
    )
    _setSelectedRows([])
  }

  const handleChange = (memberId, key, value) => {
    let tempArray = payload?.map((x) => x)
    setPayload(
      tempArray?.map((item) => {
        if (item?.memberId === memberId) {
          return { ...item, [`${key}`]: value }
        }
        return { ...item }
      })
    )
  }

  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row['fullName'],
      cell: (row) => (
        <Link
          className='flex flex-nowrap items-center gap-x-2 underline'
          to={`/admin/staff-list/${row?.id}`}
        >
          {UserStatus('online', { size: 10 })} {row?.fullName}
        </Link>
      ),
    },
    {
      name: 'Log In ID',
      selector: (row) => row['email'],
      sortable: true,
      minWidth: '50px',
      cell: (row) => <div>{row?.email}</div>,
    },
    {
      name: 'Grade',
      sortable: true,
      selector: (row) => row['grade']['name'],
      minWidth: '180px',
      cell: (row) => (
        <select
          className='text-text-dm-input items-center outline-none bg-universal'
          onChange={(e) => {
            handleChange(row?.id, 'gradeId', e.target.value)
          }}
        >
          {gradeRows?.map((grade) => {
            return (
              <option
                key={grade?.id}
                value={grade?.id}
                selected={grade?.id === row?.grade?.id}
              >
                {grade?.name}
              </option>
            )
          })}
        </select>
      ),
    },
    {
      name: 'Team',
      sortable: true,
      selector: (row) => row['team']['name'],
      minWidth: '160px',
      cell: (row) => (
        <select
          className='text-text-dm-input items-center outline-none bg-universal'
          onChange={(e) => {
            handleChange(row?.id, 'teamId', e.target.value)
          }}
        >
          {teamRows?.map((grade) => {
            return (
              <option
                key={grade?.id}
                value={grade?.id}
                selected={grade?.id === row?.team?.id}
              >
                {grade?.name}
              </option>
            )
          })}
        </select>
      ),
    },
    {
      name: 'Position',
      sortable: true,
      selector: (row) => row['position']['name'],
      minWidth: '20px',
      cell: (row) => (
        <select
          className='text-text-dm-input items-center outline-none bg-universal'
          onChange={(e) => handleChange(row?.id, 'positionId', e.target.value)}
        >
          {postitonRows?.map((grade) => {
            return (
              <option
                key={grade?.id}
                value={grade?.id}
                selected={grade?.id === row?.position?.id}
              >
                {grade?.name}
              </option>
            )
          })}
        </select>
      ),
    },
    {
      name: 'Country',
      sortable: true,
      selector: (row) => row['country']['name'],
      minWidth: '50px',
      cell: (row) => (
        <select
          className='text-text-dm-input items-center outline-none bg-universal'
          onChange={(e) => handleChange(row?.id, 'countryId', e.target.value)}
        >
          {countryRows?.map((grade) => {
            return (
              <option
                key={grade?.id}
                value={grade?.id}
                selected={grade?.id === row?.country?.id}
              >
                {grade?.name}
              </option>
            )
          })}
        </select>
      ),
    },
    {
      name: 'Last Login',
      sortable: true,
      selector: (row) =>
        moment(row?.updatedAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      minWidth: '30px',
      cell: (row) => (
        <div>
          {moment(row?.updatedAt).format(`${config.DATE_FORMAT}, HH:mm`)}
        </div>
      ),
    },
    {
      name: 'Last Logout',
      sortable: true,
      selector: (row) =>
        moment(row?.createdAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      minWidth: '30px',
      cell: (row) => (
        <p>{moment(row?.createdAt).format(`${config.DATE_FORMAT}, HH:mm`)}</p>
      ),
    },
  ]
  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader />
      </div>

      {/* content */}
      <div className='flex flex-col gap-y-3 h-[88vh]'>
        {/* counts */}
        <div className='flex justify-between h-fit'>
          <h1 className='text-lg font-semibold'>
            Total <span className='font-normal'>{_count}</span>
          </h1>
          <div className='grid grid-cols-2 gap-x-2'>
            {user?.authorityName?.includes('staff_editing') && (
              <button
                className='py-2 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold'
                onClick={handleClick}
              >
                Save
              </button>
            )}
            <button className='py-2 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'>
              Cancel
            </button>
          </div>
        </div>
        <div className='max-h-max overflow-y-auto'>
          <div className='shadow-customBtnShadow rounded-xl p-4 max-h-fit '>
            <DataTable
              columns={columns}
              data={rows}
              highlightOnHover
              selectableRows
              customStyles={customCellStyles}
              className='custom-data-table'
              onSelectedRowsChange={(row) =>
                _setSelectedRows(row?.selectedRows?.map((item) => item.id))
              }
            />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default AdminStaffList
