import React, { useEffect, useRef, useState } from 'react'
import PropsTypes from 'prop-types'
import clsx from 'clsx'
import Popper from 'components/global/Popper'
import DatePicker from 'react-datepicker'
import getOnlyTime from '_utils/getOnlyTime'

const TableInput = ({
  editable,
  value,
  edited = false,
  onClick,
  popperContent,
  disabled = false,
  onChange,
  min,
  max,
  type = 'time',
  ...args
}) => {
  const [startDate, setStartDate] = useState('')

  useEffect(() => {
    if (type === 'time') {
      setStartDate(new Date(value))
    }
  }, [type, value])

  const TableFilterModifier = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [150, -35],
        },
      },
    ],
  }
  const [popperOpen, setPopperOpen] = useState(false)
  const anchorRef = useRef()
  const handleClick = () => {
    if (editable && edited) {
      setPopperOpen(true)
    } else if (onClick) {
      onClick()
    }
  }

  return (
    <>
      {type === 'time' && (
        <>
          {!edited ? (
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date)
                onChange(date)
              }}
              disabled={disabled}
              showTimeSelect
              showTimeSelectOnly
              dropdownMode={'select'}
              onKeyDown={(e) => e.preventDefault()}
              timeIntervals={1}
              minTime={min ? new Date(min) : null}
              maxTime={max ? new Date(max) : null}
              timeCaption='Time'
              dateFormat='HH:mm'
              className={clsx(
                'text-xs placeholder:text-xs text-center !bg-transparent placeholder:text-center placeholder:text-black w-20 py-2 rounded-xl outline-none border border-transparent',
                editable && '!border-gray-300',
                edited && editable && '!text-red-600',
                edited && '!text-red-600'
              )}
              onFocus={handleClick}
              {...args}
            />
          ) : (
            <>
              <button
                ref={anchorRef}
                className={clsx(
                  'text-xs placeholder:text-xs text-center !bg-transparent placeholder:text-center placeholder:text-black w-20 py-2 rounded-xl outline-none border border-transparent',
                  editable && '!border-gray-300',
                  edited && editable && '!text-red-600',
                  edited && '!text-red-600'
                )}
                onClick={handleClick}
              >
                {getOnlyTime(startDate)}
              </button>
              <Popper
                open={popperOpen && edited}
                popperOptions={TableFilterModifier}
                onClose={() => setPopperOpen(false)}
                refElement={anchorRef.current}
              >
                {popperContent}
              </Popper>
            </>
          )}
        </>
      )}
      {type === 'number' && (
        <input
          ref={anchorRef}
          {...args}
          onClick={handleClick}
          min={min}
          max={max}
          disabled={disabled}
          onChange={(e) => {
            onChange(e)
          }}
          className={clsx(
            'text-xs placeholder:text-xs text-center !bg-transparent placeholder:text-center placeholder:text-black w-20 py-2 rounded-xl outline-none border border-transparent',
            editable && '!border-gray-300',
            edited && editable && '!text-red-600'
          )}
          required
          defaultValue={value}
        />
      )}
    </>
  )
}

TableInput.propTypes = {
  editable: PropsTypes.bool,
  disabled: PropsTypes.bool,
  value: PropsTypes.string,
  min: PropsTypes.string,
  max: PropsTypes.string,
  type: PropsTypes.string,
  popperContent: PropsTypes.node,
  edited: PropsTypes.any,
  onClick: PropsTypes.func,
  onChange: PropsTypes.func,
}

export default TableInput
