import LightBtn from 'components/global/Buttons/LightBtn'
import CustomTrash from 'components/global/icons/CustomTrash'
import React, { useCallback, useEffect, useState } from 'react'
// import { Comments } from 'DummyData/DummyData'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'
import { defaultProfileImg } from '_utils/default'
import moment from 'moment'
import config from 'config/config'
import Loader from 'components/global/Loader'
import InfinteScroll from 'react-infinite-scroll-component'

const UserDetailsProfile = ({ user }) => {

  const [memos, setMemos] = useState([])
  const [count, setCount] = useState(null)
  const [loading, setLoading] = useState(false)
  // const [page, setPage] = useState(0)

  const fetchMemos = useCallback(async () => {
    setLoading(true)
    if(user && user?.id) {
      axiosInstance.get(`/admin/user/memo/${user?.id}`, {
        params: {
          skip: 0,
          limit: 2000,
        }
      })
      .then(res => res.data)
      .then(data => {
        setMemos(data?.data?.memo)
        setCount(data?.data?._count?.memo)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    }
  }, [user])

  const deleteMemo = async (parentId, memoId) => {
    axiosInstance.delete(`/admin/user/memo/${memoId}`)
      .then(res => res.data)
      .then(data => setCount(data?.data?._count?.memo))
      .then(() => {
        if(parentId === memoId) {
          setMemos(prev => prev.filter(m => m?.id !== parentId))
        } else {
          setMemos(prev => {
            return prev.map(m => {
              if(m?.id !== parentId) {
                return {
                  ...m
                }
              }
              const replies = m?.replies?.filter(sub => sub?.id !== memoId)
              return {
                ...m,
                replies
              }
            })
          })
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchMemos()
  }, [fetchMemos])


  return (
    <div className='w-full h-[88vh] flex flex-col gap-2'>
      <h3 className='text-lg font-bold'>Comments on the Profile Memo { count !== null && count }</h3>
      <div id='scrollableDiv' className='shadow-customBtnShadow w-full h-full rounded-xl  p-4 overflow-y-auto max-h-full'>
        {
          memos?.length > 0 ? (
            <InfinteScroll
              dataLength={count}
              scrollableTarget='scrollableDiv'
              hasMore={memos.length < count}
              loader={<Loader loading={loading} />}
            >
              { memos?.map((memo, index) => <MemoCard key={index} memo={memo} onDelete={deleteMemo} />) }
            </InfinteScroll>
          ) : (<h6 className='underline text-sm'>No Records</h6>)
        }
      </div>
    </div>
  )
}

UserDetailsProfile.propTypes = {
  user: PropsTypes.object,
}

const MemoCard = ({ memo, onDelete }) => {

  return (
    <div className='w-full flex gap-x-2 p-3 border-b'>
      <img
        src={defaultProfileImg(memo?.user?.profileImage, 'user')}
        alt='user-profile'
        className='w-12 h-12 rounded-full'
      />
      <div className='flex flex-col w-full'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-col'>
            <h5 className='font-semibold'>{memo?.isOwn ? 'Me' : (memo?.user?.fullName || memo?.user?.username)}</h5>
            <h5 className='text-sm'>{memo?.memo}</h5>
            <h5 className='text-sm'>{moment(memo?.createdAt).format(config.DATE_FORMAT)}</h5>
          </div>
          <LightBtn className='!py-2' onClick={() => onDelete(memo?.id, memo?.id)} >
            <CustomTrash fillColor='#ff0000' />
          </LightBtn>
        </div>
        {
          memo?.replies?.map((subMemo, index) => <SubMemoCard key={index} parentId={memo?.id} subMemo={subMemo} onDelete={onDelete} />)
        }
      </div>
    </div>
  )
}

MemoCard.propTypes = {
  memo: PropsTypes.object,
  onDelete: PropsTypes.func
}

const SubMemoCard = ({ subMemo, parentId, onDelete }) => {
  return (
    <div className='w-full flex py-3 gap-x-2'>
      <img
        src={defaultProfileImg(subMemo?.user?.profileImage, 'user')}
        alt='user-profile'
        className='w-8 h-8 rounded-full'
      />
      <div className='flex flex-col w-full'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-col'>
            <h6 className='font-semibold text-sm'>
              {subMemo?.isOwn ? 'Me' : (subMemo?.user?.fullName || subMemo?.user?.username)}
            </h6>
            <h6 className='text-xs'>{subMemo?.memo}</h6>
            <h6 className='text-xs'>{moment(subMemo?.createdAt).format(config.DATE_FORMAT)}</h6>
          </div>
          <LightBtn className='!py-2' onClick={() => onDelete(parentId, subMemo?.id)}>
            <CustomTrash fillColor='#ff0000' />
          </LightBtn>
        </div>
      </div>
    </div>
  )
}

SubMemoCard.propTypes = {
  parentId: PropsTypes.string,
  subMemo: PropsTypes.object,
  onDelete: PropsTypes.func
}

export default UserDetailsProfile
