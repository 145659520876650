import React from 'react'
import PropTypes from 'prop-types'
import { ChevronDown } from 'lucide-react'

const CustomSelect = ({
  options,
  selectedValue,
  onChange,
  width,
  fullWidth = false,
}) => {
  const selectStyle = {
    width: width,
  }

  return (
    <div className={`relative inline-block text-left ${fullWidth && 'w-full'}`}>
      <select
        value={selectedValue}
        onChange={(e) => onChange(e.target.value)}
        style={fullWidth ? { width: '100%' } : selectStyle}
        className='block appearance-none bg-transparent border border-gray-300 text-black font-poppins font-medium text-sm h-10 pl-1 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3'
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className='hover:bg-[#00BDB2]'
          >
            {option.label}
          </option>
        ))}
      </select>

      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 font-poppins'>
        <ChevronDown size={20} className='transition-transform' />
        {/* <svg
          className='h-6 w-6'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
        >
          <path d='M10 12.293l-3.293-3.293a1 1 0 00-1.414 0 1 1 0 000 1.414l4 4a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L10 12.293z' />
        </svg> */}
      </div>
    </div>
  )
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default CustomSelect
