import { ReportData } from 'DummyData/DummyData'
import LightBtn from 'components/global/Buttons/LightBtn'
import { MoveLeft, PlusCircle } from 'lucide-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { FiHeart } from 'react-icons/fi'
import { MdMessage } from 'react-icons/md'
import RightCanvas from 'components/global/RightCanvas'
import PropsTypes from 'prop-types'
import { connectSocket, disconnectSocket, sendMessage } from '../../socket' // Import socket functions
import moment from 'moment'
import Loader from 'components/global/Loader'
import Input from 'components/global/Input'

const BannedHistory = ({ user }) => {
  const [openChatHistory, setOpenChatHistory] = useState(false)
  const [chatLoader, setChatLoader] = useState(false)
  const [messages, setMessages] = useState([])
  const currentPostId = useRef(null)
  const messageEndRef = useRef(null)
  const inputRef = useRef()

  // Function to scroll to the bottom of chat messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleOpenChatHistory = (postId) => {
    currentPostId.current = postId
    setOpenChatHistory(true)
    setChatLoader(true)
    connectSocket(
      postId,
      (message) => {
        setMessages((prevMessages) => [...prevMessages, message])
        scrollToBottom()
      },
      (oldMessages) => {
        setChatLoader(false)
        setMessages(oldMessages)
        scrollToBottom()
      }
    )
  }

  const chatClose = () => {
    setOpenChatHistory(false)
    disconnectSocket() // Disconnect socket when chat is closed
  }

  const handleSendMessage = () => {
    if (inputRef.current.value.trim()) {
      const messageData = {
        contentId: currentPostId.current,
        adminId: user?.id,
        adminFullName: user?.fullName || 'Admin',
        message: inputRef.current.value,
        createdAt: moment().format(),
      }

      sendMessage(messageData) // Use sendMessage from the socket.js
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    if (openChatHistory) {
      scrollToBottom()
    }
  }, [messages, openChatHistory])

  // Custom cell styles for DataTable
  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
  }

  const getStatusClass = (status) => {
    switch (status) {
      case 'pending':
        return 'statusPending'
      case 'approved':
        return 'statusApproved'
      case 'underReview':
        return 'statusUnderReview'
      case 'disapproved':
        return 'statusDisapproved'
      default:
        return ''
    }
  }

  const columns = [
    {
      name: 'Date & Time',
      selector: (row) => row['dateAndTime'],
      sortable: true,
      minWidth: '100px',
    },
    {
      name: 'Reason',
      sortable: true,
      minWidth: '50px',
      cell: (row) => <div>{row.reason}</div>,
    },
    {
      name: 'Target Data',
      sortable: true,
      minWidth: '150px',
      cell: (row) => (
        <div>
          {row?.TargetDataType === 'user' ? (
            <div className='flex p-2'>
              <img
                className='w-9 h-9 rounded-full'
                src='https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80'
                alt='userimage'
              />
              <div className='ml-1'>
                <h3 className='font-semibold text-xs text-black'>{row.name}</h3>
                <p className='font-normal underline underline-offset-2'>
                  {row.userName}
                </p>
                <p className='font-normal pt-1'>01/08/2023</p>
              </div>
            </div>
          ) : (
            <>
              <div className='flex p-1 gap-x-3'>
                <div className='flex-1 overflow-x-hidden aspect-card-img rounded-2xl bg-gradient-to-t from-card-img-gradient via-transparent to-transparent'>
                  <img
                    className='w-full h-full'
                    src='https://www.seiu1000.org/sites/main/files/main-images/camera_lense_0.jpeg'
                    alt='postimage'
                  />
                </div>

                {/* Right side icons  */}
                <div className='flex flex-col gap-y-2 items-center'>
                  <span className='flex flex-nowrap'>
                    <img
                      src='/assets/Icons/eye.svg'
                      alt='eye'
                      className='w-4'
                    />{' '}
                    &nbsp; <u className='text-md'>0</u>
                  </span>
                  <span className='flex flex-nowrap justify-center items-center'>
                    <FiHeart size={16} fill='#f00' color='#f00' /> &nbsp;
                    <u className='text-md'>0</u>
                  </span>
                  <span className='flex flex-nowrap'>
                    <img
                      src='/assets/Icons/bookmark.svg'
                      alt='eye'
                      className='w-4'
                    />{' '}
                    &nbsp; <u className='text-md'>0</u>
                  </span>
                  <span className='flex flex-nowrap'>
                    <img
                      src='/assets/Icons/message.svg'
                      alt='eye'
                      className='w-4'
                    />{' '}
                    &nbsp; <u className='text-md'>0</u>
                  </span>
                  <span className='flex flex-nowrap'>
                    <img
                      src='/assets/Icons/shared.svg'
                      alt='eye'
                      className='w-4'
                    />{' '}
                    &nbsp; <u className='text-md'>0</u>
                  </span>
                </div>
              </div>
              <div className='flex p-1'>
                <img
                  src='/assets/Icons/id.svg'
                  alt='idIcon'
                  className='w-4 h-4'
                />
                <p className='ml-1 underline underline-offset-2 text-ellipsis max-w-[106px] truncate'>
                  74387rhedbvbfv
                </p>
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (row) => row['status'],
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <div className={getStatusClass(row.status)}>{row.status}</div>
      ),
    },
    {
      name: 'Score',
      selector: (row) => row['score'],
      sortable: true,
      minWidth: '20px',
    },
    {
      name: 'Result',
      selector: (row) => row['result'],
      sortable: true,
      minWidth: '30px',
    },
    {
      name: 'PIC',
      selector: (row) => row['pic'],
      sortable: true,
      minWidth: '30px',
    },
    {
      name: 'Chat',
      sortable: true,
      minWidth: '10px',
      cell: (row) => (
        <div className='flex'>
          <MdMessage
            size={25}
            className='!text-text-dm-input cursor-pointer'
            onClick={() => handleOpenChatHistory(row.id)}
          />
        </div>
      ),
    },
  ]

  const goBack = () => {
    window.history.back()
  }

  return (
    <>
      <div className='md:!w-[956px] xl:!w-[1055px] 2xl:!w-[99%] DataTableMain'>
        <div className='flex gap-x-2 items-center  justify-between'>
          <LightBtn onClick={goBack}>
            <MoveLeft size={16} />
          </LightBtn>
          <h1 className='text-base font-bold'>Banned History </h1>
        </div>
        <div className='rounded-xl overflow-x-scroll h-full'>
          <DataTable
            columns={columns}
            data={ReportData}
            highlightOnHover
            selectableRows
            customStyles={customCellStyles}
            className='md:!w-[700px] xl:!w-[780px] 2xl:!w-[100%] DataTable'
          />
        </div>
      </div>

      {/* Chat history */}
      <RightCanvas isOpen={openChatHistory} onClose={chatClose}>
        <div className='h-screen flex flex-col overflow-hidden'>
          {' '}
          {/* Prevent outer scrolling */}
          {/* Sticky Header */}
          <div className='relative p-5 border-b sticky top-0 bg-white z-10'>
            {' '}
            {/* Sticky header */}
            <PlusCircle
              className='rotate-45 absolute left-4 top-5 cursor-pointer'
              onClick={chatClose}
            />
            <h1 className='text-center text-base font-bold'>Chat</h1>
          </div>
          {/* Chat messages */}
          <div className='flex flex-col justify-between h-full overflow-hidden'>
            <div className='p-5 overflow-y-auto flex-grow'>
              {' '}
              {/* Only this section scrolls */}
              {chatLoader ? (
                <Loader loading={chatLoader} />
              ) : (
                <Fragment>
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`p-4 shadow-customBtnShadow max-w-xs rounded-xl mt-3 clear-both max-w-[250px] ${
                        msg.adminId === user?.id ? 'float-right' : 'float-left'
                      }`}
                    >
                      <span className='w-full flex justify-between items-center gap-3'>
                        <h4 className='text-sm font-medium'>
                          {msg.adminFullName || 'Unknown'}
                        </h4>
                        <h5 className='text-xs font-light '>
                          {moment(msg.createdAt).format('HH:mm')}
                        </h5>
                      </span>
                      <div className='text-sm font-light max-w-[218px]'>
                        <p className='text-wrap break-all'>{msg.message}</p>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <div ref={messageEndRef} /> {/* Scroll to this div */}
            </div>

            {/* Sticky Input message */}
            <div className='p-5 sticky bottom-0 bg-white z-10'>
              {' '}
              {/* Sticky input */}
              <Input
                ref={inputRef}
                placeHolder='Start typing message...'
                inputClassName='bg-white'
                className='!p-1 !border-gray-400 !pl-5 !text-sm'
                rightIcon={
                  <button
                    onClick={handleSendMessage}
                    className='bg-text-dm-input shadow-buttonPrimary p-2 flex justify-center items-center rounded-xl'
                  >
                    <img src='/assets/svg/send-2.svg' alt='SendBtnIcon' />
                  </button>
                }
              />
            </div>
          </div>
        </div>
      </RightCanvas>
    </>
  )
}

BannedHistory.propTypes = {
  user: PropsTypes.object,
}

export default BannedHistory
