import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const FilterBox = ({ data, filteredColumn, setFilteredColumn }) => {
  const manageFilterData = (item) => {
    if (filteredColumn.includes(item)) {
      setFilteredColumn(filteredColumn.filter((x) => x !== item))
    } else {
      setFilteredColumn([...filteredColumn, item])
    }
  }

  const { leftColumns, rightColumns } = useMemo(() => {
    const leftColumns = data?.slice(1, 8)
    const rightColumns = data?.slice(8)
    return { leftColumns, rightColumns }
  }, [data])

  return (
    <div className='grid grid-cols-2'>
      <div className='grid grid-cols-1 grid-flow-row p-3 gap-3'>
        <span>
          <input
            type='checkbox'
            onChange={() => setFilteredColumn([])}
            checked={filteredColumn.length <= 0}
            name=''
            id=''
          />{' '}
          &nbsp; All
        </span>
        {leftColumns.map((item, index) => (
          <span key={index} className='flex'>
            <span>
              <input
                onChange={() => {
                  manageFilterData(item.selector)
                }}
                type='checkbox'
                name=''
                id=''
                checked={filteredColumn.includes(item.selector)}
              />
            </span>
            <span className='pl-2 font-normal text-xs'>
              {item.name.props.children[0]}
            </span>
          </span>
        ))}
      </div>
      <div className='grid grid-cols-1 grid-flow-row p-3 gap-3'>
        {rightColumns.map((item, index) => (
          <span key={index} className='flex'>
            <span>
              <input
                onChange={() => {
                  manageFilterData(item.selector)
                }}
                type='checkbox'
                name=''
                id=''
                checked={filteredColumn.includes(item.selector)}
              />
            </span>
            <span className='pl-2 font-normal text-xs'>
              {item.name.props.children[0]}
            </span>
          </span>
        ))}
      </div>
    </div>
  )
}

FilterBox.propTypes = {
  data: PropTypes.array.isRequired,
  filteredColumn: PropTypes.array.isRequired,
  setFilteredColumn: PropTypes.func.isRequired,
}

export default FilterBox
