import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

const GlobalPopper = ({ children, popperContent, popperOptions }) => {
    const [isPopperVisible, setIsPopperVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const popperRef = useRef(null);

    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        ...popperOptions,
    });

    const handleTogglePopper = () => {
        setIsPopperVisible(!isPopperVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popperRef.current && !popperRef.current.contains(event.target)) {
                setIsPopperVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (update) {
            update();
        }
    }, [update]);

    return (
        <div ref={popperRef}>
            {React.isValidElement(children)
                ? React.cloneElement(children, {
                    onClick: handleTogglePopper,
                    ref: setReferenceElement,
                    ...children.props,
                })
                : children}
            {isPopperVisible && (
                <div
                    className='z-50 border bg-white p-2 shadow-lg rounded-xl border-teal-400'
                    ref={setPopperElement}
                    style={{ ...styles.popper, visibility: isPopperVisible ? 'visible' : 'hidden' }}
                    {...attributes.popper}
                >
                    {/* Popper content */}
                    {popperContent}
                </div>
            )}
        </div>
    );
};

// Define PropTypes for the component
GlobalPopper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    popperContent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    popperOptions: PropTypes.object,
};

export default GlobalPopper;
