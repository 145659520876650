import clsx from 'clsx'
import { ChevronRight, PlusCircle } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import CustomTrash from '../icons/CustomTrash'
import PropsTypes from 'prop-types'
import { defaultProfileImg } from '_utils/default'
import moment from 'moment'
import axiosInstance from '_utils/axiosUtils'
import LoaderIcon from '../LoaderIcon'

const QnADrawer = ({ onClose, stats, questions, deleteQuestion }) => {

  const [selectedMemo, setSelectedMemo] = useState(null)
  const [answers, setAnswers] = useState([])
  const [answersCount, setAnswersCount] = useState(null)
  const [loading, setLoading] = useState(false)

  const startQuestionDeletion = async () => {
    setLoading(true)
    await deleteQuestion([selectedMemo?.id])
    setSelectedMemo(null)
    setLoading(false)
  }

  const deleteAnswer = async (ids) => {
    await axiosInstance.delete(`/admin/question/${selectedMemo?.id}/answers`, {
      data: { ids }
    })
      .then(res => res.data)
      .then(data => {
        setAnswersCount(data?.data?._count)
        setAnswers(prev => prev.filter(com => !ids?.includes(com?.id)))
      })
      .catch(err => console.log(err))
  }

  const fetchAnswers = async () => {
    await axiosInstance.get(`/admin/question/${selectedMemo?.id}/answers`)
      .then(res => res.data)
      .then(data => {
        setAnswers(data?.data?.answers)
        setAnswersCount(data?.data?._count)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (selectedMemo) {
      fetchAnswers()
    }
  }, [selectedMemo])

  return (
    <div
      className='h-screen flex-col overflow-hidden p-3 '
    >
      {/* Canvas header  */}
      <div className='flex gap-x-3 items-center border-b py-4 max-h-fit'>
        <PlusCircle className='rotate-45' size={20} onClick={(e) => {
          e.stopPropagation()
          onClose()
        }} />
        <div className='flex items-center gap-x-1'>
          <img
            src='/assets/Image/Ellipse 7495.png'
            alt='hashtagImg'
            style={{ height: '57px', width: '57px', borderRadius: '50%' }}
          />
          <div className='flex flex-col '>
            <h1 className='px-4 text-lg  font-bold'>#{stats?.name}</h1>
            <div className='flex flex-nowrap '>
              <h5 className='border-r px-4 text-sm font-normal text-black' style={{ borderColor: '#C4C4C4' }}>
                <span className='text-base font-medium' style={{ color: '#2D2D2D' }}>{stats?._count?.postTags}</span>
                &nbsp;Posts
              </h5>

              <h5 className='border-r px-4 text-sm font-normal text-black' style={{ borderColor: '#C4C4C4' }}>
                <span className='text-base font-medium' style={{ color: '#2D2D2D' }} >{stats?._count?.followers}</span>
                &nbsp;Followers
              </h5>
              <h5 className='border-r px-4 text-sm font-normal text-black' style={{ borderColor: '#C4C4C4' }}>
                <span className='text-base font-medium' style={{ color: '#2D2D2D' }} >{stats?._count?.memo}</span>
                &nbsp;Questions
              </h5>
              <h5 className='px-4 text-sm font-normal text-black'>
                <span className='text-base font-medium' style={{ color: '#2D2D2D' }} >{stats?._count?.answer}</span>
                &nbsp;Answers
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex h-full py-3'>
        <div className='flex-1 flex flex-col gap-y-3 pr-2 pb-3 max-h-[calc(100vh-112px)] overflow-y-auto memoScrollBar'>
          {
            questions?.map((ques, index) => (
              <QuestionCard
                key={index}
                setSelected={(_id) => setSelectedMemo(_id)}
                isSelected={Boolean(selectedMemo?.id === ques?.id)}
                question={ques?.title}
                description={ques?.description}
                creation={moment(ques?.createdAt).format('DD/MM/YYYY')}
                answer_count={ques?._count?.comments}
                answers={ques?.comments}
                profile={ques?.users?.profileImage}
                value={ques}
              />
            ))
          }
        </div>
        {
          selectedMemo && (
            <div className='w-3/5 flex flex-col pl-2'>
              <div className='shadow-customBtnShadow p-3 rounded-xl flex flex-col gap-y-2 '>
                <div className='flex gap-x-2'>
                  <img
                    src={defaultProfileImg(selectedMemo?.users?.profileImage, 'user')}
                    className='w-10 h-10 rounded-full'
                    alt='memoImg'
                  />
                  <h6 className='text-base font-semibold'>
                    {selectedMemo?.title}
                  </h6>
                </div>
                <div>
                  <h6 className='text-xs font-normal'>
                    {
                      selectedMemo?.description
                    }
                  </h6>
                  {/* date || comments */}
                  <div className=' flex justify-between items-center'>
                    <h6 className='text-xxs font-light'>{moment(selectedMemo?.createdAt).format('DD/MM/YYYY')}</h6>
                    <button
                      className='p-3 rounded-xl shadow-customBtnShadow'
                      onClick={startQuestionDeletion}
                    >
                      {
                        !loading ? <CustomTrash size={14} fillColor='#f00' /> : <div className='flex justify-center items-center'><LoaderIcon className='!w-5 !h-5' /></div>
                      }
                    </button>
                  </div>
                </div>
              </div>
              <h1 className='text-base font-semibold py-1'>Answers ({answersCount})</h1>
              {/* Comments */}
              <div className='flex flex-col gap-y-3'>
                {
                  answers?.map((ans, index) => (
                    <AnswerCard
                      key={index}
                      value={ans}
                      profile={defaultProfileImg(ans?.user?.profileImage, 'user')}
                      description={ans?.comment}
                      isBest={ans?.isBest}
                      creation={ans?.createdAt}
                      deleteAnswer={deleteAnswer}
                    />
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

QnADrawer.propTypes = {
  hashId: PropsTypes.string,
  deleteQuestion: PropsTypes.func,
  onClose: PropsTypes.func,
  stats: PropsTypes.object,
  questions: PropsTypes.array
}


const QuestionCard = ({
  isSelected,
  question,
  description,
  creation,
  profile,
  answer_count,
  answers,
  setSelected,
  value,
}) => {
  return (
    <div
      className={clsx(
        'shadow-customBtnShadow p-3 rounded-xl flex gap-x-2 relative transition-shadow w-full cursor-pointer',
        isSelected && '!shadow-active-btn border border-text-dm-input bg-red-100'
      )}
      onClick={() => {
        if (isSelected) {
          setSelected(null);
        } else {
          setSelected(value);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          if (isSelected) {
            setSelected(null);
          } else {
            setSelected(value);
          }
        }
      }}
      role='button'
      tabIndex={0}
    >
      <div style={{ width: '35px' }}>
        <img
          src={defaultProfileImg(profile, 'user')}
          style={{ height: '30px', width: '30px', borderRadius: '50%' }}
          alt='memoImg'
        />
      </div>
      <div className='w-full'>
        <p className='text-xs font-semibold'>
          {question}
        </p>
        <p className='text-xs font-normal'>
          {description?.substring(0, 30) + '...'}
        </p>
        <div className=' flex justify-between items-center'>
          <h6 className='text-xxs font-light'>{creation}</h6>
          <div className='flex flex-nowrap gap-x-1 py-1'>
            <span className='flex flex-nowrap'>
              {
                answers?.map((ans, index) => (
                  index === 1 ?
                    <img
                      key={index}
                      src={defaultProfileImg(ans, 'user')}
                      alt='memoCommentImg'
                      className='w-4 h-4 rounded-full z-3'
                      style={{ marginLeft: '-5px' }}
                    />
                    :
                    <img
                      key={index}
                      src={defaultProfileImg(ans, 'user')}
                      alt='memoCommentImg'
                      className='w-4 h-4 rounded-full z-10'
                    />
                ))
              }
            </span>
            <button
              className='underline text-text-dm-input text-xs font-medium' >
              {answer_count} answers
            </button>
          </div>
        </div>
      </div>
      <ChevronRight
        className='absolute right-1 top-1/2 transform -translate-y-full'
        size={12}
      />
    </div>
  )
}

QuestionCard.propTypes = {
  isSelected: PropsTypes.bool,
  question: PropsTypes.string,
  description: PropsTypes.string,
  creation: PropsTypes.string,
  profile: PropsTypes.string,
  answer_count: PropsTypes.number,
  answers: PropsTypes.array,
  setSelected: PropsTypes.func,
  value: PropsTypes.object,
}

const AnswerCard = ({
  profile,
  description,
  isBest,
  creation,
  value,
  deleteAnswer,
}) => {
  const [more, setMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const startDeletion = async () => {
    setLoading(true)
    await deleteAnswer([value?.id])
    setLoading(false)
  }
  return (
    <div className='shadow-customBtnShadow p-3 rounded-xl flex gap-2 w-full '>
      <img
        src={profile}
        className='w-8 h-8 rounded-full'
        alt='memoImg'
      />
      <div className='flex flex-col justify-between flex-grow w-11/12'>
        <div className='flex items-start'>
          <h6 className='text-xs font-normal'>
            {more || description?.length <= 30 ? description : description?.substring(0, 30) + '...'}
            {
              description?.length > 30 && (
                <button
                  className='text-text-dm-input text-xs font-normal underline'
                  onClick={() => {
                    if (more) {
                      setMore(false)
                    } else {
                      setMore(true)
                    }
                  }}
                >
                  {more ? 'see less' : 'see more'}
                </button>
              )
            }
          </h6>
          {
            isBest && (
              <img
                src='/assets/svg/comment-badge.svg'
                alt='Comment Badge'
              />
            )
          }
        </div>
        <div className='flex justify-between items-center'>
          <h6 className='text-xxs font-light'>{moment(creation).format('DD/MM/YYYY')}</h6>
          <button
            className='p-3 rounded-xl shadow-customBtnShadow'
            onClick={startDeletion}
          >
            {
              !loading ? <CustomTrash size={14} fillColor='#f00' /> : <div className='flex justify-center items-center'><LoaderIcon className='!w-5 !h-5' /></div>
            }
          </button>
        </div>
      </div>
    </div>
  )
}

AnswerCard.propTypes = {
  value: PropsTypes.object,
  profile: PropsTypes.string,
  description: PropsTypes.string,
  isBest: PropsTypes.bool,
  creation: PropsTypes.string,
  deleteAnswer: PropsTypes.func
}

export default QnADrawer