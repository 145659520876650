import StaffState from 'components/global/Badge/StaffState'
import Input from 'components/global/Input'
import PropsTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Page from 'components/global/Page'
import Popper from 'components/global/Popper'
import { ChevronDown, PenLine, ChevronUp } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import AdminHeader from 'components/admin/AdminHeader'
import useStaff from '_hooks/useStaff'

const AdminTeamList = () => {
  const teamFilterRef = useRef()
  const [openTeamFilter, setOpenTeamFilter] = useState(false)
  const [teamIds, setTeamIds] = useState([])
  const [search, setSearch] = useState({})

  const handleChange = (e) => {
    if (e.target.checked) {
      setTeamIds((prev) => [...prev, e.target.value])
    } else {
      setTeamIds((prev) => prev.filter((id) => id !== e.target.value))
    }
  }

  const {
    fetchTeams,
    fetchPositions,
    fetchGrade,
    team: { rows },
    filterArray,
  } = useStaff()

  useEffect(() => {
    fetchTeams()
    fetchPositions()
    fetchGrade()
  }, [])

  const handleClick = () => {
    fetchTeams({ teamIds, search })
  }

  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader noSearch={true} />
      </div>
      <div className='flex flex-col gap-y-3 h-[88vh]'>
        {/* filter || search  || Save Button */}
        <div className='flex justify-end gap-x-3 items-start h-fit'>
          <button
            ref={teamFilterRef}
            onClick={() => setOpenTeamFilter(true)}
            className=' py-2.5 px-3 gap-x-4 rounded-xl shadow-customBtnShadow flex justify-between text-xs font-medium items-center'
          >
            {teamIds?.length === 0
              ? 'All'
              : filterArray
                  ?.filter((team) => teamIds.includes(team?.id))
                  ?.map((team) => team?.name)
                  ?.join(', ')}
            <ChevronDown
              size={16}
              className={clsx(
                'transition-transform',
                openTeamFilter && 'rotate-180'
              )}
            />
          </button>

          {/* Team Filter Popper  */}
          <Popper
            refElement={teamFilterRef.current}
            open={openTeamFilter}
            onClose={() => setOpenTeamFilter(false)}
            popperOptions={{
              strategy: 'fixed',
              placement: 'bottom-end',
            }}
          >
            <div className='bg-universal py-3 rounded-xl  border border-teal-500 overflow-hidden px-1 mt-[-15px]'>
              <div className='overflow-y-auto memoScrollBar flex flex-col items-start justify-start gap-y-3 max-h-64  pr-3 pl-2 '>
                <span className='text-xs font-semibold flex items-center gap-x-2'>
                  <input
                    type='checkbox'
                    onChange={() => setTeamIds([])}
                    checked={teamIds?.length === 0}
                  />{' '}
                  All
                </span>
                {filterArray?.map((team) => {
                  return (
                    <span
                      className='text-xs font-semibold flex items-center gap-x-2'
                      key={team?.id}
                    >
                      <input
                        type='checkbox'
                        value={team?.id}
                        onChange={handleChange}
                        checked={teamIds.includes(team?.id)}
                      />{' '}
                      {team?.name}
                    </span>
                  )
                })}
              </div>
            </div>
          </Popper>

          <Input
            className='!border-gray-300 !py-1.5'
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <button
            className='text-base font-semibold shadow-buttonPrimary rounded-xl px-6 py-2 bg-text-dm-input text-white'
            onClick={handleClick}
          >
            Ok
          </button>
        </div>
        {/* team list */}
        <div className='grid grid-cols-3 grid-flow-row gap-6 max-h-full h-full overflow-y-auto'>
          {/* teamCard */}
          {rows?.map((row) => {
            return <TeamCard key={row?.id} team={row} />
          })}
        </div>
      </div>
    </Page>
  )
}

const TeamCard = ({ team }) => {
  const [isEditing, setIsEditing] = useState(false)
  const {
    position: { rows: postitonRows },
    grade: { rows: gradeRows },
    editMember,
  } = useStaff()

  const [payload, setPayload] = useState([])

  useEffect(() => {
    let tempArray = []
    if (isEditing) {
      tempArray = team?.members?.map((mem) => {
        return {
          memberId: mem?.id,
          gradeId: mem?.grade?.id,
          positionId: mem?.position?.id,
          teamId: team?.id,
          countryId: mem?.country?.id,
        }
      })
    } else {
      tempArray = []
    }
    setPayload(tempArray)
  }, [isEditing, team])

  const handleEditMemeber = () => {
    editMember(payload)
  }

  return (
    <span>
      <Accordion
        className={clsx(
          ' !rounded-2xl !bg-universal',
          isEditing && '!shadow-active-btn !border !border-text-dm-input',
          !isEditing && '!shadow-customBtnShadow'
        )}
      >
        <AccordionSummary
          expandIcon={!isEditing && <ChevronDown className='ml-5 mr-5' />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className=' text-base font-semibold flex justify-between items-center w-full !h-10 !bg-hovered-bg !rounded-2xl'
          // disabled={team?.members?.length === 0}
        >
          <div className='w-full flex justify-between items-center px-5'>
            <h5 className='text-base font-semibold text-black'>{team?.name}</h5>
            {isEditing && (
              <div className='flex gap-x-3'>
                <button
                  className='py-2 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold w-[76px]'
                  onClick={(event) => {
                    event.stopPropagation()
                    handleEditMemeber(team?.id)
                    setIsEditing(false)
                  }}
                >
                  Save
                </button>
                <button
                  className='py-2 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'
                  onClick={(event) => {
                    event.stopPropagation()
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='flex p-2 px-5'>
            <div className='w-full'>
              {team?.members?.length !== 0 && (
                <table className='w-full'>
                  <tbody>
                    {team?.members?.map((member) => {
                      return (
                        <tr key={member?.id}>
                          <td className='max-w-fit py-2 text-center'>
                            <h5 className='text-sm font-semibold underline flex items-center gap-x-2 text-black'>
                              <StaffState type='online' /> {member?.fullName}
                            </h5>
                          </td>
                          <td className='py-2 text-center text-black'>
                            <EditableText
                              text={member?.grade?.name}
                              isEditing={isEditing}
                              options={gradeRows?.map((grade) => {
                                return { label: grade?.name, value: grade?.id }
                              })}
                              onChange={(option) => {
                                let tempArray = payload?.map((item) => item)
                                setPayload(
                                  tempArray.map((item) => {
                                    if (item?.memberId === member?.id) {
                                      return {
                                        ...item,
                                        gradeId: option?.value,
                                      }
                                    } else {
                                      return { ...item }
                                    }
                                  })
                                )
                              }}
                            />
                          </td>
                          <td className='py-2 text-center text-black'>
                            <EditableText
                              text={member?.position?.name}
                              isEditing={isEditing}
                              options={postitonRows?.map((pos) => {
                                return { label: pos?.name, value: pos?.id }
                              })}
                              onChange={(option) => {
                                let tempArray = payload?.map((item) => item)
                                setPayload(
                                  tempArray.map((item) => {
                                    if (item?.memberId === member?.id) {
                                      return {
                                        ...item,
                                        positionId: option?.value,
                                      }
                                    } else {
                                      return { ...item }
                                    }
                                  })
                                )
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {team?.members?.length === 0 && (
                <p className='text-center'>No Team Memebers Added</p>
              )}
            </div>

            {team?.members?.length > 0 && (
              <div className='w-fit'>
                {!isEditing && (
                  <button
                    className='p-2 rounded-xl bg-text-dm-input shadow-buttonPrimary'
                    onClick={() => setIsEditing(true)}
                  >
                    <PenLine size={14} color='#fff' />
                  </button>
                )}
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </span>
  )
}

TeamCard.propTypes = {
  team: PropsTypes.object,
}

// function EditableText({ text, options, isEditing, ...args }) {
//   return (
//     <div>
//       {isEditing ? (
//         <select
//           {...args}
//           className='text-sm font-normal py-2 px-4 rounded-xl border border-gray-300 outline-none bg-universal'
//         >
//           {options.map((option, index) => (
//             <option key={index} value={option} >
//               {option}
//             </option>
//           ))}
//         </select>
//       ) : (
//         <h5 className='text-sm font-normal py-2.5 px-4 '>{text}</h5>
//       )}
//     </div>
//   )
// }

function EditableText({ text, options, isEditing, onChange }) {
  const [isOpen, setIsOpen] = useState(false)
  // const [selectedOption, setSelectedOption] = useState(text)
  const [selectedOption, setSelectedOption] = useState(
    options?.find((opt) => opt?.label === text)
  )

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = (option) => {
    setSelectedOption(option)
    onChange(option)
    toggleDropdown()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      toggleDropdown()
    }
  }

  return (
    <div>
      {isEditing ? (
        <div className='relative flex'>
          <div
            className='text-sm flex font-normal py-2 pl-4 pr-2 rounded-xl border border-gray-300 outline-none bg-universal cursor-pointer'
            onClick={() => toggleDropdown()}
            onKeyDown={handleKeyDown}
            role='button'
            tabIndex={0}
          >
            {selectedOption?.label}{' '}
            <span className='pl-2'>
              {isOpen ? <ChevronUp size={25} /> : <ChevronDown size={25} />}
            </span>
          </div>
          {isOpen && (
            <div className='absolute right-6 top-11 mt-1 w-full bg-white rounded-xl border border-gray-300 z-10 '>
              {options.map((option, index) => (
                <div
                  key={index}
                  className='py-1  cursor-pointer hover:bg-gray-100'
                  onClick={() => handleOptionSelect(option)}
                  onKeyDown={handleKeyDown}
                  role='button'
                  tabIndex={0}
                >
                  {option?.label}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <h5 className='text-sm font-normal py-2.5 px-4'>{text}</h5>
      )}
    </div>
  )
}

EditableText.propTypes = {
  text: PropsTypes.string,
  options: PropsTypes.array,
  setIsEditing: PropsTypes.func,
  isEditing: PropsTypes.bool,
  onChange: PropsTypes.func,
}

export default AdminTeamList
