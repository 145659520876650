import React from 'react'
import PropTypes from 'prop-types'

const LocationPin = ({ number, color }) => {
  return (
    <div className='relative'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-5 w-auto text-red-500'
        fill='none'
        viewBox='0 0 17 24'
      >
        <path
          d='M17 9.02335C17 13.1309 11.8203 20.4435 9.54922 23.4607C9.00469 24.1798 7.99531 24.1798 7.45078 23.4607C5.17969 20.4435 0 13.1309 0 9.02335C0 4.04171 3.80729 0 8.5 0C13.1927 0 17 4.04171 17 9.02335Z'
          fill={color}
        />
      </svg>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xxs w-8 h-8 rounded-full flex items-center justify-center'>
        {number}
      </div>
    </div>
  )
}

LocationPin.propTypes = {
  number: PropTypes.number,
  color: PropTypes.color,
}

export default LocationPin
