import config from 'config/config'

const defaultValue = {
  hashtag: config.DEFAULT_HASHTAG_IMG,
  user: config.DEFAULT_PROFILE_IMG,
  post: config.DEFAULT_POST_IMG
}
export const defaultProfileImg = (url, type) => {
  const _def = defaultValue[type]
  if (!url) url = _def
  return `${config.FTP_URL}${url}`
}

export const checkImageFile = (media) => {
  const _file = media.filter(value => value.fileType.includes('image'))

  if (_file && _file.length) return _file[0]?.fileURL
  return ''
}