export const dev = {
  MODE: 'development',
  TOKEN_KEY: 'my-indent',
  BASE_URL: 'http://localhost:8080/api/v1',
  SOCKET_URL: process.env.REACT_APP_HASHMEMO_SOCKET_API,
  FTP_URL:
    process.env.REACT_APP_FTP_URL ||
    'https://hashmemo.s3.ap-northeast-1.amazonaws.com/',
  DEFAULT_HASHTAG_IMG:
    process.env.REACT_APP_HASHTAG_PROFILE || 'profileImage/4WMqs7DgpB',
  DEFAULT_PROFILE_IMG:
    process.env.REACT_APP_USER_PROFILE || 'profileImage/WvvU2PKhzD',
  DEFAULT_POST_IMG:
    process.env.REACT_APP_EMPTY_POST_IMG || 'postMedia/Lm5wvQ18PD',
  DATE_FORMAT: 'MM/DD/YYYY',
  CLIENT_BASE_URL: 'http://localhost:3000',
}
