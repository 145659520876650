import React from 'react'

const Page404 = () => {
  return (
    <div className='w-full h-screen grid place-items-center place-content-center'>
      <img src='/assets/svg/404.svg ' alt='404' />
    </div>
  )
}

export default Page404
