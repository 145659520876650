import Page from 'components/global/Page'
import React, { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import AdminHeader from './AdminHeader'
import LightBtn from 'components/global/Buttons/LightBtn'
import useSettings from '_hooks/useSettings'
import { useNavigate } from 'react-router-dom'

const StaffReport = () => {
  const navigate = useNavigate()
  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
  }

  const { staffReport, fetchStaffReport } = useSettings()

  useEffect(() => {
    fetchStaffReport()
  }, [])

  const columns = [
    {
      name: 'Name',
      sortable: true,
      minWidth: '20px',
      cell: (row) => (
        <p className='underline font-bold text-black'>{row.name}</p>
      ),
    },
    {
      name: 'Team',
      sortable: true,
      minWidth: '20px',
      cell: (row) => <p className='font-semibold text-black'>{row.teamName}</p>,
    },
    {
      name: 'Country',
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <p className='font-semibold text-black'>{row.countryName}</p>
      ),
    },
    {
      name: 'Total Working Days',
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <p className='font-semibold text-black'>{row.totalWorkingDays}</p>
      ),
    },
    {
      name: 'Total Break Time',
      sortable: true,
      minWidth: '20px',
      cell: (row) => (
        <p className='font-semibold text-black'>{row.totalBreakTime} mins</p>
      ),
    },
    {
      name: 'Total Working Time',
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <p className='font-semibold text-black'>{row.totalWorkingTime} mins</p>
      ),
    },
    {
      name: 'Details',
      sortable: true,
      minWidth: '10px',
      cell: (row) => (
        <button className='flex' onClick={() => navigate(`${row.id}`)}>
          <img
            src='/assets/Icons/clipboard-import.svg'
            alt='eye'
            className='h-5 w-6'
          />
        </button>
      ),
    },
  ]

  return (
    <Page>
      <div id='header' className='w-full'>
        <AdminHeader />
      </div>
      <div className='w-full flex justify-between px-5'>
        <h1 className='text-xl'>
          <span className='font-semibold'>Results </span>
          {/* {_users.length} */}
        </h1>
        <LightBtn className='font-medium w-full mb-5 text-sm px-6'>
          <div className='flex flex-row items-center gap-2'>
            <img src='/assets/svg/csvadmin.svg' alt='Csv' className='w-6 h-6' />
            <span>Download as CSV</span>
          </div>
        </LightBtn>
      </div>
      <div className='rounded-xl w-full max-w-full h-full'>
        <DataTable
          columns={columns}
          data={staffReport}
          highlightOnHover
          selectableRows
          customStyles={customCellStyles}
          className='custom-data-table'
        />
      </div>
    </Page>
  )
}

export default StaffReport
