import LightBtn from 'components/global/Buttons/LightBtn'
import Input from 'components/global/Input'
import Popper from 'components/global/Popper'
import { SlidersHorizontal } from 'lucide-react'

import React, { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import StaffListFilter from './StaffListFilter'
import PropsTypes from 'prop-types'
import useStaff from '_hooks/useStaff'

const AdminHeader = ({ noSearch = false }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const popperRef = useRef()
  const [filterOpen, setFilterOpen] = useState(false)
  const { search, setSearch } = useStaff()

  //////// Input disable ////////
  const pathSegments = location.pathname
    .split('/')
    .filter((segment) => segment !== '')
  const disableCondition =
    pathSegments.length > 2 &&
    pathSegments[0].toLowerCase() === 'admin' &&
    pathSegments[1].toLowerCase() === 'staff-list'

  return (
    <div id='header' className='w-full flex gap-x-2'>
      <LightBtn
        className='text-xs font-medium !py-2.5'
        onClick={() => navigate('/admin/staff-list')}
        active={location.pathname.includes('/admin/staff-list')}
      >
        Staff List
      </LightBtn>
      <LightBtn
        className='text-xs font-medium !py-2.5'
        onClick={() => navigate('/admin/team-list')}
        active={location.pathname.includes('/admin/team-list')}
      >
        Team List
      </LightBtn>
      <LightBtn
        className='text-xs font-medium !py-2.5'
        onClick={() => navigate('/admin/staff-report')}
        active={location.pathname.includes('/admin/staff-report')}
      >
        Staff Report
      </LightBtn>
      <LightBtn
        className='text-xs font-medium !py-2.5'
        onClick={() => navigate('/admin/general-Settings')}
        active={location.pathname.includes('/admin/general-Settings')}
      >
        General Settings
      </LightBtn>
      <LightBtn
        className='text-xs font-medium !py-2.5'
        onClick={() => navigate('/admin/authorization')}
        active={location.pathname.includes('/admin/authorization')}
      >
        Authorization
      </LightBtn>
      {!noSearch && (
        <Input
          placeHolder='Search...'
          className='flex-grow !border-gray-300 !rounded-xl !text-sm font-normal !py-1.5'
          leftIcon={
            <img
              src='/assets/svg/Search2.svg'
              alt='SearchIcon'
              className='w-6 h-6'
            />
          }
          value={search}
          disabled={disableCondition}
          onChange={(e) => setSearch(e.target.value)}
          rightIcon={
            <div>
              <SlidersHorizontal
                size={20}
                ref={popperRef}
                onClick={() => setFilterOpen(true)}
                style={{ color: filterOpen ? '#00BDB2' : '' }}
              />
              <Popper
                refElement={popperRef.current}
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
                popperOptions={{
                  strategy: 'fixed',
                  placement: 'bottom-end',
                }}
              >
                <StaffListFilter onClose={() => setFilterOpen(false)} />
              </Popper>
            </div>
          }
        />
      )}
    </div>
  )
}

AdminHeader.propTypes = {
  noSearch: PropsTypes.bool,
}

export default AdminHeader
