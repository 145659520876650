const getYYYYMMDDformat = (dateString) => {
  const dateObj = new Date(dateString)

  // Extract the year, month, and day
  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0') // Months are 0-based, so +1
  const day = String(dateObj.getDate()).padStart(2, '0')

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}T00:00:00.000Z`
  return formattedDate
}

export default getYYYYMMDDformat
