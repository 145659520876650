import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MoveLeft, MoveRight } from 'lucide-react'
import clsx from 'clsx'
import { defaultProfileImg } from '_utils/default'

const ImageSlider = ({ imageUrls, className, VideoPhoto }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const imageCount = imageUrls.length

  const goToPrevImage = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + imageCount) % imageCount)
  }

  const goToNextImage = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % imageCount)
  }

  const RenderDots = ({ imageCount }) => {
    const dots = []
    for (let i = 0; i < imageCount; i++) {
      dots.push(
        <button
          key={i}
          className={clsx(
            'dot rounded-full w-2 h-2',
            i === activeIndex ? 'bg-white' : 'bg-gray-400'
          )}
          onClick={() => setActiveIndex(i)}
        ></button>
      )
    }
    return dots
  }
  RenderDots.PropTypes = {
    imageCount: PropTypes.number,
  }

  return (
    <div className={clsx('relative', className)}>
      {/* {children[activeIndex]} */}
      {
        VideoPhoto === 'video' && (
          <div className='w-2/3 h-full overflow-hidden m-auto' >
            <video >
              <source src={defaultProfileImg(imageUrls[activeIndex])} type='video/mp4' />
              <track kind='captions' src='' label='' />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
      {
        VideoPhoto === 'Photo' && (
          <img
            className='h-full w-2/3 object-contain m-auto rounded-lg'
            src={defaultProfileImg(imageUrls[activeIndex], 'post')}
            alt='sliderImage'
          />
        )
      }
      <span className='absolute right-2 bg-universal top-2 shadow-customBtnShadow text-xs p-1 px-3 rounded-xl'>
        {activeIndex + 1}/{imageCount}
      </span>
      <div className='absolute w-full bottom-1 flex items-center justify-between py-1 px-2'>
        <button
          className='flex items-center text-xs font-medium bg-universal py-1 px-3 shadow-customBtnShadow rounded-2xl'
          onClick={goToPrevImage}
        >
          <MoveLeft size={12} />
          &nbsp; Prev
        </button>
        <div className='dots flex gap-2'>
          <RenderDots imageCount={imageCount} />
        </div>
        <button
          className='flex items-center text-xs font-medium bg-universal py-1 px-3 shadow-customBtnShadow rounded-2xl'
          onClick={goToNextImage}
        >
          Next&nbsp; <MoveRight size={12} />
        </button>
      </div>
    </div>
  )
}

ImageSlider.propTypes = {
  VideoPhoto: PropTypes.string,
  className: PropTypes.string,
  imageUrls: PropTypes.array,
}

export default ImageSlider
