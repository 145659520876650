import React, { useEffect, useState } from 'react'
import GlobalAccordion from 'components/global/GlobalAccordion'
import StaffState from 'components/global/Badge/StaffState'
import { useLocation } from 'react-router-dom'
import PropsTypes from 'prop-types'
import useStaff from '_hooks/useStaff'

const StaffListFilter = ({ onClose }) => {
  const location = useLocation()
  const isStaffReportPage = location.pathname.includes('/admin/staff-report')
  const isStaffListPage = location.pathname.includes('/admin/staff-list')
  const isStaffTeamPage = location.pathname.includes('/admin/team-list')

  const {
    team: { rows: teamRows },
    position: { rows: positionRows },
    grade: { rows: gradeRows },
    country: { rows: countryRows },
  } = useStaff()

  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const [country, setCountry] = useState({
    All: false,
    Japan: false,
    'Except Japan': false,
  })

  const [team, setTeam] = useState({
    'Team A': false,
    'Team B': false,
    'Team C': false,
    'Team D': false,
    'Team E': false,
  })

  useEffect(() => {
    let tempObj = {}
    teamRows?.forEach((team) => {
      tempObj = {
        ...tempObj,
        [team?.name]: false,
      }
    })
    setTeam(tempObj)
  }, [teamRows])

  useEffect(() => {
    let tempObj = {}
    positionRows?.forEach((team) => {
      tempObj = {
        ...tempObj,
        [team?.name]: false,
      }
    })
    setPosition(tempObj)
  }, [positionRows])

  useEffect(() => {
    let tempObj = {}
    gradeRows?.forEach((team) => {
      tempObj = {
        ...tempObj,
        [team?.name]: false,
      }
    })
    setGrade(tempObj)
  }, [gradeRows])

  useEffect(() => {
    let tempObj = {
      All: false,
      'Except Japan': false,
    }
    countryRows?.forEach((team) => {
      tempObj = {
        ...tempObj,
        [team?.name]: false,
      }
    })
    setCountry(tempObj)
  }, [countryRows])

  // const [staffState, setStaffState] = useState({
  //   'Active': false,
  //   'Meeting': false,
  //   'Away': false,
  //   'Logout': false,
  // });
  const [staffState, setStaffState] = useState({
    Active: { check: false, type: 'online' },
    Meeting: { check: false, type: 'meeting' },
    Away: { check: false, type: 'away' },
    Logout: { check: false, type: 'logout' },
  })

  const [grade, setGrade] = useState({
    '1st': false,
    '2nd': false,
    '3rd': false,
  })

  const [position, setPosition] = useState({
    Manager: false,
    Leader: false,
    Staff: false,
  })

  const handleCountryChange = (event) => {
    const { name, checked } = event.target
    setCountry((prevCountry) => ({
      ...prevCountry,
      [name]: checked,
    }))
  }

  const handleTeamChange = (event) => {
    const { name, checked } = event.target
    setTeam((prevTeam) => ({
      ...prevTeam,
      [name]: checked,
    }))
  }

  const handleApplyFilter = () => {
    // console.log('ReportFilter From Date:', fromDate);
    // console.log('ReportFilter To Date:', toDate);

    const checkedCountry = []
    for (const [key, value] of Object.entries(country)) {
      if (value) {
        checkedCountry.push(key)
      }
    }
    // console.log('country checked is =', checkedCountry);

    const checkedTeam = []
    for (const [key, value] of Object.entries(team)) {
      if (value) {
        checkedTeam.push(key)
      }
    }
    // console.log('team checked is =', checkedTeam);
  }

  const handleStaffStateChange = (event) => {
    const { name, checked } = event.target
    setStaffState((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], check: checked },
    }))
  }

  const handleGradeChange = (event) => {
    const { name, checked } = event.target
    setGrade((prevGrade) => ({
      ...prevGrade,
      [name]: checked,
    }))
  }

  const handlePositionChange = (event) => {
    const { name, checked } = event.target
    setPosition((prevPosition) => ({
      ...prevPosition,
      [name]: checked,
    }))
  }

  const handleApplyFilter2 = () => {
    const checkedStaffState = []
    for (const [key, value] of Object.entries(staffState)) {
      if (value.check) {
        checkedStaffState.push(key)
      }
    }

    const checkedGrade = []
    for (const [key, value] of Object.entries(grade)) {
      if (value) {
        checkedGrade.push(key)
      }
    }

    const checkedTeam = []
    for (const [key, value] of Object.entries(team)) {
      if (value) {
        checkedTeam.push(key)
      }
    }

    const checkedPosition = []
    for (const [key, value] of Object.entries(position)) {
      if (value) {
        checkedPosition.push(key)
      }
    }

    const checkedCountry = []
    for (const [key, value] of Object.entries(country)) {
      if (value) {
        checkedCountry.push(key)
      }
    }
  }

  return (
    <div className='border-text-dm-input  rounded-xl p-2 bg-universal w-64 max-h-[80vh] overflow-y-auto border'>
      {isStaffReportPage && (
        <>
          <div className='w-auto pb-3'>
            <label className='block relative'>
              <span className='block text-sm font-semibold text-gray-800'>
                From:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                placeholder='MM/DD/YYYY'
                type='date'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                style={{ height: '20px', width: '20px' }}
              />
            </label>

            <label className='block relative pt-2'>
              <span className='block text-sm font-semibold text-gray-800'>
                To:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 cursor-pointer outline-none py-2 px-4 w-full rounded-2xl no-calendar '
                placeholder='MM/DD/YYYY'
                type='date'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2'
                style={{ height: '20px', width: '20px' }}
              />
            </label>
          </div>

          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Country</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(country).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleCountryChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          {/* Team */}
          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Team</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(team).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleTeamChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          <div className='grid grid-cols-2 gap-x-2 py-4'>
            <button
              onClick={handleApplyFilter}
              className='py-2 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold'
            >
              Apply
            </button>
            <button
              onClick={onClose}
              className='py-2 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {(isStaffListPage || isStaffTeamPage) && (
        <>
          {/* Staff State */}
          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Staff State</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(staffState).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value.check}
                      onChange={handleStaffStateChange}
                    />{' '}
                    <StaffState type={value.type} size={13} /> &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          {/* Grade */}
          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Grade</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(grade).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleGradeChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          {/* Team */}
          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Team</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(team).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleTeamChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          {/* Position */}
          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Position</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(position).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handlePositionChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          <GlobalAccordion
            header={<h6 className='text-xs font-semibold'>Country</h6>}
            content={
              <div className='flex flex-col items-start gap-y-2'>
                {Object.entries(country).map(([key, value]) => (
                  <div
                    className='flex flex-nowrap items-center gap-x-1 text-xs font-normal text-black'
                    key={key}
                  >
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleCountryChange}
                    />{' '}
                    &nbsp; {key}
                  </div>
                ))}
              </div>
            }
          />

          <div className='grid grid-cols-2 gap-x-2 py-4'>
            <button
              onClick={handleApplyFilter2}
              className='py-2 px-4 bg-text-dm-input shadow-buttonPrimary rounded-xl text-white text-sm font-semibold'
            >
              Apply
            </button>
            <button
              onClick={onClose}
              className='py-2 px-4 bg-transparent border border-text-dm-input text-gray-300 rounded-xl text-sm font-semibold'
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  )
}

StaffListFilter.propTypes = {
  onClose: PropsTypes.func,
}
export default StaffListFilter
