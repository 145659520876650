import React, { useState, Fragment, useEffect } from 'react'
import { RxCrossCircled } from 'react-icons/rx'
import PropTypes from 'prop-types'
import { UserCircle2, Bell, LucideAlertTriangle, Circle } from 'lucide-react'
import useAuth from '_hooks/useAuth'
import { BiCamera } from 'react-icons/bi'
import GlobalModal from './GlobalModal'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useSettings from '_hooks/useSettings'

const Header = ({ children }) => {
  const { user, logout } = useAuth()
  const { updateStatus } = useSettings()
  const [isLogOut, setIsLogOut] = useState(false)
  const HandleLogoutModal = () => setIsLogOut(!isLogOut)
  const [isOpenProfileBox, setIsOpenProfileBox] = useState(false)
  const [isOpenNotificationBox, setIsOpenNotificationBox] = useState(false)
  const [isNameClicked, setIsNameClicked] = useState(false)

  const toggleForProfileBox = () => {
    if (isOpenNotificationBox === false) {
      handleNameClick()
      setIsOpenProfileBox(!isOpenProfileBox)
    }
  }

  const handleNameClick = () => {
    setIsNameClicked(!isNameClicked)
  }

  const toggleForNotificationBox = () => {
    if (isOpenProfileBox === false) {
      setIsOpenNotificationBox(!isOpenNotificationBox)
    }
  }

  const handleLogout = () => {
    updateStatus({ id: user?.id, status: 'logout' }).then(() => logout())
  }

  const GlobalAccordion1 = ({ header, content }) => {
    return (
      <Fragment>
        <Accordion className='fill-shaded-white font-poppins !shadow-none !p-0 !m-0'>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            // id='panel1a-header'
            className='!p-0 !px-1'
            style={{ margin: '-15px 0px' }}
          >
            {header}
          </AccordionSummary>
          <AccordionDetails className='!p-0 !m-0'>{content}</AccordionDetails>
        </Accordion>
      </Fragment>
    )
  }

  GlobalAccordion1.propTypes = {
    header: PropTypes.node.isRequired, // Expecting a React node for the header
    content: PropTypes.node.isRequired, // Expecting a React node for the content
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpenProfileBox) {
        const profileBox = document.getElementById('profile-box')
        if (profileBox && !profileBox.contains(event.target)) {
          setIsOpenProfileBox(false)
        }
      }
      if (isOpenNotificationBox) {
        const notificationBox = document.getElementById('notification-box')
        if (notificationBox && !notificationBox.contains(event.target)) {
          setIsOpenNotificationBox(false)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenProfileBox, isOpenNotificationBox])

  return (
    <div className='fixed top-0 left-7 flex justify-between items-center w-fit-screen space-x-6 bg-universal z-nav-bar pt-8'>
      <img src='/assets/hashmemo.svg' className='w-28' alt='logo' />
      {children}

      <div className='flex relative justify-center items-center space-x-2 cursor-pointer text-ellipsis'>
        <Bell onClick={toggleForNotificationBox} className='w-8 h-8' />
        <button
          onClick={toggleForProfileBox}
          className='flex justify-center items-center space-x-2 cursor-pointer text-ellipsis'
        >
          <div className='flex justify-center items-center w-12'>
            <UserCircle2
              className='w-8 h-8'
              style={{ color: `${isNameClicked ? '#00BDB2' : 'black'}` }}
            />
            <div
              style={{
                position: 'relative',
                top: '7px',
                right: '10px',
                width: '10px',
                height: '10px',
                backgroundColor: '#29BC52',
                borderRadius: '50%',
              }}
            />
          </div>
          <span
            className={'text-sm'}
            style={{ color: `${isNameClicked ? '#00BDB2' : 'black'}` }}
          >
            {user?.fullName}
          </span>
          {/* <span className='text-sm text-black'>{user?.fullName}</span> */}
        </button>
        {isOpenNotificationBox && (
          <div
            id='notification-box'
            className='absolute z-10 right-24 top-10 !border-text-dm-input !border-input-border !border-solid w-[222px] min-h-[199px] rounded-xl p-1 flex flex-col bg-white max-h-[65vh] overflow-y-auto'
          >
            <div className='absolute w-full flex justify-end right-1'>
              <RxCrossCircled
                onClick={toggleForNotificationBox}
                color='#00BDB2'
                size={18}
              />
            </div>
            <div className='flex items-start border-b p-1'>
              <BiCamera size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                ID:ABCD1120 has posted new content
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <LucideAlertTriangle size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                Alert detected for content ID:ABC1120
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <BiCamera size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                ID:ABCD1120 has posted new content
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <LucideAlertTriangle size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                Alert detected for content ID:ABC1120
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <LucideAlertTriangle size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                Alert detected for content ID:ABC1120
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <LucideAlertTriangle size={20} />
              <div className='text-black font-poppins ml-1 font-normal text-xs'>
                Alert detected for content ID:ABC1120
              </div>
            </div>
            <div className='flex items-start border-b p-1'>
              <LucideAlertTriangle size={20} />
              <div className='text-black font-poppins font-normal text-xs'>
                Alert detected for content ID:ABC1120
              </div>
            </div>
          </div>
        )}
        {isOpenProfileBox && (
          <div
            id='profile-box'
            className='absolute z-10 right-14 top-10 !border-text-dm-input !border-input-border !border-solid w-[154px]  rounded-xl flex flex-col p-1 bg-white'
          >
            <div className='text-black text-xs font-medium font-poppins border-b p-1'>
              Outlook
            </div>
            <div className='text-black text-xs font-medium font-poppins p-1'>
              MS Teams
            </div>
            <GlobalAccordion1
              header={<p className='font-normal text-xs text-black'>Team A</p>}
              content={
                <div className='w-full'>
                  <div className='flex flex-nowrap items-center border-b p-1'>
                    <Circle stroke={0} fill='#29BC52' size={12} />
                    <h6 className='font-normal text-xs ml-1 text-black'>
                      Staff 1
                    </h6>
                  </div>
                  <div className='flex flex-nowrap items-center border-b p-1'>
                    <Circle stroke={0} fill='#FF7F00' size={12} />
                    <h6 className='font-normal text-xs pl-1 text-black'>
                      Staff 2
                    </h6>
                    <p className='font-medium text-sm ml-1 text-gray-500'>
                      Chat
                    </p>
                  </div>
                  <div className='flex flex-nowrap items-center border-b p-1'>
                    <Circle stroke={0} fill='#A3A3A3' size={12} />
                    <h6 className='font-normal text-xs pl-1 text-black'>
                      Staff 3
                    </h6>
                  </div>
                  <div className='flex flex-nowrap items-center border-b p-1'>
                    <Circle stroke={0} fill='#F00' size={12} />
                    <h6 className='font-normal text-xs pl-1 text-black'>
                      Staff 4
                    </h6>
                  </div>
                </div>
              }
            />
            <button
              onClick={HandleLogoutModal}
              className='text-black text-center text-xs font-medium font-poppins p-2'
            >
              Log out
            </button>
          </div>
        )}
      </div>

      <GlobalModal
        isOpen={isLogOut}
        handleClose={HandleLogoutModal}
        height={432}
      >
        <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
          {/* Icon */}
          <div className='flex flex-col text-center gap-y-4'>
            <div className='flex justify-center items-center w-100'>
              <RiLogoutCircleRLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
            </div>
            {/* text */}
            <div className='flex flex-col items-center gap-y-1'>
              <h6 className='font-medium text-md'>
                Are you sure you want to log out?
              </h6>
            </div>
          </div>
          <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
            <button
              onClick={handleLogout}
              className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            >
              Yes
            </button>
            <button
              className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
              onClick={HandleLogoutModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </GlobalModal>
    </div>
  )
}
Header.propTypes = {
  children: PropTypes.node,
}

export default Header
