import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
// import { BiLockAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

// import useAuth from '_hooks/useAuth'
import config from 'config/config'
import axiosInstance from '_utils/axiosUtils'
import useSettings from '_hooks/useSettings'

export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const [errorMessage1, setErrorMessage] = useState(undefined)
  const [isLoading, setLoading] = useState(undefined)
  const { updateStatus } = useSettings()

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  // const handleSubmit = async (event) => {
  //   event.preventDefault()
  //   const data = new FormData(event.currentTarget)
  //   await login(data.get('email'), data.get('password'))
  //     .then(() => {
  //       if (isError) {
  //         console.log(errorMessage)
  //         // Texting
  //         return
  //       }
  //       // navigate('/home')
  //     })
  // }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    setLoading(true)
    if (!email) throw 'no email!'
    if (!password) throw 'no password!'
    axiosInstance
      .post('/admin/auth/login', {
        email,
        password,
      })
      .then((res) => {
        window.localStorage.setItem(config.TOKEN_KEY, res.data.data?.token)
        updateStatus({ id: res.data.data.admin.id, status: 'active' })
        navigate('/home')
        window.location.reload()
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message)
        setLoading(false)
      })
  }

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate('/home')
  //   }
  // }, [isAuthenticated])

  const UserLogin = () => {
    // if (isAuthenticated) {
    //   navigate('/home')
    // }
  }

  const ForgotPassword = () => {
    navigate('/ForgotPassword')
  }

  return (
    <div className='min-h-screen flex'>
      <div className='hidden xl:flex justify-center items-center xl:w-5/12 bg-cover bg-center bg-gradient-to-t from-bg-memo-start to-bg-memo-stop'>
        <img src='/assets/hashmemo.svg' alt='Hashmemo Logo' />
      </div>
      <div className='xl:w-7/12 grid p-20'>
        <div className='w-full shadow-boxInMain rounded-2xl p-14'>
          <div className='flex flex-col items-start'>
            <h1 className='text-2xl font-semibold mt-4'>Welcome Admin!</h1>
          </div>
          <form onSubmit={handleSubmit} className='mt-24'>
            <div className='mb-4'>
              <label
                htmlFor='email'
                className=' flex items-center gap-2 text-left text-black-700 font-medium'
              >
                <img src='/assets/svg/email.svg' alt='email' /> Email Address
              </label>
              <input
                type='email'
                id='email'
                name='email'
                required
                className='w-full mt-1 p-2 pl-4 border rounded-xl outline-none'
                autoComplete='email'
              />
            </div>
            <div className='mb-2 mt-3 relative'>
              <label
                htmlFor='password'
                className='flex items-center gap-2 text-left text-black-700 font-medium'
              >
                <img src='/assets/svg/lock-green.svg' alt='email' /> Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                required
                className='w-full mt-1 p-2 pl-4 border rounded-xl outline-none'
                autoComplete='current-password'
              />
              <div className='absolute top-12 right-3 transform -translate-y-1/2 flex items-center'>
                <button
                  type='button'
                  onClick={togglePasswordVisibility}
                  className='text-gray-500 focus:outline-none'
                >
                  {showPassword ? (
                    <AiOutlineEye size={20} />
                  ) : (
                    <AiOutlineEyeInvisible size={20} />
                  )}
                </button>
              </div>
            </div>
            <div className='flex justify-between items-center'>
              <span className='text-red-600 w-5/6 text-sm'>
                {errorMessage1?.en}
              </span>
              <div className='mt-3 flex justify-end'>
                <button
                  className='underline text-sm font-medium'
                  onClick={ForgotPassword}
                  tabIndex={0}
                >
                  Forgot Password?
                </button>
              </div>
            </div>
            <div className='flex justify-center'>
              <button
                type='submit'
                className='w-40 mt-20 text-white py-2 shadow-buttonPrimary bg-text-dm-input rounded-xl backdrop-blur-sm'
                onClick={UserLogin}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
              >
                {isLoading ? 'Loading....' : 'Log In'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
