import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

/**
 * RightCanvas Component
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the drawer is open or not
 * @param {ReactNode} props.children - Content to display inside the drawer
 * @param {function} props.onClose - Callback function to close the drawer
 */
const RightCanvas = ({ isOpen, children, onClose, width }) => {
  // Render the content for the drawer
  const RenderDrawerContent = () => (
    <Box
      role='presentation'
      sx={{ width: width || 455 }}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Box>
  )

  return (
    <div className='z-20'>
      {/* The MUI Drawer component */}
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={() => onClose('right', false)}
      >
        <RenderDrawerContent>{children}</RenderDrawerContent>
      </Drawer>
    </div>
  )
}

// PropTypes for prop type checking
RightCanvas.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
}

export default RightCanvas
