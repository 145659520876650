import React, { useState } from 'react'
import Page from 'components/global/Page'
import AdminHeader from './AdminHeader'
import AuthorizationTeamWiseTable from './AuthorizationTeamWiseTable'
import AuthorizationGradeWiseTable from './AuthorizationGradeWiseTable'

const Authorization = () => {
  const [EditGradeAuthority, setEditGradeAuthority] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className={openModal ? 'blur-sm' : ''}>
      <Page>
        <div id='header' className='w-full'>
          <AdminHeader noSearch={true} />
        </div>
        <div className='shadow-customBtnShadow rounded-2xl mr-5 '>
          {EditGradeAuthority ? (
            <AuthorizationGradeWiseTable
              setOpenModal={setOpenModal}
              setEditGradeAuthority={setEditGradeAuthority}
            />
          ) : (
            <div>
              <AuthorizationTeamWiseTable />
              <div className='flex justify-end p-5'>
                <button
                  onClick={() => setEditGradeAuthority(true)}
                  className='w-[199px] h-11 text-white hover:underline rounded-xl bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'
                >
                  Edit Grade Authority
                </button>
              </div>
            </div>
          )}
        </div>
      </Page>
    </div>
  )
}

export default Authorization
