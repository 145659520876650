import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PiWarningCircle } from 'react-icons/pi';


export default function ForgotPassword() {
  const navigate = useNavigate()
  const [Error, setError] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    try {
      const response = await fetch('http://localhost:8080/api/v1/admin/auth/verifyEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message);
        return;
      }
      // navigate('/OtpVerification');
      navigate(`/OtpVerification?email=${encodeURIComponent(email)}`);
    } catch (error) {
      console.error('Error occurred:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className='min-h-screen flex'>
      <div
        className='hidden xl:flex justify-center items-center xl:w-5/12 bg-cover bg-center bg-gradient-to-t from-bg-memo-start to-bg-memo-stop'
      >
        <img src='/assets/hashmemo.svg' alt='Hashmemo Logo' />
      </div>
      <div className='xl:w-7/12 grid p-20'>
        <div className='w-full shadow-boxInMain rounded-2xl p-14'>
          <div className='flex flex-col items-start'>
            <h1 className='text-2xl font-semibold mt-4'>Enter your email address</h1>
            <div className='text-sm text-slate-500'>Please enter your email address, and we will send a verification code to reset password.</div>
          </div>
          <form onSubmit={handleSubmit} className='mt-24 md:h-[350px] xl:h-[400px] 2xl:!h-[500px] grid grid-cols-1 content-between'>
            <div className='mb-4'>
              <label htmlFor='email' className=' flex items-center gap-2 text-left text-black-700 font-medium'>
                <img src='/assets/svg/email.svg' alt='email' /> Email Address
              </label>
              <input
                type='email'
                id='email'
                name='email'
                required
                className='w-full mt-1 p-2 pl-4 border rounded-xl outline-none'
                autoComplete='email'
                placeholder='Enter Your Email Address'
              />
              <div className='flex pt-5 text-sm' style={{ color: '#FF1B1B' }} >
                {
                  Error ? <PiWarningCircle size={20} className='mr-2' /> : null
                }{Error}
              </div>
            </div>
            <div className='flex justify-center item-center h-12'>
              <button
                className='text-white py-2 px-20 shadow-buttonPrimary bg-text-dm-input rounded-xl backdrop-blur-sm w-60'
                type='submit'
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
