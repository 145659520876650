import LightBtn from 'components/global/Buttons/LightBtn'
import { MoveLeft } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

const FamilyPairing = () => {

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className='w-full  max-h-[88vh] flex flex-col gap-4 relative'>
      {/* top header */}
      <div className='w-full flex gap-x-2 items-center'>
        <LightBtn onClick={goBack} >
          <MoveLeft size={16} />
        </LightBtn>
        <h1 className='text-base font-bold '>Family Pairing</h1>
      </div>

      {/* child containers */}
      <div className='w-full grid grid-cols-2 gap-3 max-h-max overflow-y-auto'>
        {/* child container */}

        <div className='p-5 shadow-customBtnShadow rounded-xl'>
          <div className='flex justify-between'>
            <h1 className='text-base font-semibold'>
              aaron-james@xxx.com <span className='font-medium'>(Child 1)</span>
            </h1>
            <Link to={'/user'} className='underline text-text-dm-input text-sm font-medium' >
              Go to User page
            </Link>
          </div>
          {/* content */}
          <div className='py-3 flex flex-col gap-y-3'>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Pairing Date
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                13/06/2023
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Daily Time
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                5h 30mins
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Restricted Mode
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                ON
              </h4>
            </span>
            <h3 className='text-sm font-semibold'>Notifications</h3>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                Notification when your teen posts
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                ON
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                New followers
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                ON
              </h4>
            </span>
            <span className='flex flex-nowrap items-center gap-x-2'>
              <button className='w-full py-1.5 px-3 flex-1 rounded-xl shadow-customBtnShadow text-left text-sm font-medium'>
                New following
              </button>
              <h4 className='flex-1 w-full  md:text-xs lg:text-sm flex items-center flex-nowrap font-semibold'>
                ON
              </h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FamilyPairing
