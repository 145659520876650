import axiosInstance from '_utils/axiosUtils'
import React, { useRef, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { RiLockPasswordLine } from 'react-icons/ri'
import GlobalModal from 'components/global/GlobalModal'
import { TextField } from '@mui/material'
import LocationPin from './icons/LocationsPin'
import clsx from 'clsx'
import PropsTypes from 'prop-types'
import ToggleButton from './ToggelButton'

const Score = ({ score, id, access }) => {
  const [isOpenPointScale, setIsPointScale] = useState(false)
  const [lock, setLock] = useState(
    !score ? false : score?.isLocked ? true : false
  )
  const [isChange, setIsChange] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openVerifyModal, setOpenVerifyModal] = useState(false)
  const [hasOTP, setHasOTP] = useState(
    !score ? false : score?.isLocked ? true : false
  )
  const [pass, setPass] = useState('')
  const [locNumber, setLocNumber] = useState(score?.score ? score?.score : 0)
  const [tempNum, setTempNum] = useState(score?.score ? score?.score : 0)

  const updateScorePost = async (id, postId, score, isLocked = false, pass) => {
    let tempObj = { postId: postId, score: score, isLocked }
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    if (pass && isLocked) {
      tempObj = { ...tempObj, pin: pass }
    }
    try {
      const response = await axiosInstance.put(
        '/admin/post/score/update',
        tempObj
      )
      if (response.status === 200) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const verifyOTP = async (id, pass) => {
    let tempObj = {}
    if (id) {
      tempObj = { ...tempObj, id: id }
    }
    tempObj = { ...tempObj, pin: pass }
    try {
      const response = await axiosInstance.post(
        '/admin/post/score/verifyPin',
        tempObj
      )
      if (response.status === 200) {
        setHasOTP(false)
        setLock(false)
        setOpenVerifyModal(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleDropedownByPointScale = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
  }

  const PointScaleCancel = () => {
    setTempNum(locNumber)
    setIsPointScale(!isOpenPointScale)
    setLock(!score ? false : score?.isLocked ? true : false)
  }

  const savePointScale = () => {
    setLocNumber(tempNum)
    setIsPointScale(false)
  }

  const getColor = (index) => {
    if (index + 1 === tempNum) {
      return 'white' // Selected number color
    } else if (index + 1 <= 3) {
      return '#FF0000' // 1, 2, 3 color
    } else if (index + 1 <= 6) {
      return '#FF7F00' // 4, 5, 6 color
    } else {
      return '#29BC52' // 7, 8, 9, 10 color
    }
  }
  const inputRefs = useRef([])

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '')

    e.target.value = value

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      //focus on the next one when a digit is entered
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      // focus on the previous one when a digit is removed
      inputRefs.current[index - 1].focus()
    }
    setPass('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const pass = inputRefs.current.map((input) => input.value).join('')
    if (pass) {
      updateScorePost(score?.id, id, tempNum, lock, pass)
    }
  }

  const handleVerifySubmit = (e) => {
    e.preventDefault()
    const pass = inputRefs.current.map((input) => input.value).join('')
    verifyOTP(score?.id, pass)
  }

  return (
    <div>
      <button
        onClick={toggleDropedownByPointScale}
        className='flex items-center'
        disabled={!access}
      >
        {locNumber}{' '}
        {lock ? (
          <img src='/assets/svg/lock.svg' alt='' className='pl-1 h-5 w-5' />
        ) : (
          <>
            {score && score?.isLocked ? (
              <RiLockPasswordLine size={17} />
            ) : (
              <FiChevronDown size={17} />
            )}
          </>
        )}
      </button>

      {isOpenPointScale && (
        <div className='absolute z-10 right-0 !border-text-dm-input !border-input-border !border-solid w-48 h-36 rounded-xl flex items-center justify-center bg-white'>
          <div className='w-full px-3 py-0'>
            <div className='w-full flex flex-col pb-2'>
              <div className='w-full flex justify-between gap-x-1'>
                {[...Array(10)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => !lock && setTempNum(index + 1)}
                    disabled={lock}
                    value={tempNum}
                    style={{
                      color: getColor(index),
                    }}
                  >
                    {tempNum === index + 1 ? (
                      <LocationPin number={index + 1} color='black' />
                    ) : (
                      index + 1
                    )}
                  </button>
                ))}
              </div>
              <progress
                className='h-1 w-full'
                value={tempNum}
                max='10'
                color={getColor(locNumber)}
                content={getColor(locNumber)}
                style={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: getColor(locNumber),
                }}
              ></progress>
            </div>

            <div className='flex flex-nowrap gap-x-2 items-center'>
              <img src='/assets/svg/lock.svg' alt='' />{' '}
              <p className={clsx('text-xs font-medium', !lock && '!font-bold')}>
                Unlock
              </p>
              <ToggleButton
                toggle={lock}
                setToggle={() => {
                  if (lock) {
                    if (hasOTP) {
                      setOpenVerifyModal(true)
                    } else {
                      setLock(!lock)
                    }
                  } else {
                    setLock(!lock)
                  }
                  setIsChange(true)
                }}
              />
              <p className={clsx('text-xs font-medium', lock && '!font-bold')}>
                Lock
              </p>
            </div>
            <div className='flex py-2 gap-x-2 w-full'>
              <button
                className='px-3 py-1 w-1/2 bg-text-dm-input shadow-buttonPrimary h-9 rounded-xl text-sm text-white'
                onClick={() => {
                  if (lock === false) {
                    savePointScale()
                    updateScorePost(score?.id, id, tempNum, lock, pass)
                  } else {
                    if (isChange) {
                      setOpenModal(true)
                    }
                  }
                }}
              >
                Save
              </button>
              <button
                onClick={PointScaleCancel}
                className='px-3 py-1 w-1/2 h-9 bg-universal border border-teal-400 text-gray-400 rounded-xl text-sm'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <GlobalModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        height={432}
      >
        <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
          {/* Icon */}
          <div className='flex justify-center items-center w-100'>
            <RiLockPasswordLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
          </div>
          <div className='flex flex-col text-center gap-y-4'>
            {/* text */}
            <div className='flex flex-col items-center gap-y-1'>
              <h6 className='font-medium text-md'>
                {`Do you want to ${lock ? 'lock' : 'unlock'} the score?`}
              </h6>
              {lock ? (
                <p>
                  If you lock the score, it will not fluctuate automatically
                </p>
              ) : (
                <p>If you unlock the score, it will fluctuate automatically</p>
              )}
            </div>

            <p>Enter Passcode</p>
            <div className='flex gap-3 px-4 otp-text'>
              {[...Array(4)].map((_, index) => (
                <TextField
                  key={index}
                  type='password'
                  fullWidth
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  placeholder='0'
                  variant='outlined'
                  error={false}
                  name={`otp-${index}`}
                  inputProps={{
                    maxLength: 1,
                    type: 'password',
                    pattern: '[0-9]*',
                  }}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  sx={{ textAlign: 'center !important' }}
                />
              ))}
            </div>
          </div>
          <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
            <button
              onClick={handleSubmit}
              disabled={
                inputRefs?.current?.filter((input) => input?.value)?.length ===
                4
              }
              className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            >
              Save
            </button>
            <button
              className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </GlobalModal>

      <GlobalModal
        isOpen={openVerifyModal}
        handleClose={() => setOpenVerifyModal(false)}
        height={432}
      >
        <div className='flex flex-col items-center gap-y-5 h-full justify-center'>
          {/* Icon */}
          <div className='flex justify-center items-center w-100'>
            <RiLockPasswordLine className='bg-primary-200 p-3 text-teal-500 text-8xl rounded-xl' />
          </div>
          <div className='flex flex-col text-center gap-y-4'>
            {/* text */}
            <div className='flex flex-col items-center gap-y-1'>
              <h6 className='font-medium text-md'>
                Do you want to unlock the score?
              </h6>
              <p>If you unlock the score, it will fluctuate automatically</p>
            </div>

            <p>Enter Passcode</p>
            <div className='flex gap-3 px-4 otp-text'>
              {[...Array(4)].map((_, index) => (
                <TextField
                  key={index}
                  type='password'
                  fullWidth
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  placeholder='0'
                  variant='outlined'
                  error={false}
                  name={`otp-${index}`}
                  inputProps={{
                    maxLength: 1,
                    type: 'password',
                    pattern: '[0-9]*',
                  }}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  sx={{ textAlign: 'center !important' }}
                />
              ))}
            </div>
          </div>
          <div className='w-full text-center flex flex-nowrap justify-center gap-2'>
            <button
              onClick={handleVerifySubmit}
              className='bg-teal-500 w-2/6 py-2 rounded-lg text-white shadow-buttonPrimary'
            >
              Save
            </button>
            <button
              className='border-teal-500 w-2/6  rounded-lg border text-gray-400 bg-transparent py-2'
              onClick={() => setOpenVerifyModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </GlobalModal>
    </div>
  )
}

Score.propTypes = {
  score: PropsTypes.object,
  id: PropsTypes.object,
  access: PropsTypes.bool,
}

export default Score
