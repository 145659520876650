import React, { createContext, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import axiosInstance from '_utils/axiosUtils'

const StaffContext = createContext({
  staffs: {
    rows: [],
    _count: 0,
  },
  team: {
    rows: [],
  },
  position: {
    rows: [],
  },
  grade: {
    rows: [],
  },
  country: {
    rows: [],
  },
  search: '',
  setSearch: () => null,
  setStaffs: () => null,
  addStaff: () => null,
  addKey: () => null,
})

export const StaffProvider = ({ children }) => {
  const [staffs, setStaffs] = useState({
    rows: [],
    _count: 0,
  })
  const [search, setSearch] = useState()
  const [team, setTeam] = useState({
    rows: [],
  })
  const [filterArray, setFilterArray] = useState([])
  const [position, setPosition] = useState({
    rows: [],
  })
  const [grade, setGrade] = useState({
    rows: [],
  })
  const [country, setCountry] = useState({
    rows: [],
  })

  const addStaff = async (data) => {
    await axiosInstance
      .post('/admin/staff/new', data)
      .then((res) => console.log(res?.data?.data))
      .catch((err) => console.log(err))
  }

  const fetchTeams = async (filter) => {
    return axiosInstance
      .get('/admin/staff/new/team', {
        params: {
          teamIds:
            filter?.teamIds?.length > 0
              ? filter?.teamIds?.join(',')
              : undefined,
          search: filter?.search ? filter?.search : null,
        },
      })
      .then((res) => {
        if (!filter) {
          setFilterArray(res?.data?.data)
        }
        setTeam({
          rows: res?.data?.data,
        })
      })
      .catch((err) => console.log(err))
  }

  const editMember = async (payload, staffPage = false) => {
    return axiosInstance
      .patch('/admin/staff/updateStaffs', { members: payload })
      .then((res) => {
        if (staffPage) {
          setStaffs((prev) => ({
            ...prev,
            rows: [...staffs.rows, ...res.data.data],
          }))
        } else {
          let teamId = payload[0].teamId
          let tempArray = team?.rows?.map((team) => {
            if (team?.id === teamId) {
              return { ...team, members: res.data.data }
            }
            return { ...team }
          })
          setTeam({ rows: tempArray })
        }
      })
      .catch((err) => console.log(err))
  }

  const fetchPositions = async () => {
    return axiosInstance
      .get('/admin/staff/new/position')
      .then((res) => {
        setPosition({
          rows: res?.data?.data,
        })
      })
      .catch((err) => console.log(err))
  }
  const fetchGrade = async () => {
    return axiosInstance
      .get('/admin/staff/new/grade')
      .then((res) => {
        setGrade({
          rows: res?.data?.data,
        })
      })
      .catch((err) => console.log(err))
  }
  const fetchCountry = async () => {
    return axiosInstance
      .get('/admin/staff/new/country')
      .then((res) => {
        setCountry({
          rows: res?.data?.data,
        })
      })
      .catch((err) => console.log(err))
  }

  const addKey = async (type, name) => {
    axiosInstance
      .post(`/admin/staff/new/${type}`, { name })
      .then(() => fetchAllKeys())
      .catch((err) => console.log(err))
  }

  const fetchAllKeys = async () => {
    await Promise.all([
      fetchTeams(),
      fetchPositions(),
      fetchGrade(),
      fetchCountry(),
    ])
  }

  const fetchStaffs = async () => {
    axiosInstance
      .get('/admin/staff', { params: { search } })
      .then((res) => {
        setStaffs((prev) => ({
          ...prev,
          rows: res?.data?.data?.rows,
          _count: res?.data?.data?.rows ? res?.data?.data?.rows?.length : 0,
        }))
      })
      .catch((err) => console.log(err))
  }

  const editAuthority = async (payload) => {
    axiosInstance
      .post('/admin/staff/authority', { data: payload })
      .then(() => {
        window.location.reload()
      })
      .catch((err) => console.log(err))
  }

  const editGradeName = async (payload) => {
    axiosInstance
      .patch('/admin/staff/updateGradeName', payload)
      .then(() => {
        let tempArray = grade.rows.map((grade) => {
          if (grade.id === payload?.gradeId) {
            return { ...grade, ...payload }
          }
          return { ...grade }
        })
        setGrade({ rows: tempArray })
      })
      .catch((err) => console.log(err))
  }
  useEffect(() => {
    fetchAllKeys()
  }, [])

  useEffect(() => {
    fetchStaffs()
  }, [search])

  return (
    <StaffContext.Provider
      value={{
        staffs,
        setStaffs,
        fetchStaffs,
        team,
        position,
        filterArray,
        grade,
        country,
        addStaff,
        search,
        setSearch,
        addKey,
        editGradeName,
        fetchTeams,
        editAuthority,
        fetchPositions,
        fetchGrade,
        fetchCountry,
        editMember,
      }}
    >
      {children}
    </StaffContext.Provider>
  )
}

StaffProvider.propTypes = {
  children: PropsTypes.node,
}

export default StaffContext
