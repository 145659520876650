import React from 'react'
import PropTypes from 'prop-types'
import { FiCircle } from 'react-icons/fi'
import { Clock, MinusCircle, ArrowRightCircle } from 'lucide-react'

const StaffState = ({ type, size }) => {
  switch (type) {
    case 'online':
      return <FiCircle size={size || 12} fill='#29BC52' color='#29BC52' />
    case 'meeting':
      return <Clock color='#fff' fill='#FF7F00' size={size || 12} />
    case 'away':
      return <MinusCircle fill='#f00' color='#fff' size={size || 12} />
    case 'logout':
      return <ArrowRightCircle fill='#A3A3A3' color='#fff' size={size || 12} />
    default:
      return null
  }
}

StaffState.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
}

export default StaffState
