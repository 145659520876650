import config from 'config/config'
import {
  ChevronRight,
  Briefcase,
  Wrench,
  GraduationCap,
  ScrollText,
} from 'lucide-react'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { a11y } from '_utils/a11y'
import useResume from '_hooks/useResume'

const Resumes = ({ user }) => {
  const { handleOpen, data } = useResume()

  const _count = useMemo(() => {
    const expCount = user?.experience?.length || 0
    const eduCount = user?.education?.length || 0
    const skCount = user?.skills?.length || 0
    const certCount = user?.certificates?.length || 0
    return {
      _resume: expCount + eduCount + skCount + certCount,
      _experience: expCount,
      _education: eduCount,
      _skill: skCount,
      _certificate: certCount,
    }
  }, [user])

  const setSelected = (type, value) => {
    handleOpen(type, value)
  }

  return (
    <div className='w-full max-h-[88vh] overflow-y-auto'>
      <h1 className='text-lg font-semibold py-2'>Resumes {_count._resume}</h1>
      {_count._resume > 0 && (
        <div className='grid grid-cols-2 gap-x-4 gap-y-4'>
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-semibold'>
              Experience: {_count._experience}
            </h2>
            <ExperienceMap
              experience={user?.experience}
              selected={data?.id}
              setSelected={setSelected}
              type='experience'
            />
          </div>
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-semibold'>Skills: {_count._skill}</h2>
            <SkillMap
              skill={user?.skills}
              selected={data?.id}
              setSelected={setSelected}
              type='skill'
            />
          </div>
          {/* Educations  */}
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-semibold'>
              Educations: {_count._education}
            </h2>

            {/* Education box */}
            <EducationMap
              education={user?.education}
              selected={data?.id}
              setSelected={setSelected}
              type='education'
            />
          </div>
          {/* Certificates  */}
          <div className='flex flex-col gap-3'>
            <h2 className='text-md font-semibold'>
              Certificates: {_count._certificate}
            </h2>

            {/* Certificates box */}
            <CertificateMap
              certificate={user?.certificates}
              selected={data?.id}
              setSelected={setSelected}
              type='certificate'
            />
          </div>
        </div>
      )}
    </div>
  )
}

Resumes.propTypes = {
  user: PropTypes.object,
}

const ExperienceMap = ({ experience, selected, setSelected, type }) =>
  experience?.map((exp, index) => (
    <div
      key={index}
      className={clsx(
        'shadow-customBtnShadow rounded-xl p-5 justify-between flex items-center gap-3 hover:shadow-boxIn cursor-pointer border border-transparent',
        exp?.id === selected && '!border-teal-500 !shadow-active-btn'
      )}
      {...a11y(() => setSelected(type, exp))}
    >
      <div className='flex items-center gap-3'>
        {exp?.logo ? (
          <img
            src={`${config.FTP_URL}${exp?.logo}`}
            className='rounded-full w-10 h-10'
            alt='resumeIcon'
          />
        ) : (
          <Briefcase className='w-5 h-5' />
        )}
        <div>
          <h3 className='font-bold text-sm'>{exp?.name}</h3>
          <h3 className='text-sm font-semibold'>{exp?.title}</h3>
        </div>
      </div>
      <ChevronRight />
    </div>
  ))

ExperienceMap.propTypes = {
  type: PropTypes.oneOf(['experience', 'education', 'skill', 'certificate']),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      isPresent: PropTypes.bool,
      description: PropTypes.string,
      logo: PropTypes.string,
    })
  ),
}

const SkillMap = ({ skill, selected, setSelected, type }) =>
  skill?.map((sk, index) => (
    <div
      key={index}
      className={clsx(
        'shadow-customBtnShadow rounded-xl p-5 justify-between flex items-center gap-3 hover:shadow-boxIn cursor-pointer',
        sk?.id === selected && '!border-teal-500 !shadow-active-btn'
      )}
      {...a11y(() => setSelected(type, sk))}
    >
      <div className='flex items-center gap-3'>
        {sk?.logo ? (
          <img
            src={`${config.FTP_URL}${sk?.logo}`}
            className='rounded-full w-10 h-10'
            alt='resumeIcon'
          />
        ) : (
          <Wrench className='w-5 h-5' />
        )}
        <div>
          <h3 className='font-bold text-sm'>{sk?.skill}</h3>
        </div>
      </div>
      <ChevronRight />
    </div>
  ))

SkillMap.propTypes = {
  type: PropTypes.oneOf(['experience', 'education', 'skill', 'certificate']),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  skill: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      skill: PropTypes.string.isRequired,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      isPresent: PropTypes.bool,
      description: PropTypes.string,
      logo: PropTypes.string,
    })
  ),
}

const EducationMap = ({ selected, setSelected, education, type }) =>
  education?.map((edu, index) => (
    <div
      key={index}
      className={clsx(
        'shadow-customBtnShadow rounded-xl p-5 justify-between flex items-center gap-3 hover:shadow-boxIn cursor-pointer',
        edu?.id === selected && '!border-teal-500 !shadow-active-btn'
      )}
      {...a11y(() => setSelected(type, edu))}
    >
      <div className='flex items-center gap-3'>
        {edu?.logo ? (
          <img
            src={`${config.FTP_URL}${edu?.logo}`}
            className='rounded-full w-10 h-10'
            alt='resumeIcon'
          />
        ) : (
          <GraduationCap className='w-5 h-5' />
        )}
        <div>
          <h3 className='font-bold text-sm'>{edu?.school}</h3>
          <h3 className='text-sm font-semibold'>{edu?.field}</h3>
        </div>
      </div>
      <ChevronRight />
    </div>
  ))

EducationMap.propTypes = {
  type: PropTypes.oneOf(['experience', 'education', 'skill', 'certificate']),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  education: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      school: PropTypes.string.isRequired,
      degree: PropTypes.string,
      field: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      isPresent: PropTypes.bool,
      description: PropTypes.string,
      logo: PropTypes.string,
    })
  ),
}

const CertificateMap = ({ selected, setSelected, certificate, type }) =>
  certificate?.map((cert, index) => (
    <div
      key={index}
      className={clsx(
        'shadow-customBtnShadow rounded-xl p-5 justify-between flex items-center gap-3 hover:shadow-boxIn cursor-pointer',
        cert?.id === selected && '!border-teal-500 !shadow-active-btn'
      )}
      {...a11y(() => setSelected(type, cert))}
    >
      <div className='flex items-center gap-3'>
        <ScrollText className='w-5 h-5' />
        <div>
          <h3 className='font-bold text-sm'>{cert?.name}</h3>
          <h3 className='text-sm font-semibold'>{cert?.organisation}</h3>
        </div>
      </div>
      <ChevronRight />
    </div>
  ))

CertificateMap.propTypes = {
  type: PropTypes.oneOf(['experience', 'education', 'skill', 'certificate']),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  certificate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      organisation: PropTypes.string,
      issueDate: PropTypes.string,
      expireDate: PropTypes.string,
    })
  ),
}

export default Resumes
