import Input from 'components/global/Input'
import Page from 'components/global/Page'
import Popper from 'components/global/Popper'
import ObjectionFilter from 'components/objection/ObjectionFilter'
import { SlidersHorizontal } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { RiSearchLine } from 'react-icons/ri'
import useHome from '_hooks/useHome'
import moment from 'moment'
import config from 'config/config'
import useAuth from '_hooks/useAuth'
import User from 'components/global/User'
import Post from 'components/global/Post'

const Objection = () => {
  const {
    setReports,
    reports: { all, _count },
    search,
    setSearch,
  } = useHome()
  const { user } = useAuth()
  // Custom cell styles for DataTable
  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '35px',
      },
    },
  }

  useEffect(() => {
    setReports((prev) => ({ ...prev, type: '' }))
  }, [])

  // search component
  const popperRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)

  const columns = [
    {
      // name: 'Received Date',
      name: (
        <div className='flex w-full'>
          Received Date
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) =>
        moment(row?.createdAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      sortable: true,
      minWidth: '100px',
    },
    {
      // name: 'Reason',
      name: (
        <div className='flex w-full'>
          Reason
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      selector: (row) =>
        row?.commentReport?.reason?.reason ||
        row?.userReport?.reason?.reason ||
        row?.postReport?.reason?.reason ||
        row?.memoReport?.reason?.reason,
      cell: (row) => (
        <div className='flex-wrap'>
          {row?.commentReport?.reason?.reason ||
            row?.userReport?.reason?.reason ||
            row?.postReport?.reason?.reason ||
            row?.memoReport?.reason?.reason}
        </div>
      ),
    },
    {
      // name: 'User Info',
      name: (
        <div className='flex w-full'>
          User Info
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '180px',
      cell: (row) => (
        <User
          user={
            row?.commentReport?.comment?.user ||
            row?.userReport?.reportedUser ||
            row?.postReport?.post?.users ||
            row?.memoReport?.memo?.user
          }
          access={user?.authorityName.includes('change_point')}
        />
      ),
    },
    {
      name: 'Target Data',
      sortable: false,
      minWidth: '160px',
      cell: (row) => {
        return (
          <div>
            {row?.type === 'user' && <p>For account</p>}
            {row?.type === 'post' && (
              <Post
                post={row?.postReport?.post}
                showId={true}
                rowId={row?.id}
              />
            )}
            {row?.type === 'comment' && <p>For Comment</p>}
            {row?.type === 'memo' && <p>For Memo</p>}
          </div>
        )
      },
    },
    {
      // name: 'Objection ID',
      name: (
        <div className='flex w-full'>
          Objection ID
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '20px',
      cell: (row) => <p className='truncate'>{row?.uid}</p>,
    },
    {
      // name: 'Status',
      name: (
        <div className='flex w-full'>
          Status
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => <p>{row?.status.replace(/_/g, ' ')}</p>,
    },
    {
      // name: 'Result',
      name: (
        <div className='flex w-full'>
          Result
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <>
          <select className='text-text-dm-input items-center outline-none bg-universal'>
            <option value='master'>{row?.result.replace(/_/g, ' ')}</option>
            <option value='none' className='text-text-dm-input font-medium'>
              Pending
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Overruled
            </option>
            <option value='none' className='text-text-dm-input font-medium'>
              Accepted
            </option>
          </select>
        </>
      ),
    },
    {
      // name: 'PIC',
      name: (
        <div className='flex w-full'>
          PIC
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '30px',
      cell: (row) => (
        <p
          className='truncate'
          style={{ color: row?.pic?.fullName ? '#000000' : '#585858' }}
        >
          {row?.pic?.fullName || 'None'}
        </p>
      ),
    },
    {
      name: 'Form',
      sortable: true,
      minWidth: '10px',
      cell: () => (
        <div className='flex'>
          <img
            src='/assets/Icons/OutlookIcon.svg'
            alt='eye'
            className='h-5 w-6'
          />
        </div>
      ),
    },
  ]

  return (
    <Page>
      <div className='w-full pl-8' id='header'>
        <Input
          placeHolder='Search...'
          type='text'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          leftIcon={<RiSearchLine className='w-6 h-6' />}
          rightIcon={
            <SlidersHorizontal
              ref={popperRef}
              onClick={() => setFilterOpen(true)}
              className='w-6 h-6 cursor-pointer'
              style={{ color: filterOpen ? '#00BDB2' : '' }}
            />
          }
          className='flex-grow !border-light-border-search !rounded-xl'
        />
        <Popper
          refElement={popperRef.current}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          popperOptions={{
            strategy: 'fixed',
            placement: 'bottom-end',
          }}
        >
          <div className='bg-universal p-3 rounded-2xl  border border-teal-500'>
            <ObjectionFilter onClose={() => setFilterOpen(false)} />
          </div>
        </Popper>
      </div>
      <div className='w-full max-w-full'>
        <div className='w-full flex gap-x-2 justify-between items-center mb-5'>
          <h1 className='text-2xl'>
            <span className='font-semibold'>Total </span>
            {_count}
          </h1>
          {/* <div className='gap-3 flex'>
            <button className='w-20 h-11 text-white hover:underline rounded-xl !text-base bg-text-dm-input backdrop-blur-sm shadow-buttonPrimary cursor-pointer'>
              Save
            </button>
            <button className='bg-white-500 h-11 border border-teal-400 text-gray-400 text-base px-3 rounded-xl'>
              Cancel
            </button>
          </div> */}
        </div>
        <div className='rounded-xl w-full max-w-full h-full'>
          <DataTable
            columns={columns}
            data={all}
            highlightOnHover
            customStyles={customCellStyles}
            className='custom-data-table'
            // onSelectedRowsChange={(row) => _setSelectedRows(row?.selectedRows)}
          />
        </div>
      </div>
    </Page>
  )
}

export default Objection
