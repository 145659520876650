import config from 'config/config'
import React, { useState } from 'react'
import { FiHeart } from 'react-icons/fi'
import { a11y } from '_utils/a11y'
import { Link } from 'react-router-dom'
import { defaultProfileImg } from '_utils/default'
import PropsTypes from 'prop-types'

const Post = ({ post }) => {
  const [isOpenByid, setIsOpenByid] = useState(false)

  const toggleDropdownById = () => {
    setIsOpenByid(!isOpenByid)
  }

  const copyToClipBoard = async (id, type = 'id') => {
    let text = id
    if (type === 'url') {
      text = `${config.CLIENT_BASE_URL}/user/${post?.users?.id}?p_id=${id}`
    }
    try {
      await navigator.clipboard.writeText(text)
      toggleDropdownById()
    } catch (error) {
      console.log(error)
    }
  }

  // const videoRef = useRef(null);

  // const togglePlay = () => {
  //   if (videoRef.current.paused) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }
  // };

  return (
    <div>
      <div className='flex p-1 gap-x-3'>
        <div className='flex-1 overflow-x-hidden aspect-card-img rounded-2xl bg-gradient-to-t from-card-img-gradient via-transparent to-transparent'>
          {post?.postMedia && post?.postMedia?.fileType === 'video/mp4' ? (
            <div className='w-full h-full overflow-hidden'>
              <Link to={`/user/${post?.users?.id}?p_id=${post?.id}`}>
                <video>
                  <source
                    src={defaultProfileImg(post.postMedia.fileURL)}
                    type='video/mp4'
                  />
                  <track kind='captions' src='' label='' />
                  Your browser does not support the video tag.
                </video>
              </Link>
            </div>
          ) : (
            <Link to={`/user/${post?.users?.id}?p_id=${post?.id}`}>
              <img
                className='w-full h-full'
                src={defaultProfileImg(
                  typeof post?.postMedia?.fileURL === 'string'
                    ? post?.postMedia?.fileURL
                    : post?.postMedia?.[0]?.fileURL,
                  'post'
                )}
                alt='postimage'
              />
            </Link>
          )}
        </div>
        {/* Right side icons  */}
        <div className='flex flex-col gap-y-2 items-center'>
          <span className='flex flex-nowrap'>
            <img src='/assets/Icons/eye.svg' alt='eye' className='w-4' /> &nbsp;{' '}
            <u className='text-md'>0</u>
          </span>
          <span className='flex flex-nowrap justify-center items-center'>
            <FiHeart size={16} fill='#f00' color='#f00' /> &nbsp;
            <u className='text-md'>{post?._count?.likes}</u>
          </span>
          <span className='flex flex-nowrap'>
            <img src='/assets/Icons/bookmark.svg' alt='eye' className='w-4' />{' '}
            &nbsp; <u className='text-md'>{post?._count?.saved}</u>
          </span>
          <Link to={`/user/${post?.users?.id}?p_id=${post?.id}`}>
            <span className='flex flex-nowrap'>
              <img src='/assets/Icons/message.svg' alt='eye' className='w-4' />{' '}
              &nbsp; <u className='text-md'>{post?._count?.comments}</u>
            </span>
          </Link>
          <span className='flex flex-nowrap'>
            <img src='/assets/Icons/shared.svg' alt='eye' className='w-4' />{' '}
            &nbsp; <u className='text-md'>{post?._count?.share}</u>
          </span>
        </div>
      </div>
      <div className='flex p-1'>
        <img src='/assets/Icons/id.svg' alt='idIcon' className='w-4 h-4' />
        <button
          onClick={toggleDropdownById}
          className='ml-1 underline underline-offset-2 text-ellipsis max-w-[106px] truncate'
        >
          {post?.id}
        </button>
      </div>
      {isOpenByid && (
        // <div className='absolute z-10 right-0 mt-2 w-24 h-16 bg-white border rounded-lg shadow-lg'>
        <div className='absolute z-10 right-0 !border-text-dm-input !border-input-border !border-solid w-24 h-16 rounded-xl flex items-center justify-center bg-white p-1 shrink-0'>
          <ul>
            <li
              className='text-sm font-medium font-poppins text-black p-1'
              {...a11y(() => copyToClipBoard(post?.id, 'id'))}
            >
              Copy ID
            </li>
            <hr />
            <li
              className='text-sm font-medium font-poppins text-black p-1'
              {...a11y(() => copyToClipBoard(post?.id, 'url'))}
            >
              Copy Link
            </li>
          </ul>
        </div>
        // </div>
      )}
    </div>
  )
}

Post.propTypes = {
  post: PropsTypes.object,
}

export default Post
