import React from 'react'
import LightBtn from 'components/global/Buttons/LightBtn'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full  flex justify-start gap-x-3 items-center'>
      <LightBtn
        className='text-sm font-medium'
        onClick={() => {
          navigate('/settings/information')
        }}
        active={location.pathname.includes('/settings/information')}
      >
        Information
      </LightBtn>
      <LightBtn
        className='text-sm font-medium'
        onClick={() => {
          navigate('/settings/action-history')
        }}
        active={location.pathname.includes('/settings/action-history')}
      >
        Action History
      </LightBtn>
      <LightBtn
        className='text-sm font-medium'
        onClick={() => {
          navigate('/settings/bookmark')
        }}
        active={location.pathname.includes('/settings/bookmark')}
      >
        Bookmark
      </LightBtn>
      <LightBtn
        className='text-sm font-medium'
        onClick={() => {
          navigate('/settings/my-report')
        }}
        active={location.pathname.includes('/settings/my-report')}
      >
        My Report
      </LightBtn>
    </div>
  )
}

export default Header