import React, { useEffect, useMemo, useState } from 'react'
import { Card } from '@mui/material'
import { GoXCircle } from 'react-icons/go'
import PropTypes from 'prop-types'
import config from 'config/config'
import { defaultProfileImg } from '_utils/default'
import clsx from 'clsx'
import moment from 'moment'
import CustomTrash from '../icons/CustomTrash'
import LoaderIcon from '../LoaderIcon'
import { ChevronDown, ChevronUp } from 'lucide-react'
import axiosInstance from '_utils/axiosUtils'
import { Link } from 'react-router-dom'
import useAuth from '_hooks/useAuth'

const HashtagDrawer = ({
  onClose,
  posts,
  question,
  postType,
  name,
  postCount,
  followersCount,
  memoCount,
  answerCount,
  changeType,
}) => {
  const _URL = useMemo(() => {
    const file = posts?.find((_post) => {
      const _check = _post?.postMedia?.filter((value) =>
        value.fileType.includes('image')
      )
      if (_check.length > 0) return true
      return false
    })

    if (file) {
      return file?.postMedia[0]?.fileURL
    } else {
      return config.DEFAULT_HASHTAG_IMG
    }
  }, [posts])

  return (
    <Card className='p-5 !h-full !shadow-none w-[455px] '>
      <div className='grid grid-cols-3 gap-4 pt-5'>
        <div className='text-center'>
          <GoXCircle onClick={onClose} size={25} className='cursor-pointer' />
        </div>
        <div className='text-center'>
          <h2 className='text-xl not-italic font-bold leading-normal'>
            Profile
          </h2>
        </div>
      </div>
      <div className='p-1 mt-5 border-t-2 w-full'></div>
      <div className='p-2 flex'>
        <div className='w-1/4'>
          <img
            src={defaultProfileImg(_URL, 'hashtag')}
            alt='userProfile'
            className='rounded-full mt-3 !h-24 !w-24'
          />
        </div>
        <div className='ml-4 w-3/4'>
          <div className='flex items-start text-lg mt-3 not-italic font-semibold underline'>
            <h3 style={{ color: '#252525' }} className='break-words w-full'>
              #{name}
            </h3>
          </div>
          <div className='grid py-4 grid-cols-2 gap-4 text-black'>
            <div className='border-r-2'>
              <div>
                <p className='text-sm text-black font-normal'>Posts</p>
                <p
                  className='text-base font-medium'
                  style={{ color: '#2D2D2D' }}
                >
                  {postCount}
                </p>
              </div>
            </div>
            <div>
              <div className='pl-12'>
                <p className='text-sm text-black font-normal'>Followers</p>
                <p
                  className='text-base font-medium'
                  style={{ color: '#2D2D2D' }}
                >
                  {followersCount}
                </p>
              </div>
            </div>
          </div>
          <div className='grid py-4 grid-cols-2 gap-4 text-black'>
            <div className='border-r-2'>
              <div>
                <p className='text-sm text-black font-normal'>Questions</p>
                <p
                  className='text-base font-medium'
                  style={{ color: '#2D2D2D' }}
                >
                  {memoCount}
                </p>
              </div>
            </div>
            <div>
              <div className='pl-12'>
                <p className='text-sm text-black font-normal'>Answers</p>
                <p
                  className='text-base font-medium'
                  style={{ color: '#2D2D2D' }}
                >
                  {answerCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex bg-bg-tabs rounded-xl p-1 h-fit justify-around gap-x-2 border border-solid border-text-dm-input'>
        <button
          className={clsx(
            'font-semibold py-2 w-full h-11 transition-all text-sm',
            postType !== 'post' && 'text-text-lm-primary',
            postType === 'post' &&
              'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={(e) => {
            e.stopPropagation()
            changeType('post')
          }}
        >
          Post
        </button>
        <button
          className={clsx(
            'font-semibold z-10 py-2 w-full h-11 transition-all text-sm',
            postType !== 'question' && 'text-text-lm-primary',
            postType === 'question' &&
              'text-white bg-text-dm-input shadow-buttonPrimary rounded-xl '
          )}
          onClick={(e) => {
            e.stopPropagation()
            changeType('question')
          }}
        >
          Memo
        </button>
      </div>
      <div className='grid grid-cols-2 gap-4 mt-5'>
        {ListingMap(postType, posts, question, onClose)}
      </div>
    </Card>
  )
}

const ListingMap = (type, posts, questions, onClose) => {
  const [selectedMemo, setSelectedMemo] = useState(null)

  switch (type) {
    case 'post': {
      if (!posts || posts?.length === 0) return 'No posts'
      return posts?.map((_post, index) => (
        <Card key={index} className='!shadow-none'>
          {_post?.postMedia[0]?.fileType.includes('image') ? (
            <Link
              to={`/user/${_post?.users?.id}?p_id=${_post?.id}`}
              onClick={onClose}
            >
              <img
                src={`${config.FTP_URL}${_post?.postMedia[0]?.fileURL}`}
                alt={_post?.user?.username}
                className='rounded-xl w-52 h-36'
              />
            </Link>
          ) : (
            <Link
              to={`/user/${_post?.users?.id}?p_id=${_post?.id}`}
              onClick={onClose}
            >
              <video muted width='320' height='240' controls>
                <source
                  src={`${config.FTP_URL}${_post?.postMedia[0]?.fileURL}`}
                  type={_post?.postMedia[0]?.fileType}
                />
              </video>
            </Link>
          )}
          <div className='flex items-center py-3'>
            <img
              src={defaultProfileImg(_post?.users?.profileImage, 'user')}
              className='rounded-full w-8 h-8'
              alt='user'
            />
            <div className='ml-2'>
              <Link
                to={`/user/${_post?.users?.id}`}
                onClick={onClose}
                className='text-sm text-black font-medium block'
              >
                {_post?.users?.fullName
                  ? _post?.users?.fullName
                  : _post?.users?.username}
              </Link>
              <Link
                to={`/user/${_post?.users?.id}`}
                onClick={onClose}
                className='text-xs text-black underline font-normal cursor-pointer'
              >
                {_post?.users?.username}
              </Link>
            </div>
          </div>
        </Card>
      ))
    }
    case 'question': {
      if (!questions || questions?.length === 0) return 'No posts'
      return (
        <div className='h-full py-3 w-[420px] flex justify-center'>
          <div className='flex flex-col gap-y-3 pr-2 pb-3 overflow-y-auto memoScrollBar  w-full'>
            {questions?.map((ques, index) => {
              return (
                <QuestionCard
                  key={index}
                  setSelected={(_id) => setSelectedMemo(_id)}
                  isSelected={Boolean(selectedMemo?.id === ques?.id)}
                  question={ques?.title}
                  description={ques?.description}
                  creation={moment(ques?.createdAt).format('DD/MM/YYYY')}
                  answer_count={ques?._count?.comments}
                  answers={ques?.comments}
                  profile={ques?.users?.profileImage}
                  value={ques}
                  memoId={selectedMemo?.id}
                />
              )
            })}
            {/* {
              selectedMemo && (
                <div className='w-full flex flex-col pl-2 shadow-customBtnShadow'>
                  <h1 className='text-sm py-1 text-text-dm-input'>Answers ({answersCount})</h1>
                  <div className='flex flex-col gap-y-3'>
                    {
                      answers?.map((ans, index) => (
                        <AnswerCard
                          key={index}
                          value={ans}
                          profile={defaultProfileImg(ans?.user?.profileImage, 'user')}
                          description={ans?.comment}
                          isBest={ans?.isBest}
                          creation={ans?.createdAt}
                          deleteAnswer={deleteAnswer}
                        />
                      ))
                    }
                  </div>
                </div>
              )
            } */}
          </div>
        </div>
      )
    }
    default: {
      return null
    }
  }
}

const QuestionCard = ({
  isSelected,
  question,
  description,
  profile,
  creation,
  memoId,
  setSelected,
  value,
}) => {
  const { user } = useAuth()

  const [answersCount, setAnswersCount] = useState(null)
  const [answers, setAnswers] = useState([])

  const deleteAnswer = async (ids) => {
    await axiosInstance
      .delete(`/admin/question/${memoId}/answers`, {
        data: { ids },
      })
      .then((res) => res.data)
      .then((data) => {
        setAnswersCount(data?.data?._count)
        setAnswers((prev) => prev.filter((com) => !ids.includes(com?.id)))
      })
      .catch((err) => console.log(err))
  }

  const fetchAnswers = async () => {
    await axiosInstance
      .get(`/admin/question/${memoId}/answers`)
      .then((res) => res.data)
      .then((data) => {
        setAnswers(data?.data?.answers)
        setAnswersCount(data?.data?._count)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (memoId) {
      fetchAnswers()
    }
  }, [memoId])

  const [more, setMore] = useState(false)
  const [descriptionmore, setdescriptionmore] = useState(false)
  const [loading, setLoading] = useState(false)

  const startDeletion = async (id) => {
    setLoading(true)
    await deleteAnswer(id ? [id] : answers?.map((item) => item?.id))
    setLoading(false)
  }
  return (
    <div
      className={clsx(
        'shadow-customBtnShadow p-3 rounded-xl flex-col gap-x-2 relative transition-shadow h-max w-full',
        isSelected && '!shadow-active-btn border border-text-dm-input'
      )}
    >
      <div className='flex h-max'>
        <div className='w-1/5'>
          <img
            src={defaultProfileImg(profile, 'user')}
            style={{ height: '30px', width: '30px', borderRadius: '50%' }}
            alt='memoImg'
          />
        </div>

        <div className='w-full h-full'>
          <p className='text-sm font-medium'>{question}</p>
          <p className='text-xs font-normal'>
            {descriptionmore || description.length <= 30
              ? description
              : description.substring(0, 30) + '...'}
            {description.length > 30 && (
              <button
                className='text-text-dm-input text-xs font-normal underline'
                onClick={() => {
                  if (descriptionmore) {
                    setdescriptionmore(false)
                  } else {
                    setdescriptionmore(true)
                  }
                }}
              >
                {descriptionmore ? 'see less' : 'see more'}
              </button>
            )}
            {/* {description?.substring(0, 30) + '...'} */}
          </p>
        </div>

        <div className='w-1/5 '>
          <div>
            <h6 className=' text-xxs font-light'>{creation}</h6>
          </div>
          <div className='flex justify-end'>
            <ChevronDown
              className={`${isSelected ? 'hidden' : ''}`}
              size={25}
              onClick={() => {
                if (isSelected) {
                  setSelected(null)
                } else {
                  setSelected(value)
                }
              }}
            />

            <ChevronUp
              className={`${isSelected ? '' : 'hidden'}`}
              size={25}
              onClick={() => {
                if (isSelected) {
                  setSelected(null)
                } else {
                  setSelected(value)
                }
              }}
            />
          </div>
          {isSelected && (
            <div className='flex justify-end items-end '>
              <button
                className='p-3 rounded-xl shadow-customBtnShadow'
                onClick={startDeletion}
              >
                <CustomTrash size={14} fillColor='#f00' />
              </button>
            </div>
          )}
        </div>
      </div>
      {isSelected ? (
        <>
          <div
            className='py-2 pl-1 mt-3 text-xs font-medium'
            style={{
              borderColor: '#C4C4C4',
              borderTopWidth: 1,
              color: '#00BDB2',
            }}
          >
            Answers {`(${answersCount})`}
          </div>

          {answers?.map((ans, index) => {
            return (
              <div key={index} className='p-3 rounded-xl flex gap-2 w-full'>
                <img
                  src={defaultProfileImg(ans?.user?.profileImage, 'user')}
                  className='w-8 h-8 rounded-full'
                  alt='memoImg'
                />
                <div className='flex flex-row justify-between flex-grow w-11/12'>
                  <div className='flex items-start'>
                    <h6 className='text-xs font-normal'>
                      {more || ans?.comment?.length <= 30
                        ? ans?.comment
                        : ans?.comment?.substring(0, 30) + '...'}
                      {ans?.comment?.length > 30 && (
                        <button
                          className='text-text-dm-input text-xs font-normal underline'
                          onClick={() => {
                            if (more) {
                              setMore(false)
                            } else {
                              setMore(true)
                            }
                          }}
                        >
                          {more ? 'see less' : 'see more'}
                        </button>
                      )}
                    </h6>
                    {ans.isBest && (
                      <img
                        src='/assets/svg/comment-badge.svg'
                        alt='Comment Badge'
                      />
                    )}
                  </div>
                  <div className='flex justify-end items-center'>
                    {user?.authorityName?.includes('deletion_memo') && (
                      <button
                        className='p-3 rounded-xl shadow-customBtnShadow'
                        onClick={() => startDeletion(ans?.id)}
                      >
                        {!loading ? (
                          <CustomTrash size={14} fillColor='#f00' />
                        ) : (
                          <div className='flex justify-center items-center'>
                            <LoaderIcon className='!w-5 !h-5' />
                          </div>
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ) : (
        <div
          className='py-2 mt-3 flex justify-end text-xs font-medium'
          style={{
            borderColor: '#C4C4C4',
            borderTopWidth: 1,
            color: '#00BDB2',
          }}
        >
          {/* <img
              src={'https://res.cloudinary.com/dhpvia1ae/image/upload/v1709189036/Twitter_pvz6lp.jpg'}
              alt='memoCommentImg'
              className='w-4 h-4 rounded-full z-10 border border-[#C4C4C4]'
            />
            <img
              src={'https://res.cloudinary.com/dhpvia1ae/image/upload/v1709189036/Twitter_pvz6lp.jpg'}
              alt='memoCommentImg'
              className='w-4 h-4 rounded-full z-3 border border-[#C4C4C4]'
              style={{ marginLeft: '-5px' }}
            />
            {answersCount} */}
          answers
        </div>
      )}
    </div>
  )
}

// const AnswerCard = ({
//   profile,
//   description,
//   isBest,
//   value,
//   deleteAnswer,
// }) => {
//   const [more, setMore] = useState(false)
//   const [loading, setLoading] = useState(false)

//   const startDeletion = async () => {
//     setLoading(true)
//     await deleteAnswer([value?.id])
//     setLoading(false)
//   }
//   return (
//     <div className='shadow-customBtnShadow p-3 rounded-xl flex gap-2 w-full'>
//       <img
//         src={profile}
//         className='w-8 h-8 rounded-full'
//         alt='memoImg'
//       />
//       <div className='flex flex-row justify-between flex-grow w-11/12'>
//         <div className='flex items-start'>
//           <h6 className='text-xs font-normal'>
//             {more || description?.length <= 30 ? description : description?.substring(0, 30) + '...'}
//             {
//               description?.length > 30 && (
//                 <button
//                   className='text-text-dm-input text-xs font-normal underline'
//                   onClick={() => {
//                     if (more) {
//                       setMore(false)
//                     } else {
//                       setMore(true)
//                     }
//                   }}
//                 >
//                   {more ? 'see less' : 'see more'}
//                 </button>
//               )
//             }
//           </h6>
//           {
//             isBest && (
//               <img
//                 src='/assets/svg/comment-badge.svg'
//                 alt='Comment Badge'
//               />
//             )
//           }
//         </div>
//         <div className='flex justify-end items-center'>
//           {/* <h6 className='text-xxs font-light'>{moment(creation).format('DD/MM/YYYY')}</h6> */}
//           <button
//             className='p-3 rounded-xl shadow-customBtnShadow'
//             onClick={startDeletion}
//           >
//             {
//               !loading ? <CustomTrash size={14} fillColor='#f00' /> : <div className='flex justify-center items-center'><LoaderIcon className='!w-5 !h-5' /></div>
//             }
//           </button>
//         </div>
//       </div>
//     </div>
//   )
// }

QuestionCard.propTypes = {
  isSelected: PropTypes.bool,
  question: PropTypes.string,
  description: PropTypes.string,
  creation: PropTypes.string,
  profile: PropTypes.string,
  answer_count: PropTypes.number,
  memoId: PropTypes.number,
  answers: PropTypes.array,
  setSelected: PropTypes.func,
  value: PropTypes.object,
}

// AnswerCard.propTypes = {
//   value: PropTypes.object,
//   profile: PropTypes.string,
//   description: PropTypes.string,
//   isBest: PropTypes.bool,
//   creation: PropTypes.string,
//   deleteAnswer: PropTypes.func
// }

HashtagDrawer.propTypes = {
  onClose: PropTypes.func,
  changeType: PropTypes.func,
  posts: PropTypes.array,
  question: PropTypes.array,
  postType: PropTypes.oneOf(['post', 'question']),
  name: PropTypes.string,
  postCount: PropTypes.number,
  followersCount: PropTypes.number,
  memoCount: PropTypes.number,
  answerCount: PropTypes.number,
}

export default HashtagDrawer
