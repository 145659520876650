import GlobalAccordion from 'components/global/GlobalAccordion'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropsTypes from 'prop-types'
import HomeContext from 'context/home/HomeContext'

const HomeFilter = ({ onClose }) => {
  const {
    handleSetPostFilter,
    payload,
    posts: { _posts },
  } = useContext(HomeContext)
  const [payloadTo, setPayloadTo] = useState(payload?.to ? payload?.to : null)
  const [payloadFrom, setPayloadFrom] = useState(
    payload?.from ? payload?.from : null
  )
  const [picOptions, setPicOptions] = useState([])
  const [selectedPicOption, setSelectedPicOptions] = useState([])

  const shortCodeForLang = {
    English: 'en',
    Japanese: 'ja',
    en: 'English',
    ja: 'Japanese',
  }

  useEffect(() => {
    let tempArray = []
    _posts?.forEach((post) => {
      let idArray = tempArray?.map((item) => item?.id)
      let idIncludes = idArray?.includes(post?.pic?.id)
      if (!idIncludes && post?.pic?.id) {
        tempArray?.push(post?.pic)
      }
    })
    setPicOptions(
      tempArray?.map((item) => {
        return { value: item?.id, label: item?.fullName }
      })
    )
  }, [_posts])

  useEffect(() => {
    if (payload?.U_points?.length > 0) {
      let u_points = payload?.U_points
      let tempObj = {}
      u_points?.forEach((point) => {
        tempObj = { ...tempObj, [Number(point)]: true }
      })
      setUserPoints((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.scores?.length > 0) {
      let scores = payload?.scores
      let tempObj = {}
      scores?.forEach((point) => {
        tempObj = { ...tempObj, [Number(point)]: true }
      })
      setScores((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.lang?.length > 0) {
      let lang = payload?.lang
      let tempObj = {}
      lang?.forEach((point) => {
        tempObj = { ...tempObj, [`${shortCodeForLang[point]}`]: true }
      })
      setLanguages((prev) => ({ ...prev, ...tempObj }))
    }
    if (payload?.status?.length > 0) {
      let status = payload?.status
      let tempObj = {}
      status?.forEach((point) => {
        if (point === 'under_review') {
          tempObj = { ...tempObj, ['Under review']: true }
        } else {
          tempObj = {
            ...tempObj,
            [`${point.charAt(0).toUpperCase() + point.slice(1)}`]: true,
          }
        }
      })
      setStatus((prev) => ({ ...prev, ...tempObj }))
    }
    setSelectedPicOptions(payload?.pic ? payload?.pic : [])
  }, [payload])

  const rightSideNumbers = ['5', '4', '3', '2', '1']
  const leftSideNumbers = ['10', '9', '8', '7', '6']
  const [userPoints, setUserPoints] = useState({
    10: false,
    5: false,
    9: false,
    4: false,
    8: false,
    3: false,
    7: false,
    2: false,
    6: false,
    1: false,
  })

  const [languages, setLanguages] = useState({
    English: false,
    Japanese: false,
  })

  const [status, setStatus] = useState({
    Approved: false,
    'Under review': false,
    Pending: false,
    Disapproved: false,
  })

  const [scores, setScores] = useState({
    10: false,
    5: false,
    9: false,
    4: false,
    8: false,
    3: false,
    7: false,
    2: false,
    6: false,
    1: false,
  })

  const handleApplyFilter = () => {
    const updatedPoints = []
    let payload = {}

    for (const [key, value] of Object.entries(userPoints)) {
      if (value) {
        updatedPoints.push(key)
      }
    }
    payload.U_points =
      updatedPoints?.length > 0
        ? updatedPoints?.map((point) => Number(point))
        : null

    const checkedLanguages = []
    for (const [key, value] of Object.entries(languages)) {
      if (value) {
        checkedLanguages.push(key)
      }
    }
    payload.lang =
      checkedLanguages?.length > 0
        ? checkedLanguages?.map((lang) => {
            return shortCodeForLang[lang]
          })
        : null

    const checkedStatus = []
    for (const [key, value] of Object.entries(status)) {
      if (value) {
        checkedStatus.push(key)
      }
    }

    payload.status =
      checkedStatus?.length > 0
        ? checkedStatus?.map((status) => {
            let string = status.toLowerCase()
            if (string?.includes('review')) {
              return 'under_review'
            }
            return string
          })
        : null

    const checkedScores = []
    for (const [key, value] of Object.entries(scores)) {
      if (value) {
        checkedScores.push(key)
      }
    }
    payload.p_points =
      checkedScores?.length > 0
        ? checkedScores?.map((point) => Number(point))
        : null

    payload.pic = selectedPicOption?.length > 0 ? selectedPicOption : null
    handleSetPostFilter({ ...payload, to: payloadTo, from: payloadFrom })
    onClose()
  }

  const handleUserPointsChange = (event) => {
    const { name, checked } = event.target
    setUserPoints((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleLanguageChange = (event) => {
    const { name, checked } = event.target
    setLanguages((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleStatusChange = (event) => {
    const { name, checked } = event.target
    setStatus((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleScoreChange = (event) => {
    const { name, checked } = event.target
    setScores((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handlePICOptionChange = (event) => {
    const { value } = event.target
    if (selectedPicOption?.includes(value)) {
      setSelectedPicOptions((prev) => prev.filter((option) => option !== value))
    } else {
      setSelectedPicOptions((prev) => [...prev, value])
    }
  }

  return (
    <Fragment>
      <div className='overscroll-contain'>
        <div className='max-h-[65vh] overflow-y-auto megaFilterScroll bg-universal'>
          {/* creation Date */}
          <div className='min-w-[16rem] pb-3'>
            <label className='block relative'>
              <span className='block text-sm font-semibold text-gray-800'>
                From:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 outline-none py-2 px-4 w-full rounded-2xl no-calendar'
                placeholder='MM/DD/YYYY'
                type='date'
                value={payloadFrom}
                onChange={(e) => setPayloadFrom(e.target.value)}
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2 hover:cursor-pointer'
                style={{ height: '20px', width: '20px' }}
              />
            </label>

            <label className='block relative'>
              <span className='block text-sm font-semibold text-gray-800'>
                To:
              </span>
              <input
                className='border-2 border-gray-200 bg-universal hover:border-teal-400 cursor-pointer outline-none py-2 px-4 w-full rounded-2xl no-calendar '
                placeholder='MM/DD/YYYY'
                type='date'
                value={payloadTo}
                onChange={(e) => setPayloadTo(e.target.value)}
              />
              <img
                src='/assets/Icons/calendar-2.svg'
                alt='calendar'
                className='absolute right-5 top-11 transform -translate-y-1/2'
                style={{ height: '20px', width: '20px' }}
              />
            </label>
          </div>

          {/* user points  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>User points</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
                <div>
                  {leftSideNumbers.map((key) => (
                    <div
                      className='flex flex-nowrap items-center py-1'
                      key={key}
                    >
                      <input
                        type='checkbox'
                        name={key}
                        checked={userPoints[key]}
                        onChange={handleUserPointsChange}
                      />{' '}
                      &nbsp;&nbsp;
                      <h6 className='font-medium text-sm'>{key} points</h6>
                    </div>
                  ))}
                </div>
                <div>
                  {rightSideNumbers.map((key) => (
                    <div
                      className='flex flex-nowrap items-center py-1'
                      key={key}
                    >
                      <input
                        type='checkbox'
                        name={key}
                        checked={userPoints[key]}
                        onChange={handleUserPointsChange}
                      />{' '}
                      &nbsp;&nbsp;
                      <h6 className='font-medium text-sm'>{key} points</h6>
                    </div>
                  ))}
                </div>
              </div>
            }
          />

          {/* Language  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Language</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
                {Object.entries(languages).map(([key, value]) => (
                  <div className='flex flex-nowrap items-center' key={key}>
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleLanguageChange}
                    />{' '}
                    &nbsp;&nbsp; <h6 className='font-medium text-sm'>{key}</h6>
                  </div>
                ))}
              </div>
            }
          />

          {/* Status  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Status</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
                {Object.entries(status).map(([key, value]) => (
                  <div className='flex flex-nowrap items-center' key={key}>
                    <input
                      type='checkbox'
                      name={key}
                      checked={value}
                      onChange={handleStatusChange}
                    />{' '}
                    &nbsp;&nbsp; <h6 className='font-medium text-sm'>{key}</h6>
                  </div>
                ))}
              </div>
            }
          />

          {/* Score */}
          <GlobalAccordion
            header={<h6 className='font-medium'>Score</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-2 gap-y-2'>
                <div>
                  {leftSideNumbers.map((key) => (
                    <div
                      className='flex flex-nowrap items-center py-1'
                      key={key}
                    >
                      <input
                        type='checkbox'
                        name={key}
                        checked={scores[key]}
                        onChange={handleScoreChange}
                      />{' '}
                      &nbsp;&nbsp;
                      <h6 className='font-medium text-sm'>{key}</h6>
                    </div>
                  ))}
                </div>
                <div>
                  {rightSideNumbers.map((key) => (
                    <div
                      className='flex flex-nowrap items-center py-1'
                      key={key}
                    >
                      <input
                        type='checkbox'
                        name={key}
                        checked={scores[key]}
                        onChange={handleScoreChange}
                      />{' '}
                      &nbsp;&nbsp;
                      <h6 className='font-medium text-sm'>{key}</h6>
                    </div>
                  ))}
                </div>
              </div>
            }
          />

          {/* PIC  */}
          <GlobalAccordion
            header={<h6 className='font-medium'>PIC</h6>}
            content={
              <div className='min-w-[16rem] grid grid-cols-1 gap-y-2'>
                {picOptions?.map((item) => (
                  <div className='flex flex-nowrap items-center' key={item?.id}>
                    <input
                      type='checkbox'
                      name={item?.label}
                      value={item?.value}
                      checked={selectedPicOption.includes(item?.value)}
                      onChange={handlePICOptionChange}
                    />{' '}
                    &nbsp;&nbsp;{' '}
                    <h6 className='font-medium text-sm'>{item?.label}</h6>
                  </div>
                ))}
              </div>
            }
          />
        </div>
        {/* apply and cancel button  */}
        <div className='flex gap-x-4 justify-center mt-3 mb-5'>
          <button
            // onClick={handleSubmit}
            onClick={handleApplyFilter}
            className='py-2 w-2/6  rounded-xl bg-text-dm-input shadow-buttonPrimary text-white'
          >
            Apply
          </button>
          <button
            onClick={onClose}
            className='border border-text-dm-input py-2 w-2/6 rounded-xl text-gray-400'
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  )
}

HomeFilter.propTypes = {
  onClose: PropsTypes.func,
}
export default HomeFilter
