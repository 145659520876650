const adjustTime = (originalDate, date) => {
  const [year, month, day] = date.split('-')
  // "2024-10-14
  // Modify the date to the desired target date (Oct 16, 2024)
  originalDate.setFullYear(year) // Change the year to 2024
  originalDate.setMonth(Number(month) - 1) // Change the month to October (0-indexed, so 9 is October)
  originalDate.setDate(day) // Change the day to 16th

  // Now the date is set to Wed Oct 16 2024 05:32:00 GMT+0530
  return originalDate.toString()
}

export default adjustTime
