export const a11y = (onTriggered, role = 'button') => {
  return {
    role,
    tabIndex: 0,
    onClick(e) {
      onTriggered?.(e)
    },
    onKeyUp(e) {
      if (e.key === ' ' || e.key === 'Enter') {
        onTriggered?.(e)
      }
    }
  }
}