import { Hash } from 'lucide-react'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FiHeart } from 'react-icons/fi'
import PropsTypes from 'prop-types'
import moment from 'moment'
import { checkImageFile, defaultProfileImg } from '_utils/default'

const PostCard = ({ post }) => {

  const _postURL = useMemo(() => {
    return checkImageFile(post?.postMedia)
  }, [post])
  return (
    <div className='p-4 shadow-customBtnShadow flex-col rounded-xl max-w-xs w-48 max-h-full flex items-end'>
      <div className='flex gap-x-3'>
        <div className='flex-1 object-cover border overflow-x-hidden aspect-card-img rounded-2xl bg-gradient-to-t from-card-img-gradient via-transparent to-transparent'>
          {post?.postMedia && post?.postMedia[0]?.fileType === 'video/mp4' ? (
            <div className='w-full h-full overflow-hidden'>
              <Link to={`${location.pathname}?p_id=${post?.id}`} >
                <video >
                  <source src={defaultProfileImg(post.postMedia[0].fileURL)} type='video/mp4' />
                  <track kind='captions' src='' label='' />
                  Your browser does not support the video tag.
                </video>
              </Link>
            </div>
          ) : (
            <Link to={`${location.pathname}?p_id=${post?.id}`} >
              <img
                className='w-full h-full'
                src={defaultProfileImg(_postURL, 'post')}
                alt=''
              />
            </Link>
          )}
        </div>

        {/* Right side icons  */}
        <div className='flex flex-col gap-y-2 items-start'>
          <span className='flex flex-nowrap'>
            <img src='/assets/Icons/eye.svg' alt='eye' className='w-4' /> &nbsp;{' '}
            <u className='text-sm'>0</u>
          </span>
          <span className='flex flex-nowrap justify-center items-center'>
            <FiHeart size={16} fill='#f00' color='#f00' /> &nbsp;
            <u className='text-sm'>{post?._count?.likes}</u>
          </span>
          <span className='flex flex-nowrap items-center'>
            <img src='/assets/Icons/bookmark.svg' alt='eye' className='w-4' />{' '}
            &nbsp; <u className='text-sm'>{post?._count?.saved}</u>
          </span>
          <span className='flex flex-nowrap items-center'>
            <img src='/assets/Icons/message.svg' alt='eye' className='w-4' />{' '}
            &nbsp; <u className='text-sm'>{post?._count?.comments}</u>
          </span>
          <span className='flex flex-nowrap items-center'>
            <img src='/assets/Icons/shared.svg' alt='eye' className='w-4' />{' '}
            &nbsp; <u className='text-sm'>0</u>
          </span>
        </div>
      </div>
      {/* card info  */}
      <div className='pt-1 w-full flex flex-col'>
        <span className='flex flex-nowrap gap-x-2 text-sm font-semibold underline'>
          <img src='/assets/Icons/id.svg' alt='' className='w-5 h-5' />
          <button
            className='text-ellipsis max-w-[106px] truncate'
          >
            <Link to={`${location.pathname}?p_id=${post?.id}`} >{post?.id}</Link>
          </button>
        </span>
        <PostTagsRender tags={post?.postTags} />
      </div>
      <span className='flex items-center flex-nowrap mt-auto mr-auto text-sm text-text-dm-card'>
        {post?.createdAt && moment(post?.createdAt).format('MM/DD/YYYY')}
      </span>
    </div>
  )
}

PostCard.propTypes = {
  post: PropsTypes.object,
  setComponent: PropsTypes.func,
}

const PostTagsRender = ({ tags }) => {
  return (
    <div className='flex justify-start items-center flex-wrap gap-1'>
      {tags?.length <= 3
        ? tags?.map((_tag) => (
          <span
            key={_tag?.id}
            className='flex justify-start items-center flex-nowrap text-sm font-semibold text-text-dm-card w-fit'
          >
            <Hash size={15} />
            {_tag?.hashtag?.name}
          </span>
        ))
        : tags?.slice(0, 3).map((_tag) => (
          <span
            key={_tag?.id}
            className='flex justify-start items-center flex-nowrap text-sm font-semibold text-text-dm-card w-fit'
          >
            <Hash size={15} />
            {_tag?.hashtag?.name}
          </span>
        ))}
      {tags?.length > 3 && <span>&nbsp;+{tags?.length - 3}</span>}
    </div>
  )
}
PostTagsRender.propTypes = {
  tags: PropsTypes.array,
}

export default PostCard
