import React, { Fragment, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { MdMessage } from 'react-icons/md'
import useHome from '_hooks/useHome'
import moment from 'moment'
import config from 'config/config'
import Loader from 'components/global/Loader'
import RightCanvas from 'components/global/RightCanvas'
import { PlusCircle } from 'lucide-react'
import Input from 'components/global/Input'
import Score from 'components/global/Score'
import Status from 'components/global/Status'
import Post from 'components/global/Post'
import User from 'components/global/User'
import useAuth from '_hooks/useAuth'
import { connectSocket, disconnectSocket, sendMessage } from '../../socket' // Import socket functions

export default function Detection() {
  const {
    posts: { loading, _posts },
  } = useHome()
  const { user } = useAuth()

  //.....  Chat .....//
  const [openChatHistory, setOpenChatHistory] = useState(false)
  const [chatLoader, setChatLoader] = useState(false)
  const [messages, setMessages] = useState([])
  const currentPostId = useRef(null)
  const messageEndRef = useRef(null)
  const inputRef = useRef()

  const handleSendMessage = () => {
    if (inputRef.current.value.trim()) {
      const messageData = {
        contentId: currentPostId.current,
        adminId: user?.id,
        adminFullName: user?.fullName || 'Admin',
        message: inputRef.current.value,
        createdAt: moment().format(),
      }

      sendMessage(messageData) // Use sendMessage from the socket.js
      inputRef.current.value = ''
    }
  }

  useEffect(() => {
    if (openChatHistory) {
      scrollToBottom()
    }
  }, [messages, openChatHistory])

  // Function to scroll to the bottom of chat messages
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleOpenChatHistory = (postId) => {
    currentPostId.current = postId
    setOpenChatHistory(true)
    setChatLoader(true)
    connectSocket(
      postId,
      (message) => {
        setMessages((prevMessages) => [...prevMessages, message])
        scrollToBottom()
      },
      (oldMessages) => {
        setChatLoader(false)
        setMessages(oldMessages)
        scrollToBottom()
      }
    )
  }

  const chatClose = () => {
    setOpenChatHistory(false)
    disconnectSocket() // Disconnect socket when chat is closed
  }

  const customCellStyles = {
    cells: {
      style: {
        fontWeight: '500',
        fontSize: '12px',
        background: 'var(--bg-color)',
        lineHeight: 'normal',
        color: '#000',
      },
    },
    headCells: {
      style: {
        borderRight: '1px solid #C4C4C4',
        height: '35px',
      },
    },
  }

  const columns = [
    {
      name: (
        <div className='flex w-full'>
          Date & Time
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) =>
        moment(row?.createdAt).format(`${config.DATE_FORMAT} HH:mm:ss`),
      sortable: true,
      minWidth: '100px',
    },
    {
      name: (
        <div className='flex w-full'>
          User Info
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '175px',
      cell: (row) => (
        <User
          user={row?.users}
          access={user?.authorityName.includes('change_point')}
        />
      ),
    },
    {
      name: (
        <div className='flex w-full'>
          Posted Content
          {/* <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          /> */}
        </div>
      ),
      sortable: true,
      minWidth: '180px',
      cell: (row) => <Post post={row} />,
    },
    {
      name: (
        <div className='flex w-full'>
          Language
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '50px',
      cell: (row) => <div className='capitalize'>{row?.language}</div>,
    },
    {
      name: (
        <div className='flex w-full'>
          Status
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) => row?.contentStatus,
      sortable: true,
      minWidth: '50px',
      cell: (row) => (
        <Status
          contentStatus={row?.contentStatus}
          id={row?.id}
          pic={row?.pic}
          access={user?.authorityName.includes('change_status')}
        />
      ),
    },
    {
      name: (
        <div className='flex w-full'>
          Score
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      sortable: true,
      minWidth: '20px',
      cell: (row) => (
        <Score
          score={row?.score ? row?.score : null}
          id={row?.id}
          access={user?.authorityName.includes('change_score')}
        />
      ),
    },
    {
      name: (
        <div className='flex w-full'>
          PIC
          <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          />
        </div>
      ),
      selector: (row) => row?.pic?.fullName || 'None',
      sortable: true,
      minWidth: '30px',
    },
    {
      name: (
        <div className='flex w-full'>
          Chat
          {/* <img
            src='/assets/svg/Filter.svg'
            alt='SearchIcon'
            className='w-5 h-5 ml-2'
          /> */}
        </div>
      ),
      sortable: true,
      minWidth: '10px',
      cell: (row) => (
        <div className='flex'>
          <MdMessage
            size={25}
            className='!text-text-dm-input cursor-pointer'
            onClick={() => handleOpenChatHistory(row.id)}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <div className='rounded-xl w-full max-w-full h-full'>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <DataTable
            columns={columns}
            data={_posts}
            highlightOnHover
            selectableRows
            customStyles={customCellStyles}
            className='custom-data-table'
          />
        )}
      </div>

      {/* Chat history */}
      <RightCanvas isOpen={openChatHistory} onClose={chatClose}>
        <div className='h-screen flex flex-col overflow-hidden'>
          {' '}
          {/* Prevent outer scrolling */}
          {/* Sticky Header */}
          <div className='relative p-5 border-b sticky top-0 bg-white z-10'>
            {' '}
            {/* Sticky header */}
            <PlusCircle
              className='rotate-45 absolute left-4 top-5 cursor-pointer'
              onClick={chatClose}
            />
            <h1 className='text-center text-base font-bold'>Chat</h1>
          </div>
          {/* Chat messages */}
          <div className='flex flex-col justify-between h-full overflow-hidden'>
            <div className='p-5 overflow-y-auto flex-grow'>
              {' '}
              {/* Only this section scrolls */}
              {chatLoader ? (
                <Loader loading={chatLoader} />
              ) : (
                <Fragment>
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`p-4 shadow-customBtnShadow max-w-xs rounded-xl mt-3 clear-both max-w-[250px] ${
                        msg.adminId === user?.id ? 'float-right' : 'float-left'
                      }`}
                    >
                      <span className='w-full flex justify-between items-center gap-3'>
                        <h4 className='text-sm font-medium'>
                          {msg.adminFullName || 'Unknown'}
                        </h4>
                        <h5 className='text-xs font-light '>
                          {moment(msg.createdAt).format('HH:mm')}
                        </h5>
                      </span>
                      <div className='text-sm font-light max-w-[218px]'>
                        <p className='text-wrap break-all'>{msg.message}</p>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
              <div ref={messageEndRef} /> {/* Scroll to this div */}
            </div>

            {/* Sticky Input message */}
            <div className='p-5 sticky bottom-0 bg-white z-10'>
              {' '}
              {/* Sticky input */}
              <Input
                ref={inputRef}
                placeHolder='Start typing message...'
                inputClassName='bg-white'
                className='!p-1 !border-gray-400 !pl-5 !text-sm'
                rightIcon={
                  <button
                    onClick={handleSendMessage}
                    className='bg-text-dm-input shadow-buttonPrimary p-2 flex justify-center items-center rounded-xl'
                  >
                    <img src='/assets/svg/send-2.svg' alt='SendBtnIcon' />
                  </button>
                }
              />
            </div>
          </div>
        </div>
      </RightCanvas>
    </>
  )
}
