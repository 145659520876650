import React, { useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { PiWarningCircle } from 'react-icons/pi';
import useAuth from '_hooks/useAuth'


export default function NewPassword() {
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const { login, errorMessage, isError } = useAuth()
  const navigate = useNavigate()

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    await login(data.get('email'), data.get('password'))
      .then(() => {
        if (isError) {
          console.log(errorMessage)
          return
        }
        navigate('/home')
      })
  }

  const GoToLogin = () => {
    navigate('/')
  }

  return (
    <div className='min-h-screen flex'>
      <div
        className='hidden xl:flex justify-center items-center xl:w-5/12 bg-cover bg-center bg-gradient-to-t from-bg-memo-start to-bg-memo-stop'
      >
        <img src='/assets/hashmemo.svg' alt='Hashmemo Logo' />
      </div>
      <div className='xl:w-7/12 grid p-20'>
        <div className='w-full shadow-boxInMain rounded-2xl p-14 grid'>
          <div className='flex flex-col items-start row-start-1'>
            <h1 className='text-2xl font-semibold mt-4'>Enter a new password</h1>
          </div>
          <form onSubmit={handleSubmit} className=' row-start-2'>
            <div className='mb-10 mt-3 relative'>
              <label htmlFor='password' className='flex items-center gap-2 text-left text-black-700 font-medium'>
                <img src='/assets/svg/lock-green.svg' alt='email' /> New Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                required
                className='w-full mt-1 p-2 pl-4 border rounded-xl outline-none'
                autoComplete='current-password'
                placeholder='Enter Your New Password'
              />
              <div className='absolute top-12 right-3 transform -translate-y-1/2 flex items-center'>
                <button
                  type='button'
                  onClick={togglePasswordVisibility}
                  className='text-gray-500 focus:outline-none'
                >
                  {showPassword ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                </button>
              </div>
              <div className='flex pt-3 text-sm' style={{ color: '#FF1B1B' }} >
                <PiWarningCircle size={20} className='mr-2' />Password must contains at least 8 characters
              </div>
            </div>
            <div className='mb-2 mt-5 relative'>
              <label htmlFor='password' className='flex items-center gap-2 text-left text-black-700 font-medium'>
                <img src='/assets/svg/lock-green.svg' alt='email' /> Confirm Password
              </label>
              <input
                type={showPassword2 ? 'text' : 'password'}
                id='password'
                name='password'
                required
                className='w-full mt-1 p-2 pl-4 border rounded-xl outline-none'
                autoComplete='current-password'
                placeholder='Confirm Password'
              />
              <div className='absolute top-12 right-3 transform -translate-y-1/2 flex items-center'>
                <button
                  type='button'
                  onClick={togglePasswordVisibility2}
                  className='text-gray-500 focus:outline-none'
                >
                  {showPassword2 ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
                </button>
              </div>
              <div className='flex pt-3 text-sm' style={{ color: '#FF1B1B' }} >
                <PiWarningCircle size={20} className='mr-2' />Password does not match
              </div>
            </div>
          </form>
          <div className='flex justify-center row-start-7 h-14'>
            <button
              className='text-white py-2 shadow-buttonPrimary bg-text-dm-input rounded-xl backdrop-blur-sm w-40'
              onClick={GoToLogin}
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
